<template>
  <v-dialog v-model="dialogLocal" persistent :width="width">
    <!-- Botão de ativação do diálogo -->
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" :outline="outline" :small="small" v-bind="$attrs">
        <v-icon left>{{ icon }}</v-icon>
        <span v-if="!hideLabels" class="pr-2 hidden-xs-only hidden-sm-only">
          {{ startLabel }}
        </span>
        <strong class="pr-2">{{ startDate | moment(dateFormat) }}</strong>
        <span v-if="!hideLabels" class="pr-2 hidden-xs-only hidden-sm-only">
          {{ endLabel }}
        </span>
        <strong>{{ endDate | moment(dateFormat) }}</strong>
      </v-btn>
    </template>

    <!-- Conteúdo do diálogo -->
    <v-card>
      <v-toolbar dense flat color="transparent">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialogLocal = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <!-- Seleção do campo (se houver fields) -->
        <v-select
          v-if="fields.length > 0"
          dense
          outline
          v-model="selectedField"
          :items="fields"
          item-text="text"
          item-value="field"
          label="Campo para filtro"
          class="mb-3"
        ></v-select>

        <!-- Botões de alternância para tipo de intervalo -->
        <div class="text-xs-center mb-3">
          <v-btn-toggle
            v-model="selectedIntervalType"
            mandatory
            class="elevation-0"
          >
            <v-btn
              :value="'custom'"
              flat
              :class="{ 'primary--text': selectedIntervalType === 'custom' }"
            >
              <v-icon left>date_range</v-icon>
              Período
            </v-btn>
            <v-btn
              :value="'month'"
              flat
              :class="{ 'primary--text': selectedIntervalType === 'month' }"
            >
              <v-icon left>calendar_today</v-icon>
              Mês
            </v-btn>
            <v-btn
              :value="'year'"
              flat
              :class="{ 'primary--text': selectedIntervalType === 'year' }"
            >
              <v-icon left>calendar_view_month</v-icon>
              Ano
            </v-btn>
          </v-btn-toggle>
        </div>

        <!-- Divisor com título do tipo selecionado -->
        <v-divider class="mb-3"></v-divider>
        <div class="caption grey--text text--darken-1 mb-3">
          <template v-if="selectedIntervalType === 'custom'">
            Selecione o período específico
          </template>
          <template v-else-if="selectedIntervalType === 'month'">
            Selecione o mês desejado
          </template>
          <template v-else>
            Selecione o ano desejado
          </template>
        </div>

        <!-- Campos de data baseados no tipo selecionado -->
        <v-slide-x-transition>
          <template v-if="selectedIntervalType === 'custom'">
            <div>
              <v-layout row wrap>
                <v-flex xs12 sm6 class="pr-2">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startDateFormatted"
                        :label="startFieldLabel"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        dense
                        outline
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="startDateLocal" 
                      locale="pt-br"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>

                <v-flex xs12 sm6 class="pl-2">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="endDateFormatted"
                        :label="endFieldLabel"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        dense
                        outline
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                      v-model="endDateLocal" 
                      locale="pt-br"
                      @input="endDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </div>
          </template>

          <template v-else-if="selectedIntervalType === 'month'">
            <v-menu
              v-model="monthMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="monthPickerFormatted"
                  label="Selecione o mês"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                  dense
                  outline
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="monthPickerLocal"
                type="month"
                locale="pt-br"
                @input="monthMenu = false"
              ></v-date-picker>
            </v-menu>
          </template>

          <template v-else-if="selectedIntervalType === 'year'">
            <v-menu
              v-model="yearMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="yearPickerFormatted"
                  label="Selecione o ano"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                  dense
                  outline
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="yearPickerLocal"
                type="year"
                locale="pt-br"
                @input="yearMenu = false"
              ></v-date-picker>
            </v-menu>
          </template>
        </v-slide-x-transition>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn block :outline="outline" :color="buttonColor" @click="applyFilter">
          <v-icon>{{ buttonIcon }}</v-icon>
          <span>{{ buttonLabel }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  name: 'WNDateIntervalFilter',

  data() {
    return {
      dialogLocal: false,
      startDateLocal: this.startDate,
      endDateLocal: this.endDate,
      selectedField: this.fields?.[0]?.field || null,
      selectedIntervalType: this.defaultIntervalType,
      monthPickerLocal: null,
      yearPickerLocal: null,
      // Menus
      startDateMenu: false,
      endDateMenu: false,
      monthMenu: false,
      yearMenu: false
    }
  },

  computed: {
    intervalTypes() {
      return [
        { value: 'custom', text: 'Período Específico' },
        { value: 'month', text: 'Mês' },
        { value: 'year', text: 'Ano' }
      ]
    },

    startDateFormatted() {
      return this.startDateLocal ? moment(this.startDateLocal).format('DD/MM/YYYY') : ''
    },

    endDateFormatted() {
      return this.endDateLocal ? moment(this.endDateLocal).format('DD/MM/YYYY') : ''
    },

    monthPickerFormatted() {
      return this.monthPickerLocal ? moment(this.monthPickerLocal).format('MMMM [de] YYYY') : ''
    },

    yearPickerFormatted() {
      return this.yearPickerLocal ? moment(this.yearPickerLocal).format('YYYY') : ''
    }
  },

  methods: {
    applyFilter() {
      const payload = {
        startDate: this.startDateLocal,
        endDate: this.endDateLocal,
        intervalType: this.selectedIntervalType
      }

      if (this.selectedField) {
        payload.field = this.selectedField
      }

      this.$emit('filter', payload)
      this.dialogLocal = false
    },

    handleIntervalTypeChange() {
      switch (this.selectedIntervalType) {
        case 'month':
          if (this.monthPickerLocal) {
            const date = moment(this.monthPickerLocal)
            this.startDateLocal = date.startOf('month').format('YYYY-MM-DD')
            this.endDateLocal = date.endOf('month').format('YYYY-MM-DD')
          }
          break
        case 'year':
          if (this.yearPickerLocal) {
            const date = moment(this.yearPickerLocal)
            this.startDateLocal = date.startOf('year').format('YYYY-MM-DD')
            this.endDateLocal = date.endOf('year').format('YYYY-MM-DD')
          }
          break
      }
    }
  },

  props: {
    // Valores do intervalo de data
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    
    // Customização visual
    width: {
      type: [Number, String],
      default: 400
    },
    outline: {
      type: Boolean,
      default: true
    },
    small: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: 'event'
    },
    buttonIcon: {
      type: String,
      default: 'filter_list'
    },
    buttonColor: {
      type: String,
      default: 'green'
    },
    dateFormat: {
      type: String,
      default: 'DD/MM/YY'
    },
    hideLabels: {
      type: Boolean,
      default: false
    },
    
    // Labels
    title: {
      type: String,
      default: 'Filtro por Período'
    },
    startLabel: {
      type: String,
      default: 'De'
    },
    endLabel: {
      type: String,
      default: 'Até'
    },
    startFieldLabel: {
      type: String,
      default: 'Data Inicial'
    },
    endFieldLabel: {
      type: String,
      default: 'Data Final' 
    },
    buttonLabel: {
      type: String,
      default: 'Filtrar'
    },

    // Novas props
    fields: {
      type: Array,
      default: () => [],
      validator: value => value.every(item => item.field && item.text)
    },
    defaultIntervalType: {
      type: String,
      default: 'custom',
      validator: value => ['custom', 'month', 'year'].includes(value)
    }
  },

  watch: {
    startDate(newValue) {
      this.startDateLocal = newValue
    },
    endDate(newValue) {
      this.endDateLocal = newValue
    },
    monthPickerLocal() {
      if (this.selectedIntervalType === 'month') {
        this.handleIntervalTypeChange()
      }
    },
    yearPickerLocal() {
      if (this.selectedIntervalType === 'year') {
        this.handleIntervalTypeChange()
      }
    }
  },

}
</script> 