var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"talk-channel",class:{ 
    'theme--dark': _vm.$vuetify.theme.dark,
    'talk-channel--removed': _vm.channel.removed_to_campaign 
  }},[_c('div',{staticClass:"talk-channel__container"},[_c('div',{staticClass:"talk-channel__icon-wrapper"},[_c('talk-list-item-channel-icon',{staticClass:"talk-channel__icon",attrs:{"channel-type":_vm.channel.type.code}})],1),_c('div',{staticClass:"talk-channel__name"},[_c('div',{staticClass:"talk-channel__title-wrapper"},[_c('span',{staticClass:"talk-channel__title"},[_vm._v(_vm._s(_vm.channel.name))]),(_vm.channel.removed_to_campaign)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"talk-channel__removed-icon",attrs:{"x-small":""}},on),[_vm._v(" remove_circle_outline ")])]}}],null,false,3193149774)},[_c('span',[_vm._v("Canal removido da campanha")])]):_vm._e()],1)]),_c('div',{staticClass:"talk-channel__metrics"},[_c('div',{staticClass:"talk-channel__stat"},[_c('v-icon',{attrs:{"x-small":"","color":"success"}},[_vm._v("send")]),_c('span',{staticClass:"talk-channel__stat-value"},[_vm._v(_vm._s(_vm.channel.messages_sent))]),_c('span',{staticClass:"talk-channel__stat-label"},[_vm._v("env")])],1),_c('div',{staticClass:"talk-channel__stat"},[_c('v-icon',{attrs:{"x-small":"","color":"info"}},[_vm._v("reply")]),_c('span',{staticClass:"talk-channel__stat-value"},[_vm._v(_vm._s(_vm.channel.messages_received))]),_c('span',{staticClass:"talk-channel__stat-label"},[_vm._v("rec")])],1)]),_c('div',{staticClass:"talk-channel__status",style:({ 
        backgroundColor: _vm.channel.status.color + (_vm.$vuetify.theme.dark ? '20' : '15'),
        color: _vm.channel.status.color
      })},[_c('v-icon',{attrs:{"size":"12"}},[_vm._v(_vm._s(_vm.channel.status.icon))]),_c('span',{staticClass:"talk-channel__status-text"},[_vm._v(_vm._s(_vm.channel.status.name))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }