<template>
  <div class="talk-campaign-talk" @click="$emit('onTalkAction', talk)">
    <div class="talk-campaign-talk__header">
      <div class="talk-campaign-talk__avatar">
        <v-avatar size="40">
          <img 
            v-if="talk.contact.file && talk.contact.file.url"
            :src="talk.contact.file.url"
            :alt="talk.contact.name"
          >
          <img v-else-if="talk.contact.type == 2" src="static/icons/user_icon_1.png" alt=""/>
          <img v-else src="static/icons/user_icon_1.png" alt="">
        </v-avatar>
      </div>

      <div class="talk-campaign-talk__content">
        <div class="talk-campaign-talk__contact-info">
          <div class="talk-campaign-talk__name-container">
            <h3 class="talk-campaign-talk__contact-name">
              {{ talk.contact.name || 'Sem nome' }}
            </h3>
            <v-tooltip v-if="talk.contact.checked == 2" bottom lazy>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="green" small class="talk-campaign-talk__check-icon">
                  verified
                </v-icon>
              </template>
              <span>Contato validado</span>
            </v-tooltip>
            <v-tooltip v-if="talk.contact.checked == 3" bottom lazy>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="red" small class="talk-campaign-talk__check-icon">
                  do_not_disturb_on
                </v-icon>
              </template>
              <span>Contato inválido</span>
            </v-tooltip>
          </div>
          <span class="talk-campaign-talk__time">{{ formatDate(talk.created_at) }}</span>
        </div>

        <div class="talk-campaign-talk__details">
          <div class="talk-campaign-talk__phone">
            {{ formatPhone(talk.contact.number) }}
          </div>
          <div 
            class="talk-campaign-talk__tag"
            :style="{ 
              backgroundColor: getTagColor + ($wiDark ? '40' : '30'),
              color: getTagColor,
              filter: $wiDark ? 'brightness(1)' : 'brightness(0.7)'
            }"
          >
            <v-icon v-if="talk.channel.status.icon" size="14" :color="getTagColor">
              {{ talk.channel.status.icon }}
            </v-icon>
            <span>{{ talk.channel.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TalkCampaignTalk',
  props: {
    talk: {
      type: Object,
      required: true
    }
  },
  computed: {
    $wiDark() {
      return this.$vuetify.theme.dark
    },
    getTagColor() {
      return this.talk.channel.color || '#1976D2'
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      const d = new Date(date)
      return d.toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      })
    },
    formatPhone(phone) {
      if (!phone) return ''
      const phoneStr = String(phone)
      if (phoneStr.length === 12) {
        return phoneStr.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4')
      } else if (phoneStr.length === 13) {
        return phoneStr.replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, '+$1 ($2) $3 $4-$5')
      }
      return phoneStr
    }
  }
}
</script>

<style scoped>
.talk-campaign-talk {
  background-color: #f8fafc;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.theme--dark .talk-campaign-talk {
  background-color: #1E1E1E;
  border-color: rgba(255, 255, 255, 0.05);
}

.talk-campaign-talk:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.theme--dark .talk-campaign-talk:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.talk-campaign-talk__header {
  display: flex;
  gap: 12px;
  align-items: flex-start;
}

.talk-campaign-talk__avatar {
  position: relative;
  flex-shrink: 0;
}

.talk-campaign-talk__content {
  flex: 1;
  min-width: 0;
}

.talk-campaign-talk__contact-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 4px;
}

.talk-campaign-talk__name-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.talk-campaign-talk__contact-name {
  font-size: 16px;
  font-weight: 600;
  color: #334155;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.theme--dark .talk-campaign-talk__contact-name {
  color: #E0E0E0;
}

.talk-campaign-talk__check-icon {
  margin-top: 2px;
}

.talk-campaign-talk__time {
  font-size: 12px;
  color: #64748b;
  white-space: nowrap;
}

.theme--dark .talk-campaign-talk__time {
  color: #9E9E9E;
}

.talk-campaign-talk__details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.talk-campaign-talk__phone {
  font-family: 'Roboto Mono', monospace;
  font-size: 13px;
  color: #64748b;
}

.theme--dark .talk-campaign-talk__phone {
  color: #9E9E9E;
}

.talk-campaign-talk__tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px !important;
}
</style> 