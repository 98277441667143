export default [
  {
    value: 'id',
    text: 'ID',
    type: 'int',
    list: true,
    edit: false,
    search: true
  },
  {
    type: 'subheader',
    text: 'Informe o nome da categoria.',
    list: false,
    edit: true
  },
  {
    value: 'name',
    text: 'Nome da Categoria',
    type: 'text',
    required: true,

    list: true,
    edit: true,
    search: true
  },
  {
    type: 'subheader',
    text: '(Opcional) Informe a cor e ícone.',
    list: false,
    edit: true
  },
  {
    value: 'color',
    text: 'Cor da Categoria',
    type: 'color',
    required: false,
    list: false,
    edit: true,
    size: ['xs8']
  },
  {
    value: 'active',
    text: 'Ativo',
    type: 'switch',
    required: false,
    defaultValue: true,
    list: false,
    edit: true,
    size: ['xs4']
  },
  {
    value: 'icon',
    text: 'Ícone da Categoria',
    type: 'wi-google-icons',
    required: false,
    list: false,
    edit: true
  },
  {
    type: 'subheader',
    text: 'Observações adicionais.',
    list: false,
    edit: true
  },
  {
    value: 'description',
    text: 'Observações',
    type: 'textarea',
    required: false,
    list: false,

    edit: true
  }
]
