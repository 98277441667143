<template>
  <div>
    <div
      v-if="message"
      class="talk-message__message"
      :class="{
        'talk-message__message--from-me': message.from_me,
        'talk-message__message--talk-old': talkIsOld
      }"
    >
      <div
        class="talk-message__message-avatar"
        :style="{
          float: message.from_me ? 'right' : 'left'
        }"
      >
        <v-avatar v-if="message.from_me" class="elevation-10">
          <img
            v-if="message.person && message.person.file && message.person.file.url"
            :src="message.person.file.url"
          />
          <img v-else-if="message.talk_campaign_id" src="static/icons/campaign_profile_icon_2.png" />
          <img v-else-if="message.talk_menu_id" src="static/icons/chatbot_profile_icon_1.png" />
          <img v-else src="static/icons/user_icon_1.png" />
        </v-avatar>
        <v-avatar v-else class="elevation-10">
          <img
            v-if="message.contact && message.contact.file && message.contact.file.url"
            :src="message.contact.file.url"
          />
          <img v-else src="static/icons/user_icon_1.png" />
        </v-avatar>
      </div>
      <div 
        class="talk-message__message-balloon elevation-10" 
        :class="{
          'talk-message__message-balloon--from-me': message.from_me,
          'talk-message__message-balloon--old-talk': !message.from_me && talkIsOld,
          'talk-message__message-balloon--from-me--old-talk': message.from_me && talkIsOld,
          'talk-message__message-balloon--deleted': parseInt(message.ack) == 5,
          'talk-message__message-balloon--info': parseInt(message.type) == 11,
          'talk-message__message-balloon--change-of-operator': parseInt(message.type) == 15,
          'talk-message__message-balloon--note-internal': parseInt(message.type) == 17,
          'talk-message__message-balloon--change-status': parseInt(message.type) == 18,
          'talk-message__message-balloon--edited': parseInt(message.type) == 19,
          'talk-message__message-balloon--reaction': parseInt(message.type) == 20,
        }"
      >
        <div
          class="talk-message__message-balloon-contact"
          :class="{
            'talk-message__message-balloon-contact--from-me': message.from_me
          }"
          style="display: flex; justify-content: space-between;"
        >
          <label>
            <span v-if="[15, 18].includes(parseInt(message.type))" class="black--text hidden-xs-only hidden-sm-only">
              <v-icon small color="black">info</v-icon>
              <span class="ml-1">Informação</span>
            </span>
            <span v-if="parseInt(message.type) == 17" class="black--text hidden-xs-only hidden-sm-only">
              <v-icon small color="black">sticky_note_2</v-icon>
              <span class="ml-1">Nota interna</span>
            </span>
            <span v-if="[19].includes(parseInt(message.type))" class="hidden-xs-only hidden-sm-only">
              <v-icon small>edit</v-icon>
              <span class="ml-1">Mensagem editada</span>
            </span>
            <span v-if="[20].includes(parseInt(message.type))" class="hidden-xs-only hidden-sm-only">
              <v-icon small>mood</v-icon>
              <span class="ml-1">Reação</span>
            </span>
          </label>
          <label>
            <label v-if="message.from_me">
              <label
                class="black--text"
                v-if="message.person && message.person.name"
                v-text="message.person.name"
              ></label>
              <label
                v-else-if="message.campaign && message.campaign.name"
                style="color: white; border: solid 1px grey; padding: 2px 5px; border-radius: 5px; cursor: pointer;"
                :style="{ backgroundColor: message.campaign.status.color }"
                @click="$router.push({
                  name: 'talk-list',
                  params: { page: 'talk-campaign' },
                  query: { where: `id,${message.campaign.id}` }
                })"
              >
                <v-icon small color="white">{{ message.campaign.status.icon }}</v-icon>
                <span v-text="message.campaign.name" class="pl-1"></span>
              </label>
              <label
                v-else-if="message.talk_menu_id"
                style="color: white; text-align: center; padding: 2px 5px; border-radius: 5px; cursor: pointer;"
                :style="{ backgroundColor: 'rgb(1, 153, 190)' }"
                @click="$router.push({
                  name: 'talk-list',
                  params: { page: 'talk-menu' },
                  query: { where: `id,${message.talk_menu_id}` }
                })"
              >
                <v-icon small color="white">smart_toy</v-icon>
                <span class="pl-1" v-if="message.menu">{{ message.menu.name }}</span>
                <span class="pl-1" v-else>Chatbot</span>
              </label>
            </label>
            <span
              v-else @click="searchTalkContact(message.contact)"
              :style="{ cursor: (talk.contact && talk.contact.type == 2) ? 'pointer' : '' }"
            >
              <span
                class="black--text"
                v-if="message.contact && message.contact.name"
                v-text="message.contact.name"
              ></span>
            </span>
            <label>
              <v-menu v-if="![15, 18, 19, 20].includes(parseInt(message.type))" top transition="slide-y-transition" lazy>
                <template v-slot:activator="{ on }">
                  <v-icon small v-on="on" style="color: black; cursor: pointer; padding: 0 0 0 5px;">
                    expand_more
                  </v-icon>
                </template>
                <v-list>
                  <v-list-tile v-if="false && !message.from_me" @click="sendMessageToScreen(message)">
                    <v-list-tile-avatar>
                      <v-icon>connected_tv</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>
                      Enviar para a tela
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile @click="shareMessage(message)">
                    <v-list-tile-avatar>
                      <v-icon>share</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>
                      Encaminhar Mensagem
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile v-if="parseInt(message.ack) < 5" @click="replyMessage(message)">
                    <v-list-tile-avatar>
                      <v-icon>reply</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>
                      Responder
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile v-if="parseInt(message.type) == 4 || parseInt(message.type) == 8 && message.from_me" @click="transcribeAudioMessage()" :loading="loadingAudio">
                    <v-list-tile-avatar>
                      <v-icon>volume_up</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>
                      Áudio para texto
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="!message.text_translation && talk.contact && (talk.contact.locale || (talk.contact.person && talk.contact.person.locale))"
                    @click="translateMessage(message)"
                  >
                    <v-list-tile-avatar>
                      <v-icon color="blue">translate</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>
                      Traduzir
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile
                    v-if="(
                      message.from_me &&
                      message.external_code &&
                      !talkIsOld &&
                      messageTimeToDeleteIsValid() &&
                      parseInt(message.ack) < 5
                    )"
                    @click="deleteMessage(message)"
                  >
                    <v-list-tile-avatar>
                      <v-icon>delete</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-title>
                      Apagar Mensagem
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </label>
          </label>
        </div>
        <div
          v-if="message.shared"
          class="talk-message__message-balloon-shared"
          :class="{
            'talk-message__message-balloon-contact--from-me': message.from_me
          }"
        >
          <v-icon small color="gray">reply</v-icon>
          <label>
            Encaminhada...
          </label>
        </div>
        <div class="talk-message__message-balloon-text">
          <div
            v-if="message.message"
            class="talk-message__message-balloon-text-reply"
            @click="showReplyMessage(message.message)"
          >
            <div
              class="talk-message__message-balloon-contact"
              :class="{
                'talk-message__message-balloon-contact--from-me': !message.from_me
              }"
            >
              <div v-if="message.message.from_me">
                <label
                  class="black--text"
                  v-if="message.message.person && message.message.person.name"
                  v-text="message.message.person.name"
                ></label>
              </div>
              <div v-else>
                <label
                  class="black--text"
                  v-if="message.message.contact && message.message.contact.name"
                  v-text="message.message.contact.name"
                ></label>
              </div>
            </div>
            <span v-if="message.message.type == 12">
              <v-icon>list</v-icon>
              <span>
                Lista de opções
              </span>
            </span>
            <span v-else v-html="limitMessageReply(message.message.text_html)"></span>
            <div class="talk-message__message-balloon-info">
              <label
                v-if="message.message.external_time_formated"
                v-text="message.message.external_time_formated"
              ></label>
              <label
                v-else
                v-text="message.message.created_at_formated || 'Agora'"
              ></label>
              <label v-if="message.message.from_me">
                <v-icon v-if="parseInt(message.message.ack) == -2" small color="red">manage_accounts</v-icon>
                <v-icon v-if="parseInt(message.message.ack) == -1" small color="red">close</v-icon>
                <v-icon v-if="parseInt(message.message.ack) == 0" small>query_builder</v-icon>
                <v-icon v-if="parseInt(message.message.ack) == 1" small>done</v-icon>
                <v-icon v-if="parseInt(message.message.ack) == 2" small>done_all</v-icon>
                <v-icon v-if="parseInt(message.message.ack) == 3" small color="blue">done_all</v-icon>
              </label>
            </div>
          </div>
          <label>
            <span v-if="parseInt(message.type) == 1">
              <!-- <div v-if="checkIfTextIsYouTubeVideoUrl(message.text)">
                <iframe
                  :src="getYouTubeVideoEmbendUrl(message.text)"
                  frameborder="0"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <br>
                <span v-text="message.text"></span>
              </div> -->
              <!-- <div v-else-if="checkIfTextIsUrl(message.text)">
                <a target="_blank" :href="message.text">
                  <span v-text="message.text"></span>
                </a>
              </div> -->
              <span>
                <span v-html="message.text_html"></span>
                <span v-if="message.text_translation" style="margin: 10px 0;">
                  <br/>
                  <br/>
                  <v-divider></v-divider>
                  <span style="font-size: 12px; color: grey;">Tradução:</span>
                  <br/>
                  <span v-text="message.text_translation"></span>
                </span>
              </span>
            </span>
            <span v-else-if="parseInt(message.type) == 19">
              <span>
                <span v-html="message.text_html"></span>
                <span v-if="message.text_translation" style="margin: 10px 0;">
                  <br/>
                  <br/>
                  <v-divider></v-divider>
                  <span style="font-size: 12px; color: grey;">Tradução:</span>
                  <br/>
                  <span v-text="message.text_translation"></span>
                </span>
              </span>
            </span>
            <span v-else-if="parseInt(message.type) == 20">
              <span>
                <span v-html="message.text_html"></span>
              </span>
            </span>
            <span v-else-if="parseInt(message.type) == 17">
              <span>
                <span v-html="message.text_html"></span>
              </span>
            </span>
            <span v-else-if="parseInt(message.type) == 2 || (message.file && message.file.type_name == 'image/png')">
              <div v-if="message.file && message.file.url">
                <div style="max-width: 250px;">
                  <WiImageDialog
                    :src="message.file.url"
                    :download="message.file.url_download"
                    :title="`Imagem de ${message.contact.name || '- - -'}`"
                    :aspect-ratio="1"
                  ></WiImageDialog>
                </div>
                <div>
                  <span v-if="message.caption" v-html="message.caption"></span>
                  <span v-else-if="message.text" v-html="message.text_html"></span>
                </div>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 3">
              <div v-if="message.file && message.file.url">
                <div>
                  <video
                    controls="controls"
                    controlsList="nodownload"
                    style="max-height: 300px; width: 100%;"
                  >
                    <source :src="message.file.url" type="video/mp4" />
                    <!--Suportado em IE9, Chrome 6 e Safari 5 --> 
                    O seu navegador não suporta a tag vídeo
                  </video>
                </div>
                <div>
                  <span v-text="message.caption"></span>
                </div>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 5">
              <div v-if="message.file && message.file.url">
                <div @click="openFile(message.file)" style="cursor: pointer">
                  <img
                    v-if="message.file.extension == 'pdf'"
                    src="/static/icons/file_types/pdf_96.png"
                    style="max-height: 80px;"
                  />
                  <img
                    v-else-if="message.file.extension == 'csv'"
                    src="/static/icons/file_types/csv_96.png"
                    style="max-height: 80px;"
                  />
                  <img
                    v-else-if="message.file.extension == 'xls'"
                    src="/static/icons/file_types/xls_96.png"
                    style="max-height: 80px;"
                  />
                  <img
                    v-else-if="message.file.extension == 'xlsx'"
                    src="/static/icons/file_types/xls_96.png"
                    style="max-height: 80px;"
                  />
                  <img
                    v-else-if="message.file.extension == 'mp4'"
                    src="/static/icons/file_types/video_96.png"
                    style="max-height: 80px;"
                  />
                  <img
                    v-else
                    src="/static/icons/file_types/arquivo_96.png"
                    style="max-height: 80px;"
                  />
                </div>
                <div>
                  <span v-text="message.caption || message.file.name"></span>
                </div>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 4 || parseInt(message.type) == 8">
              <div v-if="message.file && message.file.url">
                <div style="border: solid 1px rgba(0,0,0,.12); border-radius: 5px; padding-top: 10px;">
                  <span>
                    <div style="text-align: center; margin-bottom: 5px;">
                      <!-- <audio controls style="width: 100%;">
                        <source :src="message.file.url" type="audio/ogg">
                        Your browser does not support the audio element.
                      </audio> -->
                      <WNAudioPlayer
                        :src="message.file.url"
                        :download="message.file.url_download"
                      ></WNAudioPlayer>
                    </div>
                    <div v-if="message.caption">
                      <span v-text="message.caption"></span>
                    </div>
                    <div v-if="message.text && message.text != '🎤 Áudio'" style="padding: 0 10px 10px 10px;">
                      <span v-html="message.text_html"></span>
                      <span v-if="message.text_translation" style="margin: 10px 0;">
                        <br/>
                        <br/>
                        <v-divider></v-divider>
                        <span style="font-size: 12px; color: grey;">Tradução:</span>
                        <br/>
                        <span v-text="message.text_translation"></span>
                      </span>
                    </div>
                    <div v-else-if="!message.from_me">
                      <v-divider></v-divider>
                      <v-btn
                        flat
                        block
                        color="primary"
                        :loading="loadingAudio"
                        class="ml-0 mb-0 mt-0"
                        @click="transcribeAudioMessage()"
                      >
                        <v-icon>volume_up</v-icon>
                        <span> Áudio para texto</span>
                      </v-btn>
                    </div>
                  </span>
                </div>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 6">
              <div v-if="message.vcard">
                <!-- <span v-html="vcardTest(message.vcard)"></span> -->
                <WiVCard :vcard="message.vcard"/>
                <p>
                  <span v-text="message.caption"></span>
                </p>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 10">
              <div v-if="message.vcard && message.vcard.length > 0">
                <WiVCard
                  v-for="(vcard, vcardKey) in message.vcard"
                  :key="vcardKey"
                  :vcard="vcard.vcard"
                />
                <p>
                  <span v-text="message.caption"></span>
                </p>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 9">
              <div v-if="message.file && message.file.url">
                <div>
                  <img
                    @click="openFile(message.file)"
                    :src="message.file.url"
                    style="max-width: 80px; cursor: pointer;"
                  />
                </div>
                <div>
                  <span v-text="message.caption"></span>
                </div>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 11">
              <div>
                <div v-if="message.text">
                  <span v-html="formatMessageInfo(message.text)"></span>
                </div>
                <div v-else>
                  Informação não definida...
                </div>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 15">
              <div>
                <div
                  v-if="message.text"
                  :style="{ marginTop: message.person_id ? '0' : '12px'}"
                  style="display: flex; align-items: center;"
                >
                  <v-icon class="mr-1">manage_accounts</v-icon>
                  <span>
                    Atendimento atribuído a <strong>{{ message.text }}</strong>
                  </span>
                </div>
                <div v-else>
                  Atribuido a outro operador...
                </div>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 18">
              <div>
                <div
                  v-if="message.text"
                  :style="{ marginTop: message.person_id ? '0' : '12px'}"
                  style="display: flex; align-items: center;"
                >
                  <span v-if="message.text == 1" style="display: flex; align-items: center;">
                    <v-icon class="mr-1" color="blue">smart_toy</v-icon>
                    <span> Atendimento atribuído a um <strong>Chatbot</strong></span>
                  </span>
                  <span v-if="message.text == 2" style="display: flex; align-items: center;">
                    <v-icon class="mr-1" color="orange">pending</v-icon>
                    <span> <strong>Aguardando atendimento</strong></span>
                  </span>
                  <span v-if="message.text == 3" style="display: flex; align-items: center;">
                    <v-icon class="mr-1" color="green">check</v-icon>
                    <span> Atendimento <strong>Concluído</strong></span>
                  </span>
                  <span v-if="message.text == 4" style="display: flex; align-items: center;">
                    <v-icon class="mr-1" color="red">close</v-icon>
                    <span> Atendimento <strong>Cancelado</strong></span>
                  </span>
                </div>
                <div v-else>
                  Atribuido a outro operador...
                </div>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 7">
              <div>
                <div v-if="message.latitude && message.longitude">
                  <a
                    :href="`https://www.google.com/maps?q=${message.latitude},${message.longitude}`"
                    target="_blank"
                  >
                    <strong>
                      Latitude: 
                    </strong>
                    <span>
                      {{ message.latitude }}
                    </span>
                    <br>
                    <strong>
                      Longitude: 
                    </strong>
                    <span>
                      {{ message.longitude }}
                    </span>
                  </a>
                </div>
                <div v-else>
                  Localização incompleta...
                </div>
              </div>
            </span>
            <span v-else-if="parseInt(message.type) == 12">
              <div
                v-if="getListOptionsData()"
                style="border: solid 1px rgba(0,0,0,.12); border-radius: 5px; padding-top: 10px;"
              >
                <span>
                  <div
                    v-if="getListOptionsData().title"
                    style="padding: 10px; margin-bottom: 20px;"
                  >
                    {{ getListOptionsData().title }}
                  </div>
                  <strong
                    v-if="getListOptionsData().description"
                    style="padding: 10px;"
                  >
                    {{ getListOptionsData().description }}
                  </strong>
                  <br>
                  <br>
                  <v-divider></v-divider>
                  <v-dialog v-model="listOptionsDialog" max-width="500" lazy>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" flat block color="primary" class="ml-0 mb-0 mt-0">
                        <v-icon>list</v-icon>
                        <span class="ml-1">
                          {{ getListOptionsData().buttonText || 'Clique para selecionar' }}
                        </span>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-toolbar dense flat>
                        <v-toolbar-title>
                          {{ getListOptionsData().buttonText || 'Opções' }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="listOptionsDialog = false">
                          <v-icon>close</v-icon>
                        </v-btn>
                      </v-toolbar>
                      <v-card-text>
                        <v-card
                          flat
                          outline
                          v-for="(section, sectionKey) in getListOptionsData().sections" 
                          :key="sectionKey" 
                          no-action
                          style="padding-bottom: 10px;"
                        >
                          <v-card-title>
                            <h3>{{ section.title }}</h3>
                          </v-card-title>
                          <v-divider></v-divider>
                          <v-list style="margin: 0; padding: 0;">
                            <template v-for="(row, rowKey) in section.rows">
                              <v-list-tile :key="rowKey" @click="listOptionSelect(row.title)">
                                <v-list-tile-avatar>
                                  <v-radio></v-radio>
                                </v-list-tile-avatar>
                                <v-list-tile-content>
                                  <v-list-tile-title>
                                    {{ row.title }}
                                  </v-list-tile-title>
                                  <v-list-tile-sub-title>
                                    {{ row.description }}
                                  </v-list-tile-sub-title>
                                </v-list-tile-content>
                              </v-list-tile>
                              <v-divider
                                :key="`divider--${rowKey}`"
                              ></v-divider>
                            </template>
                          </v-list>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </span>
              </div>
              <span v-else>
                Lista de opções não definida...
              </span>
              <span>
                {{ message.text.description }}
              </span>
            </span>
            <span v-else-if="parseInt(message.type) == 14">
              <div 
                v-if="getTemplateData()"
                class="template-container"
              >
                <!-- Header com nome da template -->
                <div class="template-header">
                  <v-icon small class="mr-2">description</v-icon>
                  Template: {{ getTemplateData().name }}
                </div>

                <!-- Corpo da mensagem -->
                <div class="template-body">
                  <template v-for="(component, index) in getTemplateData().components">
                    <!-- Texto principal -->
                    <div 
                      v-if="component.type === 'BODY'"
                      :key="`body-${index}`"
                      class="template-text"
                      v-html="formatTemplateText(component.text)"
                    ></div>

                    <!-- Botões -->
                    <div 
                      v-if="component.type === 'BUTTONS' && component.buttons"
                      :key="`buttons-${index}`"
                      class="template-buttons"
                    >
                      <v-btn
                        v-for="(button, btnIndex) in component.buttons"
                        :key="`btn-${btnIndex}`"
                        outlined
                        rounded
                        class="ma-1"
                        @click="handleTemplateButton(button.text)"
                      >
                        {{ button.text }}
                      </v-btn>
                    </div>

                    <!-- Rodapé -->
                    <div 
                      v-if="component.type === 'FOOTER'"
                      :key="`footer-${index}`"
                      class="template-footer"
                    >
                      {{ component.text }}
                    </div>
                  </template>
                </div>
              </div>
            </span>
          </label>
        </div>
        <div class="talk-message__message-balloon-info">
          <!-- <span style="font-size: 20px;">🤑</span> -->
          <label v-if="parseInt(message.ack) == 5"> 
             Mensagem apagada - 
          </label>
          <v-tooltip bottom lazy>
            <template slot="activator">
              <label>
                {{ getExternalTimeFormated() }}
              </label>
            </template>
            <span>
              {{ message.external_time | moment('DD/MM/YYYY HH:mm') }}
            </span>
          </v-tooltip>
          <label v-if="message.from_me" style="padding-left: 5px;">
            <v-icon v-if="parseInt(message.ack) == -4" small color="red">phonelink_erase</v-icon>
            <v-icon v-if="parseInt(message.ack) == -3" small color="red">block</v-icon>
            <v-icon v-if="parseInt(message.ack) == -2" small color="red">manage_accounts</v-icon>
            <v-icon v-if="parseInt(message.ack) == -1" small color="red">close</v-icon>
            <v-icon v-if="parseInt(message.ack) == 0" small>query_builder</v-icon>
            <v-icon v-if="parseInt(message.ack) == 1" small>done</v-icon>
            <v-icon v-if="parseInt(message.ack) == 2" small>done_all</v-icon>
            <v-icon v-if="parseInt(message.ack) == 3" small color="blue">done_all</v-icon>
            <v-icon v-if="parseInt(message.ack) == 4" small color="blue">mic</v-icon>
            <v-icon v-if="parseInt(message.ack) == 5" small color="#d20e00">delete</v-icon>
            <v-icon v-if="parseInt(message.ack) == 7" small color="orange">timer</v-icon>
            <v-icon v-if="parseInt(message.ack) == 8" small color="blue">info</v-icon>
            <v-icon v-if="parseInt(message.ack) == 9" small color="orange">sticky_note_2</v-icon>
          </label>
        </div>
        <div v-if="message.from_me && parseInt(message.ack) < 0" class="talk-message__message-balloon-info-error">
          <span>
            Mensagem não enviada - 
          </span>
          <v-btn
            small outline color="red"
            @click="resendMessage(message)"
          >
            <v-icon small>replay</v-icon>
            <span class="ml-1"> Reenviar</span>
          </v-btn>
        </div>
      </div>
    </div>   
  </div>      
</template>
<script>
  import { mapMutations } from 'vuex'
  import talkViewMixin from '../talkViewMixin'
  import WiVCard from '@/default/component/WiVCard/WiVCard'
  import WNAudioPlayer from '@/default/component/WNAudioRecorder/WNAudioPlayer.vue'
  export default {
    name: 'TalkMessage',
    data () {
      return {
        listOptionsDialog: false,
        loadingAudio: false
      }
    },
    computed: {
      talkIsOld () {
        return this.message.talk_id != this.talk.id
      }
    },
    methods: {
      ...mapMutations('Talk', ['SET_MESSAGE_TEXT']),
      vcardTest: function (vcard) {
        return vcard.replace(/\n/g, '<br>')
      },
      limitMessageReply: function (messageText) {
        return this.$options.filters.stringlength(messageText, 180)
      },
      openFile: function (file) {
        window.open(file.url)
      },
      checkIfTextIsYouTubeVideoUrl: function (url) {
        var isYouTubeUrl = false
        if (url !== undefined || url !== '') {
          // eslint-disable-next-line
          url = url + ''
          // eslint-disable-next-line
          var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/
          var match = url.match(regExp)
          if (match && match[2].length === 11) {
            isYouTubeUrl = true
          }
        }
        return isYouTubeUrl
      },
      getYouTubeVideoEmbendUrl: function (url) {
        if (url) {
          const baseYouTubeUrl = 'https://www.youtube.com/embed/'
          if (url.split('v=').length > 1) {
            return baseYouTubeUrl + url.split('v=')[1]
          } else {
            return baseYouTubeUrl + url.split('/')[url.split('/').length - 1]
          }
        }
        return url
      },
      checkIfTextIsUrl: function (str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' +
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
          '((\\d{1,3}\\.){3}\\d{1,3}))' +
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
          '(\\?[;&a-z\\d%_.~+=-]*)?' +
          '(\\#[-a-z\\d_]*)?$', 'i')
        return !!pattern.test(str)
      },
      replyMessage: function (message) {
        this.$store.commit('Talk/SET_REPLY_MESSAGE', message)
      },
      showReplyMessage: function (message) {
        this.$emit('showReply', message)
      },
      shareMessage: function (message) {
        this.$emit('share', message)
      },
      resendMessage: function (message) {
        this.$emit('resendMessage', message)
      },
      sendMessageToScreen: function (message) {
        console.log('selection', message)
        this.$WiApiPost({
          uri: 'talks/message/selection',
          data: {
            talk_message_id: message.id
          },
          config: {
            msgAsk: false,
            msgSucess: true,
            msgError: true
          }
        })
      },
      messageTimeToDeleteIsValid: function () {
        let date1 = new Date(this.message.external_time);
        let date2 = new Date();
        let timeDiff = Math.abs(date2.getTime() - date1.getTime());
        let minutes = Math.ceil(timeDiff / (60000));
        return minutes < 58
      },
      deleteMessage: function () {
        this.$WiApiPut({
          uri: 'talks/message/delete',
          id: this.message.id,
          config: {
            msgAsk: true,
            msgSucess: true,
            msgError: true
          }
        })
      },
      getExternalTimeFormated: function () {
        if (this.message.external_time) {
          const date = this.$moment(this.message.external_time)
          const today = this.$moment().startOf('day')
          const days = Math.round(this.$moment.duration(today - date).asDays())
          if (days > 1) {
            return this.$moment(this.message.external_time).format('DD/MM/YY HH:mm')
          }
          return this.$moment(this.message.external_time).fromNow()
        }
        return 'Agora'
      },
      updateView: function () {
        setInterval(() => {
          this.$forceUpdate()
        }, 78000)
      },
      formatMessageInfo: function (info) {
        try {
          info = JSON?.parse(info)
        } catch (error) {
          info = {}
        }
        const participant = info?.participant ? (info.participant.toString().length == 12 ? this.$options.filters.mask(info.participant, '+## (##) ####-####') : this.$options.filters.mask(info.participant, '+## (##) # ####-####') ) : 'Usuário indefinido'
        switch (info.info_code) {
            case 'group/promote':
                return '👑  Promoveu <strong style="color: white; padding: 3px 7px; border-radius: 5px; margin: 0 5px; background: orange;">' + participant + '</strong> a admin do grupo.';
            case 'group/demote':
                return '☑️ Removeu <strong style="color: white; padding: 3px 7px; border-radius: 5px; margin: 0 5px; background: #0078D7;">' + participant + '</strong> da lista de admins do grupo.';
            case 'group/add':
                return '✅ Adicionou <strong style="color: white; padding: 3px 7px; border-radius: 5px; margin: 0 5px; background: green;">' + participant + '</strong> ao grupo.';
            case 'group/remove':
                return '⛔ Removeu <strong style="color: white; padding: 3px 7px; border-radius: 5px; margin: 0 5px; background: red;">' + participant + '</strong> do grupo.';
            case 'group/invite':
                return '👍 Convidou <strong style="color: white; padding: 3px 7px; border-radius: 5px; margin: 0 5px; background: #E9AA1E;">' + participant + '</strong> para o grupo.';
            case 'call/miss':
                return '📞 <strong class="elevation-5" style="color: black; padding: 3px 7px; border-radius: 5px; margin: 0 5px; background: white;">Chamada recebida...</strong>';
            default:
                return 'Informação.';
        }
      },
      getListOptionsData: function () {
        let text = this.message.text
        try {
          text = JSON?.parse(text)
        } catch (error) {
          text = null
        }
        return text
      },
      listOptionSelect: function (option) {
        this.SET_MESSAGE_TEXT(option)
        this.listOptionsDialog = false
      },
      transcribeAudioMessage: function () {
        this.loadingAudio = true
        this.$WiApiPost({
          uri: 'talks/message/transcribe',
          data: {
            talk_message_id: this.message.id
          },
          config: {
            msgAsk: false,
            msgSucess: true,
            msgError: true
          },
          callback: () => {
            this.loadingAudio = false
          }
        })
      },
      searchTalkContact: function (contact) {
        if (this.talk.type == 2) {
          this.$swal({
            title: `Iniciar conversa com ${contact.name}?`,
            text: 'Ao clicar em "Sim, iniciar!", você será redirecionado para a conversa com este contato.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, iniciar!',
            cancelButtonText: 'Cancelar'
          }).then((response) => {
            if (response.value) {
              this.$WiApiGet({
                uri: `talks/contact/${contact.id}`,
                params: {
                  with: 'opened_talks:id,talk_contact_id'
                },
                config: {
                  msgAsk: false,
                  msgSucess: false,
                  msgError: false,
                  msgLoad: true
                },
                callback: ({ success }) => {
                  if (success) {
                    this.openTalkContact(success)
                  }
                }
              })
            }
          })
        }
      },
      translateMessage: function (message) {
        this.$WiApiPost({
          uri: 'talks/message/translate',
          data: {
            talk_message_id: message.id
          },
          config: {
            msgAsk: false,
            msgLoad: true,
            msgSucess: true,
            msgError: true
          }
        })
      },
      getTemplateData() {
        try {
          return JSON.parse(this.message.text)
        } catch (error) {
          return null
        }
      },
      formatTemplateText(text) {
        if (!text) return ''
        return text
          .replace(/\n/g, '<br>')
          .replace(/\*(.*?)\*/g, '<strong>$1</strong>')
          .replace(/_(.*?)_/g, '<em>$1</em>')
      },
      handleTemplateButton(text) {
        this.SET_MESSAGE_TEXT(text)
      }
    },
    mounted: function () {
      this.updateView()
    },
    props: ['talk', 'message'],
    components: {
      WiVCard,
      WNAudioPlayer
    },
    mixins: [talkViewMixin]
  }
</script>

<style scoped>
  .talk-message__message--talk-old {

  }
  .talk-message__message {
    width: 100%;
    margin: 30px 5px;
  }
  .talk-message__message--from-me {
    text-align: left;
  }
  .talk-message__message-balloon {
    margin: 0px 60px;
    min-height: 50px;
    background: #FFF;
    border-radius: 0px 15px 15px 15px;
  }
  .talk-message__message-balloon--old-talk {
    background-image: linear-gradient(45deg, #e8e8e8 5.56%, #ffffff 5.56%, #ffffff 50%, #e8e8e8 50%, #e8e8e8 55.56%, #ffffff 55.56%, #ffffff 100%) !important;
    background-size: 12.73px 12.73px !important;
  }
  .talk-message__message-balloon--from-me {
    background: #D4FCC8 !important;
    border-radius: 15px 0px 15px 15px;
  }
  .talk-message__message-balloon--from-me--old-talk {
    background-image: linear-gradient(45deg, #cccccc 5.56%, #d4fcc8 5.56%, #d4fcc8 50%, #cccccc 50%, #cccccc 55.56%, #d4fcc8 55.56%, #d4fcc8 100%) !important;
    background-size: 12.73px 12.73px !important;
  }
  .talk-message__message-balloon-text {
    padding: 5px 10px;
    color: #000;
    flex: none;
    font-size: 14.2px;
    line-height: 19px;
    word-break: break-all;
  }
  .talk-message__message-balloon-info {
    width: 100% !important;
    text-align: right;
    font-size: 12px;
    padding: 0px 10px;
    color: gray;
  }
  .talk-message__message-balloon-info-error {
    width: 100% !important;
    text-align: right;
    font-size: 12px;
    padding: 0px 10px;
    color: gray;
    border-top: 1px solid #dadada;
  }
  .talk-message__message-avatar img {
    /* max-width: 25% !important; */
    background: #FFF;
  }
  .talk-message__message-balloon-contact {
    width: 100% !important;
    font-weight: bold;
    padding: 3px 10px;
    font-size: 12px;
  }
  .talk-message__message-balloon-contact--from-me {
    text-align: right;
  }
  .talk-message__message-balloon-shared {
    width: 100% !important;
    padding: 0px 10px;
    font-size: 12px;
    color: gray;
  }
  .talk-message__message-balloon-text-reply {
    width: 100%;
    background: #c9c9c977;
    border-radius: 5px;
    padding: 0px 5px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .talk-message__message-balloon--deleted {
    background: #ff00004d !important;
  }
  
  .talk-message__message-balloon--info {
    background: #008cff44 !important;
    background: #dbd800;
  }
  .talk-message__message-balloon--change-of-operator {
    background: #008cff44 !important;
    background: #dbd800;
  }
  .talk-message__message-balloon--change-status {
    background: #00b7ff44 !important;
    background: #dbd800;
  }
  .talk-message__message-balloon--note-internal {
    background: #FDE68A !important;
    background: #dbd800;
  }
  .template-container {
    border: solid 1px rgba(0,0,0,.12);
    border-radius: 8px;
    overflow: hidden;
  }

  .template-header {
    /* background-color: #f5f5f5; */
    padding: 8px 12px;
    font-size: 13px;
    color: rgba(0,0,0,.7);
    border-bottom: 1px solid rgba(0,0,0,.12);
    display: flex;
    align-items: center;
  }

  .template-body {
    padding: 12px;
  }

  .template-text {
    font-size: 14px;
    line-height: 1.5;
    white-space: pre-line;
  }

  .template-buttons {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .template-footer {
    margin-top: 16px;
    font-size: 12px;
    color: rgba(0,0,0,.6);
    border-top: 1px solid rgba(0,0,0,.12);
    padding-top: 8px;
  }
</style>
