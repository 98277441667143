import { Model } from '@/default/model/Model'
import {
  PERMISSION_SYSTEM_IMPORT,
  PERMISSION_SYSTEM_IMPORT_CREATE,
  PERMISSION_SYSTEM_IMPORT_UPDATE,
  PERMISSION_SYSTEM_IMPORT_DELETE
} from '@/default/constants/permissions'

export class Import extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: PERMISSION_SYSTEM_IMPORT,
      create: PERMISSION_SYSTEM_IMPORT_CREATE,
      update: PERMISSION_SYSTEM_IMPORT_UPDATE,
      delete: PERMISSION_SYSTEM_IMPORT_DELETE
    }
    this.name = 'import'
    this.actions = {
      list: false,
      create: false,
      update: true,
      delete: true
    }
    this.title = {
      list: 'Listas de importações',
      edit: {
        insert: 'Nova lista de importação',
        update: 'Editar lista de importação'
      }
    }
    this.apiUrl = 'import'
    this.icon = 'upload_file'
    this.search = {
      placeholder: 'Pesquise pelo ID ou nome da lista de importação',
      fields: ['id', 'name']
    }
    this.maxWidth = '800px'
    this.size = ['xs12', 'sm12', 'md12', 'lg12']
    this.components.list.tableRow = () => import('@/default/pages/Imports/Imports')
    this.setFields(this.listFields)
    this.setFields(this.editFields)
  }

  listFields = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome'
    }),
    type_id: this.fieldList({
      value: 'type_id',
      text: 'Tipo'
    }),
    status_id: this.fieldList({
      value: 'status_id',
      text: 'Status'    
    }),
    statistics: this.fieldList({
      value: 'statistics',
      text: 'Estatísticas',
      sortable: false,
    }),
    actions: this.fieldList({
      value: 'actions',
      text: 'Ações',
      sortable: false,
    })
  }

  editFields = {
    header1: this.fieldSubHeader({
      text: 'Informe o nome e o tipo da importação.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome',
      placeholder: 'Ex: Importação de Contatos',
      required: true,
      size: ['xs12', 'sm12', 'md8', 'lg8']
    }),
    type_id: this.fieldSelect({
      value: 'type_id',
      text: 'Tipo',
      options: [
        { value: 'contacts', text: 'Contatos' }
      ],
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      defaultOption: 1
    }),
  }
}
