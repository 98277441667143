<template>
  <div class="wn-sheet-from-to">
    <!-- Header fixo com progresso e botão -->
    <div class="fixed-header elevation-2">
      <div class="progress-section">
        <div class="header-content">
          <!-- Progress info e chips na mesma linha -->
          <div class="progress-row">
            <div class="progress-info">
              <span class="progress-text">
                {{ totalMappedFields }} de {{ visibleHeaders.length }} campos mapeados
              </span>
              <span class="progress-percentage">
                {{ progressPercentage }}%
              </span>
            </div>
            
            <div class="required-fields">
              <span class="required-fields-label">Obrigatórios:</span>
              <div class="required-fields-list">
                <v-chip
                  v-for="field in requiredFields"
                  :key="field.value"
                  x-small
                  label
                  :color="isFieldMapped(field.value) ? 'success lighten-1' : 'grey lighten-4'"
                  :text-color="isFieldMapped(field.value) ? 'white' : 'grey darken-2'"
                  class="required-field-chip"
                >
                  {{ field.title }}
                </v-chip>
              </div>
            </div>
          </div>

          <!-- Barra de progresso -->
          <v-progress-linear
            :value="progressPercentage"
            height="3"
            :color="progressColor"
            background-color="grey lighten-4"
            class="progress-bar"
          ></v-progress-linear>
        </div>
      </div>

      <v-btn
        color="primary"
        outline
        small
        @click="addExtraField"
        class="add-field-btn"
      >
        <v-icon small left>add</v-icon>
        Adicionar Campo
      </v-btn>
    </div>

    <!-- Área com scroll -->
    <div class="scrollable-content">
      <div class="mapping-container" v-if="hasData">
        <div class="mapping-header">
          <div class="column-field">Campo da Planilha</div>
          <div class="column-map">Mapear para</div>
          <div class="column-actions">Ações</div>
        </div>

        <div class="mapping-rows">
          <div 
            v-for="header in visibleHeaders" 
            :key="header.from"
            class="mapping-row"
          >
            <div class="column-field">
              <div class="field-info">
                <div class="field-name">
                  {{ header.isExtra ? 'Campo Extra' : header.from }}
                  <v-chip
                    x-small
                    v-if="header.isExtra"
                    color="primary"
                    class="ml-2"
                  >
                    Extra
                  </v-chip>
                </div>
                <div 
                  class="example-value" 
                  :style="{ color: getExampleValue(header.from) !== null ? 'grey' : 'red' }" 
                  v-if="!header.isExtra" 
                  :title="getExampleValue(header.from) || 'Sem exemplo disponível'"
                  v-html="getExampleValue(header.from) ? `Exemplo: <strong>${getExampleValue(header.from)}</strong>` : 'Coluna vazia ou sem exemplo disponível'"
                >
                </div>
              </div>
            </div>

            <div class="column-map">
              <v-select
                v-model="header.to"
                :items="availableFields"
                item-text="title"
                item-value="value"
                :hint="getSelectedFieldDescription(header.to)"
                persistent-hint
                clearable
                :label="header.to ? header.to : 'Selecione um campo para ' + header.from"
                class="mapping-select"
                @change="updateFieldMapping(header)"
              >
                <template v-slot:selection="{ item }">
                  <span>
                    {{ item.title }}
                    <span v-if="item.required" class="required-badge">*</span>
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" class="select-item">
                        {{ item.title }}
                        <span v-if="item.required" class="required-badge">*</span>
                      </div>
                    </template>
                    <span>
                      {{ item.description }}
                      <br v-if="item.required">
                      <strong v-if="item.required">Campo obrigatório</strong>
                    </span>
                  </v-tooltip>
                </template>
              </v-select>

              <!-- Campo de valor default para campos extras -->
              <v-text-field
                v-if="header.isExtra"
                v-model="header.defaultValue"
                :label="'Valor Padrão'"
                :placeholder="'Digite um valor padrão'"
                class="mt-2"
                dense
                @input="updateFieldMapping(header)"
              ></v-text-field>
            </div>

            <div class="column-actions">
              <v-btn 
                icon 
                @click="removeHeader(header)"
                class="delete-btn"
              >
                <v-icon color="error">delete</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="no-data-message">
        <p>Aguardando dados da planilha...</p>
      </div>
    </div>

    <!-- Footer fixo -->
    <div class="fixed-footer elevation-20">
      <v-btn 
        flat
        @click="cancel()"
        :disabled="isProcessing"
      >
        Cancelar
      </v-btn>
      <v-btn 
        text
        @click="back()"
        :disabled="isProcessing"
      >
        Voltar
      </v-btn>
      <v-btn
        color="primary"
        @click="handleContinue"
        :disabled="!canContinue || isProcessing"
        :loading="isProcessing"
        class="pulse-button"
      >
        Continuar
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WNSheetFromTo',
  props: {
    config: {
      type: Object,
      required: false,
      default: () => ({
        title: 'Importação',
        description: 'Configure o mapeamento dos campos da sua planilha.',
        fields: []
      })
    },
    processedData: {
      type: Object,
      required: false,
      default: () => ({
        headers: [],
        rows: []
      })
    }
  },
  data() {
    return {
      headers: [], // Apenas os headers originais
      visibleHeaders: [], // Array com o mapeamento dos campos
      mappedFields: new Map(), // Mapa de/para dos campos
      sampleRow: null, // Apenas uma linha de exemplo
      isProcessing: false, // Novo estado para controlar o loading
      extraFieldCounter: 1 // Contador para campos extras
    }
  },
  computed: {
    hasData() {
      return this.headers.length > 0
    },
    availableFields() {
      // Adiciona a opção de campo adicional à lista de campos disponíveis
      return [
        {
          value: '_additional',
          title: 'Campo Adicional',
          description: 'Manter campo original sem mapeamento'
        },
        ...(this.config?.fields || [])
      ]
    },
    canContinue() {
      return this.visibleHeaders.length > 0 && 
             this.visibleHeaders.every(header => header.to !== null) &&
             this.hasAllRequiredFields
    },
    totalMappedFields() {
      // Conta campos que foram mapeados (incluindo adicionais e extras)
      return this.visibleHeaders.filter(header => header.to !== null).length
    },
    progressPercentage() {
      if (!this.visibleHeaders.length) return 0
      return Math.round((this.totalMappedFields / this.visibleHeaders.length) * 100)
    },
    progressColor() {
      if (this.progressPercentage < 50) return 'warning'
      if (this.progressPercentage < 100) return 'info'
      return 'success'
    },
    requiredFields() {
      return this.config.fields.filter(field => field.required)
    },
    hasAllRequiredFields() {
      return this.requiredFields.every(field => 
        Array.from(this.mappedFields.values()).includes(field.value)
      )
    },
    isFieldMapped() {
      return (fieldValue) => {
        return Array.from(this.mappedFields.values()).includes(fieldValue)
      }
    }
  },
  methods: {
    initializeLocalData() {
      if (this.processedData?.headers) {
        // Armazena apenas os headers
        this.headers = [...this.processedData.headers]
        
        // Guarda apenas a primeira linha como exemplo
        this.sampleRow = this.processedData.rows[0] || {}
        
        // Inicializa os headers visíveis
        this.visibleHeaders = this.headers.map(header => ({
          from: header,
          to: null,
          isExtra: false
        }))

        // Limpa o mapa de campos mapeados
        this.mappedFields.clear()
      }
    },
    getExampleValue(header) {
      const value = this.sampleRow?.[header]
      return value !== undefined && value !== null && value !== '' ? value : null
    },
    getSelectedFieldDescription(fieldValue) {
      if (!fieldValue) return ''
      const field = this.availableFields.find(f => f.value === fieldValue)
      return field ? field.description : ''
    },
    updateFieldMapping(header) {
      if (header.to) {
        this.mappedFields.set(header.from, header.to)
      } else {
        this.mappedFields.delete(header.from)
      }
    },
    removeHeader(header) {
      this.visibleHeaders = this.visibleHeaders.filter(h => h.from !== header.from)
      this.mappedFields.delete(header.from)
    },
    addExtraField() {
      const extraFieldKey = `extra${this.extraFieldCounter}`
      
      // Adiciona o campo extra aos headers visíveis com defaultValue
      this.visibleHeaders.push({
        from: extraFieldKey,
        to: null,
        isExtra: true,
        defaultValue: '' // Novo campo para valor padrão
      })
      
      this.extraFieldCounter++
    },
    async handleContinue() {
      try {
        this.isProcessing = true
        
        const result = {
          fields: Array.from(this.mappedFields.entries()).map(([from, to]) => ({
            from,
            to: to === '_additional' ? from : to
          })),

          headers: Array.from(this.mappedFields.entries()).map(([from, to]) => {
            if (to === '_additional') {
              return {
                value: from,
                title: from,
                description: 'Campo adicional da planilha',
                example: this.sampleRow?.[from] || null
              }
            }

            const fieldConfig = this.config.fields.find(f => f.value === to)
            const header = this.visibleHeaders.find(h => h.from === from)
            const exampleValue = header?.isExtra ? header.defaultValue : this.sampleRow?.[from]
            
            return {
              ...(fieldConfig || {
                value: to,
                title: to,
                description: 'Campo sem configuração'
              }),
              example: exampleValue
            }
          }),

          data: this.processedData.rows.map(row => {
            const mappedRow = {}
            this.mappedFields.forEach((to, from) => {
              const header = this.visibleHeaders.find(h => h.from === from)
              
              if (to === '_additional') {
                mappedRow[from] = row[from]
              } else if (header?.isExtra) {
                // Usa o valor padrão para campos extras
                const field = this.config.fields.find(f => f.value === to)
                const value = header.defaultValue
                mappedRow[to] = field?.format ? field.format(value) : value
              } else {
                const field = this.config.fields.find(f => f.value === to)
                const value = row[from]
                mappedRow[to] = field?.format ? field.format(value) : value
              }
            })
            return mappedRow
          })
        }

        this.$emit('continue', result)
      } catch (error) {
        console.error('Erro ao processar dados:', error)
      } finally {
        this.isProcessing = false
      }
    },
    back() {
      this.$emit('back')
    },
    cancel() {
      this.$emit('cancel')
    }
  },
  mounted() {
    this.initializeLocalData()
  },
  watch: {
    processedData: {
      handler(newValue) {
        if (newValue) {
          this.initializeLocalData()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.wn-sheet-from-to {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.fixed-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding: 8px 16px 6px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #eee;
  border-top: solid 1px #eee;
}

.theme--dark .fixed-header {
  background: #1E1E1E;
  border-bottom-color: #424242;
}

.progress-section {
  flex: 1;
  min-width: 0;
}

.header-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.progress-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.progress-info {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
}

.progress-text {
  font-size: 0.75rem;
  color: #666;
  font-weight: 500;
}

.progress-percentage {
  font-size: 0.75rem;
  color: #444;
  font-weight: 600;
}

.required-fields {
  display: flex;
  align-items: center;
  gap: 6px;
  flex: 1;
  min-width: 0;
}

.required-fields-label {
  color: #666;
  font-size: 0.75rem;
  font-weight: 500;
  white-space: nowrap;
}

.required-fields-list {
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.required-fields-list::-webkit-scrollbar {
  display: none;
}

.required-field-chip {
  height: 18px !important;
  font-size: 0.7rem !important;
  font-weight: 500 !important;
  flex-shrink: 0;
}

.progress-bar {
  margin-top: 2px;
}

.add-field-btn {
  white-space: nowrap;
  height: 28px !important;
}

.scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding: 8px;
  min-height: 0;
  max-height: calc(100vh - 300px);
}

.mapping-container {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
}

.mapping-header {
  /* position: sticky; */
  top: 0;
  z-index: 0;
  background: #f5f5f5;
  display: grid;
  grid-template-columns: 2fr 3fr 60px;
  gap: 8px;
  padding: 8px;
  font-size: 0.85rem;
  font-weight: 500;
}

.mapping-row {
  display: grid;
  grid-template-columns: 2fr 3fr 60px;
  gap: 8px;
  padding: 6px 8px;
  border-bottom: 1px solid #eee;
  align-items: anchor-center;
}

.fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 0;
  background: #fff;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  gap: 8px;
  border-top: 1px solid #eee;
}

.theme--dark .fixed-footer {
  background: #1E1E1E;
  border-top-color: #424242;
}

/* Tema escuro */
.theme--dark .mapping-container {
  background: #1E1E1E;
}

.theme--dark .mapping-header {
  background: #2D2D2D;
}

.theme--dark .mapping-row {
  border-bottom-color: #424242;
}

.field-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.field-name {
  font-weight: 500;
  color: #333;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
}

.theme--dark .field-name {
  color: #fff;
}

.example-value {
  color: #666;
  font-size: 0.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme--dark .example-value {
  color: #aaa;
}

.column-map {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.mapping-select {
  width: 100%;
}

.mapping-select >>> .v-input__slot {
  min-height: 36px !important;
}

.mapping-select >>> .v-select__slot {
  height: 36px !important;
}

.column-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
}

.delete-btn {
  opacity: 0.7;
  transition: opacity 0.2s;
}

.delete-btn:hover {
  opacity: 1;
}

/* Ajustes para o campo de valor padrão */
.v-text-field.dense >>> .v-input__slot {
  min-height: 32px !important;
}

/* Ajustes para chips */
.v-chip.x-small {
  height: 18px !important;
  font-size: 0.75rem !important;
}

/* Ajustes responsivos */
@media (max-width: 600px) {
  .mapping-header, .mapping-row {
    grid-template-columns: 1fr 1.5fr 40px;
    gap: 8px;
    padding: 8px;
  }

  .field-name {
    font-size: 0.85rem;
  }

  .example-value {
    font-size: 0.75rem;
  }
}

.no-data-message {
  text-align: center;
  padding: 40px;
  color: #666;
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 20px;
}

.theme--dark .no-data-message {
  background: #2D2D2D;
  color: #aaa;
}

.select-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.required-badge {
  color: #ff5252;
  font-weight: bold;
  margin-left: 2px;
}

.theme--dark .required-badge {
  color: #ff7676;
}

.theme--dark .progress-text,
.theme--dark .required-fields-label {
  color: #aaa;
}

.theme--dark .progress-percentage {
  color: #fff;
}

.theme--dark .required-field-chip.grey {
  background-color: #333 !important;
  color: #aaa !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.6);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 15px rgba(25, 118, 210, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
}

.pulse-button:not(:disabled):not(.loading) {
  animation: pulse 1.5s infinite;
}

/* Tema escuro */
.theme--dark .pulse-button:not(:disabled):not(.loading) {
  animation-name: pulse-dark;
}

@keyframes pulse-dark {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(100, 181, 246, 0.6);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 15px rgba(100, 181, 246, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(100, 181, 246, 0);
  }
}
</style> 