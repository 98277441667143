<template>
  <div class="wn-upload-file">
    <div class="content-wrapper scrollable-content">
      <div class="sheet-header">
        <h2 v-if="config && config.title" class="sheet-title">
          Upload da planilha
        </h2>
        <div class="sheet-description">
          <p class="description-text">
            <span>Faça upload do seu arquivo (XLSX, XLS ou CSV). </span><br>
            <span class="required-label red--text">Se certifique que o arquivo contem os campos obrigatórios abaixo.</span>
          </p>
          <div v-if="requiredFields.length" class="required-fields">
            <span class="required-label">Campos obrigatórios: </span>
            <div class="required-chips">
              <v-chip
                v-for="field in requiredFields"
                :key="field.value"
                small
                outline
                color="primary"
                class="field-chip"
              >
                {{ field.title }}
              </v-chip>
            </div>
          </div>
        </div>
      </div>
      <div
        class="upload-area"
        :class="{ 'drag-over': isDragging }"
        @dragenter.prevent="handleDragEnter"
        @dragleave.prevent="handleDragLeave"
        @dragover.prevent
        @drop.prevent="handleDrop"
        @click="triggerFileInput"
      >
        <input
          ref="fileInput"
          type="file"
          accept=".xlsx,.xls,.csv"
          class="file-input"
          @change="handleFileSelect"
        >
        
        <div class="upload-content" v-if="!selectedFile">
          <v-icon size="48" color="primary">cloud_upload</v-icon>
          <h3>Arraste sua planilha aqui</h3>
          <p>ou clique para selecionar</p>
          <span class="file-types">Arquivos suportados: XLSX, XLS, CSV</span>
        </div>

        <div class="file-preview" v-else>
          <v-icon size="32" color="primary">description</v-icon>
          <div class="file-info">
            <span class="file-name">{{ selectedFile.name }}</span>
            <span class="file-size">{{ formatFileSize(selectedFile.size) }}</span>
            <span v-if="isProcessing" class="processing-status">
              <span>Processando arquivo... Por favor, aguarde...</span>
              <v-progress-linear
                indeterminate
                height="3"
                color="primary"
                background-color="grey lighten-4"
                class="progress-bar"
              ></v-progress-linear>
            </span>
            <span v-if="processError" class="error-message">{{ processError }}</span>
          </div>
          <v-btn icon small @click.stop="removeFile">
            <v-icon>close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>  

    <div class="fixed-footer elevation-2">
      <v-btn 
        flat 
        @click="cancel()"
      >
        Cancelar
      </v-btn>
      <v-btn 
        text 
        @click="back()"
      >
        Voltar
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!isValidToContinue"
        @click="handleContinue"
        class="pulse-button"
      >
        Continuar
        <v-icon right>arrow_forward</v-icon>
      </v-btn>
    </div>

    <!-- Preview Modal -->
    <v-dialog v-model="showPreview" max-width="850px" persistent>
      <v-card>
        <v-card-title class="header">
          <span>Prévia da Planilha</span>
          <v-spacer></v-spacer>
          <v-switch
            v-model="hasHeaders"
            label="Primeira linha contém cabeçalhos"
            class="mt-0"
            hide-details
          ></v-switch>
        </v-card-title>

        <v-card-text class="preview-content">
          <div class="preview-table-wrapper">
            <table class="preview-table">
              <thead>
                <tr>
                  <th 
                    v-for="(header, index) in previewHeaders" 
                    :key="index"
                    class="header-cell"
                  >
                    <div class="header-content">
                      <div 
                        v-if="editingHeader !== index"
                        class="header-text"
                        @click="startEditingHeader(index)"
                        :title="header"
                      >
                        {{ header }}
                        <v-icon small class="edit-icon">edit</v-icon>
                      </div>
                      <input
                        v-else
                        v-model="previewHeaders[index]"
                        @blur="stopEditingHeader"
                        @keyup.enter="stopEditingHeader"
                        @keyup.esc="cancelEditingHeader(index)"
                        ref="headerInput"
                        class="header-input"
                        :placeholder="'Coluna ' + (index + 1)"
                      >
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, rowIndex) in previewData" :key="rowIndex">
                  <td 
                    v-for="(cell, cellIndex) in row" 
                    :key="cellIndex"
                    :title="cell"
                  >
                    {{ cell }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div class="preview-info">
            <small>Mostrando {{ previewData.length }} de {{ totalRows }} linhas</small>
          </div>
        </v-card-text>

        <v-card-actions class="preview-actions">
          <v-spacer></v-spacer>
          <v-btn flat @click="cancelPreview">Cancelar</v-btn>
          <v-btn 
            color="primary" 
            @click="confirmPreview"
            class="pulse-button"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'

export default {
  name: 'WNUploadFile',
  data() {
    return {
      isDragging: false,
      selectedFile: null,
      isProcessing: false,
      processError: null,
      processedData: null,
      progressPercentage: 0,
      showPreview: false,
      hasHeaders: true,
      previewData: [],
      previewHeaders: [],
      rawData: null,
      originalHeaders: [],
      totalRows: 0,
      editingHeader: null,
      headerBackup: null
    }
  },
  computed: {
    isValidToContinue() {
      return this.processedData && 
             !this.isProcessing && 
             !this.processError &&
             this.processedData.rows.length > 0 && 
             this.processedData.headers.length > 0
    },
    requiredFields() {
      return this.config?.fields?.filter(field => field.required) || []
    }
  },
  watch: {
    hasHeaders(newValue) {
      if (newValue) {
        this.previewHeaders = this.originalHeaders
        this.previewData = this.rawData.slice(1, 6)
      } else {
        this.previewHeaders = this.generateDefaultHeaders(this.rawData[0].length)
        this.previewData = this.rawData.slice(0, 5)
      }
    }
  },
  methods: {
    handleDragEnter() {
      this.isDragging = true
    },
    handleDragLeave() {
      this.isDragging = false
    },
    handleDrop(e) {
      this.isDragging = false
      const files = e.dataTransfer.files
      if (files.length > 0) {
        this.validateAndSetFile(files[0])
      }
    },
    triggerFileInput() {
      if (!this.selectedFile) {
        this.$refs.fileInput.click()
      }
    },
    handleFileSelect(e) {
      const files = e.target.files
      if (files.length > 0) {
        this.validateAndSetFile(files[0])
      }
    },
    validateAndSetFile(file) {
      const validTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv'
      ]
      
      if (validTypes.includes(file.type)) {
        this.selectedFile = file
        this.processFile(file)
      } else {
        this.$emit('error', 'Formato de arquivo inválido. Use XLSX, XLS ou CSV.')
      }
    },
    async processFile(file) {
      try {
        this.isProcessing = true
        this.processError = null
        
        // Lê apenas os dados brutos primeiro
        const rawData = await this.readFileData(file)
        
        if (!rawData || !rawData.length) {
          throw new Error('A planilha está vazia')
        }

        // Armazena dados brutos e total de linhas
        this.rawData = rawData
        this.totalRows = rawData.length

        // Armazena os headers originais
        this.originalHeaders = [...rawData[0]]

        // Configura preview inicial (assumindo que tem headers)
        this.previewHeaders = this.originalHeaders
        this.previewData = rawData.slice(1, 6)
        this.hasHeaders = true

        // Mostra modal de preview
        this.showPreview = true

      } catch (error) {
        this.processError = error.message
        this.$emit('error', error.message)
        console.error('Erro no processamento:', error)
      } finally {
        this.isProcessing = false
      }
    },
    readFileData(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result)
            
            // Configura as opções para leitura inicial
            const workbook = XLSX.read(data, { 
              type: 'array',
              cellDates: true,
              cellNF: true, // Mantém o formato original da célula
              cellText: false
            })
            
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
            
            // Função auxiliar para verificar se um valor é uma data válida
            const isValidDate = (d) => d instanceof Date && !isNaN(d)

            // Função para tentar converter string em data
            const parseDate = (value) => {
              if (!value) return null

              // Se já é um objeto Date válido, retorna ele mesmo
              if (isValidDate(value)) return value

              // Remove espaços extras e caracteres inválidos
              const cleanValue = value.toString().trim().replace(/[^\d/\-.]/g, '')
              
              // Array com diferentes formatos de data possíveis
              const dateFormats = [
                // Formatos com /
                'DD/MM/YYYY', 'D/M/YYYY', 'DD/MM/YY', 'D/M/YY',
                'MM/DD/YYYY', 'M/D/YYYY', 'MM/DD/YY', 'M/D/YY',
                // Formatos com -
                'YYYY-MM-DD', 'YY-MM-DD',
                'DD-MM-YYYY', 'DD-MM-YY',
                // Formatos com .
                'DD.MM.YYYY', 'DD.MM.YY'
              ]

              // Tenta converter usando cada formato
              for (const format of dateFormats) {
                // Usa uma biblioteca de data como moment.js ou date-fns
                // Aqui vou simular com uma implementação básica
                const parts = cleanValue.split(/[/\-.]/g)
                
                if (parts.length === 3) {
                  let day, month, year

                  switch (format) {
                    case 'DD/MM/YYYY':
                    case 'D/M/YYYY':
                    case 'DD/MM/YY':
                    case 'D/M/YY':
                    case 'DD-MM-YYYY':
                    case 'DD-MM-YY':
                    case 'DD.MM.YYYY':
                    case 'DD.MM.YY':
                      day = parseInt(parts[0])
                      month = parseInt(parts[1]) - 1
                      year = parseInt(parts[2])
                      break
                      
                    case 'MM/DD/YYYY':
                    case 'M/D/YYYY':
                    case 'MM/DD/YY':
                    case 'M/D/YY':
                      day = parseInt(parts[1])
                      month = parseInt(parts[0]) - 1
                      year = parseInt(parts[2])
                      break
                      
                    case 'YYYY-MM-DD':
                    case 'YY-MM-DD':
                      day = parseInt(parts[2])
                      month = parseInt(parts[1]) - 1
                      year = parseInt(parts[0])
                      break
                  }

                  // Ajusta ano de 2 dígitos
                  if (year < 100) {
                    year += year < 50 ? 2000 : 1900
                  }

                  // Valida os valores
                  if (month >= 0 && month < 12 && day > 0 && day <= 31) {
                    const date = new Date(year, month, day)
                    if (isValidDate(date) && 
                        date.getDate() === day && 
                        date.getMonth() === month && 
                        date.getFullYear() === year) {
                      return date
                    }
                  }
                }
              }

              return null
            }

            // Processa cada célula
            for (let cell in firstSheet) {
              if (cell[0] === '!') continue // Pula células especiais
              
              const cellValue = firstSheet[cell]
              if (!cellValue) continue

              let dateValue = null

              // Tenta identificar se é uma data de várias formas
              if (cellValue.t === 'd' && isValidDate(cellValue.v)) {
                // Já é um objeto Date
                dateValue = cellValue.v
              } else if (cellValue.w && typeof cellValue.w === 'string') {
                // Tenta converter o valor formatado
                dateValue = parseDate(cellValue.w)
              } else if (cellValue.v) {
                // Tenta converter o valor bruto
                dateValue = parseDate(cellValue.v)
              }

              // Se conseguiu identificar uma data válida, formata no padrão brasileiro
              if (dateValue) {
                const day = String(dateValue.getDate()).padStart(2, '0')
                const month = String(dateValue.getMonth() + 1).padStart(2, '0')
                const year = dateValue.getFullYear()
                
                firstSheet[cell].v = `${day}/${month}/${year}`
                firstSheet[cell].w = `${day}/${month}/${year}`
                firstSheet[cell].t = 's'
              }
            }

            // Converte para array final
            const jsonData = XLSX.utils.sheet_to_json(firstSheet, {
              header: 1,
              raw: false,
              defval: '',
              blankrows: false
            })

            resolve(jsonData)
          } catch (error) {
            reject(new Error('Erro ao ler a planilha. Verifique se o arquivo está corrompido.'))
          }
        }

        reader.onerror = () => {
          reject(new Error('Erro ao ler o arquivo. Tente novamente.'))
        }

        reader.readAsArrayBuffer(file)
      })
    },
    validateHeaders(headers) {
      if (!headers || !headers.length) {
        throw new Error('A planilha não possui cabeçalhos válidos')
      }

      // Remove espaços em branco e caracteres especiais, adiciona nomes padrão para colunas vazias
      return headers.map((header, index) => {
        const cleanHeader = header ? header.toString().trim() : ''
        if (!cleanHeader) {
          return `Col ${index + 1}` // Nome padrão para colunas sem cabeçalho
        }
        return cleanHeader
      })
    },
    normalizeHeader(header) {
      return header
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove acentos
        .replace(/[^a-z0-9]/g, '_') // Substitui caracteres especiais por _
        .replace(/_+/g, '_') // Remove underscores duplicados
        .replace(/^_|_$/g, '') // Remove underscores no início e fim
    },
    processRows(rows, headers) {
      return rows
        .filter(row => row.some(cell => cell !== '')) // Remove linhas vazias
        .map((row, index) => {
          const rowData = {}
          headers.forEach((header, colIndex) => {
            let value = row[colIndex]
            
            // Converte para string se não for undefined ou null
            if (value != null) {
              value = value.toString().trim()
            } else {
              value = ''
            }

            rowData[header] = value
          })

          // Adiciona metadados úteis
          rowData._row = index + 2 // +2 porque índice começa em 0 e primeira linha são headers
          rowData._valid = this.validateRow(rowData)

          return rowData
        })
    },
    validateRow() {
      // Implementar validações específicas aqui
      // Por exemplo, verificar se campos obrigatórios estão preenchidos
      return true
    },
    removeFile() {
      this.selectedFile = null
      this.processError = null
      this.$refs.fileInput.value = ''
      this.$emit('file-removed')
    },
    formatFileSize(bytes) {
      if (bytes === 0) return '0 Bytes'
      const k = 1024
      const sizes = ['Bytes', 'KB', 'MB', 'GB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    },
    handleContinue() {
      if (this.processedData) {
        this.$emit('continue', this.processedData)
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    generateDefaultHeaders(count) {
      return Array.from({ length: count }, (_, i) => `Col ${i + 1}`)
    },
    cancelPreview() {
      this.showPreview = false
      this.removeFile()
    },
    async confirmPreview() {
      try {
        this.isProcessing = true
        // Processa os dados com base na escolha do usuário
        const headers = this.validateHeaders(this.previewHeaders)

        const rows = this.processRows(
          this.hasHeaders ? this.rawData.slice(1) : this.rawData,
          headers
        )
        
        this.processedData = {
          headers,
          rows,
          originalFile: this.selectedFile
        }

        this.showPreview = false

        this.handleContinue()

      } catch (error) {
        console.error('Erro ao confirmar preview:', error)
        alert('Erro ao confirmar preview: ' + error.message)
        this.processError = error.message
        this.$emit('error', error.message)
      } finally {
        this.isProcessing = false
      }
    },
    startEditingHeader(index) {
      this.editingHeader = index
      this.headerBackup = String(this.previewHeaders[index] || '')
      this.$nextTick(() => {
        const input = this.$refs.headerInput
        if (input && input[0]) {
          input[0].focus()
          input[0].select()
        }
      })
    },
    stopEditingHeader() {
      if (this.editingHeader !== null) {
        const currentValue = this.previewHeaders[this.editingHeader]
        const newValue = String(currentValue || '').trim()
        
        if (newValue === '') {
          this.previewHeaders[this.editingHeader] = `Col ${this.editingHeader + 1}`
        } else {
          this.previewHeaders[this.editingHeader] = newValue
        }
        
        this.editingHeader = null
        this.headerBackup = null
      }
    },
    cancelEditingHeader(index) {
      if (this.headerBackup !== null) {
        this.previewHeaders[index] = this.headerBackup
      }
      this.editingHeader = null
      this.headerBackup = null
    },
    back() {
      this.$emit('back')
    }
  },
  props: {
    config: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.wn-upload-file {
  padding: 0px;
}
.scrollable-content {
  overflow-y: auto !important;
  max-height: calc(100vh - 250px);
  padding: 15px 50px;
}
.upload-area {
  position: relative;
  border: 2px dashed #e0e0e0;
  border-radius: 8px;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #fafafa;
  margin-bottom: 20px;
}

.theme--dark .upload-area {
  border-color: #424242;
  background: #1E1E1E;
}

.upload-area:hover {
  border-color: #1976d2;
  background: #f5f5f5;
}

.theme--dark .upload-area:hover {
  border-color: #64B5F6;
  background: #2D2D2D;
}

.drag-over {
  border-color: #1976d2;
  background: #e3f2fd;
}

.theme--dark .drag-over {
  border-color: #64B5F6;
  background: #1E1E1E;
}

.file-input {
  display: none;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.upload-content h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.theme--dark .upload-content h3 {
  color: #fff;
}

.upload-content p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.theme--dark .upload-content p {
  color: #aaa;
}

.file-types {
  font-size: 12px;
  color: #999;
  margin-top: 8px;
}

.file-preview {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.theme--dark .file-preview {
  background: #2D2D2D;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.file-info {
  flex: 1;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.file-name {
  font-weight: 500;
  color: #333;
}

.theme--dark .file-name {
  color: #fff;
}

.file-size {
  font-size: 12px;
  color: #666;
}

.theme--dark .file-size {
  color: #aaa;
}

.processing-status {
  font-size: 12px;
  color: #1976d2;
}

.error-message {
  font-size: 12px;
  color: #f44336;
}

.theme--dark .processing-status {
  color: #64B5F6;
}

.theme--dark .error-message {
  color: #ef5350;
}

.fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 0;
  background: #fff;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  gap: 8px;
  border-top: 1px solid #eee;
}

.theme--dark .fixed-footer {
  background: #1E1E1E;
  border-top-color: #424242;
}

.header {
  padding: 16px;
  border-bottom: 1px solid #eee;
}

.preview-content {
  padding: 16px;
}

.preview-table-wrapper {
  overflow-x: auto;
  max-height: 400px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.preview-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
  table-layout: auto;
}

.preview-table th,
.preview-table td {
  padding: 8px 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.preview-table th {
  background: #f5f5f5;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 1;
}

.preview-table tr:hover td {
  background: #f8f9fa;
}

.preview-table td {
  color: #666;
}

/* Tema escuro */
.theme--dark .preview-table-wrapper {
  border-color: #424242;
}

.theme--dark .preview-table th {
  background: #1E1E1E;
  color: #fff;
  border-bottom-color: #424242;
}

.theme--dark .preview-table td {
  color: #aaa;
  border-bottom-color: #424242;
}

.theme--dark .preview-table tr:hover td {
  background: #2d2d2d;
}

.preview-info {
  margin-top: 8px;
  color: #666;
}

.preview-actions {
  padding: 8px 16px;
  border-top: 1px solid #eee;
}

.theme--dark .header,
.theme--dark .preview-actions {
  border-color: #424242;
}

.theme--dark .preview-info {
  color: #aaa;
}

.header-cell {
  padding: 0 !important;
  min-width: 120px;
}

.header-content {
  position: relative;
  padding: 8px 12px;
}

.header-text {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding-right: 20px;
}

.header-text:hover .edit-icon {
  opacity: 1;
}

.edit-icon {
  opacity: 0;
  font-size: 14px !important;
  color: #666;
  transition: opacity 0.2s;
  position: absolute;
  right: 8px;
}

.header-input {
  width: 100%;
  padding: 4px 8px;
  border: 1px solid #1976d2;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
  background: #fff;
  outline: none;
}

/* Tema escuro */
.theme--dark .header-input {
  background: #1E1E1E;
  border-color: #64B5F6;
  color: #fff;
}

.theme--dark .edit-icon {
  color: #aaa;
}

.theme--dark .header-text:hover .edit-icon {
  color: #fff;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.6);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 15px rgba(25, 118, 210, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
}

.pulse-button:not(:disabled) {
  animation: pulse 1.5s infinite;
}

/* Tema escuro */
.theme--dark .pulse-button:not(:disabled) {
  animation-name: pulse-dark;
}

@keyframes pulse-dark {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(100, 181, 246, 0.6);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 15px rgba(100, 181, 246, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(100, 181, 246, 0);
  }
}

.sheet-header {
  text-align: center;
  margin-bottom: 16px;
}

.sheet-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin: 0 0 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.title-icon {
  opacity: 0.9;
  margin-top: -4px;
}

.sheet-description {
  /* max-width: 600px; */
  margin: 0 auto;
}

.description-text {
  font-size: 0.875rem;
  color: #727272;
  line-height: 1.4;
  margin-bottom: 8px;
}

.required-fields {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.required-label {
  font-size: 0.813rem;
  color: #666;
}

.required-chips {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
}

.field-chip {
  height: 20px !important;
  font-size: 0.75rem !important;
}

/* Tema escuro */
.theme--dark .sheet-title {
  color: #fff;
}

.theme--dark .description-text,
.theme--dark .required-label {
  color: rgba(255, 255, 255, 0.7);
}

/* Responsivo */
@media (max-width: 600px) {
  .sheet-title {
    font-size: 1.25rem;
  }

  .description-text {
    font-size: 0.813rem;
  }

  .required-label {
    font-size: 0.75rem;
  }
}
</style> 