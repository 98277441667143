<template>
  <v-card 
    class="dashboard-card" 
    :style="{ background: `linear-gradient(135deg, ${color} 0%, ${lightenColor(color, 20)} 100%)` }"
    :class="{ 'card-hover': hover }"
    @mouseenter="hover = true"
    @mouseleave="hover = false">
    <div class="card-background-effect"></div>
    <v-card-text class="card-content">
      <div class="card-icon-container" :class="{ 'icon-pulse': hover }">
        <v-icon medium color="white">{{ icon }}</v-icon>
      </div>
      <div class="card-data-container">
        <transition name="fade" mode="out-in">
          <span :key="data" class="card-data" :class="{ 'data-small': dataSmall }">
            {{ data }}
          </span>
        </transition>
      </div>
      <div class="card-title-container">
        <span class="card-title">{{ title }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'DashboardTalksCard',
  props: {
    title: {
      required: true,
      type: String
    },
    icon: {
      required: true,
      type: String
    },
    color: {
      required: false,
      default: '#4CAF50'
    },
    data: {
      required: false,
      default: '- - -'
    },
    progress: {
      required: false,
      default: 0
    },
    route: {
      required: false,
      type: Object
    },
    dataSmall: {
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      hover: false
    }
  },
  methods: {
    lightenColor: function(color, percent) {
      // Se a cor já for um código hexadecimal, usamos uma função simples
      if (color.startsWith('#')) {
        const num = parseInt(color.slice(1), 16);
        const amt = Math.round(2.55 * percent);
        const R = (num >> 16) + amt;
        const G = (num >> 8 & 0x00FF) + amt;
        const B = (num & 0x0000FF) + amt;
        return '#' + (
          0x1000000 + 
          (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + 
          (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 + 
          (B < 255 ? (B < 1 ? 0 : B) : 255)
        ).toString(16).slice(1);
      }
      // Se for uma cor nomeada, retornamos uma versão mais clara
      return color;
    }
  }
}
</script>
<style scoped>
.dashboard-card {
  border-radius: 12px;
  transition: all 0.3s ease;
  overflow: hidden;
  height: 100%;
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(8px); }
  to { opacity: 1; transform: translateY(0); }
}

.card-background-effect {
  position: absolute;
  top: -50%;
  right: -50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.12) 0%, rgba(255,255,255,0) 70%);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.card-hover .card-background-effect {
  opacity: 1;
  animation: pulse-bg 2s infinite;
}

@keyframes pulse-bg {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

.card-hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.card-content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

.card-icon-container {
  margin-bottom: 8px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.card-icon-container::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card-hover .card-icon-container::after {
  opacity: 1;
}

.icon-pulse {
  animation: pulse 1.5s infinite;
}

.card-data-container {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.card-data {
  font-size: 28px;
  font-weight: 600;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.data-small {
  font-size: 20px;
}

.card-title-container {
  margin-top: auto;
  width: 100%;
  text-align: center;
  position: relative;
}

.card-title-container::before {
  content: '';
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  transition: width 0.3s ease;
}

.card-hover .card-title-container::before {
  width: 40px;
}

.card-title {
  font-size: 13px;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  opacity: 0.9;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(8px);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .dashboard-card {
    border-radius: 10px;
  }
  
  .card-content {
    padding: 12px;
  }
  
  .card-icon-container {
    width: 40px;
    height: 40px;
    margin-bottom: 6px;
  }
  
  .card-data {
    font-size: 24px;
  }
  
  .data-small {
    font-size: 18px;
  }
  
  .card-title {
    font-size: 12px;
    letter-spacing: 0.3px;
  }
}
</style>