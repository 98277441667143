<template>
  <div class="wi-textarea" :class="{ 'wi-textarea--disabled': isDisabled }">
    <label v-if="field.text" class="wi-textarea__main-label">
      {{ field.text }}
      <span v-if="isRequired" class="wi-textarea__required">*</span>
    </label>
    <div class="wi-textarea__container">
      <div v-if="!isDisabled" class="wi-textarea__header">
        <div class="wi-textarea__tools">
          <MessageAIMenu
            :message="localValue"
            @text-updated="updateAIText"
            button-icon="emoji_objects"
            :small-button="true"
            :small-icon="true"
            :button-style="{ padding: '0px', width: '8px', height: '8px' }"
            :disabled="isDisabled"
          />
          <!-- Formatações padrão -->
          <template v-for="(format, index) in availableFormats">
            <button 
              v-if="shouldShowFormat(format.type)"
              :key="index"
              class="wi-textarea__tool" 
              @click="format.action ? format.action() : formatText(format.marker)" 
              :title="format.title"
            >
              <v-icon small class="wi-textarea__tool-icon">{{format.icon}}</v-icon>
            </button>
            <div 
              v-if="format.separator && shouldShowNextSeparator(index)"
              :key="'sep-'+index" 
              class="wi-textarea__separator"
            ></div>
          </template>

          <!-- Variáveis -->
          <template v-if="hasVariables">
            <div class="wi-textarea__separator"></div>
            <span class="wi-textarea__label">Variáveis:</span>
            
            <!-- Primeiras 2 variáveis -->
            <button 
              v-for="(variable, index) in firstTwoVariables"
              :key="'var-'+index"
              class="wi-textarea__tool wi-textarea__variable-btn" 
              @click="insertVariable(variable.value || variable)"
              :title="variable.description || ''"
            >
              <v-icon small class="wi-textarea__tool-icon">{{variable.icon || 'code'}}</v-icon>
              <span class="wi-textarea__variable-text">{{variable.label || variable}}</span>
            </button>

            <!-- Menu "Ver mais" para variáveis adicionais -->
            <v-menu
              v-if="remainingVariables.length > 0"
              offset-y
              content-class="wi-textarea__menu"
            >
              <v-btn
                slot="activator"
                small
                flat
                class="wi-textarea__tool wi-textarea__other-btn"
              >
                Outras
              </v-btn>
              <v-card>
                <v-list>
                  <v-list-tile
                    v-for="(variable, index) in remainingVariables"
                    :key="index"
                    @click="insertVariable(variable.value || variable)"
                  >
                    <v-list-tile-avatar v-if="variable.icon">
                      <v-icon>{{variable.icon}}</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{variable.label || variable}}</v-list-tile-title>
                      <v-list-tile-sub-title v-if="variable.description">{{variable.description}}</v-list-tile-sub-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-menu>
          </template>
        </div>
      </div>
      <textarea v-if="!isDisabled"
        ref="textarea"
        class="wi-textarea__input"
        v-model="localValue"
        :placeholder="getPlaceholder"
        :required="isRequired"
        :disabled="isDisabled"
        :rows="field.rows || 4"
      ></textarea>
      <div v-else class="wi-textarea__disabled-content" :style="{ minHeight: (field.rows || 4) * 24 + 'px' }">
        <div class="wi-textarea__disabled-text">
          {{ localValue || getPlaceholder }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WiEditFieldsMixin from './WiEditFieldsMixin'
import MessageAIMenu from '@/modules/talk/pages/TalkView/Talk/MessageAIMenu.vue'
export default {
  name: 'WiEditFieldTextAreaFormat',
  mixins: [WiEditFieldsMixin],
  data() {
    return {
      localValue: this.value,
      defaultFormats: [
        { type: 'bold', marker: '*', title: 'Negrito', icon: 'format_bold' },
        { type: 'italic', marker: '_', title: 'Itálico', icon: 'format_italic' },
        { type: 'strike', marker: '~', title: 'Tachado', icon: 'format_strikethrough', separator: true }
      ]
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    field: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.localValue = newValue
      },
      immediate: true
    },
    localValue(newValue) {
      this.$emit('input', newValue)
    }
  },
  computed: {
    isRequired() {
      return typeof this.field.required === 'function' 
        ? this.field.required(this.form) 
        : this.field.required
    },
    isDisabled() {
      return typeof this.field.disabled === 'function'
        ? this.field.disabled(this.form)
        : this.field.disabled
    },
    availableFormats() {
      return this.defaultFormats
    },
    hasVariables() {
      return this.field.variables && Array.isArray(this.field.variables) && this.field.variables.length > 0
    },
    firstTwoVariables() {
      if (!this.hasVariables) return []
      return this.field.variables.slice(0, 2)
    },
    remainingVariables() {
      if (!this.hasVariables) return []
      return this.field.variables.slice(2)
    },
    getPlaceholder() {
      const placeholder = this.field.placeholder || this.field.text || ''
      return this.isRequired ? `${placeholder}*` : placeholder
    }
  },
  methods: {
    shouldShowFormat(type) {
      if (!this.field.formats) return true
      return this.field.formats.includes(type)
    },
    shouldShowNextSeparator(index) {
      if (!this.defaultFormats[index].separator) return false
      
      for (let i = index + 1; i < this.defaultFormats.length; i++) {
        if (this.shouldShowFormat(this.defaultFormats[i].type)) {
          return true
        }
      }
      return false
    },
    getSelection() {
      const textarea = this.$refs.textarea
      return {
        start: textarea.selectionStart,
        end: textarea.selectionEnd,
        text: textarea.value.substring(textarea.selectionStart, textarea.selectionEnd)
      }
    },
    formatText(marker) {
      const textarea = this.$refs.textarea
      const selection = this.getSelection()
      
      // Se não houver texto selecionado, insere os marcadores e posiciona o cursor entre eles
      if (!selection.text) {
        const cursorPos = selection.start
        const value = textarea.value
        const newValue = value.substring(0, cursorPos) + marker + marker + value.substring(cursorPos)
        this.localValue = newValue
        
        this.$nextTick(() => {
          textarea.focus()
          textarea.setSelectionRange(cursorPos + marker.length, cursorPos + marker.length)
        })
        return
      }
      
      // Se houver texto selecionado, aplica a formatação
      const newText = `${marker}${selection.text}${marker}`
      const value = textarea.value
      const newValue = value.substring(0, selection.start) + newText + value.substring(selection.end)
      this.localValue = newValue
      
      this.$nextTick(() => {
        textarea.focus()
        textarea.setSelectionRange(selection.start, selection.end + (marker.length * 2))
      })
    },
    insertText(text) {
      const textarea = this.$refs.textarea
      const cursorPos = textarea.selectionStart
      const value = textarea.value
      const newValue = value.substring(0, cursorPos) + text + value.substring(cursorPos)
      this.localValue = newValue
      
      this.$nextTick(() => {
        textarea.focus()
        const newPos = cursorPos + text.length
        textarea.setSelectionRange(newPos, newPos)
      })
    },
    insertVariable(variable) {
      this.insertText(`{{${variable}}}`)
    },
    updateAIText(newText) {
      if (newText && typeof newText === 'string') {
        this.localValue = newText
        this.$nextTick(() => {
          if (this.$refs.textarea) {
            this.$refs.textarea.focus()
          }
        })
      }
    }
  },
  components: {
    MessageAIMenu
  }
}
</script>

<style scoped>
.wi-textarea {
  margin: 10px 0;
}

.wi-textarea--disabled .wi-textarea__container {
  background-color: #f8f9fa;
  cursor: not-allowed;
}

.wi-textarea--disabled .wi-textarea__disabled-content {
  padding: 12px;
  color: #666;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
  line-height: 1.5;
}

.wi-textarea--disabled .wi-textarea__disabled-text {
  opacity: 0.8;
}

.wi-textarea__container {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
  transition: background-color 0.2s ease;
}

.wi-textarea__header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
  padding: 4px 6px;
}

.wi-textarea__tools {
  display: flex;
  align-items: center;
  gap: 2px;
}

.wi-textarea__tool {
  background: none;
  border: none;
  padding: 2px 6px;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 28px;
}

.wi-textarea__label {
  font-size: 13px;
  color: #666;
  margin: 0 4px;
}

.wi-textarea__variable-btn {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  margin: 0 2px;
  white-space: nowrap;
  border: 1px solid #ccc;
  background-color: #fff;
}

.wi-textarea__other-btn {
  display: inline-flex;
  align-items: center;
  padding: 10px 12px;
  margin: 0 2px;
  white-space: nowrap;
  background-color: #eef1f5 !important;
  font-size: 12px;
  color: #666 !important;
  text-transform: none;
  letter-spacing: 0;
  min-width: 0;
  border-radius: 15px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.wi-textarea__other-btn:hover {
  background-color: #e4e7eb !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.15);
}

.wi-textarea__variable-text {
  margin-left: 4px;
  font-size: 12px;
}

.wi-textarea__tool:hover {
  background-color: #e9ecef;
}

.wi-textarea__variable-btn:hover {
  border-color: #999;
  background-color: #f8f9fa;
}

.wi-textarea__tool-icon {
  color: #495057 !important;
}

.wi-textarea__separator {
  width: 1px;
  height: 20px;
  background-color: #ddd;
  margin: 0 4px;
}

.wi-textarea__input {
  width: 100%;
  min-height: 120px;
  padding: 12px;
  border: none;
  resize: vertical;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  color: #212529;
}

.wi-textarea__input:focus {
  outline: none;
}

.wi-textarea__menu {
  max-height: 400px;
  overflow-y: auto;
}

.wi-textarea__main-label {
  display: block;
  font-size: 14px;
  color: #727272;
  margin-bottom: 4px;
  font-weight: 500;
  padding-left: 2px;
}

.wi-textarea__required {
  color: #ff5252;
  margin-left: 2px;
}

/* Dark theme support */
.theme--dark .wi-textarea__container {
  border-color: #424242;
}

.theme--dark .wi-textarea--disabled .wi-textarea__container {
  background-color: #2d2d2d;
}

.theme--dark .wi-textarea--disabled .wi-textarea__disabled-content {
  color: #999;
}

.theme--dark .wi-textarea__header {
  background-color: #363636;
  border-bottom-color: #424242;
}

.theme--dark .wi-textarea__tool:hover {
  background-color: #424242;
}

.theme--dark .wi-textarea__variable-btn {
  border-color: #555;
  background-color: #424242;
}

.theme--dark .wi-textarea__variable-btn:hover {
  border-color: #666;
  background-color: #4a4a4a;
}

.theme--dark .wi-textarea__other-btn {
  background-color: #2d2d2d !important;
  color: #ddd !important;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
}

.theme--dark .wi-textarea__other-btn:hover {
  background-color: #383838 !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.25);
}

.theme--dark .wi-textarea__tool-icon {
  color: #e0e0e0 !important;
}

.theme--dark .wi-textarea__label {
  color: #aaa;
}

.theme--dark .wi-textarea__separator {
  background-color: #424242;
}

.theme--dark .wi-textarea__input {
  background-color: #1e1e1e;
  color: #e0e0e0;
}

.theme--dark .wi-textarea__main-label {
  color: #aaa;
}
</style> 