import { Model } from '@/default/model/Model'
import {
  PERMISSION_TALKS_TALK_STAGE_CLUSTER,
  PERMISSION_TALKS_TALK_STAGE_CLUSTER_CREATE,
  PERMISSION_TALKS_TALK_STAGE_CLUSTER_UPDATE,
  PERMISSION_TALKS_TALK_STAGE_CLUSTER_DELETE
} from '@/default/constants/permissions'

export class TalkStageCluster extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: PERMISSION_TALKS_TALK_STAGE_CLUSTER,
      create: PERMISSION_TALKS_TALK_STAGE_CLUSTER_CREATE,
      update: PERMISSION_TALKS_TALK_STAGE_CLUSTER_UPDATE,
      delete: PERMISSION_TALKS_TALK_STAGE_CLUSTER_DELETE
    }
    this.name = 'talk-stage-cluster'
    this.title = {
      list: 'Grupos de Etapas',
      edit: {
        insert: 'Novo Grupo de Etapas',
        update: 'Editar Grupo de Etapas'
      }
    }
    this.apiUrl = 'talks/stage-cluster'
    this.search = {
      placeholder: 'Pesquise ID ou nome do grupo de etapas',
      fields: ['id', 'name']
    }
    this.params = {
      limit: 10
    }
    this.icon = 'view_kanban'
    
    this.size = ['xs12', 'sm12', 'md8', 'ls8', 'xl8', 'offset-md2', 'offset-ls2', 'offset-xl2']

    this.maxWidth = '370px'

    // this.components.list.tableRow = () => import('@/modules/business/pages/Businesses/BusinessCategories')

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }
  
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome da categoria'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome deste grupo de etapas.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome do Grupo de Etapas',
      required: true
    }),
    subHeader2: this.fieldSubHeader({
      text: '(Opcional) Informe a cor e ícone.'
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone do Grupo de Etapas'
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor do Grupo de Etapas'
    }),
    subHeader3: this.fieldSubHeader({
      text: 'Observações adicionais.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Observações adicionais sobre este grupo de etapas.'
    })
  }
}
