<template>
  <div style="background: white !important;">
    <v-toolbar dense :color="!$wiDark ? 'primary' : 'dark'" style="color: white;">
      <v-btn  icon ripple @click="$router.go(-1)" style="color: white;">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{title || 'Processos'}}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <WiListFilters
        v-if="wiConfig && wiConfig.filters"
        :config="wiConfig.filters"
        :params="apiGetParams"
        @filter="filters => { setFilters(filters) }"
        :disabled="loading"
      ></WiListFilters>
      <v-menu 
        v-if="orderOptions && orderOptions.length > 0" 
        v-model="orderMenu" 
        :close-on-content-click="true" 
        :nudge-width="300"
      >
        <v-btn 
          class="hidden-xs-only hidden-sm-only" 
          small 
          outline 
          color="white" 
          :disabled="loading" 
          slot="activator"
        >
          <v-icon>sort</v-icon>
          <span>Reordenar</span>
        </v-btn>
        <v-card>
          <v-subheader>Selecione a ordem</v-subheader>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-tile 
              v-for="option in orderOptions" 
              :key="option.value"
              @click="handleOrderChange(option.value)"
              :class="{
                'wi-kanban__order-item': true,
                'wi-kanban__order-item--selected': option.value === order
              }"
            >
              <v-list-tile-avatar>
                <v-icon :class="{'wi-kanban__order-icon--selected': option.value === order}">
                  {{ option.icon }}
                </v-icon>
              </v-list-tile-avatar>
              <v-list-tile-title>{{ option.label }}</v-list-tile-title>
            </v-list-tile>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn flat @click="orderMenu = false">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-btn v-if="!hideAddButton" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="$WiEditDialog({wiConfig: store + '-open', onSubmit: load, data: {}})" :disabled="loading">
        <v-icon>add</v-icon>
        <span>Adicionar Novo</span>
      </v-btn>
      <v-btn :disabled="loading" icon @click="load()"><v-icon style="color: white;">refresh</v-icon></v-btn>
    </v-toolbar>
    <v-progress-linear v-if="loading" :indeterminate="true" style="margin: unset; position: absolute;"></v-progress-linear>
    <v-flex sm12 v-if="stages.length < 1 && !loading">
      <KanbanEmptyStages 
        :icon="emptyStagesIcon"
        :title="emptyStagesTitle"
        :description="emptyStagesDescription"
        :buttonText="emptyStagesButtonText"
        @action="handleEmptyStagesAction"
      />
    </v-flex>
    <v-flex sm12 v-if="stages.length < 1 && loading">
      <LoadingIndicator />
    </v-flex>
    <div v-if="stages.length > 0" style="height: calc(100vh - 97px) !important; overflow-x: auto !important; overflow-y: hidden !important; padding: 5px 5px;">
      <div :style="{width: (getActiveStagesCount * 301) + 'px'}" class="wi-kanban__container">
        <div 
          v-for="(stage, stageIndex) in stages" 
          :key="stageIndex"
          :class="{
            'box': true,
            'wi-kanban__stage': true,
            'wi-kanban__stage--closed': stage.stage_action === 'closed',
            'wi-kanban__stage--canceled': stage.stage_action === 'canceled',
            'wi-kanban__stage--floating': stage.stage_action === 'closed' || stage.stage_action === 'canceled',
            'wi-kanban__stage--dragging': isCardDragging
          }"
        >
          <template v-if="stage.stage_action === 'open'">
            <div style="padding: 5px; max-width: 300px !important; margin: unset;">
              <div class="elevation-0">
                <KanbanColumnHeader
                  :stage-color="stage.color"
                  :stage-icon="stage.icon"
                  :stage-name="stage.name"
                  :items-total="stage.items.total"
                  :store-stage="storeStage"
                  :stage-data="stage"
                  :show-stage-action="showStageAction"
                  :stage-action-icon="stageActionIcon"
                  :stage-menu-items="stageMenuItems"
                  @refresh="load"
                  @stage-menu-action="(data) => { $emit('stage-menu-action', data) }"
                />
                <v-divider></v-divider>
                <div
                  class="wi-kanban__header"
                  style="height: calc(100vh - 180px) !important; overflow-y: auto; padding: 5px; background: #DFE1E6;"
                >
                  <v-toolbar
                    v-if="$Auth.user().account.id == '490fba83-2a5e-4b11-935a-754b25000880' && stage.items.data && stage.items.data.length > 0 && stage.sum_quantity_unit"
                    dense
                    class="elevation-10 toolbar_status"
                    style="padding: 0px !important; margin-bottom: 5px;"
                  >
                    <v-icon>list</v-icon>
                    <strong style="font-size: 14px;">
                      Produtos/Serviços:
                    </strong>
                    <v-spacer></v-spacer>
                    <strong style="font-size: 16px;">
                      {{ stage.sum_quantity_unit }}
                    </strong>
                  </v-toolbar>
                  <v-toolbar
                    v-if="showAmountSum && $Auth.user().account.id != '490fba83-2a5e-4b11-935a-754b25000880' && stage.items.data && stage.items.data.length > 0"
                    dense
                    class="elevation-10 toolbar_status wi-kanban__column-amount-total"
                    style="padding: 0px !important; margin-bottom: 5px;"
                  >
                    <v-icon>attach_money</v-icon>
                    <strong style="font-size: 14px;">
                      Valor total:
                    </strong>
                    <v-spacer></v-spacer>
                    <strong style="font-size: 16px;">
                      {{ stage.sum_total_liquid | money }}
                    </strong>
                  </v-toolbar>
                  <Container
                    @drag-start="handleDragStart"
                    @drag-end="handleDragEnd"
                    @drop="onDrop(stageIndex, $event)"
                    group-name="col"
                    :get-child-payload="(index) => {
                      return stage.items.data[index]
                    }"
                    drag-class="card-ghost"
                    drop-class="card-ghost-drop"
                    :drop-placeholder="{
                      className: 'cards-drop-preview',
                      animationDuration: '150',
                      showOnTop: true
                    }"
                    class="wi-kanban__container"
                    :should-animate-drop="() => false"
                  >
                    <Draggable v-for="(item, itemIndex) in reorderCards(stage.items.data, order)" :key="itemIndex">
                      <slot name="items" :item="item" :index="itemIndex" :refresh="load" :loading="loading"></slot>
                    </Draggable>
                    <v-btn
                      v-if="!noPaginate && stage.items.data && stage.items.data.length > 0"
                      block
                      outline color="black"
                      @click="itemPaginate(stage)"
                      :disabled="stage.items.last_page == stage.items.current_page"
                      :loading="stage.loading"
                    >Buscar Mais</v-btn>
                  </Container>
                </div>
                <v-footer style="background: #DFE1E6; min-height: 5px !important; height: 5px !important;"></v-footer>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="wi-kanban__stage-floating-content">
              <v-icon :color="stage.color" size="32">
                {{ stage.icon }}
              </v-icon>
              <div class="wi-kanban__stage-floating-title">{{ stage.name }}</div>
              <Container
                @drop="onDrop(stageIndex, $event)"
                group-name="col"
                :get-child-payload="() => null"
                :should-animate-drop="() => false"
                drag-class="card-ghost"
                drop-class="card-ghost-drop"
                :drop-placeholder="{
                  className: 'cards-drop-preview',
                  animationDuration: '150',
                  showOnTop: true
                }"
              >
                <!-- Container vazio apenas para aceitar drops -->
              </Container>
            </div>
          </template>
        </div>
      </div>
    </div>
    <v-btn
      @click="$WiEditDialog({wiConfig: store + '-open', onSubmit: load, data: {}})"
      color="green"
      class="white--text hidden-md-only hidden-lg-only hidden-xl-only elevation-17" 
      fab fixed bottom right
    >
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import WiListFilters from '@/default/component/WiList/Filters'
  import {Container, Draggable} from 'vue-smooth-dnd'
  import {applyDrag} from '@/default/components/utils/helpers'
  import {callApi} from '@/default/service/Api'
  import LoadingIndicator from '@/modules/service-desk/pages/ChamadosKanban/components/LoadingIndicator.vue'
  import KanbanColumnHeader from './components/KanbanColumnHeader.vue'
  import KanbanEmptyStages from './components/KanbanEmptyStages.vue'
  export default {
    name: 'WiKanban',
    data () {
      return {
        innerHeight: window.innerHeight,
        stages: [],
        loading: false,
        editModal: false,
        orderMenu: false,
        modalForm: {},
        modalStore: 'chamados-todos',
        currentMovement: {
          added: {},
          removed: {}
        },
        order: '',
        filters: {},
        isCardDragging: false
      }
    },
    methods: {
      /**
       * Manipula o evento de drop de um item no kanban
       * @param {Number} collection - Índice do estágio onde o item foi solto
       * @param {Object} dropResult - Objeto contendo informações do evento de drop
       * @param {Number} dropResult.addedIndex - Índice onde o item foi adicionado
       * @param {Number} dropResult.removedIndex - Índice de onde o item foi removido
       * @param {Object} dropResult.payload - Dados do item movido
       * 
       * O método gerencia 3 cenários principais:
       * 1. Movimentação entre colunas diferentes (mudança de status)
       * 2. Reordenação dentro da mesma coluna (mudança de prioridade)
       * 3. Bloqueio de movimentação para itens com status específico
       */
      onDrop (collection, dropResult) {
        // Registra posição de adição do item
        if (dropResult.addedIndex !== null) {
          this.currentMovement.added = {
            index: collection,
            data: dropResult.payload,
            position: dropResult.addedIndex
          }
        }

        // Registra posição de remoção do item
        if (dropResult.removedIndex !== null) {
          this.currentMovement.removed = {
            index: collection,
            data: dropResult.payload,
            position: dropResult.removedIndex
          }
        }

        // Array para controle de ordem dos itens
        const itensUpdate = []

        // Verifica se o item pode ser movido usando a função fornecida via prop
        if (this.canMoveItem(dropResult.payload)) {
          // Atualiza dados da coluna aplicando o drag
          this.stages[collection].items.data = applyDrag(
            this.stages[collection].items.data, 
            dropResult
          ).filter((item, key) => {
            itensUpdate.push({id: item.id, order: key})
            return item
          })
          
          this.$forceUpdate()

          // Verifica se é mudança de coluna (status)
          const isStagingChange = dropResult.removedIndex === null && 
                                 dropResult.addedIndex !== null && 
                                 dropResult.payload[this.fieldReferenceInItem] !== this.stages[collection].id

          if (isStagingChange) {
            // Prepara dados para mudança de status
            this.agendarDialog = { 
              id: dropResult.payload.id,
              active: false,
              stage: this.stages[collection],
              DateTime: null,
              order: dropResult.addedIndex 
            }

            // Verifica se precisa de data para mudança
            if (this.stages[collection].exigir_data === 'sim') {
              this.agendarDialog.active = true
            } else if (this.confirmationOnMove) {
              // Solicita confirmação do usuário apenas se confirmationOnMove for true
              this.$swal({
                title: this.messageAlertTitle,
                text: this.messageAlertText,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, alterar!',
                cancelButtonText: 'Cancelar'
              }).then((response) => {
                if (response.value) {
                  this.alterarChamado(this.agendarDialog)
                  this.setItemAttributesAfterchange()
                } else {
                  this.stages[collection].items.data = this.stages[collection].items.data.filter(
                    item => item.id !== dropResult.payload.id
                  )
                  this.revertCardMovement()
                  this.$forceUpdate()
                }
              })
            } else {
              // Se confirmationOnMove for false, altera diretamente
              this.alterarChamado(this.agendarDialog)
              this.setItemAttributesAfterchange()
            }
          }

          // Se for apenas reordenação na mesma coluna
          if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
            this.alterarPrioridade()
          }
        } else {
          // Fecha dialog se estiver aberto
          if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
            this.$swal.close()
            this.alterarPrioridade()
          }

          // Usa mensagem customizada via prop
          const message = this.immobileItemMessage(dropResult.payload)
          this.$swal({
            type: 'info',
            title: message.title,
            text: message.text
          })
        }
      },
      handleDragStart(dragResult) {
        if (dragResult.isSource) {
          this.isCardDragging = true
        }
      },
      handleDragEnd(dragResult) {
        if (dragResult.isSource) {
          this.isCardDragging = false
        }
      },
      setItemAttributesAfterchange: function () {
        this.stages[this.currentMovement.added.index].items.total++
        this.stages[this.currentMovement.removed.index].items.total--
        this.stages[this.currentMovement.added.index].items.data[this.currentMovement.added.position][this.fieldReferenceInItem] = this.stages[this.currentMovement.added.index].id
      },
      revertCardMovement: function () {
        this.stages[this.currentMovement.added.index].items.data = applyDrag(this.stages[this.currentMovement.added.index].items.data, {
          addedIndex: null,
          payload: this.currentMovement.added.data,
          removedIndex: this.currentMovement.added.position
        })
        this.stages[this.currentMovement.removed.index].items.data = applyDrag(this.stages[this.currentMovement.removed.index].items.data, {
          addedIndex: this.currentMovement.removed.position,
          payload: this.currentMovement.removed.data,
          removedIndex: null
        })
      },
      load: function (isRouteChange) {
        if (isRouteChange) this.stages = []
        this.loading = true
        callApi.get({
          uri: this.apiGetUrl,
          params: this.apiParams,
          sucess: (data) => {
            this.stages = data.data
            this.$forceUpdate()
            this.loading = false
          },
          error: (err) => {
            console.error(err)
            this.loading = false
          }
        })
      },
      /**
       * Altera o estágio/status de um item
       * @param {Object} config - Configuração da alteração
       * @returns {Promise<void>}
       */
      async alterarChamado(config) {
        try {
          this.loading = true
          
          const dados = {
            id: config.id,
            order: config.order,
            stage_id: config.stage.id
          }

          // Adiciona data de agendamento se existir
          if (config.DateTime) {
            dados.agendamento_log = String(config.DateTime).replace('T', ' ')
          }

          // Chama função fornecida via prop
          const result = await this.onStageChange(config)

          if (result.success) {
            this.agendarDialog.active = false
          } else {
            this.$swal({ 
              type: 'error', 
              title: 'Oops... Algo deu errado!', 
              text: result.error?.message || 'Tente novamente ou contacte o suporte.' 
            })
          }
        } catch (error) {
          console.error('Erro ao alterar item:', error)
          this.$swal({ 
            type: 'error', 
            title: 'Erro inesperado', 
            text: 'Tente novamente ou contacte o suporte.' 
          })
        } finally {
          this.loading = false
        }
      },
      /**
       * Altera a prioridade/ordem de um item dentro da mesma coluna
       * @returns {Promise<void>}
       */
      async alterarPrioridade() {
        const item = this.stages[this.currentMovement.added.index]
        const data = {
          id: this.currentMovement.added.data.id,
          order: this.currentMovement.added.position,
          stage_id: item.id
        }

        try {
          this.loading = true
          
          const result = await this.onReorder(data)

          if (!result.success && result.error) {
            console.error('Erro ao reordenar:', result.error)
            this.$swal({
              type: 'error',
              title: 'Erro ao reordenar',
              text: result.error.message || 'Não foi possível alterar a ordem do item'
            })
          }
        } catch (error) {
          console.error('Erro ao reordenar:', error)
          this.$swal({
            type: 'error',
            title: 'Erro inesperado',
            text: 'Não foi possível alterar a ordem do item'
          })
        } finally {
          this.loading = false
        }
      },
      cancelDialog: function () {
        this.load()
        this.agendarDialog.active = false
      },
      openEditModal: function (store, dados) {
        this.modalForm = dados
        this.modalStore = store
        this.editModal = true
      },
      closeModal: function () {
        this.editModal = false
      },
      handleOrderChange(orderValue) {
        this.order = orderValue
        // this.$emit('order-change', orderValue)
        localStorage.setItem(`${this.store}-kanban-order`, orderValue)
        this.orderMenu = false
        this.load()
      },
      getOrderClass(orderValue) {
        return {
          'wi-kanban__order--selected': orderValue === this.order
        }
      },
      setFilters: function (filters) {
        this.filters = filters
        this.$forceUpdate()
        this.load()
      },
      /**
       * Realiza a paginação de uma coluna
       * @param {Object} selectedStage - Estágio selecionado para paginação
       */
      async itemPaginate(selectedStage) {
        const stageIndex = this.stages.findIndex(stage => stage.id === selectedStage.id)
        if (stageIndex === -1) return

        try {
          this.stages[stageIndex].loading = true
          this.$forceUpdate()

          const result = await this.onPaginate({
            stageId: selectedStage.id,
            currentPage: selectedStage.items.current_page,
            nextPage: selectedStage.items.current_page + 1,
            params: {
              ...this.apiParams,
              limit: 5
            }
          })

          if (result.success) {
            const { items, total, currentPage, lastPage } = result.data

            // Atualiza total de itens
            this.stages[stageIndex].items.total = total

            // Adiciona novos itens sem duplicatas
            items.forEach(item => {
              const exists = this.stages[stageIndex].items.data.some(
                stageItem => stageItem.id === item.id
              )
              if (!exists) {
                this.stages[stageIndex].items.data.push(item)
              }
            })

            // Atualiza informações de paginação
            this.stages[stageIndex].items.last_page = lastPage
            this.stages[stageIndex].items.current_page = currentPage
          } else if (result.error) {
            console.error('Erro na paginação:', result.error)
          }
        } catch (error) {
          console.error('Erro ao paginar:', error)
        } finally {
          this.stages[stageIndex].loading = false
          this.$forceUpdate()
        }
      },
      /**
       * Configura a ordem inicial do kanban
       * Prioriza ordem salva no localStorage, senão usa a ordem padrão
       */
      setCachedOrder() {
        const savedOrder = localStorage.getItem(`${this.store}-kanban-order`)
        this.order = savedOrder || this.defaultOrder
      },
      handleEmptyStagesAction() {
        this.$emit('empty-stages-action')
      },
      subscribeOnKanban() {
        if (this.subscribeOnCards) {
          this.subscribeOnCards(this.setCardsBySubscribe)
        }
      },
      setCardsBySubscribe(card) {
        if (!card || !this.stages) return

        // Percorre todos os stages
        this.stages.forEach((stage) => {
          if (!stage.items || !stage.items.data) return

          // Verifica se o card pertence a este stage
          if (card[this.fieldReferenceInItem] === stage.id) {
            // Procura se o card já existe neste stage
            const existingCardIndex = stage.items.data.findIndex(item => item.id === card.id)
            
            if (existingCardIndex !== -1) {
              // Atualiza o card existente
              this.$set(stage.items.data, existingCardIndex, { ...card })
            } else {
              // Adiciona o novo card
              stage.items.data.push({ ...card })
              stage.items.total++
            }
          } else {
            // Verifica se o card está em um stage errado e remove
            const wrongCardIndex = stage.items.data.findIndex(item => item.id === card.id)
            if (wrongCardIndex !== -1) {
              stage.items.data.splice(wrongCardIndex, 1)
              stage.items.total--
            }
          }
        })

        // Força a atualização do Vue
        this.$forceUpdate()
      }
    },
    created: function () {
      this.setCachedOrder()
    },
    mounted: function () {
      this.load()
      this.subscribeOnKanban()
    },
    computed: {
      dark () {
        return this.$store.state.style.dark || false
      },
      auth () {
        return this.$store.state.user || {}
      },
      wiConfig () {
        return this.$store.state.pages[this.store + '-open'] || {}
      },
      apiParams () {
        return {
          ...this.apiGetParams,
          ...this.filters,
          order: this.order
        }
      },
      getActiveStagesCount() {
        return this.stages.filter(stage => stage.stage_action === 'open').length
      }
    },
    watch: {
      '$route': 'load'
    },
    components: {
      Container,
      Draggable,
      WiListFilters,
      LoadingIndicator,
      KanbanColumnHeader,
      KanbanEmptyStages
    },
    props: {
      'title': String,
      'store': String,
      'noPaginate': {
        type: Boolean,
        default: false
      },
      'apiGetUrl': {
        type: String,
        required: true
      },
      'apiGetParams': Object,
      'apiPutUrl': String,
      'apiPutParams': Object,
      'fieldOrder': String,
      'fieldReferenceInItem': String,
      'messageAlertTitle': String,
      'messageAlertText': String,
      'routerClick': Object,
      'storeStage': String,
      hideAddButton: {
        type: Boolean,
        default: false
      },
      hideReorderButton: {
        type: Boolean,
        default: false
      },
      'showAmountSum': {
        type: Boolean,
        default: true
      },
      /**
       * Função que valida se um item pode ser movido
       * @param {Object} payload - Dados do item sendo movido
       * @returns {Boolean} true se o item pode ser movido, false caso contrário
       * @example
       * :can-move-item="(item) => item.status !== 'completed'"
       */
      canMoveItem: {
        type: Function,
        default: (payload) => payload.stage === 1 // mantém comportamento padrão legado
      },
      /**
       * Mensagem de erro quando item não pode ser movido
       * @param {Object} payload - Dados do item sendo movido
       * @returns {Object} Objeto com título e texto da mensagem
       */
      immobileItemMessage: {
        type: Function,
        default: (payload) => ({
          title: `Não é possível mover um item "${payload.status.name}"!`,
          text: 'Altere seu status manualmente.'
        })
      },
      /**
       * Função que realiza a atualização do item quando movido entre colunas
       * @param {Object} data - Dados do item a ser atualizado
       * @param {String|Number} data.id - ID do item
       * @param {Number} data.order - Nova ordem do item
       * @param {String|Number} data.stage_id - ID do novo estágio
       * @param {String} [data.agendamento_log] - Data de agendamento (opcional)
       * @returns {Promise} Promise que resolve com sucesso ou rejeita com erro
       * @example
       * :on-stage-change="async (data) => {
       *   try {
       *     await api.updateItem(data)
       *     return { success: true }
       *   } catch (error) {
       *     return { success: false, error }
       *   }
       * }"
       */
      onStageChange: {
        type: Function,
        required: true
      },
      /**
       * Função que realiza a reordenação de itens dentro da mesma coluna
       * @param {Object} data - Dados para reordenação
       * @param {String|Number} data.id - ID do item
       * @param {Number} data.order - Nova posição do item
       * @param {String|Number} data.stage_id - ID do estágio
       * @returns {Promise<Object>} Promise que resolve com o resultado da operação
       * @example
       * :on-reorder="(data) => {
       *   return new Promise((resolve) => {
       *     api.reorderItem(data).then(() => {
       *       resolve({ success: true })
       *     }).catch(error => {
       *       resolve({
       *         success: false,
       *         error: { message: 'Erro ao reordenar item' }
       *       })
       *     })
       *   })
       * }"
       */
      onReorder: {
        type: Function,
        required: true
      },
      /**
       * Lista de opções de ordenação
       * @type {Array<Object>}
       * @property {string} value - Valor usado para ordenação (ex: 'created_at,desc')
       * @property {string} label - Texto exibido no menu
       * @property {string} icon - Ícone do Vuetify
       * @example
       * :order-options="[
       *   {
       *     value: 'created_at,desc',
       *     label: 'Data de criação (Mais recentes)',
       *     icon: 'mdi-clock'
       *   },
       *   {
       *     value: 'priority,asc',
       *     label: 'Prioridade (Crescente)',
       *     icon: 'mdi-arrow-up'
       *   }
       * ]"
       */
      orderOptions: {
        type: Array,
        default: () => []
      },
      /**
       * Ordenação padrão inicial do kanban
       * @type {string}
       * @example 'created_at,desc'
       * @required
       */
      defaultOrder: {
        type: String,
        required: true
      },
      onPaginate: {
        type: Function,
        required: true
      },
      /**
       * Controla a visibilidade do botão de ação em cada coluna
       * @type {Boolean}
       */
      showStageAction: {
        type: Boolean,
        default: false
      },
      /**
       * Ícone do botão de ação das colunas
       * @type {String}
       */
      stageActionIcon: {
        type: String,
        default: 'mdi-dots-vertical'
      },
      /**
       * Lista de itens do menu de ações do estágio
       * @type {Array<Object>}
       * @property {string} id - Identificador único do item
       * @property {string} title - Texto a ser exibido
       * @property {string} icon - Ícone do item (opcional)
       * @example
       * :stage-menu-items="[
       *   { id: 'edit', title: 'Editar', icon: 'mdi-pencil' },
       *   { id: 'delete', title: 'Excluir', icon: 'mdi-delete' }
       * ]"
       */
      stageMenuItems: {
        type: Array,
        default: () => []
      },
      // Props para o KanbanEmptyStages
      emptyStagesIcon: {
        type: String,
        default: 'inbox'
      },
      emptyStagesTitle: {
        type: String,
        default: 'Nenhuma etapa encontrada'
      },
      emptyStagesDescription: {
        type: String,
        default: 'Não existem etapas configuradas para este kanban'
      },
      emptyStagesButtonText: {
        type: String,
        default: 'Adicionar Etapa'
      },
      reorderCards: {
        type: Function,
        default: (cards) => cards
      },
      subscribeOnCards: {
        type: Function,
        default: null
      },
      confirmationOnMove: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style scoped>
.wi-kanban__order-item {
  transition: background-color 0.2s ease;
}

.wi-kanban__order-item--selected {
  background-color: #FB8C00 !important;
}

.wi-kanban__order-item--selected .v-list__tile__title {
  color: white !important;
}

.wi-kanban__order-icon--selected {
  color: white !important;
}

.theme--dark .wi-kanban__order-item--selected {
  background-color: #FF9800 !important;
}

.wi-kanban__container {
  position: relative;
}

.wi-kanban__stage {
  transition: all 0.3s ease;
}

.wi-kanban__stage--floating {
  position: fixed;
  width: 250px;
  height: 120px;
  z-index: 100;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  bottom: 20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  pointer-events: none;
}

.wi-kanban__stage--dragging.wi-kanban__stage--floating {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.wi-kanban__stage--closed {
  left: calc(100vw - 540px); /* 100vw - (250px + 20px + 250px + 20px) */
  border: 2px dashed #4CAF50;
  z-index: 101;
}

.wi-kanban__stage--canceled {
  left: calc(100vw - 270px); /* 100vw - (250px + 20px) */
  border: 2px dashed #F44336;
  z-index: 102;
}

.wi-kanban__stage-floating-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.wi-kanban__stage-floating-title {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.wi-kanban__stage--closed {
  bottom: 20px;
  right: 20px;
  border: 2px dashed #4CAF50;
}

.wi-kanban__stage--closed:hover {
  background: rgba(76, 175, 80, 0.1);
}

.wi-kanban__stage--canceled:hover {
  background: rgba(244, 67, 54, 0.1);
}

.wi-kanban__stage--floating:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.02),
    rgba(0, 0, 0, 0.02) 10px,
    rgba(0, 0, 0, 0.04) 10px,
    rgba(0, 0, 0, 0.04) 20px
  );
  border-radius: 8px;
  z-index: -1;
}

/* Ajuste para garantir que o Container do vue-smooth-dnd funcione nas colunas flutuantes */
.wi-kanban__stage--floating .smooth-dnd-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
