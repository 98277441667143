import { Model } from '@/default/model/Model'
import {
  PERMISSION_TALKS_TALK_STAGE,
  PERMISSION_TALKS_TALK_STAGE_CREATE,
  PERMISSION_TALKS_TALK_STAGE_UPDATE,
  PERMISSION_TALKS_TALK_STAGE_DELETE
} from '@/default/constants/permissions'

export class TalkStage extends Model {
  constructor () {
    super()
    this.list = true
    this.name = 'talk-stage'
    this.id = {
      id: PERMISSION_TALKS_TALK_STAGE,
      create: PERMISSION_TALKS_TALK_STAGE_CREATE,
      update: PERMISSION_TALKS_TALK_STAGE_UPDATE,
      delete: PERMISSION_TALKS_TALK_STAGE_DELETE
    }
    this.title = {
      list: 'Etapas de Atendimento',
      edit: {
        insert: 'Nova Etapa de Atendimento',
        update: 'Editar Etapa de Atendimento'
      }
    }
    this.apiUrl = 'talks/stage'
    this.params['type_id'] = 1
    this.search = {
      placeholder: 'Pesquise pelo nome da etapa',
      fields: ['id', 'name']
    }
    this.params = {
      limit: 10
    }
    this.icon = 'sell'
    
    this.size = ['xs12']

    this.maxWidth = '700px'

    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkStages')

    this.setFields(this.fieldsList)
    this.setFields(this.fieldsEdit)
  }
  
  fieldsList = {
    id: this.fieldList({
      value: 'id',
      text: 'ID'
    }),
    name: this.fieldList({
      value: 'name',
      text: 'Nome da etapa de atendimento'
    })
  }

  fieldsEdit = {
    subHeader1: this.fieldSubHeader({
      text: 'Informe o nome desta etapa de atendimento.'
    }),
    name: this.fieldText({
      value: 'name',
      text: 'Nome da Etapa de Atendimento',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'ls6', 'xl6']
    }),
    talk_stage_cluster_id: this.fieldSelectApi({
      value: 'talk_stage_cluster_id',
      text: 'Fluxo de Etapas',
      required: true,
      show: 'name',
      api: {
        url: 'talks/stage-cluster',
        params: {
          type_id: 1
        }
      },
      size: ['xs12', 'sm12', 'md6', 'ls6', 'xl6']
    }),
    subHeader2: this.fieldSubHeader({
      text: '(Opcional) Informe a cor e ícone.'
    }),
    order: this.fieldNumber({
      value: 'order',
      text: 'Posição no Fluxo',
      size: ['xs12', 'sm12', 'md3', 'ls3', 'xl3']
    }),
    icon: this.fieldWiGoogleIcon({
      value: 'icon',
      text: 'Ícone da Etapa de Atendimento',
      size: ['xs12', 'sm12', 'md4', 'ls4', 'xl4']
    }),
    color: this.fieldColor({
      value: 'color',
      text: 'Cor da Etapa de Atendimento',
      size: ['xs12', 'sm12', 'md5', 'ls5', 'xl5']
    }),
    subHeader3: this.fieldSubHeader({
      text: 'Observações adicionais.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Observações adicionais sobre esta etapa de atendimento.'
    })
  }
}
