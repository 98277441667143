<template>
  <div>
    <WiCategoriesReport
      :title="config.title"
      viewIndex="finance-categories-in"
      :apiEndpoint="config.api.url"
      parentCategoryField="finance_category_id"
      colorField="color"
      iconField="icon"
    />
  </div>
</template>

<script>
import WiCategoriesReport from '@/default/component/WiCategories/Report/index.vue'
export default {
  name: 'FinancesByCategories',
  computed: {
    config() {
      const { title, in_out } = this.$route.meta
      return {
        title,
        api: {
          url: `finance/category/hierarchy/${in_out === 1 ? 'in' : 'out'}`
        }
      }
    }
  },
  components: {
    WiCategoriesReport
  }
}
</script>

