import i18n from '@/lang/i18n'
import { Auth } from '@/default/service/Auth'
import {
  PERMISSION_TALKS_DASHBOARD_TALKS,
  PERMISSION_TALKS_DASHBOARD_MESSAGES,
  PERMISSION_TALKS_TALK_CATEGORY,
  PERMISSION_TALKS_TALK_STAGE_KANBAN,
  PERMISSION_TALKS_TALK_STAGE
} from '@/default/constants/permissions'

const hasDashboardTalksPermission = Auth.checkPermission(PERMISSION_TALKS_DASHBOARD_TALKS)
const hasDashboardMessagesPermission = Auth.checkPermission(PERMISSION_TALKS_DASHBOARD_MESSAGES)

// Define os menus de dashboard
const dashboardTalksMenu = {
  id: PERMISSION_TALKS_DASHBOARD_TALKS,
  icon: 'dashboard',
  title: i18n.t('modules.talks.menu.dashboard.title'),
  route: {
    name: 'dashboard-talks'
  }
}

const dashboardMessagesMenu = {
  id: PERMISSION_TALKS_DASHBOARD_MESSAGES,
  icon: 'dashboard',
  title: i18n.t('modules.talks.menu.dashboardMessages.title'),
  route: {
    name: 'dashboard-talk-messages'
  }
}

// Monta o menu de dashboards baseado nas permissões
let dashboardMenu = []

if (hasDashboardTalksPermission && hasDashboardMessagesPermission) {
  dashboardMenu.push({
    id: PERMISSION_TALKS_DASHBOARD_TALKS,
    icon: 'dashboard',
    title: 'Dashboards',
    children: [dashboardTalksMenu, dashboardMessagesMenu]
  })
} else if (hasDashboardTalksPermission) {
  dashboardMenu.push(dashboardTalksMenu)
} else if (hasDashboardMessagesPermission) {
  dashboardMenu.push(dashboardMessagesMenu)
}

export default [
  ...(dashboardMenu.length ? [dashboardMenu[0]] : []),
  {
    id: 'f55197d7',
    icon: 'message',
    title: i18n.t('modules.talks.menu.talkView.title'),
    route: {
      name: 'talk-view'
    }
  },
  {
    id: '9f88b04d',
    icon: 'category',
    title: i18n.t('modules.talks.menu.tags.title'),
    route: {
      name: 'register-list',
      params: {
        page: 'tag'
      }
    }
  },
  {
    id: PERMISSION_TALKS_TALK_STAGE_KANBAN,
    icon: 'view_kanban',
    title: 'Etapas de Atendimento',
    route: {
      name: 'talk-stages-kanban'
    },
    hideOnDevices: ['mobile']
  },
  // {
  //   id: '9f88b04d',
  //   icon: 'category',
  //   title: i18n.t('modules.talks.menu.tagsKanban.title'),
  //   children: [
  //     {
  //       id: '9f88b04d',
  //       icon: 'view_week',
  //       title: i18n.t('modules.talks.menu.kanban.title'),
  //       route: {
  //         name: 'talks-tags-kanban'
  //       }
  //     },
  //     {
  //       id: '9f88b04d',
  //       icon: 'category',
  //       title: i18n.t('modules.talks.menu.tags.title'),
  //       route: {
  //         name: 'register-list',
  //         params: {
  //           page: 'tag'
  //         }
  //       }
  //     }
  //   ]
  // },
  {
    id: 'f55197d7',
    icon: 'send_to_mobile',
    title: 'Campanhas e envio em massa',
    route: {
      name: 'talks-campaigns'
    }
  },
  {
    id: 'aa41348f',
    icon: 'event',
    title: 'Agendamentos',
    children: [
      {
        id: 'aa41348f',
        icon: 'calendar_month',
        title: 'Calendário de Agendamentos',
        route: {
          name: 'talk-schedules-calendar'
        },
        hideOnDevices: ['mobile']
      },
      {
        id: 'aa41348f',
        icon: 'checklist',
        title: 'Lista de Agendamentos',
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-schedule'
          }
        }
      }
    ]
  },
  {
    id: 'f55197d7',
    icon: 'sms',
    title: i18n.t('modules.talks.menu.talks.title'),
    children: [
      {
        id: 'f55197d7',
        icon: 'density_small',
        title: 'Todos os Atendimentos',
        route: {
          name: 'talk-list',
          params: {
            page: 'talk'
          }
        }
      },
      {
        id: 'f55197d7',
        icon: 'select_all',
        title: i18n.t('modules.talks.menu.talks.opened.title'),
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-opened'
          }
        }
      },
      {
        id: 'f55197d7',
        icon: 'done_outline',
        title: i18n.t('modules.talks.menu.talks.closed.title'),
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-closed'
          }
        }
      },
      {
        id: 'f55197d7',
        icon: 'close',
        title: i18n.t('modules.talks.menu.talks.canceled.title'),
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-canceled'
          }
        }
      }
    ]
  },
  {
    id: '9f88b04d',
    icon: 'group',
    title: 'Contatos e Grupos',
    children: [
      {
        id: '9f88b04d',
        icon: 'perm_identity',
        title: i18n.t('modules.talks.menu.contacts.title'),
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-contact'
          }
        }
      },
      {
        id: '9f88b04d',
        icon: 'group',
        title: i18n.t('modules.talks.menu.groups.title'),
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-group'
          }
        }
      }
    ]
  },
  {
    id: 'b49f9043',
    icon: 'phone_iphone',
    title: i18n.t('modules.talks.menu.channels.title'),
    route: {
      name: 'talk-list',
      params: {
        page: 'talk-channel'
      }
    }
  },
  {
    id: '37c54bf1',
    icon: 'settings',
    title: i18n.t('modules.talks.menu.configurations.title'),
    children: [
      {
        id: '37c54bf1',
        icon: 'smart_toy',
        title: `Meus ${i18n.t('modules.talks.menu.chatbots.title')}`,
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-menu'
          }
        }
      },
      {
        id: '23af840f',
        icon: 'precision_manufacturing',
        title: i18n.t('modules.talks.menu.chatbotsBuilder.title'),
        route: {
          name: 'talk-flow'
        }
      },
      {
        id: 'f55197d7',
        icon: 'message',
        title: 'Mensagens Rápidas',
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-message-template'
          }
        }
      },
      {
        id: PERMISSION_TALKS_TALK_CATEGORY,
        icon: 'bookmark',
        title: 'Categorias de Atendimentos',
        route: {
          name: 'talk-categories'
        }
      },
      {
        id: 'f55197d7',
        icon: 'bookmark',
        title: 'Categorias de Mensagens',
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-message-template-category'
          }
        }
      },
      {
        id: PERMISSION_TALKS_TALK_STAGE,
        icon: 'bookmark',
        title: 'Etapas de Atendimento',
        route: {
          name: 'talk-list',
          params: {
            page: 'talk-stage'
          }
        }
      },
      // {
      //   id: 'f55197d7',
      //   icon: 'build',
      //   title: 'Ferramentas de Atendimento',
      //   route: {
      //     name: 'talks-tools'
      //   }
      // }
    ]
  }
]
