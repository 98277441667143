<template>
  <div class="business-card-value">
    <v-card-title class="business-card-value__title">
      <v-icon
        color="white"
        class="business-card-value__icon business-card-value__icon--value"
      >attach_money</v-icon>
      <label class="business-card-value__label"><strong>Valor:</strong></label>
      <v-spacer></v-spacer>
      <strong class="business-card-value__amount">{{ item.total_liquid | money }}</strong>
    </v-card-title>
  </div>
</template>

<script>
export default {
  name: 'BusinessCardValue',
  props: {
    item: { type: Object, required: true }
  }
}
</script>

<style scoped>
.business-card-value__divider {
  margin: 0;
  opacity: 0.5;
}

.business-card-value__title {
  padding: 0px 8px;
  min-height: 30px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.theme--light .business-card-value__title {
  background: linear-gradient(45deg, #4caf50, #2e7d32);
}

.theme--dark .business-card-value__title {
  background: linear-gradient(45deg, #388e3c, #1b5e20);
}

.business-card-value__icon {
  margin-right: 6px;
  font-size: 18px;
  /* opacity: 0.9; */
}

.business-card-value__icon--value {
  color: #ffffff;
}

.theme--light .business-card-value__label {
  color: rgba(255, 255, 255, 0.9);
}

.theme--dark .business-card-value__label {
  color: rgba(255, 255, 255, 0.9);
}

.business-card-value__label {
  font-size: 0.85em;
  font-weight: 500;
}

.theme--light .business-card-value__amount {
  color: #ffffff;
}

.theme--dark .business-card-value__amount {
  color: #ffffff;
}

.business-card-value__amount {
  font-size: 0.95em;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.business-card-value__title:hover {
  filter: brightness(1.1);
}
</style> 