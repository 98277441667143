<template>
  <v-card class="elevation-17">
    <div v-if="business && business.items && business.items.length > 0">
      <v-progress-linear
        v-if="orderLoading"
        :height="4"
        :indeterminate="true"
        style="width: 100%; margin: 0;"
      ></v-progress-linear>
      <table dense class="business-items__table elevation-0">
        <thead>
          <tr style="height: 40px;">
            <th
              v-for="header in selectedHeaders"
              class="hidden-xs-only hidden-sm-only"
              :key="header.text"
              style="text-align: left;margin: 0 !important; padding: 5px !important;"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        
        <Container
          tag="tbody"
          @drop="onDrop"
          drag-handle-selector=".column-drag-handle"
          drag-class="opacity-ghost"
          drop-class="opacity-ghost-drop"
          :animation-duration="200"
          :get-child-payload="getChildPayload"
          :group-name="'business-items'"
          :lock-axis="'y'"
        >
          <Draggable
            v-for="(item, itemIndex) in items"
            :key="`${item.item.id}--${itemIndex}`"
            tag="tr"
          >
            <td class="justify-center layout px-0" style="margin-top: 0px;">
              <div class="justify-left layout">
                <v-tooltip
                  v-if="business.stage == 1"
                  class="hidden-xs-only hidden-sm-only"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on" small
                      color="primary"
                      class="column-drag-handle"
                    >
                      drag_handle
                    </v-icon>
                  </template>
                  <span>
                    Arraste para reordenar
                  </span>
                </v-tooltip>

                <v-menu bottom left lazy>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" small style="margin: 2px 0px !important;">
                      <v-icon>settings</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-tile 
                      v-if="item.item.type == 1" 
                      :disabled="business.stage != 1"
                      @click="handleEditProduct(item)"
                    >
                      <v-list-tile-avatar>
                        <v-avatar>
                          <v-icon color="orange">edit</v-icon>
                        </v-avatar>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Editar</v-list-tile-title>
                    </v-list-tile>
                    
                    <v-list-tile 
                      v-if="item.item.type == 2" 
                      :disabled="business.stage != 1"
                      @click="handleEditService(item)"
                    >
                      <v-list-tile-avatar>
                        <v-avatar>
                          <v-icon color="orange">edit</v-icon>
                        </v-avatar>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Editar</v-list-tile-title>
                    </v-list-tile>
                    
                    <v-divider></v-divider>
                    
                    <v-list-tile
                      :disabled="business.stage != 1"
                      @click="handleDeleteItem(item)"
                    >
                      <v-list-tile-avatar>
                        <v-avatar>
                          <v-icon color="red">delete</v-icon>
                        </v-avatar>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Apagar</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </div>
            </td>
            <td style="padding: 0 !important; font-size: 11px;">
              {{ item.item.name || '- - -' }}
            </td>
            <td class="hidden-xs-only hidden-sm-only" style="padding: 0 !important; font-size: 11px;">
              {{ item.description || '- - -' }}
            </td>
            <td class="hidden-xs-only hidden-sm-only" style="padding: 0 !important; font-size: 12px;">
              {{ item.quantity | float }}
            </td>
            <td class="hidden-xs-only hidden-sm-only" style="padding: 0 !important; font-size: 12px;">
              {{ item.item.unit.code || '- - -' }}
            </td>
            <td class="hidden-xs-only hidden-sm-only" style="padding: 0 !important; font-size: 12px; width: 80px;">
              <span v-if="item.category">{{ item.category.name || '- - -' }}</span>
              <span v-else>- - -</span>
            </td>
            <td v-if="showAmounts" class="hidden-xs-only hidden-sm-only" style="padding: 0 !important; font-size: 12px;">
              {{ item.amount_unit | money }}
            </td>
            <td v-if="showAmounts" class="hidden-xs-only hidden-sm-only" style="padding: 0 !important; font-size: 12px;">
              {{ item.amount_discount | money }}
            </td>
            <td v-if="showAmounts" style="padding: 0 !important; font-size: 12px;">
              {{ item.amount_total | money }}
            </td>
          </Draggable>
          <!-- Nova linha para adicionar item -->
          <tr 
            v-if="isAddingNewItem" 
            ref="newItemRow" 
            :class="[
              'business-items__new-row',
              `business-items__new-row--${itemType}-${$vuetify.theme.dark ? 'dark' : 'light'}`
            ]"
          >
            <td>
              <div :class="[
                'business-items__type-badge',
                `business-items__type-badge--${itemType}`
              ]">
                <v-icon small>{{ getTypeIcon }}</v-icon>
                <span class="business-items__type-text">{{ typeLabels[itemType] }}</span>
              </div>
            </td>
            <td>
              <v-autocomplete
                v-if="business.make_stock && itemType === 'product'"
                v-model="selectedStock"
                :items="stocks"
                :loading="loadingStocks"
                item-text="name"
                item-value="id"
                label="Estoque"
                placeholder="Selecione o estoque"
                dense
                hide-details
                :class="[
                  'business-items__search',
                  `business-items__search--${itemType}`
                ]"
                @change="handleStockChange"
              >
                <template v-slot:item="{ item }">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-icon small class="mr-2">store</v-icon>
                      {{ item.name }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-model="newItem.selected"
                :items="searchResults"
                :loading="isSearching"
                :search-input.sync="searchQuery"
                :item-text="'name'"
                :item-value="'id'"
                :label="`Buscar ${typeLabels[itemType]}`"
                :placeholder="getSearchPlaceholder"
                :hide-details="true"
                :no-data-text="getNoDataText"
                :append-icon="''"
                ref="itemSearch"
                return-object
                :disabled="shouldDisableSearch"
                :item-disabled="item => isItemDisabled(item)"
                @keydown.enter="handleEnterKey"
                :class="[
                  'business-items__search',
                  `business-items__search--${itemType}`
                ]"
              >
                <template v-slot:item="{ item }">
                  <v-list-tile-content 
                    :class="[
                      'business-items__item',
                      isItemDisabled(item) ? 'business-items__item--disabled' : ''
                    ]"
                  >
                    <v-list-tile-title>
                      <v-icon small :color="typeColors[itemType]" class="mr-2">{{ getTypeIcon }}</v-icon>
                      {{ item.name }}
                    </v-list-tile-title>
                    <v-list-tile-sub-title class="business-items__item-details">
                      <div class="business-items__item-info">
                        <span :class="[
                          'business-items__unit-badge',
                          $vuetify.theme.dark ? 'business-items__unit-badge--dark' : ''
                        ]">
                          {{ item.unit.code }}
                        </span>
                        <span v-if="item.amount_sale > 0" class="business-items__price">
                          R$ {{ item.amount_sale.toFixed(2) }}
                        </span>
                        <span v-else class="grey--text">
                          Sem valor definido
                        </span>
                        <div v-if="shouldShowStock" 
                             :class="[
                               'business-items__stock-info',
                               `business-items__stock-info--${getStockStatus(item.stock_quantity, item.id)}`
                             ]"
                             :title="getItemAvailabilityMessage"
                        >
                          <v-icon small class="mr-1">{{ getStockIcon(item.stock_quantity) }}</v-icon>
                          <template v-if="getExistingItemQuantity(item.id) > 0">
                            <span class="business-items__stock-existing">
                              {{ getExistingItemQuantity(item.id) }} já {{ getExistingItemQuantity(item.id) === 1 ? 'adicionado' : 'adicionados' }}
                            </span>
                            <span class="business-items__stock-separator">|</span>
                          </template>
                          <span>
                            {{ getAvailableQuantity(item.stock_quantity, item.id) }} 
                            {{ getAvailableQuantity(item.stock_quantity, item.id) === 1 ? 'disponível' : 'disponíveis' }}
                          </span>
                        </div>
                      </div>
                    </v-list-tile-sub-title>
                  </v-list-tile-content>
                </template>
              </v-autocomplete>
            </td>
            <td>
              <v-text-field
                v-model="newItem.description"
                ref="description"
                label="Descrição"
                placeholder="Descrição"
                dense small
                hide-details
                @keydown.enter="focusNext('quantity')"
              />
            </td>
            <td style="max-width: 70px;">
              <v-text-field
                v-model="newItem.quantity"
                ref="quantity"
                type="number"
                label="Qtd."
                dense
                hide-details
                step="0.01"
                min="0.01"
                :max="getMaxQuantity"
                :rules="[
                  v => v > 0 || 'Quantidade deve ser maior que 0',
                  v => !shouldValidateStock || parseFloat(v) <= getMaxQuantity || `Quantidade máxima disponível: ${getMaxQuantity}`
                ]"
                @keydown.enter="focusNext('category')"
                @input="handleQuantityInput"
              />
            </td>
            <td colspan="2">
              <v-autocomplete
                v-model="newItem.category"
                :items="categories"
                :loading="loadingCategories"
                item-text="name"
                item-value="id"
                label="Categoria"
                placeholder="Selecione a categoria (opcional)"
                dense
                hide-details
                clearable
                ref="category"
                @keydown.enter="handleCategoryEnter"
                @change="handleCategoryChange"
                :class="[
                  'business-items__search',
                  `business-items__search--${itemType}`
                ]"
              >
                <template v-slot:item="{ item }">
                  <v-list-tile-content>
                    <v-list-tile-title>
                      <v-icon small class="mr-2">category</v-icon>
                      {{ item.name }}
                    </v-list-tile-title>
                  </v-list-tile-content>
                </template>
                <template v-slot:no-data>
                  <v-list-tile>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Nenhuma categoria disponível
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </template>
              </v-autocomplete>
            </td>
            <td>
              <WNMoneyInput
                v-model="newItem.amount"
                ref="amount"
                :label="'Val. Unit.'"
                :placeholder="'Valor unitário'"
                :hide-details="true"
                @keydownEnter="focusNext('discount')"
              />
            </td>
            <td>
              <WNMoneyInput
                v-model="newItem.discount"
                ref="discount"
                :label="'Desconto'"
                :placeholder="'Desconto'"
                :hide-details="true"
                @keydownEnter="addNewItem"
              />
            </td>
            <td>
              {{ calculatedTotal | money }}
            </td>
          </tr>
        </Container>
        
        <tr class="hidden-xs-only hidden-sm-only" style="height: 40px;">
          <td class="justify-center layout px-0" style="margin-top: 0px;"></td>
          <td style="padding: 0 !important; font-size: 11px;"></td>
          <td style="padding: 0 !important; font-size: 11px;"></td>
          <td style="padding: 0 !important;">
            <strong>
              {{ business.sum_quantity_unit | float }}
            </strong>
          </td>
          <td style="padding: 0 !important; font-size: 11px;"></td>
          <td style="padding: 0 !important; font-size: 11px;"></td>
          <td v-if="showAmounts" style="padding: 0 !important;">
            <strong>
              {{ business.sum_amount_unit | money }}
            </strong>
          </td>
          <td v-if="showAmounts" style="padding: 0 !important;">
            <strong>
              {{ business.sum_discount_unit | money }}
            </strong>
          </td>
          <td v-if="showAmounts" style="padding: 0 !important;">
            <strong>
              {{ business.sum_amount_total | money }}
            </strong>
          </td>
        </tr>
      </table>
      <v-divider></v-divider>
      <v-layout row wrap style="padding-top: 15px; padding-bottom: 15px;">
        <v-flex xs12 sm12 md7 lg7>
          <div style="width: 100%; text-align: center;">
            <div style="text-align: center;">
              <v-btn
                :disabled="business.stage != 1"
                outline small
                color="primary"
                @click="handleAddProduct"
              >
                <v-icon>add</v-icon>
                <span>Adicionar Produto</span>
              </v-btn>
              <v-btn
                :disabled="business.stage != 1"
                outline small
                color="primary"
                @click="handleAddService"
              >
                <v-icon>add</v-icon>
                <span>Adicionar Serviço</span>
              </v-btn>
            </div>
          </div>
        </v-flex>
        <v-flex v-if="showAmounts" xs12 sm12 md5 lg5>
          <table style="width: 100%;">
            <tr>
              <td style="padding: 2px 0px; text-align: left; border-bottom: solid 1px lightgray !important; border-top: solid 1px lightgray !important;">
                <strong style="padding: 10px;">
                  Valor Total:
                </strong>
              </td>
              <td style="padding: 2px 0px; text-align: right; border-bottom: solid 1px lightgray !important; border-top: solid 1px lightgray !important;">
                <span style="padding: 2px; font-size: 16px;">
                  {{business.sum_amount_total | money}}
                </span>
              </td>
            </tr>
            <tr>
              <td style="padding: 2px 0px; text-align: left; border-bottom: solid 1px lightgray !important;">
                <strong style="padding: 2px;">
                  Desconto:
                </strong>
              </td>
              <td style="padding: 2px 0px; text-align: right; border-bottom: solid 1px lightgray !important;">
                <span style="padding: 2px; font-size: 16px;">
                  {{business.amount_discount | money}}
                </span>
              </td>
            </tr>
            <tr>
              <td style="padding: 2px 0px; text-align: left; border-bottom: solid 1px lightgray !important;">
                <strong style="padding: 2px;">
                  Frete:
                </strong>
              </td>
              <td style="padding: 2px 0px; text-align: right; border-bottom: solid 1px lightgray !important;">
                <span style="padding: 2px; font-size: 16px;">
                  {{business.amount_freight | money}}
                </span>
              </td>
            </tr>
            <tr>
              <td style="padding: 2px 0px; text-align: left; border-bottom: solid 1px lightgray !important;">
                <strong style="padding: 2px; font-size: 20px;">
                  Total líquido:
                </strong>
              </td>
              <td style="padding: 2px 0px; text-align: right; border-bottom: solid 1px lightgray !important;">
                <strong style="padding: 2px; font-size: 20px;">
                  {{business.total_liquid | money}}
                </strong>
              </td>
            </tr>
          </table>
        </v-flex>
        <v-flex v-else xs12 sm12 md5 lg5>
          <table style="width: 100%;">
            <tr>
              <td style="padding: 2px 0px; text-align: left; border-bottom: solid 1px lightgray !important; border-top: solid 1px lightgray !important;">
                <strong style="padding: 2px; font-size: 20px;">
                  Quantidade total:
                </strong>
              </td>
              <td style="padding: 2px 0px; text-align: right; border-bottom: solid 1px lightgray !important; border-top: solid 1px lightgray !important;">
                <strong style="padding: 2px; font-size: 20px;">
                  {{ business.sum_quantity_unit | float }} 
                  <span v-if="business.sum_quantity_unit > 1"> itens</span>
                  <span v-else> item</span>
                </strong>
              </td>
            </tr>
          </table>
        </v-flex>
      </v-layout>
    </div>
    <div v-else style="text-align: center; padding: 20px;">
      <h3>Nenhum Produto ou Serviço vinculados.</h3>
      <v-btn
        :disabled="business.stage != 1"
        outline small
        color="primary"
        @click="handleAddProduct"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Produto</span>
      </v-btn>
      <v-btn
        :disabled="business.stage != 1"
        outline small
        color="primary"
        @click="handleAddService"
      >
        <v-icon>add</v-icon>
        <span>Adicionar Serviço</span>
      </v-btn>
    </div>
  </v-card>   
</template>
<script>
import { callApi } from '@/default/service/Api'
import { Container, Draggable } from 'vue-smooth-dnd'
import { applyDrag } from '@/default/components/utils/helpers'
import { debounce } from 'lodash'
// import { searchItems } from '@/services/itemsService'
import WNMoneyInput from '@/default/component/WNMoneyInput'

export default {
  name: 'BusinessItems',
  components: {
    Container,
    Draggable,
    WNMoneyInput
  },
  data () {
    return {
      headers: [
        { text: '', value: 'id', sortable: false },
        { text: 'Produto/Serviço', value: 'nome_produto' },
        { text: 'Detalhes', value: 'desc_negocio_produto' },
        { text: 'Qtd.', value: 'qtd_produto' },
        { text: 'Unid.', value: 'code' },
        { text: 'Categoria', value: 'category' },
        { text: 'Val. Unit.', value: 'valor_unit' },
        { text: 'Desconto', value: 'valor_desc' },
        { text: 'Val. Total', value: 'valor_total' }
      ],
      orderLoading: false,
      items: [],
      debounce: null,
      filters: this.$options.filters,
      isAddingNewItem: false,
      isSearching: false,
      searchQuery: '',
      searchResults: [],
      newItem: {
        selected: null,
        description: '',
        quantity: 1,
        amount: 0,
        discount: 0,
        category: null
      },
      selectedUnit: '',
      loadingItems: false,
      loadingSavingItem: false,
      itemType: null, // 'product' ou 'service'
      typeColors: {
        product: 'primary',
        service: 'deep-purple'
      },
      typeLabels: {
        product: 'Produto',
        service: 'Serviço'
      },
      stocks: [],
      selectedStock: null,
      loadingStocks: false,
      categories: [],
      loadingCategories: false
    }
  },
  computed: {
    selectedHeaders () {
      const amountFields = ['valor_unit', 'valor_desc', 'valor_total']
      return this.headers.filter(
        header => (amountFields.indexOf(header.value) === -1 || this.showAmounts)
      )
    },
    calculatedTotal() {
      const { quantity, amount, discount } = this.newItem
      const parsedQuantity = this.parseNumber(quantity)
      const parsedAmount = this.parseNumber(amount)
      const parsedDiscount = this.parseNumber(discount)
      
      return (parsedQuantity * parsedAmount) - parsedDiscount
    },
    getNoDataText() {
      if (!this.searchQuery) {
        return 'Digite para iniciar a busca'
      }
      if (this.isSearching) {
        return 'Buscando...'
      }
      return 'Nenhum item encontrado'
    },
    getTypeIcon() {
      return this.itemType === 'product' 
        ? 'shopping_cart' // ou 'inventory_2' se preferir
        : 'engineering' // ou 'build' se preferir
    },
    shouldDisableSearch() {
      return this.business.make_stock && 
             this.itemType === 'product' && 
             !this.selectedStock
    },
    getSearchPlaceholder() {
      if (this.business.make_stock && 
          this.itemType === 'product' && 
          !this.selectedStock) {
        return 'Selecione um estoque primeiro'
      }
      return 'Digite para buscar...'
    },
    shouldShowStock() {
      return this.business.make_stock && 
             this.itemType === 'product' && 
             this.selectedStock
    },
    shouldValidateStock() {
      return this.business.make_stock && 
             this.itemType === 'product' && 
             this.selectedStock
    },
    getMaxQuantity() {
      if (!this.shouldValidateStock || !this.newItem.selected) return Infinity
      
      const stockQuantity = this.newItem.selected.stock_quantity || 0
      const existingQuantity = this.getExistingItemQuantity(this.newItem.selected.id)
      
      return Math.max(0, stockQuantity - existingQuantity)
    },
    getItemAvailabilityMessage() {
      if (!this.shouldValidateStock || !this.newItem.selected) return ''
      
      const stockQuantity = this.newItem.selected.stock_quantity || 0
      const existingQuantity = this.getExistingItemQuantity(this.newItem.selected.id)
      
      if (existingQuantity > 0) {
        return `Já adicionado: ${existingQuantity} | Disponível: ${stockQuantity - existingQuantity}`
      }
      
      return `Disponível: ${stockQuantity}`
    },
    newItemInDialog() {
      return (this.items.length < 1 || ['xs', 'sm'].includes(this.$vuetify.breakpoint.name))
    },
    hasAccount() {
      return this.$Auth.user().account.id == '3b138904-1091-4a6b-9738-6e6eb9001d71'
    }
  },
  props: {
    'wiConfigId': {
      default: ''
    },
    'business': {
      default: {}
    },
    'onRefresh': {
      required: true
    },
    'showAmounts': {
      default: true
    }
  },
  methods: {
    handleEditProduct(item) {
      this.$WiEditDialog({
        wiConfig: this.wiConfigId + '-item-product',
        onSubmit: this.onRefresh,
        data: {
          ...item,
          business: this.business,
          business_stage: this.business.stage
        },
        redirectOnSubmit: false
      })
    },
    
    handleEditService(item) {
      this.$WiEditDialog({
        wiConfig: this.wiConfigId + '-item-service',
        onSubmit: this.onRefresh,
        data: {
          ...item,
          business: this.business
        },
        redirectOnSubmit: false
      })
    },

    getProducts() {
      this.loadingItems = true
      callApi.get({
        uri: 'register/service',
        params: {
          orWhere: `id,${this.searchQuery};name,${this.searchQuery}`
        },
        sucess: ({ data }) => {
          console.log('data -- products', data.data)
          this.products = data.data
          this.$forceUpdate()
          this.loadingItems = false
        },
        error: (err) => {
          console.error(err)
          this.loadingItems = false
        }
      })
    },
    
    handleDeleteItem(item) {
      this.$WiApiDelete({
        uri: 'sales/budget/item',
        id: item.id,
        callback: this.onRefresh
      })
    },
    
    handleAddProduct() {
      this.itemType = 'product'
      // se for mobile iremos usar $WiEditDialog
      if (this.newItemInDialog || !this.hasAccount) {
        this.$WiEditDialog({
          wiConfig: this.wiConfigId + '-item-product',
          onSubmit: this.onRefresh,
          data: {
            business_id: this.business.id,
            business: this.business,
            business_stage: this.business.stage
          },
          redirectOnSubmit: false
        })
      } else {
        this.startAddingItem()
      }
    },
    
    handleAddService() {
      this.itemType = 'service' 
      // se for mobile iremos usar $WiEditDialog
      if (this.newItemInDialog || !this.hasAccount) {
        this.$WiEditDialog({
          wiConfig: this.wiConfigId + '-item-service',
          onSubmit: this.onRefresh,
          data: {
            business_id: this.business.id,
            business: this.business,
            business_stage: this.business.stage
          },
          redirectOnSubmit: false
        })
      } else {
        this.startAddingItem()
      }
    },

    getChildPayload(index) {
      return this.items[index]
    },
    onDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === null && addedIndex === null) return

      this.items = applyDrag(this.items, dropResult)
      this.orderLoading = true
      
      if (this.debounce) {
        clearTimeout(this.debounce)
        this.debounce = null
      }
      this.debounce = setTimeout(() => {
        this.updateItemsOrder()
      }, 1000)
    },
    updateItemsOrder() {
      if (!this.items || !this.items.length) return

      this.$WiApiPut({
        uri: 'sales/budget/item',
        id: 'multiple',
        data: this.items.map((item, itemIndex) => ({
          id: item.id,
          order: itemIndex + 1
        })),
        callback: () => {
          this.orderLoading = false
          // this.onRefresh()
        }
      })
    },
    startAddingItem() {
      this.isAddingNewItem = true
      this.resetNewItem()
      this.searchResults = []
      
      // Carrega estoques e categorias se ainda não foram carregados
      if (!this.stocks.length || !this.categories.length) {
        this.loadStocks()
        this.loadCategories()
      }

      this.$nextTick(() => {
        // Se precisar selecionar estoque para produto, foca nele primeiro
        if (this.business.make_stock && 
            this.itemType === 'product' && 
            !this.selectedStock) {
          const stockInput = this.$el.querySelector('.stock-select input')
          if (stockInput) {
            stockInput.focus()
          }
        } else if (this.$refs.itemSearch) {
          const input = this.$refs.itemSearch.$el.querySelector('input')
          if (input) {
            input.focus()
          }
        }
      })
    },
    resetNewItem() {
      this.newItem = {
        selected: null,
        description: '',
        quantity: 1,
        amount: 0,
        discount: 0,
        category: null
      }
      this.selectedUnit = ''
      this.searchQuery = ''
      
      // Não reseta o estoque se for produto
      if (this.itemType !== 'product') {
        this.selectedStock = null
      }
    },
    handleEnterKey() {
      if (this.searchQuery && !this.newItem.selected) {
        // Se tem texto digitado mas nenhum item selecionado, seleciona o primeiro
        if (this.searchResults.length > 0) {
          this.newItem.selected = this.searchResults[0]
        }
      } else if (this.newItem.selected) {
        // Se já tem item selecionado, vai para o próximo campo
        this.focusNext('description')
      }
    },
    handleItemSelect(selectedItem) {
      if (!selectedItem) {
        this.newItem.selected = null
        return
      }

      // Verifica se deve validar estoque
      if (this.shouldValidateStock) {
        const availableQuantity = this.getMaxQuantity
        
        if (availableQuantity <= 0) {
          this.newItem.selected = null
          return
        }
      }

      // Se chegou aqui, o item pode ser selecionado
      this.newItem.amount = selectedItem.amount_sale || 0
      this.selectedUnit = selectedItem.unit.code
      this.newItem.quantity = 1
      
      if (!selectedItem.amount_sale) {
        this.newItem.amount = 0
      }
      
      this.$nextTick(() => {
        this.focusNext('description')
      })
    },
    focusNext(ref) {
      this.$nextTick(() => {
        if (this.$refs[ref]) {
          if (this.$refs[ref].$el) {
            const input = this.$refs[ref].$el.querySelector('input')
            if (input) {
              input.focus()
            }
          } else {
            this.$refs[ref].focus()
          }
        }
      })
    },
    async addNewItem() {
      console.log('addNewItem')
      if (!this.newItem.selected) return

      const selectedItem = this.searchResults.find(i => i.id === this.newItem.selected.id)
      
      let lastOrder = 0
      this.items.map(item => {
        if (item.order > lastOrder) {
          lastOrder = item.order
        }
      })
      
      const newBusinessItem = {
        business_id: this.business.id,
        item_id: selectedItem.id,
        item: selectedItem,
        description: this.newItem.description,
        quantity: this.newItem.quantity,
        amount_unit: this.newItem.amount,
        amount_discount: this.newItem.discount,
        amount_total: this.calculatedTotal,
        order: lastOrder + 1,
        is_saved: false,
        stock_id: this.selectedStock,
        business_item_category_id: this.newItem.category
      }

      console.log('newBusinessItem', newBusinessItem)

      this.loadingSavingItem = true

      try {
        // Aqui você chamaria sua API para salvar o item
        this.items.push(newBusinessItem)
        this.$forceUpdate()
        // Reseta o form e prepara para novo item
        this.resetNewItem()
        
        // Foca no campo de busca para o próximo item
        this.$nextTick(() => {
          if (this.$refs.itemSearch) {
            const input = this.$refs.itemSearch.$el.querySelector('input')
            if (input) {
              input.focus()
            }
          }
        })
      } catch (error) {
        console.error('Erro ao adicionar item:', error)
        // Aqui você pode adicionar uma notificação de erro
      }

      callApi.post({
        uri: 'sales/sale/item',
        data: newBusinessItem,
        sucess: () => {
          this.loadingSavingItem = false
          this.onRefresh()
        },
        error: (err) => {
          this.loadingSavingItem = false
          console.error('Erro ao adicionar item:', err)
        }
      })
    },
    searchItems(query) {
      if (!query) {
        this.searchResults = []
        this.isSearching = false
        return
      }
      
      // Verifica necessidade de estoque apenas para produtos
      if (this.business.make_stock && 
          this.itemType === 'product' && 
          !this.selectedStock) {
        return
      }
      
      this.isSearching = true
      const endpoint = this.itemType === 'service' 
        ? 'register/service' 
        : 'register/product'
      
      const params = {
        orWhere: `id,${query};name,${query}`
      }

      // Adiciona stock_id apenas para produtos com make_stock
      if (this.business.make_stock && 
          this.itemType === 'product' && 
          this.selectedStock) {
        params.stock_id = this.selectedStock
      }
      
      callApi.get({
        uri: endpoint,
        params,
        sucess: ({ data }) => {
          // Não filtra mais os itens, apenas marca os sem estoque
          this.searchResults = data.data
          this.isSearching = false
        },
        error: (err) => {
          console.error('Erro ao buscar items:', err)
          this.searchResults = []
          this.isSearching = false
        }
      })
    },
    handleQuantityInput(value) {
      let numValue = parseFloat(value)
      if (!isNaN(numValue)) {
        // Se deve validar estoque, limita ao máximo disponível
        if (this.shouldValidateStock && this.newItem.selected) {
          numValue = Math.min(numValue, this.newItem.selected.stock_quantity)
        }
        numValue = Math.round(numValue * 100) / 100
        this.newItem.quantity = numValue
      } else {
        this.newItem.quantity = 1
      }
    },
    parseNumber(value) {
      const parsed = parseFloat(String(value).replace(',', '.'))
      return isNaN(parsed) ? 0 : parsed
    },
    async loadStocks() {
      this.loadingStocks = true
      callApi.get({
        uri: 'stock',
        params: {
          fields: 'id,name',
          order: 'name,asc',
          limit: 10000
        },
        sucess: ({ data }) => {
          this.stocks = data.data
          
          let shouldFocusSearch = false
          
          // Seleciona estoque master ou único automaticamente
          if (this.stocks.length === 1) {
            this.selectedStock = this.stocks[0].id
            shouldFocusSearch = true
          } else {
            const masterStock = this.stocks.find(stock => stock.is_master)
            if (masterStock) {
              this.selectedStock = masterStock.id
              shouldFocusSearch = true
            }
          }
          
          this.loadingStocks = false
          
          // Move o foco se foi selecionado automaticamente
          if (shouldFocusSearch) {
            this.$nextTick(() => {
              if (this.$refs.itemSearch) {
                const input = this.$refs.itemSearch.$el.querySelector('input')
                if (input) {
                  input.focus()
                }
              }
            })
          }
        },
        error: (err) => {
          console.error('Erro ao carregar estoques:', err)
          this.loadingStocks = false
        }
      })
    },
    handleStockChange() {
      // Limpa resultados da busca quando troca o estoque
      this.searchResults = []
      this.searchQuery = ''
      this.newItem.selected = null
      
      // Se tiver uma busca em andamento, refaz com o novo estoque
      if (this.searchQuery) {
        this.searchItems(this.searchQuery)
      }
      
      // Move o foco para o autocomplete de produto
      this.$nextTick(() => {
        if (this.$refs.itemSearch) {
          const input = this.$refs.itemSearch.$el.querySelector('input')
          if (input) {
            input.focus()
          }
        }
      })
    },
    getExistingItemQuantity(itemId) {
      if (!this.items) return 0
      
      return this.items.reduce((total, item) => {
        if (item.item_id === itemId && item.stock_id === this.selectedStock) {
          return total + parseFloat(item.quantity || 0)
        }
        return total
      }, 0)
    },
    getAvailableQuantity(stockQuantity, itemId) {
      const existingQuantity = this.getExistingItemQuantity(itemId)
      const available = stockQuantity - existingQuantity
      return Math.max(0, available)
    },
    getStockStatus(quantity, itemId) {
      if (quantity === undefined) return 'unknown'
      
      const availableQuantity = this.getAvailableQuantity(quantity, itemId)
      
      if (availableQuantity <= 0) return 'empty'
      if (availableQuantity <= 3) return 'critical'
      if (availableQuantity <= 10) return 'low'
      return 'normal'
    },
    isItemDisabled(item) {
      if (!this.shouldShowStock) return false
      const availableQuantity = this.getAvailableQuantity(item.stock_quantity, item.id)
      return availableQuantity < 1
    },
    getStockIcon(quantity) {
      if (quantity === undefined) return 'help_outline'
      
      const availableQuantity = this.getAvailableQuantity(quantity)
      
      if (availableQuantity <= 0) return 'cancel'
      if (availableQuantity <= 3) return 'inventory_2'
      if (availableQuantity <= 10) return 'warning'
      return 'inventory'
    },
    loadCategories() {
      this.loadingCategories = true
      callApi.get({
        uri: 'sales/sale/item-category',
        params: {
          fields: 'id,name',
          order: 'name,asc',
          limit: 10000
        },
        sucess: ({ data }) => {
          this.categories = data.data || []
          this.loadingCategories = false
        },
        error: (err) => {
          console.error('Erro ao carregar categorias:', err)
          this.categories = []
          this.loadingCategories = false
        }
      })
    },
    handleCategoryEnter() {
      // Se tiver um item selecionado ou se o campo estiver vazio (já que é opcional)
      this.focusNext('amount')
    },
    handleCategoryChange() {
      // Quando seleciona uma categoria pelo clique, move para o próximo campo
      this.$nextTick(() => {
        this.focusNext('amount')
      })
    }
  },
  mounted() {
    this.items = this.business.items
  },
  watch: {
    'business.items': {
      handler(newItems) {
        this.items = newItems
      },
      deep: true
    },
    searchQuery: debounce(function(query) {
      // Chama o método searchItems que já tem a lógica correta
      this.searchItems(query)
    }, 300),
    'newItem.selected': function(newValue) {
      if (newValue) {
        this.handleItemSelect(newValue)
      }
    }
  }
}
</script>
<style scoped>
/* Block principal */
.business-items__new-row {
  transition: all 0.3s ease;
}

/* Temas */
.business-items__new-row--light {
  background-color: #fff;
}

.business-items__new-row--dark {
  background-color: #1E1E1E;
}

/* Modificadores por tipo */
.business-items__new-row--product-light {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.05), transparent);
}

.business-items__new-row--service-light {
  background: linear-gradient(to right, rgba(103, 58, 183, 0.05), transparent);
}

.business-items__new-row--product-dark {
  background: linear-gradient(to right, rgba(33, 150, 243, 0.1), transparent);
}

.business-items__new-row--service-dark {
  background: linear-gradient(to right, rgba(103, 58, 183, 0.1), transparent);
}

/* Badge de tipo */
.business-items__type-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 2px;
  border-radius: 4px;
  font-size: 12px;
  transition: all 0.3s ease;
}

.business-items__type-badge--product {
  background-color: rgba(33, 150, 243, 0.1);
  color: #2196F3;
}

.business-items__type-badge--service {
  background-color: rgba(103, 58, 183, 0.1);
  color: #673AB7;
}

.business-items__type-text {
  margin-left: 4px;
  font-weight: 500;
}

/* Campo de busca */
.business-items__search {
  transition: all 0.3s ease;
}

.business-items__search--product .v-input__slot {
  border-left: 3px solid #2196F3;
}

.business-items__search--service .v-input__slot {
  border-left: 3px solid #673AB7;
}

/* Detalhes do item */
.business-items__item-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.business-items__item-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.business-items__unit-badge {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 11px;
}

.business-items__unit-badge--dark {
  background-color: rgba(255, 255, 255, 0.1);
}

.business-items__price {
  font-weight: 500;
  color: #4CAF50;
}

/* Animações */
.business-items__fade-enter-active,
.business-items__fade-leave-active {
  transition: opacity 0.3s ease;
}

.business-items__fade-enter,
.business-items__fade-leave-to {
  opacity: 0;
}

/* Responsividade */
@media (max-width: 600px) {
  .business-items__type-text {
    display: none;
  }
  
  .business-items__type-badge {
    padding: 4px;
  }
}

.business-items__search:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Badges de estoque */
.business-items__stock-badge {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 11px;
  margin-left: 8px;
  transition: all 0.3s ease;
}

.business-items__stock-badge--normal {
  background-color: rgba(76, 175, 80, 0.1);
  color: #4CAF50;
}

.business-items__stock-badge--low {
  background-color: rgba(255, 152, 0, 0.1);
  color: #FF9800;
}

.business-items__stock-badge--empty {
  background-color: rgba(244, 67, 54, 0.1);
  color: #F44336;
}

.business-items__stock-badge--unknown {
  background-color: rgba(158, 158, 158, 0.1);
  color: #9E9E9E;
}

/* Efeito de item desabilitado */
.v-list__tile--disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: rgba(0, 0, 0, 0.05);
}

/* Animação suave para mudanças de estado */
.v-list__tile {
  transition: all 0.3s ease;
}

.business-items__item-details {
  transition: opacity 0.3s ease;
}

.business-items__item--out-of-stock {
  position: relative;
  opacity: 0.7;
  cursor: not-allowed !important;
}

.business-items__item--out-of-stock::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.05);
  pointer-events: none;
  transition: background-color 0.3s ease;
}

.business-items__stock-info {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 11px;
  /* transition: all 0.3s ease; */
  font-weight: 500;
}

.business-items__stock-info--normal {
  background-color: rgba(76, 175, 80, 0.15);
  color: #2E7D32;
  border: 1px solid rgba(76, 175, 80, 0.3);
}

.business-items__stock-info--low {
  background-color: rgba(255, 152, 0, 0.15);
  color: #E65100;
  border: 1px solid rgba(255, 152, 0, 0.3);
}

.business-items__stock-info--critical {
  background-color: rgba(255, 87, 34, 0.15);
  color: #D84315;
  border: 1px solid rgba(255, 87, 34, 0.3);
}

.business-items__stock-info--empty {
  background-color: rgba(244, 67, 54, 0.15);
  color: #C62828;
  border: 1px solid rgba(244, 67, 54, 0.3);
}

.business-items__stock-info--unknown {
  background-color: rgba(158, 158, 158, 0.15);
  color: #616161;
  border: 1px solid rgba(158, 158, 158, 0.3);
}

/* Efeito hover nos badges de estoque */
.business-items__stock-info:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.business-items__stock-info--normal:hover {
  background-color: rgba(76, 175, 80, 0.2);
}

.business-items__stock-info--low:hover {
  background-color: rgba(255, 152, 0, 0.2);
}

.business-items__stock-info--critical:hover {
  background-color: rgba(255, 87, 34, 0.2);
}

.business-items__stock-info--empty:hover {
  background-color: rgba(244, 67, 54, 0.2);
}

.business-items__stock-info--unknown:hover {
  background-color: rgba(158, 158, 158, 0.2);
}

/* Animação do badge */
/* @keyframes pulseStock {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.business-items__stock-info--critical,
.business-items__stock-info--empty {
  animation: pulseStock 2s infinite;
} */

.business-items__stock-existing {
  color: #FB8C00;
  font-weight: 500;
}

.business-items__stock-separator {
  margin: 0 4px;
  opacity: 0.5;
}

.business-items__stock-info {
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 11px;
  transition: all 0.3s ease;
  font-weight: 500;
  cursor: help;
}
</style>
