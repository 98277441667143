import { Model } from '@/default/model/Model'
import searchAddressByZipCode from '@/modules/register/config/Person/Address/searchAddressByZipCode'

const parcelQuantity = []
for (let i = 1; i <= 120; i++) {
  parcelQuantity.push({ value: i, text: `${i} vez${i > 1 ? 'es' : ''}` })
}

export class BudgetCredFull extends Model {
  constructor () {
    super()
    this.id = {
      id: '10b505c9',
      create: 'b9fba2ea',
      update: 'd3c48579',
      delete: '55bf11f3'
    }
    this.title = {
      list: 'Propostas',
      edit: {
        create: 'Nova Proposta',
        update: 'Editar Proposta'
      }
    }
    this.icon = 'shop'
    this.apiUrl = 'sales/budget/full-credit'
    
    this.setFields(this.fieldsEdit)
  }

  // esta tela de cadastro terá os dados do cliente para que o usuário possa editar se necessário
  // Terá também os dados da proposta/orçamento
  // Terá também a possibilidade de vincular a um endereço existente ou criar um novo
  // Terá também a possibilidade de vincular a uma conta bancária existente ou criar uma nova
  fieldsEdit = {
    // Informações do cliente
    subheaderPerson: this.fieldSubHeader({
      text: 'Informações do cliente',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }),
    person: this.fieldWiListDialog({
      value: 'person_id',
      text: 'Cliente/Prospect',
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      wiConfig: 'customer',
      show: 'person.first_name',
      icon: 'person_outline',
      disabled: true
    }),
    birthDate: this.fieldDate({
      value: 'birthday',
      text: 'Data de Nascimento',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true
    }),
    rg: this.fieldText({
      value: 'rg',
      text: 'RG',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true
    }),
    rgIssuer: this.fieldText({
      value: 'rg_issuing_agency',
      text: 'RG Emissor',
      placeholder: 'Ex: SSP',
      required: true,
      size: ['xs12', 'sm12', 'md1', 'lg1']
    }),
    rgIssuerState: this.fieldSelectApi({  
      value: 'rg_state_id',
      text: 'RG UF',
      placeholder: 'Ex: MG',
      size: ['xs12', 'sm12', 'md1', 'lg1'],
      required: true,
      api: {
        url: 'register/state',
        params: {fields: 'id,name,code', order: 'code,asc'}
      },
      show: 'code'
    }),
    rgIssueDate: this.fieldDate({
      value: 'rg_issue_date',
      text: 'RG Data Emissão',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true
    }),
    // additionalInfo: this.fieldSwitch({
    //   value: 'additional_info',
    //   text: 'Informações Adicionais',
    //   size: ['xs12', 'sm12', 'md2', 'lg2'],
    //   defaultValue: true
    // }),
    motherName: this.fieldText({
      value: 'mothers_name',
      text: 'Nome da Mãe',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      // showField: (form) => { return form.additional_info }
    }),
    fatherName: this.fieldText({
      value: 'fathers_name',
      text: 'Nome do Pai',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      // showField: (form) => { return form.additional_info }
    }),
    // Novos campos
    naturalness: this.fieldText({
      value: 'naturalness',
      text: 'Naturalidade',
      placeholder: 'Ex: São Paulo',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      // showField: (form) => { return form.additional_info }
    }),
    nationality: this.fieldSelect({
      value: 'nationality',
      text: 'Nacionalidade',
      placeholder: 'Ex: Brasileiro',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      options: [
        {value: 'Brasileiro', text: 'Brasileiro'},
        {value: 'Estrangeiro', text: 'Estrangeiro'}
      ],
      // showField: (form) => { return form.additional_info }
    }),
    sex: this.fieldSelect({
      value: 'sex',
      text: 'Sexo',
      placeholder: 'Masculino/Feminino',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      options: [
        {value: 'Feminino', text: 'Feminino'},
        {value: 'Masculino', text: 'Masculino'},
        {value: 'Outro', text: 'Não especificado'}
      ],
      // showField: (form) => { return form.additional_info }
    }),
    maritalStatus: this.fieldSelect({
      value: 'marital_status',
      text: 'Estado civil',
      placeholder: 'Selecione o estado civil',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      options: [
        {value: 'Casado(a)', text: 'Casado(a)'},
        {value: 'Divorciado(a)', text: 'Divorciado(a)'},
        {value: 'Separado(a)', text: 'Separado(a)'},
        {value: 'Solteiro(a)', text: 'Solteiro(a)'},
        {value: 'União estável', text: 'União estável'},
        {value: 'Viúvo(a)', text: 'Viúvo(a)'}
      ],
      // showField: (form) => { return form.additional_info }
    }),
    incomeGross: this.fieldMoney({
      value: 'income_gross',
      text: 'Renda Bruta',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      // showField: (form) => { return form.additional_info } // TODO: verificar se é necessário
    }),
    salaryLiquid: this.fieldMoney({
      value: 'salary_liquid',
      text: 'Salário Líquido',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      // showField: (form) => { return form.additional_info } // TODO: verificar se é necessário
    }),
    socialName: this.fieldText({
      value: 'social_name',
      text: 'Nome Social',
      placeholder: 'Digite o nome social',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      // showField: (form) => { return form.additional_info } // TODO: verificar se é necessário
    }),
// adicionar mais alguns campos avançados: naturalidade, sexo, estado civil, renda, salário líquido, nome social

    // Informações de endereço
    subheaderAddress: this.fieldSubHeader({
      text: 'Informações de endereço',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }),
    createAddress: this.fieldSelect({
      value: 'create_address',
      text: 'Configuração do endereço',
      options: [
        {value: 'create', text: 'Criar novo endereço'},
        {value: 'use-existing', text: 'Usar endereço existente'}
      ],
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      defaultOption: 'use-existing'
    }),
    address: this.fieldWiListDialog({
      value: 'address_id',
      text: 'Endereço',
      size: ['xs12', 'sm12', 'md9', 'lg9'],
      wiConfig: 'address',
      show: 'address.address_full',
      icon: 'location_on',
      filter: (form) => {
        return {
          where: `person_adresses.person_id,${form.person_id}`
        }
      },
      showField: (form) => { return form.create_address === 'use-existing' },
      wiEditData: (form) => {
        return {
          person_id: form.person_id
        }
      }
    }),

    // Campos de endereço
    addressZipCode: this.fieldText({
      value: 'zip_code',
      text: 'CEP',
      required: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      placeholder: '#####-###',
      mask: '#####-###',
      showField: (form) => { return form.create_address === 'create' },
      button: searchAddressByZipCode
    }),
    addressStreet: this.fieldText({
      value: 'address',
      text: 'Endereço/Logradouro',
      required: true,
      size: ['xs12', 'sm12', 'md5', 'lg5'],
      placeholder: 'Digite o endereço',
      showField: (form) => { return form.create_address === 'create' }
    }),
    addressNumber: this.fieldText({
      value: 'number',
      text: 'Número',
      required: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      placeholder: 'Ex: 820',
      showField: (form) => { return form.create_address === 'create' }
    }),
    addressDistrict: this.fieldText({
      value: 'district',
      text: 'Bairro',
      required: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      placeholder: 'Digite o bairro',
      showField: (form) => { return form.create_address === 'create' }
    }),
    addressComplement: this.fieldText({
      value: 'complement',
      text: 'Complemento',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      placeholder: 'Ex: APT. 201',
      showField: (form) => { return form.create_address === 'create' }
    }),
    addressCityIso: this.fieldSelectApi({
      value: 'iso',
      text: 'Cidade',
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      placeholder: 'Selecione uma cidade',
      api: {
        url: 'register/city',
        params: {
          limit: 10000,
          order: 'name,asc'
        }
      },
      show: 'name_and_state',
      selectValue: 'iso',
      showField: (form) => { return form.create_address === 'create' && form.iso }
    }),
    addressCity: this.fieldSelectApi({
      value: 'city_id',
      text: 'Cidade',
      required: true,
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      placeholder: 'Selecione uma cidade',
      api: {
        url: 'register/city',
        params: {
          limit: 10000,
          order: 'name,asc'
        }
      },
      show: 'name_and_state',
      showField: (form) => { return form.create_address === 'create' && !form.iso }
    }),
    addressDescription: this.fieldText({
      value: 'description',
      text: 'Descrição',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      placeholder: 'Se desejar, coloque uma descrição ou observação.',
      showField: (form) => { return form.create_address === 'create' }
    }),

    // Informações de conta bancária
    subheaderBankAccount: this.fieldSubHeader({
      text: 'Informações de conta bancária',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }),
    createFinanceAccount: this.fieldSelect({
      value: 'create_finance_account',
      text: 'Configuração da conta bancária',
      options: [
        {
          value: 'create', 
          text: 'Criar nova conta bancária'
        },
        {
          value: 'use-existing', 
          text: 'Usar conta bancária existente'
        }
      ],
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      defaultOption: 'use-existing'
    }),
    financeAccount: this.fieldWiListDialog({
      value: 'finance_account_id',
      text: 'Conta bancária',
      size: ['xs12', 'sm12', 'md9', 'lg9'],
      wiConfig: 'finance-accounts-person',
      show: 'finance_account.name',
      icon: 'account_balance',
      filter: (form) => {
        return {
          where: `finance_accounts.person_id,${form.person_id}`
        }
      },
      showField: (form) => { return form.create_finance_account === 'use-existing' },
      wiEditData: (form) => {
        return {
          person_id: form.person_id
        }
      }
    }),
    // Campos para criar uma nova conta bancária
    financeAccountType: this.fieldSelectApi({
      value: 'finance_account_type_id',
      text: 'Tipo de Conta',
      required: true,
      placeholder: 'Poupança ou Corrente',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      api: {
        url: 'finance/account/type',
        params: {
          fields: 'id,name',
          order: 'name,asc',
          orWhere: 'id,2;id,4'
        }
      },
      show: 'name',
      showField: (form) => { return form.create_finance_account === 'create' }
    }),

    financeAccountBank: this.fieldSelectApi({
      value: 'finance_bank_id',
      text: 'Instituição Bancária',
      placeholder: 'Clique para buscar',
      required: true,
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      api: {
        url: 'finance/bank',
        params: {
          fields: 'id,name,code,logo_url',
          order: 'code,asc;id,asc;name,asc'
        }
      },
      show: 'name_full',
      showField: (form) => { 
        return form.create_finance_account === 'create'
      }
    }),
    financeAccountAgency: this.fieldText({
      value: 'agency',
      text: 'Agência',
      placeholder: 'Ex: 1234',
      size: ['xs12', 'sm12', 'md1', 'lg1'],
      required: true,
      showField: (form) => { 
        return form.create_finance_account === 'create'
      }
    }),
    financeAccountNumber: this.fieldText({
      value: 'account',
      text: 'N° da Conta',
      placeholder: 'Ex: 12345',
      size: ['xs12', 'sm12', 'md2', 'lg2'],
      required: true,
      showField: (form) => { 
        return form.create_finance_account === 'create'
      }
    }),
    financeAccountOperation: this.fieldText({
      value: 'operation',
      text: 'Dígito',
      placeholder: 'Ex: 8',
      size: ['xs12', 'sm12', 'md1', 'lg1'],
      showField: (form) => { 
        return form.create_finance_account === 'create'
      }
    }),
    keyPix: this.fieldText({
      value: 'integration_code',
      text: 'Chave Pix',
      placeholder: 'Ex: 12934587890',
      size: ['xs12', 'sm12', 'md1', 'lg1'],
      showField: (form) => { 
        return form.create_finance_account === 'create'
      }
    }),

    subheaderTalkContact: this.fieldSubHeader({
      text: 'Informações de contato',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }),
    talkContact: this.fieldWiListDialog({
      value: 'talk_contact_id',
      text: 'Contato',
      placeholder: 'Ex: 12934587890',
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      wiConfig: 'talk-contact',
      show: 'talk_contact.number',
      icon: 'phone',
      filter: (form) => {
        return {
          where: `person_id,${form.person_id}`
        }
      },
      required: true
    }),
    email: this.fieldText({
      value: 'email',
      text: 'E-mail',
      placeholder: 'Ex: nome@gmail.com',
      size: ['xs12', 'sm12', 'md6', 'lg6']
    }),

    subHeader1: this.fieldSubHeader({
      text: 'Informe os dados básicos desta proposta.',
      size: ['xs12', 'sm12', 'md12', 'lg12']
    }),
    
    dateStart: this.fieldDate({
      value: 'date_start',
      text: 'Data de Emissão',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    totalLiquid: this.fieldMoney({
      value: 'amount_additional',
      text: 'Valor',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    externalCode: this.fieldText({
      value: 'external_code',
      text: 'Código da Proposta',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    integrationCode: this.fieldText({
      value: 'integration_code',
      text: 'Matrícula',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    sumAmountUnit: this.fieldMoney({
      value: 'sum_amount_unit',
      text: 'Margem Total',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    advanced_view: this.fieldSwitch({
      value: 'advanced_view',
      text: 'Opções avançadas',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    subHeader2: this.fieldSubHeader({
      text: 'Informações complementares: Prazo de pagamento, comissões, etc.'
    }),
    agreementId: this.fieldSelectApi({
      value: 'business_agreement_id',
      text: 'Convênio',
      size: ['xs12', 'sm12', 'md4', 'lg4'],
      api: {
        url: 'sales/budget/agreement',
        params: {fields: 'id,name,icon,color', order: 'name,asc'}
      },
      show: 'name',
      icon: 'icon',
      color: 'color',
    }),
    installmentAmount: this.fieldMoney({
      value: 'amount_installment',
      text: 'Valor da Parcela',
      size: ['xs12', 'sm12', 'md3', 'lg2']
    }),
    installmentQuantity: this.fieldSelect({
      value: 'quantity_installment',
      text: 'Quantidade de Parcelas',
      placeholder: 'Ex: 12 vezes',
      options: parcelQuantity,
      size: ['xs12', 'sm12', 'md3', 'lg2']
    }),
    integration: this.fieldText({
      value: 'integration',
      text: 'Cod. Correspondente',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    invoice: this.fieldText({
      value: 'invoice',
      text: 'ID Averbação',
      size: ['xs12', 'sm12', 'md2', 'lg2']
    }),
    subHeader3: this.fieldSubHeader({
      text: 'Opções avançadas',
      showField: (form) => { return form.advanced_view }
    }),
    // opções avançadas, date_end, date_validity, company_id, collaborator_id
    dateEnd: this.fieldDate({
      value: 'date_end',
      text: 'Data de Entrega',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: (form) => { return form.advanced_view }
    }),
    dateValidity: this.fieldDate({
      value: 'date_validity',
      text: 'Data de Validade',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: (form) => { return form.advanced_view }
    }),
    percentageCollaborator: this.fieldNumber({
      value: 'percentage_collaborator',
      text: 'Comissão',
      placeholder: 'Ex: 10%',
      min: 1,
      max: 100,
      step: '.01',
      prefix: '%',
      suffix: '%',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: (form) => { return form.advanced_view }
    }),
    amountDiscount: this.fieldMoney({
      value: 'amount_discount',
      text: 'Valor de Desconto',
      size: ['xs12', 'sm12', 'md3', 'lg3'],
      showField: (form) => { return form.advanced_view }
    }),
    company: this.fieldSelectApi({
      value: 'company_id',
      text: 'Empresa',
      api: {
        url: 'register/person/company',
        params: {fields: 'id,name', order: 'name,asc'}
      },
      show: 'name',
      size: form => !form.id ? ['xs12', 'sm12', 'md12', 'lg12'] : ['xs12', 'sm12', 'md6', 'lg6'],
      showField: (form) => { return form.advanced_view }
    }),
    collaboratorAdvanced: this.fieldSelectApi({
      value: 'collaborator_id',
      text: 'Colaborador Responsável',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      api: {
        url: 'register/person/collaborator',
        params: {fields: 'id,name', order: 'name,asc'}
      },
      show: 'name',
      showField: (form) => { return !!form.id && form.advanced_view }
    }),
    subHeaderObservation: this.fieldSubHeader({
      text: 'O campo abaixo pode ser usado para registrar observações.'
    }),
    observation: this.fieldTextArea({
      value: 'observation',
      text: 'Observações',
      placeholder: 'Use este campo para adicionar observações...'
    })
  }
}


