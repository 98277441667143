<template>
  <v-list-tile-title class="talk-list-item-labels">
    <!-- Channel Label -->
    <v-tooltip bottom lazy>
      <template v-slot:activator="{ on }">
        <WNTag
          v-on="on"
          :text="channel.name | stringlength(getMaxCharLength)"
          :icon="labelsCount <= 2 ? channel.status.icon : null"
          :color="channel.color"
        />
      </template>
      <span>
        <span v-t="'modules.talks.pages.talkView.talks.labels.channel'"></span>: 
        <span> {{ channel.name }}</span>
      </span>
    </v-tooltip>

    <!-- Team Label -->
    <v-tooltip v-if="team" bottom lazy>
      <template v-slot:activator="{ on }">
        <WNTag
          v-on="on"
          :text="team.name | stringlength(getMaxCharLength)"
          :color="team.color"
          :icon="labelsCount <= 2 ? 'groups' : null"
        />      
      </template>
      <span>
        <span v-t="'modules.talks.pages.talkView.talks.labels.team'"></span>: 
        <span> {{ team.name }}</span>
      </span>
    </v-tooltip>

    <!-- Stage Label -->
    <v-tooltip v-if="stage" bottom lazy>
      <template v-slot:activator="{ on }">
        <WNTag
          v-on="on"
          :text="stage.name | stringlength(getMaxCharLength)"
          :color="stage.color"
          :icon="labelsCount <= 2 ? stage.icon : null"
        />        
      </template>
      <span>
        <span>Etapa:</span>
        <span> {{ stage.name }}</span>
      </span>
    </v-tooltip>
    

    <!-- Category Label -->
    <v-tooltip v-if="category" bottom lazy>
      <template v-slot:activator="{ on }">
        <WNTag
          v-on="on"
          :text="category.name | stringlength(getMaxCharLength)"
          :color="category.color"
          :icon="labelsCount <= 2 ? category.icon : null"
        />
      </template>
      <span>
        <span>Categoria: </span>
        <span> {{ category.name }}</span>
      </span>
    </v-tooltip>
  </v-list-tile-title>
</template>

<script>
import WNTag from '@/default/component/WNTag'
export default {
  name: 'TalkListItemLabels',
  
  props: {
    channel: {
      type: Object,
      required: true
    },
    team: {
      type: Object,
      default: null
    },
    category: {
      type: Object,
      default: null
    },
    stage: {
      type: Object,
      default: null
    }
  },

  computed: {
    labelsCount() {
      const labels = [this.channel, this.team, this.category, this.stage]
      return labels.filter(label => !!label).length
    },
    getMaxCharLength() {     
      if (this.labelsCount === 0) {
        return 30
      }

      if (this.labelsCount === 1) {
        return 20
      }

      if (this.labelsCount === 2) {
        return 12
      }
      
      if (this.labelsCount === 3) {
        return 7
      }

      return 12
    }
  },

  methods: {
    getLabelStyle(color) {
      return {
        margin: '0 1px',
        borderRadius: '5px',
        padding: '2px 3px',
        fontSize: '10px',
        background: color
      }
    }
  },

  components: {
    WNTag
  }
}
</script> 
<style scoped>
.talk-list-item-labels {
  gap: 1px;
  display: flex;
  align-items: center;
}
</style>