<template>
  <WiView 
    ref="wiView"
    :index="viewIndex" 
    :apiUrl="apiEndpoint" 
    :apiParams="apiParams"
    :notShowToolbar="true" 
    :notGetID="true" 
    @afterLoad="setContent"
  >
    <template slot="toolbar" slot-scope="{ refresh }">
      <v-container fluid class="pa-0">
        <div class="report-header">
          <div class="header-content">
            <v-toolbar class="elevation-1 toolbar-custom">
              <v-toolbar-title>{{ title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <WNDateIntervalFilter
                :startDate="apiParams.date_start"
                :endDate="apiParams.date_end"
                width="400"
                @filter="handleDateFilter"
              />
              <v-btn icon flat color="green" @click="refresh()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
        </div>
      </v-container>
    </template>

    <template slot-scope="{ content }">
      <v-container fluid class="pa-4">
        <template v-if="content && content.data">
          <div class="table-wrapper">
            <table class="finance-table">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Descrição</th>
                  <th>Pessoa</th>
                  <th>Categoria</th>
                  <th>Forma Pagto</th>
                  <th class="text-right">Valor</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(group, groupIndex) in content.data">
                  <tr :key="'group-' + groupIndex" class="group-header">
                    <td colspan="5">{{ formatDate(group.date) }}</td>
                    <td class="text-right">{{ formatCurrency(group.sum_total) }}</td>
                  </tr>
                  <tr v-for="item in group.items" :key="item.id" class="item-row">
                    <td>{{ formatDate(item.date_receive) }}</td>
                    <td>{{ item.description || 'Sem descrição' }}</td>
                    <td>{{ item.person && item.person.name || 'Sem pessoa' }}</td>
                    <td>{{ item.category && item.category.name || 'Sem categoria' }}</td>
                    <td>{{ item.form_payment && item.form_payment.name || 'Não definida' }}</td>
                    <td class="text-right">{{ formatCurrency(item.amount_total) }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </template>
      </v-container>
    </template>
  </WiView>
</template>

<script>
import moment from 'moment'
import WNDateIntervalFilter from '@/default/component/WNDateIntervalFilter'

export default {
  name: 'FinancesByDateReport',
  components: {
    WNDateIntervalFilter
  },
  data: () => ({
    content: null,
    apiParams: {
      date_field: 'date_receive',
      date_start: moment().startOf('month').format('YYYY-MM-DD'),
      date_end: moment().endOf('month').format('YYYY-MM-DD')
    }
  }),
  props: {
    title: {
      type: String,
      default: 'Relatório por Data'
    },
    viewIndex: {
      type: String,
      default: 'finance-by-date-report'
    }
  },
  computed: {
    apiEndpoint() {
      return 'finance/report/finances-in-by-date'
    }
  },
  methods: {
    setContent({ content }) {
      this.content = content
    },
    handleDateFilter(date) {
      this.apiParams.date_start = date.startDate
      this.apiParams.date_end = date.endDate
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value)
    }
  }
}
</script>

<style scoped>
.report-header {
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 16px 24px 0 24px;
  background-color: #ffffff;
}

.header-content {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
}

.toolbar-custom {
  border-radius: 4px;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.finance-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.finance-table th {
  background-color: #f5f5f5;
  color: #333333;
  font-weight: 500;
  text-align: left;
  padding: 12px 16px;
  border-bottom: 2px solid #e0e0e0;
  white-space: nowrap;
}

.finance-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
}

.finance-table .group-header {
  background-color: #f8f9fa;
  font-weight: 500;
}

.finance-table .group-header td {
  border-top: 2px solid #e0e0e0;
}

.finance-table .item-row:hover {
  background-color: #f5f5f5;
}

.text-right {
  text-align: right;
}

/* Tema escuro */
.theme--dark .report-header,
.theme--dark .table-wrapper {
  background-color: #424242;
}

.theme--dark .finance-table th {
  background-color: #333333;
  color: #ffffff;
  border-bottom-color: #616161;
}

.theme--dark .finance-table td {
  border-bottom-color: #616161;
}

.theme--dark .finance-table .group-header {
  background-color: #333333;
}

.theme--dark .finance-table .item-row:hover {
  background-color: #505050;
}

@media screen and (max-width: 768px) {
  .finance-table {
    font-size: 13px;
  }
  
  .finance-table th,
  .finance-table td {
    padding: 8px 12px;
  }
}
</style>
