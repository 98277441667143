import Vue from 'vue'
import { Storage } from '@/default/service/Storage'

export class Auth {
  static setAuth () {
    this.auth = Storage.get('f777bbf0-ef5d-45db-b77a-dd7802f92824')
    if (this.auth) {
      this.auth.user = Storage.decrypt(this.auth.user)
    }
  }
  static get () {
    this.setAuth()
    return this.auth
  }
  static account () {
    this.setAuth()
    return {
      ...this.auth?.user?.account
    }
  }
  static config () {
    this.setAuth()
    return {
      ...this.auth.user.config
    }
  }
  static user () {
    this.setAuth()
    return {
      ...this.auth.user.person,
      account: {
        ...this.auth.user.account
      },
      profile: {
        ...this.auth.user.profile,
        checkPermission: this.checkPermission
      },
      config: {
        ...this.auth.user.config
      }
    }
  }
  static token () {
    this.setAuth()
    return this.auth.token
  }
  static hash () {
    this.setAuth()
    return this.auth.hash
  }
  static check () {
    this.setAuth()
    return this.auth && this.auth.token && this.auth.hash && this.auth.user
  }
  static checkPermission (permission) {
    this.setAuth()
    if (this.auth && permission) {
      return this.auth.user.profile.permissions.includes(permission)
    }
    return false
  }
  static logout () {
    // Limpa localStorage
    localStorage.clear()

    // Configurações comuns para remoção de cookies
    const cookieOptions = [
      'path=/',
      'expires=Thu, 01 Jan 1970 00:00:00 GMT',
      'secure',
      'samesite=strict'
    ].join(';');

    // Remove cookies específicos
    document.cookie = `7aa191f1-550e-417a-ab3e-7f88442ef305=;${cookieOptions}`;
    document.cookie = `auth=;${cookieOptions}`;

    // Redireciona para login
    location.href = '/#/login'
    location.reload()
  }
  static confirmLogout () {
    Vue.swal({
      title: 'Deseja realmente sair?',
      text: 'Você será redirecionado para a tela de login!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, sair!',
      cancelButtonText: 'Cancelar'
    })
    .then((response) => {
      if (response.value) {
        this.logout()
      }
    })
  }
}