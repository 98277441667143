<template>
  <div class="campaigns-container">
    <div class="campaigns-content">
      <div class="header-text">
        <h1 class="hidden-xs-only hidden-sm-only">Bem-vindo(a) ao módulo de Campanhas</h1>
        <h2 class="hidden-md-only hidden-lg-only hidden-xl-only">Bem-vindo(a)</h2>
        <p class="subtitle">Crie campanhas de disparos de WhatsApp e torpedos de voz com nossa ferramenta de campanhas</p>
      </div>

      <div class="features-cards">
        <div class="feature-card">
          <div class="status-badge" :class="{ 'active': $WiCheckPermission('cdf8059b') }">
            <span class="status-icon">{{ $WiCheckPermission('cdf8059b') ? 'check_circle' : 'lock' }}</span>
            {{ $WiCheckPermission('cdf8059b') ? 'Ativo' : 'Não contratado' }}
          </div>
          <div class="card-image" @click="showWhatsAppDialog = true">
            <img
              src="https://trypeach.io/images/uploads/wa.webp"
              alt="Disparos de WhatsApp"
            />
          </div>
          <div class="card-content">
            <h3 @click="showWhatsAppDialog = true">Disparos de WhatsApp</h3>
            <p @click="showWhatsAppDialog = true">Envie mensagens em massa de forma inteligente e personalizada</p>
            <div class="card-actions">
              <template v-if="$WiCheckPermission('cdf8059b')">
                <button class="action-button primary" @click="$router.push({ name: 'talk-list', params: { page: 'talk-campaign' } })">
                  <span class="icon">campaign</span>
                  Acessar
                </button>
              </template>
              <template v-else>
                <button class="action-button secondary" @click="showWhatsAppDialog = true">
                  <span class="icon">info_outline</span>
                  Saiba Mais
                </button>
              </template>
            </div>
          </div>
        </div>

        <div class="feature-card">
          <div class="status-badge" :class="{ 'active': $WiCheckPermission('cdf805123') }">
            <span class="status-icon">{{ $WiCheckPermission('cdf805123') ? 'check_circle' : 'lock' }}</span>
            {{ $WiCheckPermission('cdf805123') ? 'Ativo' : 'Não contratado' }}
          </div>
          <div class="card-image" @click="showUraDialog = true">
            <img
              src="/static/background/ura-reversa-wise-message.jpg"
              alt="URA Reversa"
            />
          </div>
          <div class="card-content">
            <h3 @click="showUraDialog = true">URA Reversa</h3>
            <p @click="showUraDialog = true">Ligue para seus cliente automaticamente com nossa de URA Reversa</p>
            <div class="card-actions">
              <template v-if="$WiCheckPermission('cdf805123')">
                <button class="action-button primary" @click="$router.push({ name: 'talk-list', params: { page: 'talk-campaign' } })">
                  <span class="icon">campaign</span>
                  Acessar
                </button>
              </template>
              <template v-else>
                <button class="action-button secondary" @click="showWhatsAppDialog = true">
                  <span class="icon">info_outline</span>
                  Saiba Mais
                </button>
              </template>
            </div>
          </div>
        </div>

        <div class="feature-card coming-soon">
          <div class="status-badge">
            <span class="status-icon">update</span>
            Em breve
          </div>
          <div class="card-content">
            <h3>Em breve</h3>
            <p>Novos recursos serão adicionados</p>
          </div>
        </div>
      </div>

      <!-- Dialog WhatsApp -->
      <v-dialog v-model="showWhatsAppDialog" max-width="800" persistent>
        <v-card class="dialog-card">
          <div class="dialog-header">
            <div class="dialog-header-content">
              <span class="dialog-title">Disparos de WhatsApp</span>
              <div class="dialog-status" :class="{ 'active': $WiCheckPermission('cdf8059b') }">
                <span class="status-icon">{{ $WiCheckPermission('cdf8059b') ? 'check_circle' : 'lock' }}</span>
                {{ $WiCheckPermission('cdf8059b') ? 'Ativo' : 'Não contratado' }}
              </div>
            </div>
            <v-btn icon @click="showWhatsAppDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </div>

          <v-card-text class="dialog-content">
            <div class="dialog-image">
              <img
                src="https://trypeach.io/images/uploads/wa.webp"
                alt="Disparos de WhatsApp"
              />
            </div>

            <p class="dialog-intro">
              Quer alcançar seus clientes de forma rápida e eficiente? Nossa ferramenta de Disparos de WhatsApp oferece tudo o que você precisa para campanhas de sucesso, seja utilizando a API Oficial do WhatsApp ou a API Não Oficial.
            </p>

            <h4 class="section-title">Principais Vantagens:</h4>
            
            <div class="features-list">
              <div class="feature-item">
                <v-icon color="primary">cloud_upload</v-icon>
                <div class="feature-text">
                  <h5>Envio para Sua Base ou Mailing Novo</h5>
                  <p>Faça upload de contatos via planilha ou utilize a base cadastrada no sistema.</p>
                </div>
              </div>

              <div class="feature-item">
                <v-icon color="primary">campaign</v-icon>
                <div class="feature-text">
                  <h5>Disparo de Múltiplas Campanhas Simultâneas</h5>
                  <p>Lance várias campanhas ao mesmo tempo sem complicação.</p>
                </div>
              </div>

              <div class="feature-item">
                <v-icon color="primary">phone_iphone</v-icon>
                <div class="feature-text">
                  <h5>Disparo por Diversos Números Simultaneamente</h5>
                  <p>Utilize múltiplos números de WhatsApp para aumentar o alcance e evitar bloqueios.</p>
                </div>
              </div>

              <div class="feature-item">
                <v-icon color="primary">analytics</v-icon>
                <div class="feature-text">
                  <h5>Acompanhamento Completo e em Tempo Real</h5>
                  <p>Tenha relatórios detalhados e dashboards com métricas como taxa de entrega, leitura e resposta.</p>
                </div>
              </div>

              <div class="feature-item">
                <v-icon color="primary">security</v-icon>
                <div class="feature-text">
                  <h5>Algoritmo Anti-Bloqueio</h5>
                  <p>Embora não exista solução mágica, nosso sistema é equipado com algoritmos inteligentes e ferramentas que ajudam significativamente a minimizar riscos de bloqueio e garantir a continuidade de suas campanhas.</p>
                </div>
              </div>
            </div>

            <div class="dialog-footer">
              <p class="call-to-action">Transforme a comunicação da sua empresa com a nossa solução avançada de Disparos de WhatsApp!</p>
              
              <div v-if="!$WiCheckPermission('cdf8059b')" class="activation-section">
                <p class="activation-message">
                  Quer começar a usar agora mesmo? Entre em contato com nosso suporte e contrate este recurso!
                </p>
                <button class="activation-button" @click="setHelpDialogActive(true)">
                  <span class="icon">support_agent</span>
                  Solicitar Contratação
                </button>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Dialog URA -->
      <v-dialog v-model="showUraDialog" max-width="800" persistent>
        <v-card class="dialog-card">
          <div class="dialog-header">
            <div class="dialog-header-content">
              <span class="dialog-title">URA Reversa</span>
              <div class="dialog-status" :class="{ 'active': $WiCheckPermission('cdf805123') }">
                <span class="status-icon">{{ $WiCheckPermission('cdf805123') ? 'check_circle' : 'lock' }}</span>
                {{ $WiCheckPermission('cdf805123') ? 'Ativo' : 'Não contratado' }}
              </div>
            </div>
            <v-btn icon @click="showUraDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </div>

          <v-card-text class="dialog-content">
            <div class="dialog-image">
              <img
                src="/static/background/ura-reversa-wise-message.jpg"
                alt="URA Reversa"
              />
            </div>

            <p class="dialog-intro">
              A URA Reversa é a ferramenta definitiva para maximizar o contato com seus leads e clientes, utilizando chamadas automatizadas para gerar conversões de forma inteligente e eficiente.
            </p>

            <h4 class="section-title">Como Funciona:</h4>
            
            <div class="features-list">
              <div class="feature-item">
                <v-icon color="primary">people</v-icon>
                <div class="feature-text">
                  <h5>Seleção de Base de Contatos</h5>
                  <p>Escolha entre a sua base existente ou faça o upload de um novo mailing via planilha.</p>
                </div>
              </div>

              <div class="feature-item">
                <v-icon color="primary">call</v-icon>
                <div class="feature-text">
                  <h5>Disparo Inteligente</h5>
                  <p>Nossa URA realiza chamadas automáticas para cada número e reproduz uma mensagem de áudio totalmente projetada para conversão.</p>
                </div>
              </div>

              <div class="feature-item">
                <v-icon color="primary">touch_app</v-icon>
                <div class="feature-text">
                  <h5>Interação e Conversão</h5>
                  <p>Quando o cliente demonstra interesse (teclando 1), o sistema identifica automaticamente e envia uma mensagem de WhatsApp para o cliente/lead.</p>
                </div>
              </div>

              <div class="feature-item">
                <v-icon color="primary">support_agent</v-icon>
                <div class="feature-text">
                  <h5>Atendimento Personalizado</h5>
                  <p>O cliente é direcionado para conversar com um atendente humano ou um robô com Inteligência Artificial treinado para fornecer o melhor atendimento possível.</p>
                </div>
              </div>
            </div>

            <h4 class="section-title">Principais Diferenciais:</h4>

            <div class="features-list">
              <div class="feature-item">
                <v-icon color="primary">phone_callback</v-icon>
                <div class="feature-text">
                  <h5>Discagem Inteligente</h5>
                  <p>Utiliza números móveis e realiza discagens pelo mesmo DDD do cliente para maximizar as chances de contato.</p>
                </div>
              </div>

              <div class="feature-item">
                <v-icon color="primary">security</v-icon>
                <div class="feature-text">
                  <h5>Rota Anti-Spam</h5>
                  <p>Reduz drasticamente a possibilidade de bloqueios e garante a entrega eficiente das chamadas.</p>
                </div>
              </div>

              <div class="feature-item">
                <v-icon color="primary">trending_up</v-icon>
                <div class="feature-text">
                  <h5>Escalabilidade Altíssima</h5>
                  <p>Nossa URA é capaz de realizar volumes gigantescos de chamadas diariamente sem perder qualidade.</p>
                </div>
              </div>
            </div>

            <div class="dialog-footer">
              <p class="call-to-action">Automatize suas ligações e aumente suas conversões com nossa solução de URA Reversa de alta performance!</p>
              
              <div v-if="!$WiCheckPermission('cdf805123')" class="activation-section">
                <p class="activation-message">
                  Quer começar a usar agora mesmo? Entre em contato com nosso suporte e contrate este recurso!
                </p>
                <button class="activation-button" @click="setHelpDialogActive(true)">
                  <span class="icon">support_agent</span>
                  Solicitar Contratação
                </button>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Dialog de Contratação URA -->
      <v-dialog v-model="showUraActivationDialog" max-width="500" persistent>
        <v-card>
          <v-card-title class="headline">
            Contratar URA Reversa
          </v-card-title>
          <v-card-text>
            <p class="activation-dialog-text">
              Para contratar nossa solução de URA Reversa e começar a automatizar seus atendimentos, 
              entre em contato com nossa equipe de suporte. Teremos o prazer em apresentar todos os 
              benefícios e condições especiais para sua empresa.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey darken-1"
              text
              @click="showUraActivationDialog = false"
            >
              Fechar
            </v-btn>
            <v-btn
              color="primary"
              @click="handleActivationRequest"
            >
              Falar com Suporte
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Versão sem acesso -->
      <div v-if="false" class="locked-version">
        <div class="features-container">
          <h2 class="features-title">Recursos Disponíveis</h2>
          
          <div class="features-grid">
            <!-- Gestão de Contatos -->
            <div class="feature-card">
              <div class="feature-header">
                <span class="feature-icon">group</span>
                <h3>Gestão de Contatos e Segmentação</h3>
              </div>
              <ul class="feature-list">
                <li><span class="check">✓</span> Importação e exportação via CSV, Excel e API</li>
                <li><span class="check">✓</span> Segmentação avançada por tags e interesses</li>
                <li><span class="check">✓</span> Listas dinâmicas com atualização automática</li>
                <li><span class="check">✓</span> Verificação de números ativos</li>
              </ul>
            </div>

            <!-- Automação -->
            <div class="feature-card highlight">
              <div class="feature-header">
                <span class="feature-icon">smart_toy</span>
                <h3>Automação com IA</h3>
              </div>
              <ul class="feature-list">
                <li><span class="check">✓</span> Respostas automáticas inteligentes</li>
                <li><span class="check">✓</span> Follow-up automatizado</li>
                <li><span class="check special">★</span> IA avançada para conversão de leads</li>
                <li><span class="check">✓</span> Sequências personalizadas</li>
              </ul>
            </div>

            <!-- Personalização -->
            <div class="feature-card">
              <div class="feature-header">
                <span class="feature-icon">brush</span>
                <h3>Personalização e Criatividade</h3>
              </div>
              <ul class="feature-list">
                <li><span class="check">✓</span> Mensagens altamente personalizadas</li>
                <li><span class="check">✓</span> Suporte completo a multimídia</li>
                <li><span class="check">✓</span> Templates prontos para uso</li>
                <li><span class="check">✓</span> Variáveis dinâmicas</li>
              </ul>
            </div>

            <!-- Conformidade -->
            <div class="feature-card">
              <div class="feature-header">
                <span class="feature-icon">security</span>
                <h3>Conformidade e Segurança</h3>
              </div>
              <ul class="feature-list">
                <li><span class="check">✓</span> Gestão de Opt-in/Opt-out</li>
                <li><span class="check">✓</span> API Oficial WhatsApp</li>
                <li><span class="check">✓</span> Relatórios detalhados</li>
                <li><span class="check">✓</span> Controle anti-spam</li>
              </ul>
            </div>

            <!-- Integrações -->
            <div class="feature-card">
              <div class="feature-header">
                <span class="feature-icon">extension</span>
                <h3>Integrações e Expansibilidade</h3>
              </div>
              <ul class="feature-list">
                <li><span class="check">✓</span> API para sistemas externos</li>
                <li><span class="check">✓</span> Respostas com IA</li>
                <li><span class="check">✓</span> Múltiplos números</li>
                <li><span class="check">✓</span> Webhooks personalizados</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="activation-card">
          <div class="activation-content">
            <span class="activation-icon">lock</span>
            <h3>Módulo não ativado</h3>
            <p>Para liberar todos os recursos apresentados acima, entre em contato com nossa equipe de suporte</p>
          </div>
        </div>
      </div>

      <!-- Versão com acesso -->
      <div v-if="$WiCheckPermission('cdf8059b')" class="unlocked-version">
        <button class="primary-button" @click="$router.push({ name: 'talk-list', params: { page: 'talk-campaign' } })">
          <span class="icon">campaign</span>
          Acessar Campanhas
        </button>

        <div class="import-section">
          <h2>Importação de Contatos</h2>
          <p class="section-description">
            Importe seus contatos de forma organizada para criar campanhas mais efetivas.
            Siga os passos abaixo para começar.
          </p>
          
          <div class="import-card">
            <div class="import-step">
              <div class="step-header">
                <span class="step-number">1</span>
                <h3>Baixe a Planilha Modelo</h3>
              </div>
              <p class="step-description">
                Faça o download da nossa planilha modelo. Ela já está formatada com todas as colunas necessárias 
                para garantir uma importação sem erros.
              </p>
              <a href="/static/planilhas/ImportarContatos.xlsx" target="_blank" class="download-button">
                <span class="icon">download</span>
                Baixar Planilha
              </a>
            </div>

            <div class="import-step highlight-step">
              <div class="step-header">
                <span class="step-number">2</span>
                <h3>Organize seus Contatos</h3>
              </div>
              <p class="step-description">
                Preencha a planilha com seus contatos e adicione tags para melhor organização. 
                As tags são essenciais para segmentar seus contatos e criar campanhas direcionadas.
              </p>
            </div>

            <div class="import-step">
              <div class="step-header">
                <span class="step-number">3</span>
                <h3>Importe sua Lista</h3>
              </div>
              <p class="step-description">
                Após preencher a planilha, clique no botão abaixo para fazer o upload. 
                Certifique-se de que todos os dados estão corretos antes de importar.
              </p>
              <button class="upload-button" @click="importContacts">
                <span class="icon">upload_file</span>
                Importar Planilha
              </button>
            </div>
          </div>
          <div v-if="$WiCheckPermission('cdf8059b')" class="import-section">
            <WNImportFromSheet
              buttonText="Importar Contatos V2"
              :config="personImportConfig" 
              @done="handleImportDone"
            />
          </div>
          <p class="import-notice">
            <span class="icon">schedule</span>
            O processo de importação pode levar alguns minutos. Você será notificado quando for concluído.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import personImport from './personImport'
import WNImportFromSheet from '@/default/component/WNImportFromSheet'
export default {
  name: 'Campaigns',
  data() {
    return {
      showWhatsAppDialog: false,
      showUraDialog: false,
      showUraActivationDialog: false
    }
  },
  computed: {
    personImportConfig() {
      return personImport
    }
  },
  methods: {
    ...mapMutations(['setHelpDialogActive']),
    importContacts() {
      this.$WiEditDialog({
        wiConfig: 'import-talk-contacts'
      })
    },
    handleImportDone(data) {
      this.$router.push({
        name: 'register-list',
        params: {
          page: 'import'
        },
        query: {
          where: `id,${data.import_id}`
        }
      })
    },
    requestUraActivation() {
      this.showUraActivationDialog = true
    },
    handleActivationRequest() {
      // Placeholder para futura implementação
      console.log('Implementação futura: Solicitação de ativação')
    }
  },
  components: {
    WNImportFromSheet
  }
}
</script>

<style scoped>
.campaigns-container {
  padding: 20px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.campaigns-content {
  background: white;
  border-radius: 16px;
  padding: 32px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.theme--dark .campaigns-content {
  background: #1E1E1E;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.header-image {
  text-align: center;
  margin-bottom: 32px;
}

.header-image img {
  max-width: 400px;
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.header-text {
  text-align: center;
  margin-bottom: 24px;
}

.header-text h1, .header-text h2 {
  font-size: 28px;
  margin-bottom: 4px;
}

.theme--dark .header-text h1 {
  color: #fff;
}

.theme--dark .header-text h2 {
  color: #fff;
}

.subtitle {
  font-size: 16px;
}

.theme--dark .subtitle {
  color: #aaa;
}

/* Versão bloqueada */
.features-container {
  padding: 32px;
  margin-bottom: 40px;
}

.features-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 40px;
  color: #333;
}

.theme--dark .features-title {
  color: #fff;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.feature-card {
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.theme--dark .feature-card {
  background: #1E1E1E;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card.highlight {
  border: 2px solid #1976d2;
  background: #f8f9fa;
}

.theme--dark .feature-card.highlight {
  border: 2px solid #64B5F6;
  background: #2D2D2D;
}

.feature-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.feature-icon {
  font-family: 'Material Icons';
  font-size: 24px;
  color: #1976d2;
  background: #e3f2fd;
  padding: 8px;
  border-radius: 8px;
}

.theme--dark .feature-icon {
  color: #64B5F6;
  background: #1E1E1E;
}

.feature-header h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.theme--dark .feature-header h3 {
  color: #fff;
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  color: #666;
  font-size: 14px;
}

.theme--dark .feature-list li {
  color: #aaa;
}

.check {
  color: #4caf50;
  font-weight: bold;
}

.check.special {
  color: #f57c00;
}

.activation-card {
  text-align: center;
  padding: 40px;
  background: linear-gradient(145deg, #f6f7f9, #ffffff);
  border-radius: 16px;
  margin-top: 32px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
  border: 1px solid #e0e0e0;
}

.theme--dark .activation-card {
  background: linear-gradient(145deg, #2d2d2d, #1e1e1e);
  border: 1px solid #424242;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.activation-content {
  max-width: 400px;
  margin: 0 auto;
}

.activation-icon {
  font-family: 'Material Icons';
  font-size: 48px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: #e3f2fd;
  color: #1976d2;
  border-radius: 50%;
  margin: 0 auto 24px;
  display: block;
}

.theme--dark .activation-icon {
  background: #1E1E1E;
  color: #64B5F6;
}

.activation-card h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 16px;
  font-weight: 500;
}

.theme--dark .activation-card h3 {
  color: #fff;
}

.activation-card p {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 24px;
}

.theme--dark .activation-card p {
  color: #aaa;
}

.activation-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background: #1976d2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.activation-button:hover {
  background: #1565c0;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(25, 118, 210, 0.2);
}

.theme--dark .activation-button:hover {
  background: #1565c0;
  box-shadow: 0 4px 12px rgba(25, 118, 210, 0.4);
}

.button-icon {
  font-family: 'Material Icons';
  font-size: 20px;
}

/* Versão desbloqueada */
.unlocked-version {
  text-align: center;
}

.primary-button, .secondary-button {
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.primary-button {
  background: #1976d2;
  color: white;
}

.secondary-button {
  background: #e3f2fd;
  color: #1976d2;
}

.primary-button:hover, .secondary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.import-section {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid #eee;
}

.import-section h2 {
  text-align: center;
  color: #333;
  margin-bottom: 24px;
  font-size: 28px;
}

/* dark mode */
.theme--dark .import-section h2 {
  color: #fff;
}

.section-description {
  text-align: center;
  color: #666;
  max-width: 600px;
  margin: 0 auto 40px;
  line-height: 1.6;
}

.theme--dark .section-description {
  color: #aaa;
}

.import-card {
  max-width: 600px;
  margin: 0 auto;
  background: #fff;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.theme--dark .import-card {
  background: #2D2D2D;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

.import-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;
  border-bottom: 1px solid #eee;
}

.theme--dark .import-step {
  border-bottom: 1px solid #363636;
}

.import-step:last-child {
  border-bottom: none;
}

.step-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.step-header h3 {
  margin: 0;
  color: #333;
  font-size: 18px;
  font-weight: 500;
}

.theme--dark .step-header h3 {
  color: #fff;
}

.step-number {
  width: 28px;
  height: 28px;
  background: #1976d2;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
}

.step-description {
  color: #666;
  margin: 8px 0 24px 0;
  line-height: 1.5;
  font-size: 15px;
  padding-bottom: 10px;
}

.theme--dark .step-description {
  color: #aaa;
}

.highlight-step {
  background: #f8f9fa;
  border-radius: 8px;
  margin: 8px 0;
}

.theme--dark .highlight-step {
  background: #363636;
}

.download-button, .upload-button {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  font-size: 15px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.download-button {
  background: #e3f2fd;
  color: #1976d2;
}

.theme--dark .download-button {
  background: #1E1E1E;
  color: #64B5F6;
}

.upload-button {
  background: #1976d2;
  color: white;
}

.theme--dark .upload-button {
  background: #1976d2;
  color: white;
}

.download-button:hover, .upload-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.import-notice {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #f57c00;
  margin-top: 20px;
  font-size: 14px;
}

.theme--dark .import-notice {
  color: #FFB74D;
}

.icon {
  font-family: 'Material Icons';
  font-size: 18px;
}

.features-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin: 16px auto;
  padding: 0 16px;
  max-width: 1200px;
}

.feature-card {
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.theme--dark .feature-card {
  background: #1E1E1E;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.feature-card:hover {
  transform: translateY(-5px);
}

.card-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease;
}

.card-content {
  padding: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.card-content h3 {
  font-size: 20px;
  margin-bottom: 8px;
  font-weight: 500;
}

.card-content p {
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
}

.feature-card.coming-soon {
  border: 2px dashed #e0e0e0;
  opacity: 0.7;
}

@media (max-width: 1200px) {
  .features-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .features-cards {
    grid-template-columns: 1fr;
    padding: 0 8px;
  }

  .card-image {
    height: 160px;
  }
}

.clickable {
  cursor: pointer;
}

.dialog-card {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.dialog-header {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: linear-gradient(135deg, #1976d2, #2196f3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dialog-header-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dialog-status {
  display: flex;
  align-items: center;
  gap: 6px;
  background: rgba(0, 0, 0, 0.2);
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 13px;
}

.dialog-status.active {
  background: rgba(76, 175, 80, 0.9);
}

.dialog-title {
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.dialog-content {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
}

.dialog-image {
  width: 100%;
  height: 240px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 24px;
}

.dialog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dialog-intro {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 32px;
}

.section-title {
  font-size: 20px;
  color: #1976d2;
  margin-bottom: 24px;
  font-weight: 500;
}

.features-list {
  display: grid;
  gap: 24px;
  margin-bottom: 32px;
}

.feature-item {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 12px;
  transition: transform 0.2s ease;
}

.feature-item:hover {
  transform: translateY(-2px);
}

.feature-text {
  flex: 1;
}

.feature-text h5 {
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
  font-weight: 500;
}

.feature-text p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.dialog-footer {
  margin-top: 32px;
  text-align: center;
}

.call-to-action {
  font-size: 18px;
  color: #1976d2;
  font-weight: 500;
}

/* Dark theme */
.theme--dark .dialog-intro,
.theme--dark .feature-text p {
  color: #aaa;
}

.theme--dark .feature-text h5 {
  color: #fff;
}

.theme--dark .feature-item {
  background: #2d2d2d;
}

.theme--dark .section-title {
  color: #64B5F6;
}

.theme--dark .call-to-action {
  color: #64B5F6;
}

@media (max-width: 600px) {
  .status-badge {
    padding: 4px 8px;
    font-size: 11px;
  }

  .status-icon {
    font-size: 14px;
  }

  .dialog-header {
    padding: 8px 12px;
  }

  .dialog-title {
    font-size: 18px;
  }

  .dialog-status {
    font-size: 12px;
    padding: 3px 10px;
  }
}

.card-actions {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.theme--dark .card-actions {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.action-button {
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.action-button.primary {
  background: #1976d2;
  color: white;
}

.action-button.secondary {
  background: #e3f2fd;
  color: #1976d2;
}

.theme--dark .action-button.secondary {
  background: rgba(25, 118, 210, 0.1);
  color: #64B5F6;
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-card {
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: pointer;
}

.theme--dark .feature-card {
  background: #1E1E1E;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.theme--dark .feature-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.feature-card:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(25, 118, 210, 0.1), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.feature-card:hover:before {
  opacity: 1;
}

.status-badge {
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 12px;
  z-index: 1;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

.status-badge.active {
  background: rgba(76, 175, 80, 0.9);
}

.status-icon {
  font-family: 'Material Icons';
  font-size: 16px;
}

.activation-section {
  margin-top: 24px;
  padding: 24px;
  background: rgba(25, 118, 210, 0.1);
  border-radius: 12px;
  text-align: center;
}

.theme--dark .activation-section {
  background: rgba(25, 118, 210, 0.05);
}

.activation-message {
  font-size: 16px;
  color: #1976d2;
  margin-bottom: 16px;
  font-weight: 500;
}

.theme--dark .activation-message {
  color: #64B5F6;
}

.activation-button {
  padding: 12px 24px;
  background: #1976d2;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.activation-button:hover {
  background: #1565c0;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(25, 118, 210, 0.2);
}

.activation-dialog-text {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  margin: 16px 0;
}

.theme--dark .activation-dialog-text {
  color: #aaa;
}
</style>
