<template>
  <div class="business-card-actions">
    <v-divider class="business-card-actions__divider"></v-divider>
    <v-card-text class="business-card-actions__content pa-0">
      <v-card-actions class="business-card-actions__buttons">
        <v-tooltip bottom lazy>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              flat
              v-bind="attrs"
              v-on="on"
              @click="openEditDialog"
              class="business-card-actions__button"
            >
              <v-icon small>edit</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        
        <v-tooltip bottom lazy  >
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              icon 
              flat
              v-bind="attrs"
              v-on="on"
              @click="openEventsDialog"
              class="business-card-actions__button"
            >
              <v-icon small>timeline</v-icon>
            </v-btn>
          </template>
          <span>Histórico de Eventos</span>
        </v-tooltip>
        
        <v-tooltip bottom lazy>
          <template v-slot:activator="{ on, attrs }">
            <v-btn 
              icon 
              flat
              v-bind="attrs"
              v-on="on"
              @click="openFilesDialog"
              class="business-card-actions__button"
            >
              <v-icon small>attachment</v-icon>
            </v-btn>
          </template>
          <span>Arquivos Anexados</span>
        </v-tooltip>
        
        <v-spacer></v-spacer>
        
        <v-tooltip bottom lazy>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-prms="{ 'id': 'f55197d7' }"
              icon
              flat
              v-bind="attrs"
              v-on="on"
              @click="$emit('open:talk', item)"
              class="business-card-actions__button business-card-actions__button--chat"
            >
              <v-icon small>message</v-icon>
            </v-btn>
          </template>
          <span>Abrir Chat</span>
        </v-tooltip>
      </v-card-actions>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'BusinessCardActions',
  
  props: {
    item: { type: Object, required: true },
    config: { type: Object, required: true },
    refresh: { type: Function, required: true },
    wiDialogs: { type: Object, required: true }
  },

  methods: {
    openEditDialog() {
      this.$WiEditDialog({
        wiConfig: `${this.config.store}-open`,
        onSubmit: this.refresh,
        data: this.item
      })
    },

    openEventsDialog() {
      this.$WiDialog(this.wiDialogs.events(this.item))
    },

    openFilesDialog() {
      this.$WiDialog(this.wiDialogs.files(this.item))
    }
  }
}
</script>

<style scoped>
.business-card-actions__divider {
  margin: 0;
  opacity: 0.5;
}

.theme--light .business-card-actions__content {
  background-color: #f8f9fa;
}

.theme--dark .business-card-actions__content {
  background-color: #2d2d2d;
}

.business-card-actions__content {
  border-radius: 0 0 8px 8px;
  position: relative;
  overflow: hidden;
}

.business-card-actions__buttons {
  padding: 4px 2px;
  display: flex;
  align-items: center;
}

.business-card-actions__button {
  margin: 0 2px;
  transition: all 0.2s ease;
  border-radius: 6px;
  width: 28px;
  height: 28px;
  position: relative;
  overflow: hidden;
}

.theme--light .business-card-actions__button:hover {
  background-color: #e9ecef;
  transform: translateY(-1px);
}

.theme--dark .business-card-actions__button:hover {
  background-color: #3d3d3d;
  transform: translateY(-1px);
}

.business-card-actions__button--chat {
  color: #1e9e23;
}

.theme--light .business-card-actions__button--chat:hover {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.theme--dark .business-card-actions__button--chat:hover {
  background-color: #1b3d1f;
  color: #4caf50;
}

/* Estilo para o tooltip */
.v-tooltip__content {
  background-color: #2c3e50;
  color: white;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 0.8em;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}
</style> 