<template>
  <v-card class="elevation-17" style="border-radius: 10px;">
    <v-list dense two-line style="padding: 0 !important;">
      <v-menu offset-y top>
        <template v-slot:activator="{ on }">
          <v-list-tile v-on="on" class="person-statistics__info-tile" avatar>
            <v-list-tile-avatar class="person-statistics__info-avatar">
              <v-avatar>
                <img :src="statistic.image">
              </v-avatar>
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-sub-title>
                {{ statistic.title }}
              </v-list-tile-sub-title>
              <v-list-tile-title>
                <strong class="person-statistics__info-text">
                  {{ content }}
                </strong>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <v-list>
          <v-list-tile v-if="statistic.data && statistic.data.total > 0" @click="list()">
            <v-list-tile-avatar>
              <v-icon large>list</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>{{ statistic.list.title }}</v-list-tile-title>
          </v-list-tile>
          <v-divider v-if="statistic.data && statistic.data.total > 0"></v-divider>
          <v-list-tile @click="add()">
            <v-list-tile-avatar>
              <v-icon large>add</v-icon>
            </v-list-tile-avatar>
            <v-list-tile-title>{{ statistic.add.title }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-list>
  </v-card>
</template>

<script>
  export default {
    name: 'PersonStatistic',
    computed: {
      attributes () {
        return {
          statistics: this.person.statistics,
          statistic: this.statistic,
          person: this.person
        }
      },
      content () {
        return this.statistic.content(this.attributes)
      }
    },
    methods: {
      list: function () {
        if (this.statistic.list) {
          this.statistic.list.action(this.attributes)
        }
      },
      add: function () {
        if (this.statistic.add) {
          this.statistic.add.action(this.attributes)
        }
      }
    },
    props: {
      statistic: {
        type: Object,
        required: true
      },
      person: {
        type: Object,
        required: true
      }
    }
  }
</script>
<style>
  .person-statistics__info-number {
    font-size: 18px;
  }
  .person-statistics__info-text {
    font-size: 14px;
  }
  .person-statistics__info-tile .v-list__tile {
    padding: 0 7px !important;
    text-align: right !important;
    height: 50px !important;
  }
  .person-statistics__info-avatar {
    min-width: 50px !important;
  }
</style>
