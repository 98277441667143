<template>
  <v-toolbar 
    dense 
    :color="$wiDark ? 'dark' : 'white'" 
    flat 
    class="kanban-header elevation-10"
  >
    <div class="kanban-header__content">
      <!-- Ícone/Botão de Edição -->
      <div class="kanban-header__icon">
        <template v-if="!storeStage">
          <v-icon
            :color="stageColor"
            class="kanban-header__stage-icon"
          >{{ stageIcon }}</v-icon>
        </template>
        <template v-else>
          <v-btn 
            icon 
            small 
            flat
            class="kanban-header__edit-button"
            @click="handleEditClick"
          >
            <v-icon :color="stageColor">{{ stageIcon }}</v-icon>
          </v-btn>
        </template>
      </div>

      <!-- Título e Total -->
      <div class="kanban-header__info">
        <span 
          class="kanban-header__title" 
          :style="{ color: stageColor }"
        >
          {{ stageName }}
        </span>
        
        <div class="kanban-header__counter">
          <div class="kanban-header__counter-badge">
            {{ itemsTotal }}
          </div>
          <v-menu
            v-if="showStageAction && stageMenuItems.length"
            offset-y
            :close-on-content-click="true"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                x-small
                class="kanban-header__action-button ml-1"
                v-on="on"
              >
                <v-icon small>{{ stageActionIcon }}</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <template v-for="(item, index) in stageMenuItems">
                <v-subheader 
                  v-if="item.type === 'subheader'"
                  :key="'sub' + index"
                >
                  {{ item.title }}
                </v-subheader>
                <v-list-tile
                  v-else
                  :key="item.id"
                  @click="handleMenuAction(item.id)"
                  :disabled="item.disabled && item.disabled(stageData)"
                  :class="{ 
                    'v-list__tile--disabled': item.disabled && item.disabled(stageData)
                  }"
                >
                  <v-list-tile-avatar v-if="item.icon">
                    <v-icon :color="item.color">{{ item.icon }}</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-title>
                    {{ item.title }}
                  </v-list-tile-title>
                </v-list-tile>
              </template>
            </v-list>
          </v-menu>
        </div>
      </div>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  name: 'KanbanColumnHeader',
  
  props: {
    stageColor: {
      type: String,
      required: true
    },
    stageIcon: {
      type: String,
      required: true
    },
    stageName: {
      type: String,
      required: true
    },
    itemsTotal: {
      type: Number,
      required: true
    },
    storeStage: {
      type: String,
      default: ''
    },
    stageData: {
      type: Object,
      required: true
    },
    /**
     * Controla a visibilidade do botão de ação do estágio
     * @type {Boolean}
     */
    showStageAction: {
      type: Boolean,
      default: false
    },

    /**
     * Ícone do botão de ação do estágio
     * @type {String}
     */
    stageActionIcon: {
      type: String,
      default: 'mdi-dots-vertical'
    },
    stageMenuItems: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    handleEditClick() {
      this.$WiEditDialog({
        wiConfig: this.storeStage,
        onSubmit: () => this.$emit('refresh'),
        data: this.stageData
      })
    },
    handleMenuAction(menuItemId) {
      this.$emit('stage-menu-action', {
        stage: this.stageData,
        menuItemId
      })
    }
  }
}
</script>

<style scoped>
.kanban-header {
  border-radius: 8px 8px 0 0 !important;
  height: 40px !important;
  margin-bottom: 1px;
  z-index: 1;
}

.kanban-header >>> .v-toolbar__content {
  padding: 0 !important;
  height: 40px !important;
}

.kanban-header__content {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 8px;
}

.kanban-header__icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.kanban-header__stage-icon {
  font-size: 20px !important;
}

.kanban-header__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
}

.kanban-header__title {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  min-width: 0;
  margin-right: 8px;
}

.kanban-header__counter {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.kanban-header__counter-badge {
  background-color: #f5f5f5;
  color: #424242;
  font-size: 12px;
  font-weight: 600;
  min-width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
}

.kanban-header__edit-button {
  margin: 0 !important;
  height: 32px !important;
  width: 32px !important;
}

.kanban-header__edit-button:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.kanban-header__action-button {
  margin-left: 4px;
}

/* Ajuste para o conteúdo do toolbar do Vuetify */
.v-toolbar__content {
  padding: 0 !important;
  height: 48px !important;
}

.v-list__tile--disabled {
  opacity: 0.6;
  cursor: not-allowed !important;
}

.v-list__tile--disabled .v-list__tile__title,
.v-list__tile--disabled .v-icon {
  color: rgba(0, 0, 0, 0.38) !important;
}
</style> 