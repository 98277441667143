<template>
  <v-text-field
    type="datetime-local" 
    :mask="mask"
    :label="text" 
    v-model="model"
    :append-icon="appendIcon"
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    :disabled="typeof disabled == 'function' ? disabled(filters) : disabled"
  ></v-text-field>
</template>
<script>
  export default {
    name: 'FilterFieldDateTime',
    computed: {
      model: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    props: {
      value: {
        required: true
      },
      filters: {
        required: true
      },
      prependIcon: {
        default: null
      },
      appendIcon: {
        default: null
      },
      placeholder: {
        default: 'Insira aqui...'
      },
      text: {
        required: true
      },
      mask: {
        default: null
      },
      disabled: {
        default: false
      }
    }
  }
</script>