import { BusinessCategory } from '@/modules/business/config/BusinessCategory'

export class OrderCategory extends BusinessCategory {
  constructor() {
    super()
    this.id = '4f1be58b'
    this.title.list = 'Categorias de O.S'
    this.apiUrl = 'service-desk/config/category/order'

    // this.fieldsEdit.form.disabled = true
  }
}
