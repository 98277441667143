<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card class="image-cropper">
        <v-card-title class="image-cropper__title py-2">
          <span>{{ title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon small @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="image-cropper__content">
          <!-- Etapa de seleção de arquivo -->
          <div v-if="!imageSelected" class="image-cropper__upload-area">
            <input
              type="file"
              ref="fileInput"
              :accept="acceptedFormats"
              class="image-cropper__file-input"
              @change="onFileSelected"
            />
            <div 
              class="image-cropper__upload-placeholder" 
              @click="triggerFileInput"
              @dragover.prevent="onDragOver"
              @dragleave.prevent="onDragLeave"
              @drop.prevent="onDrop"
              :class="{'image-cropper__upload-placeholder--drag-over': isDragOver}"
            >
              <v-icon class="image-cropper__upload-icon">cloud_upload</v-icon>
              <p class="image-cropper__upload-text">Clique para selecionar uma imagem</p>
              <p class="image-cropper__upload-hint">ou arraste e solte aqui</p>
              <p class="image-cropper__formats-hint">Formatos aceitos: {{ formatsList }}</p>
            </div>
          </div>

          <!-- Etapa de recorte -->
          <div v-else-if="!previewMode" class="image-cropper__editor">
            <div class="image-cropper__instructions">
              <v-icon small color="info" class="mr-1">info</v-icon>
              <span>Arraste a área de recorte para ajustar a imagem</span>
            </div>
            
            <div class="image-cropper__preview-container">
              <div class="image-cropper__image-container" ref="imageContainer">
                <img
                  :src="imageUrl"
                  ref="image"
                  class="image-cropper__image"
                  @load="initializeCropper"
                />
                <div class="image-cropper__crop-area" ref="cropArea" :style="cropAreaStyle">
                  <div class="image-cropper__crop-border"></div>
                  <div 
                    v-for="(handle, index) in resizeHandles" 
                    :key="index"
                    class="image-cropper__resize-handle"
                    :class="`image-cropper__resize-handle--${handle.position}`"
                    @mousedown="startResize($event, handle.cursor)"
                  ></div>
                </div>
              </div>
            </div>

            <div class="image-cropper__controls">
              <div class="image-cropper__zoom-container">
                <v-icon small>zoom_out</v-icon>
                <v-slider
                  v-model="zoom"
                  min="100"
                  max="300"
                  hide-details
                  class="image-cropper__zoom-slider mx-2"
                  @input="updateZoom"
                ></v-slider>
                <v-icon small>zoom_in</v-icon>
              </div>
              
              <div class="image-cropper__buttons">
                <v-btn text color="error" @click="resetImage" class="image-cropper__btn">
                  <v-icon left small class="hidden-xs-only hidden-sm-only">refresh</v-icon>
                  Trocar
                </v-btn>
                <v-btn color="primary" @click="previewCrop" class="image-cropper__btn">
                  <v-icon left small class="hidden-xs-only hidden-sm-only">crop</v-icon>
                  Recortar
                </v-btn>
              </div>
            </div>
          </div>

          <!-- Etapa de confirmação -->
          <div v-else class="image-cropper__confirmation">
            <div class="image-cropper__instructions">
              <v-icon small color="success" class="mr-1">check_circle</v-icon>
              <span>Confirme se o recorte está como desejado</span>
            </div>
            
            <div class="image-cropper__preview-result">
              <div class="image-cropper__preview-frame">
                <img :src="croppedImageUrl" class="image-cropper__preview-image" alt="Imagem recortada" />
              </div>
            </div>
            
            <div class="image-cropper__confirmation-controls">
              <v-btn text color="error" @click="backToEdit" class="image-cropper__btn">
                <v-icon left small>arrow_back</v-icon>
                Voltar
              </v-btn>
              <v-btn color="success" @click="confirmAndSave" class="image-cropper__btn">
                <v-icon left small>save</v-icon>
                Confirmar e Salvar
              </v-btn>
            </div>
          </div>
        </v-card-text>

        <div v-if="loading" class="image-cropper__loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { callApi } from '@/default/service/Api'
export default {
  name: 'ImageCropper',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    aspectRatio: {
      type: Number,
      default: 1
    },
    personId: {
      type: [Number, String],
      required: true
    },
    personName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Selecionar Imagem de Perfil'
    },
    formats: {
      type: Array,
      default: () => ['jpg', 'jpeg', 'png', 'gif']
    }
  },
  data() {
    return {
      dialog: false,
      imageSelected: false,
      imageUrl: null,
      originalFile: null,
      zoom: 100,
      cropPosition: { x: 0, y: 0 },
      cropSize: 200,
      isDragging: false,
      dragStart: { x: 0, y: 0 },
      isResizing: false,
      resizeCursor: 'nwse-resize',
      loading: false,
      imageSize: { width: 0, height: 0 },
      imagePosition: { x: 0, y: 0 },
      isDragOver: false,
      previewMode: false,
      croppedImageUrl: null,
      croppedBlob: null,
      resizeHandles: [
        { position: 'top-left', cursor: 'nwse-resize' },
        { position: 'top-right', cursor: 'nesw-resize' },
        { position: 'bottom-left', cursor: 'nesw-resize' },
        { position: 'bottom-right', cursor: 'nwse-resize' }
      ]
    }
  },
  computed: {
    cropAreaStyle() {
      return {
        width: `${this.cropSize}px`,
        height: `${this.cropSize}px`,
        left: `${this.cropPosition.x}px`,
        top: `${this.cropPosition.y}px`
      }
    },
    acceptedFormats() {
      return this.formats.map(format => `.${format}`).join(',');
    },
    formatsList() {
      return this.formats.join(', ').toUpperCase();
    }
  },
  watch: {
    value(newVal) {
      this.dialog = newVal
    },
    dialog(newVal) {
      this.$emit('input', newVal)
      if (!newVal) {
        this.resetState()
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    onFileSelected(event) {
      const file = event.target.files[0]
      if (!file) return
      
      this.processFile(file)
    },
    onDragOver() {
      this.isDragOver = true
    },
    onDragLeave() {
      this.isDragOver = false
    },
    onDrop(event) {
      this.isDragOver = false
      const file = event.dataTransfer.files[0]
      if (!file) return
      
      // Verificar se o formato do arquivo é aceito
      const fileExt = file.name.split('.').pop().toLowerCase()
      if (!this.formats.includes(fileExt)) {
        alert(`Formato não suportado. Por favor, use: ${this.formatsList}`)
        return
      }
      
      this.processFile(file)
    },
    processFile(file) {
      this.originalFile = file
      this.imageUrl = URL.createObjectURL(file)
      this.imageSelected = true
      this.previewMode = false
    },
    initializeCropper() {
      const image = this.$refs.image
      const container = this.$refs.imageContainer
      
      // Obter dimensões reais da imagem
      this.imageSize = {
        width: image.naturalWidth,
        height: image.naturalHeight
      }
      
      // Calcular dimensões iniciais do container
      const containerRect = container.getBoundingClientRect()
      
      // Centralizar área de recorte
      this.cropPosition = {
        x: (containerRect.width - this.cropSize) / 2,
        y: (containerRect.height - this.cropSize) / 2
      }
      
      // Configurar eventos de arrastar
      this.$refs.cropArea.addEventListener('mousedown', this.startDrag)
      
      // Adicionar eventos ao documento
      document.addEventListener('mousemove', this.onDrag)
      document.addEventListener('mouseup', this.stopDrag)
    },
    startDrag(event) {
      // Verificar se o clique foi em uma alça de redimensionamento
      if (event.target.classList.contains('image-cropper__resize-handle')) return
      
      this.isDragging = true
      this.dragStart = {
        x: event.clientX - this.cropPosition.x,
        y: event.clientY - this.cropPosition.y
      }
      event.preventDefault()
    },
    onDrag(event) {
      if (this.isDragging) {
        const container = this.$refs.imageContainer
        const containerRect = container.getBoundingClientRect()
        
        let newX = event.clientX - this.dragStart.x
        let newY = event.clientY - this.dragStart.y
        
        // Limitar movimento dentro do container
        newX = Math.max(0, Math.min(newX, containerRect.width - this.cropSize))
        newY = Math.max(0, Math.min(newY, containerRect.height - this.cropSize))
        
        this.cropPosition = { x: newX, y: newY }
      } else if (this.isResizing) {
        const container = this.$refs.imageContainer
        const containerRect = container.getBoundingClientRect()
        
        // Calcular novo tamanho baseado na posição do mouse
        const size = Math.min(
          Math.max(100, event.clientX - containerRect.left - this.cropPosition.x),
          Math.min(
            containerRect.width - this.cropPosition.x,
            containerRect.height - this.cropPosition.y
          )
        )
        
        this.cropSize = size
      }
    },
    stopDrag() {
      this.isDragging = false
      this.isResizing = false
    },
    startResize(event, cursor) {
      this.isResizing = true
      this.resizeCursor = cursor
      event.preventDefault()
      event.stopPropagation()
    },
    updateZoom() {
      if (this.$refs.image) {
        this.$refs.image.style.width = `${this.zoom}%`
        
        // Ajustar posição da área de recorte se necessário
        this.adjustCropAreaPosition()
      }
    },
    adjustCropAreaPosition() {
      const container = this.$refs.imageContainer
      const containerRect = container.getBoundingClientRect()
      
      // Garantir que a área de recorte permaneça dentro do container
      let newX = this.cropPosition.x
      let newY = this.cropPosition.y
      
      if (newX + this.cropSize > containerRect.width) {
        newX = containerRect.width - this.cropSize
      }
      
      if (newY + this.cropSize > containerRect.height) {
        newY = containerRect.height - this.cropSize
      }
      
      this.cropPosition = { x: newX, y: newY }
    },
    resetImage() {
      this.imageSelected = false
      this.imageUrl = null
      this.zoom = 100
      this.previewMode = false
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = ''
      }
      if (this.croppedImageUrl) {
        URL.revokeObjectURL(this.croppedImageUrl)
        this.croppedImageUrl = null
      }
    },
    previewCrop() {
      // Criar canvas para recortar a imagem
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      
      // Definir tamanho do canvas como o tamanho da área de recorte
      canvas.width = 300
      canvas.height = 300
      
      const image = this.$refs.image
      const container = this.$refs.imageContainer
      const containerRect = container.getBoundingClientRect()
      
      // Calcular a escala real da imagem em relação ao contêiner
      const displayedWidth = image.offsetWidth
      const displayedHeight = image.offsetHeight
      
      // Calcular proporção entre tamanho real da imagem e tamanho exibido
      const scaleX = image.naturalWidth / displayedWidth
      const scaleY = image.naturalHeight / displayedHeight
      
      // Calcular a posição real da imagem dentro do contêiner
      const imageLeft = (containerRect.width - displayedWidth) / 2
      const imageTop = (containerRect.height - displayedHeight) / 2
      
      // Calcular coordenadas de recorte na imagem original
      const sourceX = (this.cropPosition.x - imageLeft) * scaleX
      const sourceY = (this.cropPosition.y - imageTop) * scaleY
      const sourceWidth = this.cropSize * scaleX
      const sourceHeight = this.cropSize * scaleY
      
      // Garantir que as coordenadas estejam dentro dos limites da imagem
      const validSourceX = Math.max(0, Math.min(sourceX, image.naturalWidth - 1))
      const validSourceY = Math.max(0, Math.min(sourceY, image.naturalHeight - 1))
      const validSourceWidth = Math.min(sourceWidth, image.naturalWidth - validSourceX)
      const validSourceHeight = Math.min(sourceHeight, image.naturalHeight - validSourceY)
      
      // Desenhar a parte recortada no canvas
      ctx.drawImage(
        image,
        validSourceX, validSourceY, validSourceWidth, validSourceHeight,
        0, 0, canvas.width, canvas.height
      )
      
      // Converter canvas para blob e criar URL para visualização
      canvas.toBlob((blob) => {
        if (this.croppedImageUrl) {
          URL.revokeObjectURL(this.croppedImageUrl)
        }
        
        this.croppedBlob = blob
        this.croppedImageUrl = URL.createObjectURL(blob)
        this.previewMode = true
        
        // Adicionar efeito de transição suave
        setTimeout(() => {
          const previewImage = document.querySelector('.image-cropper__preview-image')
          if (previewImage) {
            previewImage.classList.add('image-cropper__preview-image--loaded')
          }
        }, 100)
      }, this.originalFile.type)
    },
    backToEdit() {
      this.previewMode = false
    },
    confirmAndSave() {
      if (!this.croppedBlob) return
      
      this.loading = true
      
      // Criar arquivo a partir do blob
      const croppedFile = new File([this.croppedBlob], this.originalFile.name, {
        type: this.originalFile.type
      })
      
      // Enviar para o servidor
      this.uploadImage(croppedFile)
    },
    uploadImage(file) {
      // Criar FormData para envio
      const formData = new FormData()
      const infos = {
        type: file.type,
        size: file.size,
        id: this.personId,
        name: this.personName,
        profile: true
      }
      
      formData.append('files[0]', file)
      formData.append('infos[0]', JSON.stringify(infos))
      
      // Enviar para o servidor usando a API
      callApi.post({
        uri: 'system/file',
        msgLoad: false,
        data: formData,
        headers: { headers: { 'Content-Type': 'multipart/form-data' } },
        sucess: (data) => {
          if (data.status === 201 && data.data.status === 'success') {
            this.$emit('success', data.data.data[0])
            this.closeDialog()
          } else {
            this.$emit('error')
            console.error(JSON.stringify({
              color: 'error',
              message: 'Erro ao atualizar imagem de perfil.'
            }))
          }
          this.loading = false
        },
        error: (err) => {
          console.error(err)
          this.$emit('error')
          console.error(JSON.stringify({
            color: 'error',
            message: 'Erro ao enviar imagem para o servidor.'
          }))
          this.loading = false
        }
      })
    },
    resetState() {
      this.imageSelected = false
      this.imageUrl = null
      this.zoom = 100
      this.cropPosition = { x: 0, y: 0 }
      this.loading = false
      this.previewMode = false
      
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = ''
      }
      
      if (this.croppedImageUrl) {
        URL.revokeObjectURL(this.croppedImageUrl)
        this.croppedImageUrl = null
      }
      
      // Remover event listeners
      if (this.$refs.cropArea) {
        this.$refs.cropArea.removeEventListener('mousedown', this.startDrag)
      }
      
      document.removeEventListener('mousemove', this.onDrag)
      document.removeEventListener('mouseup', this.stopDrag)
    }
  },
  beforeDestroy() {
    // Limpar event listeners
    document.removeEventListener('mousemove', this.onDrag)
    document.removeEventListener('mouseup', this.stopDrag)
    
    // Liberar URL do objeto
    if (this.imageUrl) {
      URL.revokeObjectURL(this.imageUrl)
    }
    
    if (this.croppedImageUrl) {
      URL.revokeObjectURL(this.croppedImageUrl)
    }
  }
}
</script>

<style>
.image-cropper {
  border-radius: 10px !important;
}
.image-cropper__title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  font-size: 18px;
  font-weight: 500;
}

.theme--dark .image-cropper__title {
  border-bottom-color: #5f5f5f;
}

.image-cropper__content {
  position: relative;
}

.image-cropper__upload-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  border: 2px dashed #bdbdbd;
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 16px;
}

.theme--dark .image-cropper__upload-area {
  background-color: #2d2d2d;
  border-color: #424242;
}

.image-cropper__upload-area:hover {
  background-color: #eeeeee;
  border-color: #1976d2;
}

.theme--dark .image-cropper__upload-area:hover {
  background-color: #333333;
  border-color: #64b5f6;
}

.image-cropper__file-input {
  display: none;
}

.image-cropper__upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 32px;
  transition: transform 0.2s ease, background-color 0.3s ease;
}

.image-cropper__upload-placeholder--drag-over {
  background-color: rgba(25, 118, 210, 0.1);
  transform: scale(0.98);
  border-color: #1976d2;
}

.theme--dark .image-cropper__upload-placeholder--drag-over {
  background-color: rgba(100, 181, 246, 0.1);
  border-color: #64b5f6;
}

.image-cropper__upload-icon {
  font-size: 64px !important;
  color: #1976d2;
  margin-bottom: 16px;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.theme--dark .image-cropper__upload-icon {
  color: #64b5f6;
}

.image-cropper__upload-text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
}

.image-cropper__upload-hint {
  font-size: 14px;
  color: #757575;
  text-align: center;
  margin-bottom: 16px;
}

.image-cropper__formats-hint {
  font-size: 12px;
  color: #9e9e9e;
  text-align: center;
}

.theme--dark .image-cropper__upload-hint {
  color: #bdbdbd;
}

.theme--dark .image-cropper__formats-hint {
  color: #757575;
}

.image-cropper__editor {
  display: flex;
  flex-direction: column;
  height: 500px;
  position: relative;
}

.image-cropper__instructions {
  padding: 8px 16px;
  background-color: #e3f2fd;
  color: #0d47a1;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.theme--dark .image-cropper__instructions {
  background-color: #263238;
  color: #90caf9;
}

.image-cropper__preview-container {
  position: relative;
  width: 100%;
  flex: 1;
  overflow: hidden;
  background-color: #f5f5f5;
}

.theme--dark .image-cropper__preview-container {
  background-color: #2d2d2d;
}

.image-cropper__image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-cropper__image {
  max-width: 100%;
  max-height: 100%;
  transition: width 0.3s ease;
}

.image-cropper__crop-area {
  position: absolute;
  cursor: move;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease;
}

.theme--dark .image-cropper__crop-area {
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7);
}

.image-cropper__crop-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid white;
  box-sizing: border-box;
  pointer-events: none;
}

.image-cropper__resize-handle {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: white;
  border: 2px solid #1976d2;
  border-radius: 50%;
  z-index: 10;
  transition: transform 0.2s ease;
}

.image-cropper__resize-handle:hover {
  transform: scale(1.2);
}

.image-cropper__resize-handle--top-left {
  top: -6px;
  left: -6px;
  cursor: nwse-resize;
}

.image-cropper__resize-handle--top-right {
  top: -6px;
  right: -6px;
  cursor: nesw-resize;
}

.image-cropper__resize-handle--bottom-left {
  bottom: -6px;
  left: -6px;
  cursor: nesw-resize;
}

.image-cropper__resize-handle--bottom-right {
  bottom: -6px;
  right: -6px;
  cursor: nwse-resize;
}

.theme--dark .image-cropper__resize-handle {
  background-color: #424242;
  border-color: #64b5f6;
}

.image-cropper__controls {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.theme--dark .image-cropper__controls {
  background-color: #2d2d2d;
  border-top-color: #424242;
}

.image-cropper__zoom-container {
  /* display: flex; */
  display: none;
  align-items: center;
  margin-bottom: 16px;
}

.image-cropper__zoom-slider {
  flex: 1;
}

.image-cropper__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.image-cropper__btn {
  transition: transform 0.2s ease !important;
}

.image-cropper__btn:hover {
  transform: translateY(-2px);
}

/* Estilos para a tela de confirmação */
.image-cropper__confirmation {
  display: flex;
  flex-direction: column;
  height: 500px;
}

.image-cropper__preview-result {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 24px;
  overflow: hidden;
}

.theme--dark .image-cropper__preview-result {
  background-color: #2d2d2d;
}

.image-cropper__preview-frame {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: scale(0.95);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: fadeIn 0.5s ease;
}

.image-cropper__preview-frame:hover {
  transform: scale(1);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.theme--dark .image-cropper__preview-frame {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.theme--dark .image-cropper__preview-frame:hover {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.4);
}

.image-cropper__preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.image-cropper__preview-image--loaded {
  opacity: 1;
}

.image-cropper__confirmation-controls {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.theme--dark .image-cropper__confirmation-controls {
  background-color: #2d2d2d;
  border-top-color: #424242;
}

.image-cropper__loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  animation: fadeIn 0.3s ease;
}

.theme--dark .image-cropper__loading {
  background-color: rgba(0, 0, 0, 0.8);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .image-cropper__editor {
    height: 450px;
  }
  
  .image-cropper__buttons {
    flex-direction: row;
    justify-content: space-between;
  }
  
  .image-cropper__btn {
    flex: 1;
  }
}
</style> 