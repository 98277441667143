<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card class="image-viewer">
      <v-card-title class="image-viewer__title py-2">
        <span>{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="image-viewer__content pa-0">
        <div class="image-viewer__container">
          <img :src="imageUrl" :alt="personName" class="image-viewer__image">
        </div>
        
        <div v-if="updateProfileImage" class="image-viewer__actions">
          <v-btn 
            flat color="red" small
            class="image-viewer__change-btn" 
            @click="clearImage"
          >
            <v-icon left class="hidden-xs-only hidden-sm-only">close</v-icon>
            Remover Foto
          </v-btn>
          <v-btn 
            color="primary" small
            class="image-viewer__change-btn" 
            @click="changeImage"
          >
            <v-icon left class="hidden-xs-only hidden-sm-only">photo_camera</v-icon>
            Alterar Foto
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ImageViewer',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    imageUrl: {
      type: String,
      required: true
    },
    personName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Foto de Perfil'
    },
    updateProfileImage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    }
  },
  watch: {
    value(newVal) {
      this.dialog = newVal
    },
    dialog(newVal) {
      this.$emit('input', newVal)
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    changeImage() {
      this.closeDialog()
      this.$emit('change-image')
    },
    clearImage() {
      this.$emit('clear-image')
    }
  }
}
</script>

<style>
.image-viewer {
  border-radius: 10px !important;
}

.image-viewer__title {
  border-bottom: 1px solid #e0e0e0;
  font-size: 18px;
  font-weight: 500;
}

.theme--dark .image-viewer__title {
  border-bottom-color: #424242;
}

.image-viewer__content {
  background-color: #f5f5f5;
}

.theme--dark .image-viewer__content {
  background-color: #1e1e1e;
}

.image-viewer__container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #000; */
  padding: 16px;
}

.image-viewer__image {
  max-width: 100%;
  max-height: 320px;
  object-fit: contain;
  display: block;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
}

.image-viewer__actions {
  padding: 16px;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  border-radius: 10px;
}

.theme--dark .image-viewer__actions {
  background-color: #2d2d2d;
  border-top-color: #424242;
}

.image-viewer__change-btn {
  transition: transform 0.2s ease !important;
}

.image-viewer__change-btn:hover {
  transform: translateY(-2px);
}
</style> 