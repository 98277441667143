<template>
  <WiView 
    ref="wiView"
    :index="viewIndex" 
    :apiUrl="apiEndpoint" 
    :apiParams="apiParams"
    :notShowToolbar="true" 
    :notGetID="true" 
    @afterLoad="setContent"
  >
    <template slot="toolbar" slot-scope="{ refresh }">
      <v-container fluid class="pa-0">
        <div class="categories-header">
          <div class="header-content">
            <v-toolbar dense class="elevation-10" style="border-radius: 10px;">
              <v-toolbar-title>{{ title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <WNDateIntervalFilter
                :startDate="apiParams.dateStart"
                :endDate="apiParams.dateEnd"
                width="400"
                @filter="handleDateFilter"
              />
              <v-btn flat icon color="green" @click="refresh()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
        </div>
      </v-container>
    </template>
    <template slot-scope="{ content }">
      <v-container fluid class="pa-0 categories-container">
        <!-- <div class="categories-header">
          <div class="header-content">
            <v-toolbar dense class="elevation-10" style="border-radius: 10px;">
              <v-toolbar-title>{{ title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <WNDateIntervalFilter
                :startDate="apiParams.dateStart"
                :endDate="apiParams.dateEnd"
                width="400"
                @filter="handleDateFilter"
              />
              <v-btn flat icon color="green" @click="refresh()">
                <v-icon>refresh</v-icon>
              </v-btn>
            </v-toolbar>
          </div>
        </div> -->

        <div class="categories-wrapper">
          <template v-if="hasCategories(content)">
            <v-layout row wrap class="categories-content">
              <v-flex xs12 md7 class="tree-container">
                <CategoryTree 
                  :items="content.data || []"
                  @select="selectCategory"
                />
              </v-flex>

              <v-flex xs12 md5 class="selected-container">
                <SelectedCategory 
                  v-if="selectedCategory"
                  :category="selectedCategory"
                  @select-category="selectCategory"
                />
                <SelectedCategory 
                  v-else
                  :category="rootCategory"
                  @select-category="selectCategory"
                />
              </v-flex>
            </v-layout>
          </template>
          <template v-else>
            <!-- <EmptyCategories @create="handleAddCategory" /> -->
          </template>
        </div>
      </v-container>
    </template>
  </WiView>
</template>

<script>
import CategoryTree from './CategoryTree.vue'
// import EmptyCategories from './EmptyCategories.vue'
import SelectedCategory from './SelectedCategory.vue'
import WNDateIntervalFilter from '@/default/component/WNDateIntervalFilter'
import moment from 'moment'

export default {
  name: 'wi-categories',
  components: {
    CategoryTree,
    SelectedCategory,
    WNDateIntervalFilter
  },
  data: () => ({
    refresh: null,
    content: null,
    selectedCategory: null,
    apiParams: {
      dateField: null,
      dateStart: null,
      dateEnd: null,
      returnInactiveCategories: false
    }
  }),
  methods: {
    setContent({ content, refresh }) {
      this.content = content
      this.refresh = refresh
    },
    selectCategory(item) {
      this.selectedCategory = item
    },
    handleEditCategory(category) {
      this.$emit('on-edit-category', {
        refresh: this.refresh,
        category: category
      })
    },
    handleAddChildCategory(parentCategory) {
      this.$emit('on-add-child-category', {
        refresh: this.refresh,
        parentCategory: parentCategory,
      })
    },
    handleDeleteCategory(category) {
      this.$WiApiDelete({
        uri: this.deleteEndpoint,
        id: category.id,
        config: {
          msgLoad: {
            title: `Removendo categoria`,
            text: `Aguarde enquanto a categoria é removida.`
          },
          msgSucess: {
            title: 'Categoria removida com sucesso!',
            text: 'A categoria foi removida com sucesso.'
          },
          msgError: {
            title: 'Erro ao remover categoria',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: 'Excluir categoria',
            text: `Deseja realmente excluir a categoria "${category.name}"?`,
            confirmText: 'Excluir',
            confirmColor: 'error',
          }
        },
        callback: () => {
          this.refresh()
          this.$emit('category-deleted', category)
        }
      })
    },
    hasCategories(content) {
      return content?.data && content.data.length > 0
    },
    handleAddCategory() {
      this.$emit('on-add-category', {
        refresh: this.refresh
      })
    },
    handleDateFilter(date) {
      this.apiParams.dateStart = date.startDate
      this.apiParams.dateEnd = date.endDate
      this.refresh()
    }
  },
  computed: {
    rootCategory() {
      if (!this.content?.data) return null

      const rootCategories = this.content.data.filter(cat => !cat.parents || cat.parents.length === 0)
      
      return {
        id: -1,
        name: 'Todas as Categorias',
        icon: 'category',
        level: -1,
        color: '#2196F3', // cor padrão do vuetify blue
        description: 'Visão geral de todas as categorias',
        parents: [],
        children: rootCategories,
        amount_total: rootCategories.reduce((sum, cat) => sum + (cat.amount_total || 0), 0),
        amount_total_paid: rootCategories.reduce((sum, cat) => sum + (cat.amount_total_paid || 0), 0),
        amount_total_unpaid: rootCategories.reduce((sum, cat) => sum + (cat.amount_total_unpaid || 0), 0),
        percentage_total: 100,
        percentage_paid: rootCategories.reduce((sum, cat) => sum + (cat.percentage_paid || 0), 0) / rootCategories.length,
        percentage_unpaid: rootCategories.reduce((sum, cat) => sum + (cat.percentage_unpaid || 0), 0) / rootCategories.length,
        children_count: rootCategories.length,
        all_children_count: rootCategories.reduce((sum, cat) => sum + (cat.all_children_count || 0), 0)
      }
    }
  },
  props: {
    title: {
      type: String,
      default: 'Categorias'
    },
    viewIndex: {
      type: String,
      required: true
    },
    apiEndpoint: {
      type: String,
      required: true
    },
    parentCategoryField: {
      type: String,
      required: true
    },
    colorField: {
      type: String, 
      required: true
    },
    iconField: {
      type: String,
      required: true
    },
    defaultDateField: {
      type: String,
      default: 'date_receive'
    },
    defaultStartDate: {
      type: String,
      default: () => moment().startOf('month').format('YYYY-MM-DD')
    },
    defaultEndDate: {
      type: String,
      default: () => moment().endOf('month').format('YYYY-MM-DD')
    }
  },
  created() {
    // Inicializa os valores do apiParams com os defaults
    this.apiParams = {
      ...this.apiParams,
      dateField: this.defaultDateField,
      dateStart: this.defaultStartDate,
      dateEnd: this.defaultEndDate
    }
  }
}
</script>

<style scoped>
.categories-container {
  min-height: calc(100vh - 130px);
}

.theme--dark .categories-container {
  background-color: #121212 !important;
}

.categories-header {
  position: sticky;
  /* top: 47px; */
  z-index: 3;
  padding: 16px 24px 0 24px;
}

.theme--dark .categories-header {
  background-color: #121212;
}

.header-content {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
}

.categories-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 0px;
}

.theme--dark .categories-wrapper {
  background-color: #121212;
}

.categories-content {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.theme--dark .categories-content {
  background-color: #121212;
}

.tree-container {
  padding: 16px 24px;
  height: calc(100vh - 47px - 65px);
  overflow-y: auto;
}

.selected-container {
  padding: 16px 24px;
  position: sticky;
  top: calc(47px + 80px);
  height: calc(100vh - 47px - 65px);
  overflow-y: auto;
}

@media (max-width: 960px) {
  .categories-wrapper {
    padding: 0 16px;
  }

  .tree-container,
  .selected-container {
    padding: 16px;
  }

  .selected-container {
    position: relative;
    top: 0;
    height: auto;
  }

  .tree-container {
    height: auto;
  }
}

@media (max-width: 600px) {
  .categories-wrapper {
    padding: 0 12px;
  }

  .tree-container,
  .selected-container {
    padding: 12px;
  }
}
</style>
