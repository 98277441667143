<template>
  <div style="overflow-y: hide;">
    <v-navigation-drawer permanent absolute class="elevation-5" width="355">
      <v-toolbar dense :color="!$wiDark ? 'primary' : 'dark'" class="elevation-5">
        <v-btn icon ripple @click="$router.go(-1)" style="color: white;">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span style="color: white;">Conversas/Chats</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-title>
          <span class="talks-unseen-messages-count" v-text="unseenCount()"></span>
        </v-toolbar-title>
        <v-btn icon ripple @click="loadTalksToApi()" style="color: white;">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar dense flat class="theme--light v-navigation-drawer">
        <input v-model="searchTalkQuery" class="talk-input" type="text" placeholder="Pesquise uma conversa..." @keyup.enter="searchTalkByQuery()">
        <v-btn icon v-if="searchTalkQuery" @click="clearSearchByQuery()">
          <v-icon>close</v-icon>
        </v-btn>
        <!-- <v-btn icon>
          <v-icon>filter_list</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>low_priority</v-icon>
        </v-btn> -->
      </v-toolbar>
      <div v-if="api.loading">
        <div center style="width: 100%; text-align:center;">
          <br><br>
          <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
          <h1>Carregando...</h1>
        </div>
      </div>
      <div v-else-if="talks.length > 0" :style="{height: (innerHeight - 145) + 'px'}" style="padding:0 !important; overflow-y: scroll;">
        <v-list dense three-line style="padding: 0;">
          <template v-for="(talk, talkKey) in talks">
            <v-divider v-if="talkKey" :key="talkKey" inset></v-divider>
            <v-list-tile :key="talkKey + '-new'" avatar :style="{background: getTalkStatusBackground(talk)}">
              <v-list-tile-avatar @click="talkAction(talk)" style="cursor: pointer;">
                <img v-if="talk.contact && talk.contact.file" :src="talk.contact.file.url">
                <img v-else-if="talk.contact.type == 1" src="static/icons/user_icon_1.png">
                <group-icon v-else />
              </v-list-tile-avatar>
              <v-list-tile-content @click="talkAction(talk)" style="cursor: pointer;">
                <v-list-tile-title>
                  <img src="static/icons/monitor/whatsapp.png" style="width: 10px;">
                  <strong v-html="talk.contact.name"></strong>
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  <span v-text="talk.protocol"></span> | 
                  <span v-text="talk.time_total"></span>
                </v-list-tile-sub-title>
                <v-list-tile-title>
                  At: 
                  <strong v-if="talk.operator && talk.operator.name" v-text="talk.operator.name"></strong>
                  <span v-else>Não definido</span>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text>
                  <span v-text="talk.last_event_at"></span>
                </v-list-tile-action-text>
                <strong v-if="talk.unseen_messages && talk.unseen_messages != '0' && talk.id != $route.params.id" v-text="talk.unseen_messages" class="talks-unseen-messages-count"></strong>
                <v-menu v-if="talk.status < 3" right transition="slide-y-transition">
                  <template v-slot:activator="{ on }">
                      <div>
                        <v-icon v-if="talk.muted" v-on="on" color="grey lighten-1" small style="cursor:pointer;">volume_off</v-icon>
                        <v-icon v-on="on" color="grey lighten-1" style="cursor:pointer;">more_vert</v-icon>
                      </div>
                  </template>
                  <v-list>
                    <v-list-tile @click="$WiApiPut({uri: 'talk/talk', id: talk.id, data: {muted: !talk.muted}})">
                      <v-list-tile-avatar>
                        <v-icon v-if="talk.muted">volume_up</v-icon>
                        <v-icon v-else>volume_off</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>
                        <span v-if="talk.muted">Reativar</span>
                        <span v-else>Silenciar</span>
                         Notificações
                      </v-list-tile-title>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile v-if="talk.status < 3" @click="$WiApiPut({uri: 'talk/talk', id: talk.id, data: {status: 3}})">
                      <v-list-tile-avatar>
                        <v-icon color="green">done</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Concluir Atendimento</v-list-tile-title>
                    </v-list-tile>
                    <v-divider></v-divider>
                    <v-list-tile v-if="talk.status < 3" @click="$WiApiPut({uri: 'talk/talk', id: talk.id, data: {status: 4}})">
                      <v-list-tile-avatar>
                        <v-icon color="red">close</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-title>Cancelar Atendimento</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-list-tile-action>
            </v-list-tile>
          </template>
        </v-list>
        <div style="width: 100%; text-align: center; padding: 10px;">
          <v-btn @click="paginateTalks()" :disabled="!paginate.next">
            <v-icon>refresh</v-icon>
            <span>Carregar Mais</span>
          </v-btn>
        </div>
        <v-btn @click="newTalk()" color="green" class="white--text" fab fixed bottom right>
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <div v-else style="width: 100%; text-align: center; padding: 50px; font-size: 20px;">
        <img src="static/icons/talk-clear.png" alt="" style="width: 100px;">
        <div style="padding-bottom: 20px;">
          Nenhum atendimento em andamento, inicie um novo atendimento clicando logo abaixo.
        </div>
        <v-btn @click="newTalk()" color="primary">
          <v-icon>add</v-icon> 
          Iniciar Novo Atendimento
        </v-btn>
      </div>
    </v-navigation-drawer>
    <div style="margin-left: 355px;">
      <router-view v-if="$route.params.id"></router-view>
      <div v-else style="width: 100%; text-align: center; margin: auto; max-width: 400px;">
        <img src="static/background/whatsapp.JPG" alt="" style="width: 100%">
        <br>
        <span style="font-size: 20px;">
          Para que o atendimento via <strong>WhatsApp</strong> funcione, mantenha seu celular conectado.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  import GroupIcon from '@/modules/talk/components/icons/GroupIcon.vue';
  import {callApi} from '@/default/service/Api'
  export default {
    name: 'Teste',
    data () {
      return {
        searchTalkQuery: '',
        innerHeight: window.innerHeight,
        socket: {status: false},
        drawer: true,
        talks: [],
        info: {},
        api: {
          uri: 'talk/talk',
          loading: false
        },
        paginate: {
          talks: [],
          next: true
        }
      }
    },
    methods: {
      clearSearchByQuery: function () {
        this.searchTalkQuery = ''
        this.loadTalksToApi()
      },
      searchTalkByQuery: function () {
        this.loadTalksToApi({
          orWhere: 'protocol,' + this.searchTalkQuery + ';contact.name,' + this.searchTalkQuery
        })
      },
      newTalk: function () {
        this.$WiListDialog({
          wiConfig: 'talk-contact',
          mode: 'select',
          callback: (contact) => {
            if (contact) {
              this.$WiApiPost({
                uri: 'talk/talk',
                data: {
                  status: 2,
                  talk_contact_id: contact.id,
                  talk_channel_id: contact.talk_channel_id,
                  operator_id: this.$Auth.user().id,
                  responsible_id: this.$Auth.user().id
                },
                config: {msgLoad: true, msgSucess: true},
                callback: (response) => {
                  this.$router.push({name: 'talk-messages', params: {id: response.sucess.id}})
                  this.loadTalksToApi()
                }
              })
            }
          },
          params: {order: 'created_at,desc'},
          filter: {order: 'created_at,desc'}
        })
      },
      loadTalksToApi: function (searchQuery = {}) {
        this.unsubscribeOnTalks()
        this.api.loading = true
        this.talks = []
        callApi.get({
          uri: this.api.uri,
          params: {
            ...searchQuery,
            order: 'last_event_at,desc',
            where: 'status,<,3',
            limit: 20
          },
          sucess: (response) => {
            if (response && response.data && response.data.data && response.data.data.length > 0) {
              this.info = response.data
              this.talks = response.data.data
            }
            this.api.loading = false
            this.serverConnect()
            this.subscribeOnTalks()
          },
          error: (error) => {
            this.$WiMakeError({error: error, id: 103, title: 'Erro ao buscar informações.'})
            this.api.loading = false
          }
        })
      },
      serverConnect: function () {
        window.Echo.connector.socket.on('connect', () => {
          this.socket.status = true
          console.log('Chat Connect!')
        })
        window.Echo.connector.socket.on('disconnect', () => {
          this.socket.status = false
          console.log('Chat Disconnect!')
        })
      },
      subscribeOnTalks: function () {
        console.log('subscribing in talks')
        window.Echo.private(this.$Auth.account().id + '.talks')
        .listen('.new', (talk) => {
          console.log('new', talk)
          this.createTalk(talk)
        })
        .listen('.change', (talk) => {
          this.prepareUpdateTalk(talk)
        })
      },
      unsubscribeOnTalks: function () {
        window.Echo.leave(this.$Auth.account().id + '.talks')
        console.log('unsubscribe')
      },
      prepareUpdateTalk: function (talkChanged) {
        var key = null
        this.talks.filter((talk, talkKey) => {
          if (talk.id === talkChanged.id) {
            key = talkKey
          }
        })
        if (key != null && key > -1) {
          this.updateTalk(key, talkChanged)
        } else {
          this.createTalk(talkChanged)
        }
        this.$forceUpdate()
      },
      talkChangeNotification: function (talk) {
        if (parseInt(talk.id) !== this.$route.params.id && parseInt(talk.last_message_direction) === 1 && !talk.muted) {
          this.$makeSystemNotification(
            'Nova mensagem:',
            talk.contact.name,
            talk.contact.file ? talk.contact.file.url : 'static/icons/monitor/whatsapp.png'
          )
        }
      },
      createTalk: function (talk) {
        this.talks.unshift(talk)
        this.talkChangeNotification(talk)
      },
      updateTalk: function (keyTalk, talk) {
        this.talks[keyTalk].unseen_messages = talk.unseen_messages
        if (this.talks[keyTalk].last_event_at !== talk.last_event_at) {
          this.talks.splice(keyTalk, 1)
          this.talks.unshift(talk)
          this.talkChangeNotification(talk)
        } else {
          this.talks.splice(keyTalk, 1, talk)
        }
      },
      talkAction: function (talk) {
        console.log(talk)
        this.$router.push({name: 'talk-messages', params: {id: talk.id}, query: {previousTalkId: this.$route.params.id}})
      },
      paginateTalks: function () {
        var page = 2
        if (this.paginate.page) {
          page = this.paginate.page + 1
        }
        callApi.get({
          uri: this.api.uri,
          params: {page: page, order: 'last_event_at,desc', where: 'status,!=,3', limit: 20},
          sucess: (response) => {
            console.log(response)
            if (response && response.data && response.data.data && response.data.data.length > 0) {
              this.paginate.page = response.data.current_page
              this.talks = this.talks.concat(response.data.data)
              this.checkIfNextPaginateExist(response.data)
              console.log(response.data.data)
            }
          },
          error: (error) => {
            this.$WiMakeError({error: error, id: 103, title: 'Erro ao buscar informações.'})
          },
          msgLoad: true,
          msgSuccess: true
        })
      },
      checkIfNextPaginateExist: function (config) {
        if (config) {
          this.paginate.next = config.next_page_url || false
        }
      },
      unseenCount: function () {
        var unseenCount = 0
        this.talks.filter((talk) => {
          if (talk.unseen_messages > 0) {
            unseenCount++
          }
        })
        return unseenCount
      },
      getTalkStatusBackground: function (talk) {
        var background = ''
        if (parseInt(talk.status) === 3) {
          background = '#b2dfdb'
        }
        if (parseInt(talk.status) === 4) {
          background = '#ffccbc'
        }
        return background
      }
    },
    mounted () {
      this.loadTalksToApi()
    },
    beforeDestroy () {
      this.unsubscribeOnTalks()
      console.log('beforeDestroy')
    },
    computed: {
      user () {
        return this.$store.state.user
      }
    },
    components: {
      GroupIcon
    }
  }
</script>

<style scoped>
  .talk-input {
    min-height: 30px !important;
    padding: 0;
    background: white;
    width: 100%;
    border-radius: 5px;
    padding-left: 10px;
  }
  .talks-unseen-messages-count {
    padding: 0 5px;
    background: #009600;
    color: white;
    border-radius: 20px;
  }
  .v-list__tile {
    padding: 0 5px 0 5px !important;
  }
</style>