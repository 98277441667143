<template>
  <v-dialog 
    v-model="dialog" 
    scrollable 
    width="70%"
    max-width="1200px"
    persistent 
    lazy 
    :fullscreen="$vuetify.breakpoint.xsOnly"
    :dark="$wiDark"

  >
    <v-card style="min-height: 350px; border-radius: 10px;">
      <Talk
        v-if="dialog && talkId"
        :setTalkId="talkId"
        mode="dialog"
        @onClose="handleClose"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import Talk from '@/modules/talk/pages/TalkView/Talk/Talk'

export default {
  name: 'TalkDialog',
  
  components: {
    Talk
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    talkId: {
      type: [String, Number],
      default: null
    }
  },

  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script> 