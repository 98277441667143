<template>
  <div>
    <v-card class="talk-card" @click="handleCardClick" :style="{ background: getTalkStatusBackground }">
      <div class="talk-card__header">
        <!-- Contato -->
        <div class="talk-card__contact">
          <div class="talk-card__avatar-wrapper">
            <v-badge 
              overlap bottom right 
              offset-x="20" 
              offset-y="22" 
              color="transparent"
              class="talk-card__badge"
            >
              <template v-slot:badge>
                <div class="talk-card__badge-content">
                  <img 
                    :src="getChannelIcon" 
                    :style="getIconStyle"
                  >
                </div>
              </template>
              <v-avatar size="32">
                <v-img 
                  v-if="talkData.contact && talkData.contact.file"
                  :src="talkData.contact.file.url"
                  lazy-src="static/icons/not-found.png"
                />
                <v-img 
                  v-else-if="talkData.contact.type == 1" 
                  src="static/icons/user_icon_1.png"
                />
                <group-icon v-else />
              </v-avatar>
            </v-badge>
          </div>

          <div class="talk-card__info">
            <div class="talk-card__name">
              {{ talkData.contact.person ? talkData.contact.person.name : talkData.contact.name }}
            </div>
            <div class="talk-card__channel-wrapper">
              <div 
                class="talk-card__channel-name"
                :style="{ 
                  backgroundColor: talkData.channel.color + '30',
                  color: talkData.channel.color,
                  filter: 'brightness(0.7)'
                }"
              >
                <v-icon 
                  v-if="talkData.channel.status && talkData.channel.status.icon" 
                  size="12"
                  :color="talkData.channel.color"
                >
                  {{ talkData.channel.status.icon }}
                </v-icon>
                <span class="talk-card__channel-text">{{ talkData.channel.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Tempo desde última mensagem -->
        <div class="talk-card__time">
          <v-icon size="12" class="talk-card__time-icon">schedule</v-icon>
          <span>{{ getExternalTimeFormated(talkData.last_event_at) }}</span>
        </div>
      </div>

      <div class="talk-card__body">
        <div 
          class="talk-card__operator-section"
        >
          <!-- Status -->
          <div v-if="[2, 3, 4].includes(talkData.status)" class="talk-card__status">
            <strong
              v-if="talkData.status === 3"
              class="talk-card__status-text success--text"
              v-t="'modules.talks.pages.talkView.talks.labels.closed'"
            ></strong>
            <strong
              v-if="talkData.status === 4"
              class="talk-card__status-text error--text"
              v-t="'modules.talks.pages.talkView.talks.labels.cancelled'"
            ></strong>
            <div v-if="talkData.operator && talkData.operator.name" class="talk-card__operator">
              <v-avatar size="20" :color="talkData.operator && talkData.operator.file ? '' : getOperatorAvatarColor">
                <v-img 
                  v-if="talkData.operator && talkData.operator.file" 
                  :src="talkData.operator.file.url"
                />
                <span v-else class="white--text text-caption">{{ getOperatorInitials }}</span>
              </v-avatar>
              <span class="talk-card__operator-name">{{ talkData.operator.name | stringlength(22) }}</span>
            </div>
            <div
              v-else-if="talkData.status === 2"
              class="talk-card__status-text orange--text"
            >
              <v-icon size="14" class="orange--text">schedule</v-icon>
              <span v-t="'modules.talks.pages.talkView.talks.labels.awaiting'"></span>
            </div>
          </div>

          <div
            v-else-if="talkData.status === 1"
            class="talk-card__status-text info--text"
          >
            <v-icon size="14" class="info--text">smart_toy</v-icon>
            <span v-t="'modules.talks.pages.talkView.talks.labels.chatbot'"></span>
          </div>
        </div>

        <div 
          v-if="talkData.team || talkData.category"
          class="talk-card__footer"
        >
          <div 
            v-if="talkData.team"
            class="talk-card__team"
            :style="{ 
              backgroundColor: talkData.team.color + '30',
              color: talkData.team.color,
              filter: 'brightness(0.7)'
            }"
          >
            <v-icon size="14" :color="talkData.team.color">groups</v-icon>
            <span>{{ talkData.team.name }}</span>
          </div>

          <div 
            v-if="talkData.category"
            class="talk-card__category"
            :style="{ 
              backgroundColor: talkData.category.color + '30',
              color: talkData.category.color,
              filter: 'brightness(0.7)'
            }"
          >
            <v-icon size="14" :color="talkData.category.color">{{ talkData.category.icon }}</v-icon>
            <span>{{ talkData.category.name }}</span>
          </div>
        </div>
      </div>
    </v-card>

    <!-- Talk Dialog -->
    <TalkDialog
      v-model="talkDialog"
      :talkId="currentTalkId"
      @close="onCloseTalkDialog"
    />
  </div>
</template>

<script>
import TalkDialog from '@/modules/talk/components/TalkDialog'
import GroupIcon from '@/modules/talk/components/icons/GroupIcon.vue';
export default {
  name: 'TalkCard',
  components: {
    GroupIcon,
    TalkDialog
  },

  props: {
    /**
     * Dados do atendimento
     */
    talkData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      talkDialog: false,
      currentTalkId: null
    }
  },

  computed: {
    getChannelIcon() {
      const icons = {
        'wpp': 'static/icons/monitor/whatsapp.png',
        'instagram': 'static/icons/monitor/instagram.png',
        'facebook': 'static/icons/monitor/facebook.png',
        'email': 'static/icons/monitor/email.png',
        'sms': 'static/icons/monitor/sms.png'
      }
      return icons[this.talkData.channel.type.code] || ''
    },

    getIconStyle() {
      const styles = {
        'instagram': 'width: 20px; height: 20px;',
        'facebook': 'border-radius: 5px; width: 18px; height: 18px;',
        'email': 'width: 20px; height: 20px;',
        'sms': 'width: 20px; height: 20px;'
      }
      return styles[this.talkData.channel.type.code] || ''
    },

    getOperatorInitials() {
      if (!this.talkData.operator?.name) return ''
      return this.talkData.operator.name
        .split(' ')
        .map(n => n[0])
        .slice(0, 2)
        .join('')
        .toUpperCase()
    },

    getOperatorAvatarColor() {
      const colors = ['#1976D2', '#388E3C', '#D32F2F', '#FFA000', '#7B1FA2']
      const hash = this.talkData.operator?.name
        .split('')
        .reduce((acc, char) => char.charCodeAt(0) + acc, 0)
      return colors[hash % colors.length]
    },

    getTalkStatusBackground() {
      switch(parseInt(this.talkData.status)) {
        case 3: return this.$vuetify.theme.dark ? '#003161' : '#b2dfdb'
        case 4: return '#ffccbc'
      }

      if (this.talkData.is_waiting_response) {
        return this.$wiDark ? '#1E1E1E' : '#dfd2b2'
      }

      return ''
    }
  },

  methods: {
    handleCardClick() {
      const isInTalksRoute = ['talks', 'talk-messages', 'talk', 'talk-view', 'talk-view-messages', 'talk-view-messages-page'].includes(this.$route.name)
      
      if (isInTalksRoute) {
        this.openTalkPage()
      } else {
        this.openTalkDialog()
      }
    },

    openTalkPage() {
      const routeName = this.$vuetify.breakpoint.smAndDown ? 'talk-view-messages-page' : 'talk-view-messages'
      
      this.$router.push({
        name: routeName,
        params: { id: this.talkData.id },
        query: { previousTalkId: this.$route.params.id }
      })
    },

    openTalkDialog() {
      this.currentTalkId = this.talkData.id
      this.talkDialog = true
    },

    onCloseTalkDialog() {
      this.talkDialog = false
      this.currentTalkId = null
    },

    getExternalTimeFormated(time) {
      if (!time) return 'Agora'

      const date = this.$moment(time)
      const today = this.$moment().startOf('day')
      const days = Math.round(this.$moment.duration(today - date).asDays())
      
      if (days > 1) {
        return this.$moment(time).format('DD/MM HH:mm')
      }
      
      const ago = this.$moment(time).fromNow()
      let timeMessage = ago.includes('há poucos segundos') ? 'há segundos' : ago
      timeMessage = timeMessage.replaceAll('minutos', 'min')
      timeMessage = timeMessage.replaceAll('segundos', 'seg')
      timeMessage = timeMessage.replaceAll('horas', 'hr')

      return timeMessage
    }
  }
}
</script>

<style scoped>
.talk-card {
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 4px;
  transition: transform 0.2s, box-shadow 0.2s;
  max-width: 272px;
  min-width: 272px;
  cursor: grab;
}

.talk-card:active {
  cursor: grabbing;
}

.talk-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.talk-card__header {
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}

.talk-card__contact {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 0;
  flex: 1;
}

.talk-card__avatar-wrapper {
  position: relative;
  min-width: 32px;
  height: 32px;
}

.talk-card__info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 0;
}

.talk-card__name {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.talk-card__channel-wrapper {
  display: flex;
  min-width: 0;
  margin-right: 5px;
}

.talk-card__channel-name {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 11px;
  max-width: 100%;
}

.talk-card__channel-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.talk-card__time {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 9px;
  padding: 2px 6px;
  border-radius: 3px;
  white-space: nowrap;
  margin-left: auto;
  flex-shrink: 0;
}

.theme--dark .talk-card__time {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.05);
}

.theme--dark .talk-card__time-icon {
  color: white !important;
}

.theme--light .talk-card__time {
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.05);
}

.theme--light .talk-card__time-icon {
  color: rgba(0, 0, 0, 0.6) !important;
}

.talk-card__body {
  padding: 0 12px 8px;
}

.talk-card__operator-section {
  position: relative;
  padding: 8px 0;
}

.talk-card__operator-section::before,
.talk-card__operator-section::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
}

.talk-card__operator-section::before {
  top: 0;
}

.talk-card__operator-section::after {
  bottom: 0;
}

.theme--light .talk-card__operator-section::before,
.theme--light .talk-card__operator-section::after {
  background-color: rgba(0, 0, 0, 0.05);
}

.theme--dark .talk-card__operator-section::before,
.theme--dark .talk-card__operator-section::after {
  background-color: rgba(255, 255, 255, 0.05);
}

.talk-card__status {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 11px;
}

.talk-card__status-text {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  font-weight: 500;
}

.talk-card__operator {
  display: inline-flex;
  align-items: center;
  gap: 6px;
}

.talk-card__operator-name {
  font-size: 11px;
  font-weight: 500;
}

.theme--dark .talk-card__operator-name {
  color: white !important;
}

.theme--light .talk-card__operator-name {
  color: rgba(0, 0, 0, 0.87);
}

.talk-card__footer {
  margin-top: 8px;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.talk-card__team,
.talk-card__category {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 2px 6px;
  border-radius: 3px;
  font-size: 11px;
}

.talk-card__badge {
  display: block !important;
}

.talk-card__badge :deep(.v-badge__badge) {
  background-color: transparent !important;
}

.talk-card__badge-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* Ajuste para o ícone do canal */
.v-badge__badge {
  pointer-events: none;
}

.v-badge__badge img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
</style> 