// Funções auxiliares de validação
const validateCPF = (cpf) => {
  const cleanCPF = cpf.replace(/\D/g, '')
  if (cleanCPF.length !== 11) return false
  if (/^(\d)\1{10}$/.test(cleanCPF)) return false

  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cleanCPF.charAt(i)) * (10 - i)
  }
  let digit = 11 - (sum % 11)
  if (digit >= 10) digit = 0
  if (digit !== parseInt(cleanCPF.charAt(9))) return false

  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cleanCPF.charAt(i)) * (11 - i)
  }
  digit = 11 - (sum % 11)
  if (digit >= 10) digit = 0
  if (digit !== parseInt(cleanCPF.charAt(10))) return false

  return true
}

const validateCNPJ = (cnpj) => {
  const cleanCNPJ = cnpj.replace(/\D/g, '')
  if (cleanCNPJ.length !== 14) return false
  if (/^(\d)\1{13}$/.test(cleanCNPJ)) return false

  let size = cleanCNPJ.length - 2
  let numbers = cleanCNPJ.substring(0, size)
  const digits = cleanCNPJ.substring(size)
  let sum = 0
  let pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) pos = 9
  }

  let result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result !== parseInt(digits.charAt(0))) return false

  size = size + 1
  numbers = cleanCNPJ.substring(0, size)
  sum = 0
  pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += numbers.charAt(size - i) * pos--
    if (pos < 2) pos = 9
  }

  result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result !== parseInt(digits.charAt(1))) return false

  return true
}

const validateName = (value) => {
  if (!value) return false
  const cleanValue = value.toString().trim()
  return cleanValue.length >= 4 && cleanValue.length <= 100
}

const validatePhone = (value) => {
  if (!value) return false
  
  // Remove todos os caracteres não numéricos
  const cleanValue = value.toString().replace(/\D/g, '')
  
  // Se começar com código do país (1-3 dígitos), remove-o para validar apenas o número
  const phoneNumber = cleanValue.replace(/^(\d{1,3})/, '')
  
  // Valida o comprimento do número (min 6 dígitos para números locais, max 15 conforme padrão E.164)
  // Referência: https://en.wikipedia.org/wiki/E.164
  return phoneNumber.length >= 6 && phoneNumber.length <= 15
}

const validateCountryCode = (value) => {
  if (!value) return false
  const cleanValue = value.toString().replace(/\D/g, '').trim()
  return cleanValue.length >= 1 && cleanValue.length <= 3
}

const validateDate = (value) => {
  if (!value) return true // Data de nascimento é opcional
  
  try {
    const date = new Date(value)
    
    // Verifica se é uma data válida
    if (isNaN(date.getTime())) return false
    
    // Verifica se o ano está em um range razoável
    const year = date.getFullYear()
    const currentYear = new Date().getFullYear()
    
    return year >= 1900 && year <= currentYear + 100
    
  } catch (error) {
    return false
  }
}

const validateEmail = (value) => {
  if (!value) return true // Email é opcional
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(value.toString().trim())
}

const validateDocument = (value) => {
  if (!value) return true // Documento é opcional
  const cleanValue = value.toString().replace(/\D/g, '')
  return cleanValue.length === 11 ? validateCPF(cleanValue) : validateCNPJ(cleanValue)
}

export {
  validateName,
  validateDate,
  validateEmail,
  validatePhone,
  validateDocument,
  validateCountryCode
}