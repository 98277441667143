<template>
  <div class="empty-stages">
    <div class="empty-stages__container">
      <div class="empty-stages__content">
        <div class="empty-stages__icon-wrapper">
          <div class="empty-stages__icon-bg">
            <v-icon size="48">{{ icon }}</v-icon>
          </div>
          <div class="empty-stages__icon-circle"></div>
        </div>
        
        <div class="empty-stages__text">
          <h3 class="empty-stages__title">{{ title }}</h3>
          <p class="empty-stages__description">{{ description }}</p>
        </div>

        <button 
          v-if="buttonText"
          class="empty-stages__button pulse"
          @click="$emit('action')"
        >
          <span class="empty-stages__button-bg"></span>
          <span class="empty-stages__button-content">
            <v-icon size="20">add</v-icon>
            {{ buttonText }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KanbanEmptyStages',

  props: {
    icon: {
      type: String,
      default: 'inbox'
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.empty-stages {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 150px);
  padding: 24px;
  background: linear-gradient(135deg, rgba(247, 249, 252, 0.5), rgba(247, 249, 252, 0.9));
}

.empty-stages__container {
  width: 100%;
  max-width: 480px;
  padding: 48px;
  background: #fff;
  border-radius: 24px;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.06);
  transition: transform 0.3s ease;
}

.empty-stages__container:hover {
  transform: translateY(-4px);
}

.empty-stages__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.empty-stages__icon-wrapper {
  position: relative;
  margin-bottom: 32px;
}

.empty-stages__icon-bg {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
  background: linear-gradient(135deg, #2196f3, #1976d2);
  border-radius: 28px;
  color: white;
  box-shadow: 0 8px 24px rgba(33, 150, 243, 0.25);
}

.empty-stages__icon-circle {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, #64b5f6, #42a5f5);
  border-radius: 50%;
  opacity: 0.5;
}

.empty-stages__text {
  margin-bottom: 32px;
}

.empty-stages__title {
  font-size: 24px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 12px;
  letter-spacing: -0.5px;
}

.empty-stages__description {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
  max-width: 360px;
  margin: 0 auto;
}

.empty-stages__button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0 32px;
  height: 48px;
  border: none;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.2s ease;
  animation: pulse 2s infinite;
}

.empty-stages__button-bg {
  position: absolute;
  inset: 0;
  background: linear-gradient(135deg, #2196f3, #1976d2);
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.empty-stages__button-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
}

.empty-stages__button:hover {
  transform: translateY(-2px);
}

.empty-stages__button:hover .empty-stages__button-bg {
  transform: scale(1.1);
}

.empty-stages__button:active {
  transform: translateY(0);
}

/* Dark Theme */
.theme--dark .empty-stages {
  background: linear-gradient(135deg, rgba(26, 32, 44, 0.5), rgba(26, 32, 44, 0.9));
}

.theme--dark .empty-stages__container {
  background: #1a1a1a;
  box-shadow: 0 12px 32px rgba(0, 0, 0, 0.2);
}

.theme--dark .empty-stages__title {
  color: #fff;
}

.theme--dark .empty-stages__description {
  color: #999;
}

.theme--dark .empty-stages__icon-bg {
  background: linear-gradient(135deg, #1e88e5, #1565c0);
}

.theme--dark .empty-stages__icon-circle {
  background: linear-gradient(135deg, #42a5f5, #1e88e5);
}

.theme--dark .empty-stages__button-bg {
  background: linear-gradient(135deg, #1e88e5, #1565c0);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(33, 150, 243, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(33, 150, 243, 0);
  }
}

.theme--dark .empty-stages__button {
  animation: darkPulse 2s infinite;
}

@keyframes darkPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 136, 229, 0.4);
  }
  70% {
    box-shadow: 0 0 0 15px rgba(30, 136, 229, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(30, 136, 229, 0);
  }
}
</style>