<template>
  <WiView
    v-if="apiParams" index="talks-dashboard" class="dashboard-talks"
    apiUrl="talks/report/dashboard" :apiParams="apiParams"
    :notShowToolbar="true" :notGetID="true" @afterLoad="setContent"
  >
    <template slot-scope="{ content, refresh }">
      <div class="dashboard" :class="{ 'theme--dark': $wiDark }">
        <!-- Header -->
        <div class="dashboard__header-container">
          <CompactHeader
            title="Atendimentos"
            icon="static/systems/modules/wises-technology-module-talk.png"
            @back="$router.go(-1)"
            @refresh="refresh"
          >
            <template slot="actions">
              <DateRangePicker
                v-model="dateRange"
                @change="changeFilters"
              />
            </template>
          </CompactHeader>
          
          <!-- Cache info -->
          <div v-if="content.cached_at" class="dashboard__cache-info" :class="{ 'theme--dark': $wiDark }">
            <v-icon x-small class="dashboard__cache-icon">access_time</v-icon>
            <span class="dashboard__cache-text">
              Dados obtidos em {{ $options.filters.moment(content.cached_at, 'DD/MM/YY à\\s HH:mm:ss') }}.
              <template v-if="cacheExpiredAt >= 0">
                <span class="dashboard__cache-expires">
                  {{ cacheExpiredAt }} seg.
                </span>
              </template>
              <template v-else>
                <span 
                  class="dashboard__cache-refresh" 
                  @click="refresh"
                >
                  Atualizar
                </span>
              </template>
            </span>
          </div>
        </div>

        <!-- Cards -->
        <div class="dashboard__cards">
          <v-layout row wrap>
            <v-flex xs12 sm6 md4 lg2 class="dashboard__card-item">
              <DashboardTalksCard
                title="Entrantes"
                icon="format_list_bulleted"
                color="#3F51B5"
                :data="content.statistics ? formatNumber(content.statistics.everyone_in_queue) : '- - -'"
              ></DashboardTalksCard>
            </v-flex>
            <v-flex xs12 sm6 md4 lg2 class="dashboard__card-item">
              <DashboardTalksCard
                title="Atendimentos"
                icon="checklist"
                color="#4CAF50"
                :data="content.statistics ? formatNumber(content.statistics.attendance_rate) : '- - -'"
              ></DashboardTalksCard>
            </v-flex>
            <v-flex xs12 sm6 md4 lg2 class="dashboard__card-item">
              <DashboardTalksCard
                title="Abandonos"
                icon="playlist_remove"
                color="#F44336"
                :data="content.statistics ? formatNumber(content.statistics.abandonment_rate) : '- - -'"
              ></DashboardTalksCard>
            </v-flex>
            <v-flex xs12 sm6 md4 lg2 class="dashboard__card-item">
              <DashboardTalksCard
                title="Média Duração"
                icon="schedule"
                color="#FF9800"
                :data="content.statistics ? content.statistics.average_time : '- - -'"
                :dataSmall="true"
              ></DashboardTalksCard>
            </v-flex>
            <v-flex xs12 sm6 md4 lg2 class="dashboard__card-item">
              <DashboardTalksCard
                title="Maior Duração"
                icon="more_time"
                color="#9C27B0"
                :data="content.statistics ? content.statistics.max_time : '- - -'"
                :dataSmall="true"
              ></DashboardTalksCard>
            </v-flex>
            <v-flex xs12 sm6 md4 lg2 class="dashboard__card-item">
              <DashboardTalksCard
                title="Menor Duração"
                icon="history_toggle_off"
                color="#00BCD4"
                :data="content.statistics ? content.statistics.min_time : '- - -'"
                :dataSmall="true"
              ></DashboardTalksCard>
            </v-flex>
          </v-layout>
        </div>

        <!-- Tables -->
        <div class="dashboard__tables">
          <v-layout row wrap>
            <!-- Operators table -->
            <v-flex xs12 md6 class="dashboard__table-col">
              <CustomTable
                title="Top 10 Colaboradores"
                icon="people"
                :headers="headersOperatorsSimplified"
                :items="content.operators || []"
                @more="handleMoreOperators"
              >
                <template slot="row" slot-scope="{ item }">
                  <td class="custom-table__td">
                    <div class="dashboard__user-cell">
                      <div class="dashboard__user-avatar">
                        <img 
                          v-if="item && item.file"
                          :src="item.file.url"
                          :alt="item.name"
                        >
                        <img v-else src="static/icons/user_icon_1.png" :alt="item.name">
                      </div>
                      <span class="dashboard__user-name">{{ item.name }}</span>
                    </div>
                  </td>
                  <td class="custom-table__td custom-table__td--center">
                    <span class="dashboard__talks-count">{{ item.talks }}</span>
                  </td>
                  <td class="custom-table__td custom-table__td--center">
                    <span class="dashboard__time-value">{{ item.average_time }}</span>
                  </td>
                </template>
              </CustomTable>
            </v-flex>

            <!-- Teams table -->
            <v-flex xs12 md6 class="dashboard__table-col">
              <CustomTable
                title="Top 10 Equipes"
                icon="group_work"
                :headers="headersTeamsSimplified"
                :items="content.teams || []"
                @more="handleMoreTeams"
              >
                <template slot="row" slot-scope="{ item }">
                  <td class="custom-table__td">
                    <div class="dashboard__team-cell">
                      <div class="dashboard__team-icon">
                        <v-icon small color="primary">domain</v-icon>
                      </div>
                      <span class="dashboard__team-name">{{ item.name }}</span>
                    </div>
                  </td>
                  <td class="custom-table__td custom-table__td--center">
                    <span class="dashboard__talks-count">{{ item.talks }}</span>
                  </td>
                  <td class="custom-table__td custom-table__td--center">
                    <span class="dashboard__time-value">{{ item.average_time }}</span>
                  </td>
                </template>
              </CustomTable>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </template>
  </WiView>
</template>

<script>
import DashboardTalksCard from '@/modules/talk/pages/Dashboards/DashboardCard'
import CompactHeader from '@/modules/talk/pages/Dashboards/CompactHeader'
import DateRangePicker from '@/modules/talk/pages/Dashboards/DateRangePicker'
import CustomTable from '@/modules/talk/pages/Dashboards/CustomTable'

export default {
  name: 'dashboard-talks',
  data: function() {
    return {
      content: {},
      refresh: null,
      apiParams: null,
      dateRange: {
        start: null,
        end: null
      },
      headersOperatorsSimplified: [
        {
          text: 'Operador',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Atendimentos',
          value: 'talks',
          align: 'center'
        },
        {
          text: 'Duração média',
          value: 'average_time',
          align: 'center'
        }
      ],
      headersTeamsSimplified: [
        {
          text: 'Equipe',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Atendimentos',
          value: 'talks',
          align: 'center'
        },
        {
          text: 'Duração média',
          value: 'average_time',
          align: 'center'
        }
      ],
      cacheExpiredAt: 0,
      cacheExpiredAtInterval: null
    }
  },
  methods: {
    setContent: function ({ content, refresh }) {
      this.content = content || { statistics: {}, operators: [], teams: [] }
      this.refresh = refresh
      this.setCacheExpiredAt(content?.cache_expire_in || 0)
    },
    setInitialDateInterval: function () {
      this.dateRange.start = this.$DateTime.date() + 'T00:00:00'
      this.dateRange.end = this.$DateTime.date() + 'T23:59:59'
    },
    setApiParams: function () {
      if (this.dateRange.start && this.dateRange.end) {
        this.apiParams = `?dateStart=${this.dateRange.start}&dateEnd=${this.dateRange.end}`
        this.$forceUpdate()
      }
    },
    changeFilters: function () {
      this.setApiParams()
      setTimeout(() => {
        if (typeof this.refresh === 'function') {
          this.refresh()
        }
      }, 50);
    },
    setCacheExpiredAt: function (cacheExpiredAt) {
      this.cacheExpiredAt = cacheExpiredAt
      clearInterval(this.cacheExpiredAtInterval)
      this.cacheExpiredAtInterval = setInterval(() => {
        this.cacheExpiredAt = this.cacheExpiredAt - 1
        if (this.cacheExpiredAt < -60) {
          clearInterval(this.cacheExpiredAtInterval)
        }
      }, 1000);
    },
    handleMoreOperators: function() {
      // Função a ser implementada para exibir mais detalhes dos operadores
      console.log('Ver mais detalhes dos operadores')
    },
    handleMoreTeams: function() {
      // Função a ser implementada para exibir mais detalhes das equipes
      console.log('Ver mais detalhes das equipes')
    },
    formatNumber: function(value) {
      if (!value && value !== 0) return '- - -'
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }
  },
  created: function () {
    this.setInitialDateInterval()
    this.setApiParams()    
  },
  beforeDestroy: function() {
    clearInterval(this.cacheExpiredAtInterval)
  },
  components: {
    DashboardTalksCard,
    CompactHeader,
    DateRangePicker,
    CustomTable
  }
}
</script>

<style>
.dashboard-talks {
  background-color: #f5f7fa;
}

.dashboard {  
  min-height: 100vh;
  padding: 16px;
}

.theme--dark.dashboard {
  background-color: #121212;
}

.dashboard__header-container {
  position: relative;
}

.dashboard__cache-info {
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 10px;
}

.theme--dark .dashboard__cache-info {
  color: rgba(255, 255, 255, 0.7);
}

.dashboard__cache-icon {
  margin-right: 4px;
  opacity: 0.7;
}

.dashboard__cache-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dashboard__cache-expires {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 4px;
}

.theme--dark .dashboard__cache-expires {
  color: rgba(255, 255, 255, 0.9);
}

.dashboard__cache-refresh {
  color: #3f51b5;
  text-decoration: underline;
  cursor: pointer;
  transition: opacity 0.2s ease;
  margin-left: 4px;
}

.theme--dark .dashboard__cache-refresh {
  color: #7986cb;
}

.dashboard__cache-refresh:hover {
  opacity: 0.8;
}

.dashboard__cards {
  margin-bottom: 10px;
  
}

.dashboard__card-item {
  padding: 8px;
}

.dashboard__tables {
  margin-bottom: 24px;
}

.dashboard__table-col {
  padding: 8px;
  height: 100%;
  display: flex;
}

.dashboard__table-col > div {
  width: 100%;
}

.dashboard__user-cell {
  display: flex;
  align-items: center;
}

.dashboard__user-avatar {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.theme--dark .dashboard__user-avatar {
  background-color: #333;
}

.dashboard__user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard__user-name {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark .dashboard__user-name {
  color: rgba(255, 255, 255, 0.87);
}

.dashboard__team-cell {
  display: flex;
  align-items: center;
}

.dashboard__team-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: rgba(63, 81, 181, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.theme--dark .dashboard__team-icon {
  background-color: rgba(63, 81, 181, 0.2);
}

.dashboard__team-name {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark .dashboard__team-name {
  color: rgba(255, 255, 255, 0.87);
}

.dashboard__talks-count {
  font-weight: 600;
  color: #3f51b5;
  font-size: 13px;
}

.theme--dark .dashboard__talks-count {
  color: #7986cb;
}

.dashboard__time-value {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
}

.theme--dark .dashboard__time-value {
  color: rgba(255, 255, 255, 0.7);
}

/* Responsividade */
@media (max-width: 960px) {
  .dashboard__table-col {
    margin-bottom: 16px;
  }
  
  .dashboard__tables .layout {
    margin: 0;
  }
}

@media (max-width: 600px) {
  .dashboard {
    padding: 8px;
  }
  
  .dashboard__header-container {
    margin-bottom: 12px;
  }
  
  .dashboard__card-item {
    padding: 4px;
  }
  
  .dashboard__table-col {
    padding: 4px;
  }
  
  .dashboard__cache-info {
    font-size: 9px;
    padding-left: 8px;
  }
}
</style>