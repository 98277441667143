<template>
  <div class="talk-contact__profile-info">
    <TalkContactPhoto :contact="contact" :refresh="refresh" />
    <div style="display: flex; flex-direction: column; justify-content: space-evenly;">
      <span>
        <h3 style="font-size: 14px;">
          <span v-if="contact.person">
            {{contact.person.name}}
          </span>
          <br v-if="contact.person">
          <span style="color: gray;" v-html="contact.name"></span>
          <v-tooltip v-if="contact.checked == 3" bottom lazy>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on" color="red" small
              >do_not_disturb_on</v-icon>
            </template>
            <span>
              Contato inválido
            </span>
          </v-tooltip>
          <v-tooltip v-if="contact.checked == 2" bottom lazy>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on" color="green" small
              >verified</v-icon>
            </template>
            <span>
              Contato validado
            </span>
          </v-tooltip>
          <v-tooltip v-if="contact.checked == 1 || !contact.checked" bottom lazy>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on" color="grey" small
              >help</v-icon>
            </template>
            <span>
              Contato não validado
            </span>
          </v-tooltip>
          <v-tooltip v-if="contact.type == 2" bottom lazy>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
                style="cursor: pointer;"
                @click="refreshContact()"
              >refresh</v-icon>
            </template>
            <span>
              Atualizar dados do contato
            </span>
          </v-tooltip>
        </h3>
      </span>
      <h3 v-if="contact.type == 1" style="display: flex; align-items: center; justify-content: center; font-size: 14px;">
        <span v-if="contact.number.toString().length == 12">
          <strong>
            {{ contact.number | mask('+## (##) ####-####') }}
          </strong>
        </span>
        <span v-else-if="contact.number.toString().length == 13">
          <strong>
            {{ contact.number | mask('+## (##) # ####-####') }}
          </strong>
        </span>
        <span v-else>
          {{ contact.number }}
        </span>
        <wi-copy-to
          title="Número"
          color="primary"
          :text="contact.number"
        ></wi-copy-to>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              small
              v-on="on"
              style="cursor: pointer;"
              @click="refreshContact()"
            >refresh</v-icon>
          </template>
          <span>
            Atualizar dados do contato
          </span>
        </v-tooltip>
      </h3>
    </div>
  </div>    
</template>
<script>
  import TalkContactPhoto from './TalkContactPhoto'
  export default {
    name: 'TalkContactName',
    methods: {
      refreshContact: function () {
        this.$WiApiPost({
          uri: 'talks/contact-refresh',
          data: {
            talk_contact_id: this.contact.id
          },
          config: {
            msgLoad: true,
            msgSucess: true,
            msgError: true,
            msgAsk: {
              title: `Atualizar contato?`
            }
          },
          callback: () => {
            this.refresh(this.$route.params.id)
          }
        })
      }
    },
    props: ['contact', 'refresh'],
    components: {
      TalkContactPhoto
    }
  }
</script>
<style>
  .talk-contact__profile-info {
    display: flex;
    gap: 10px;
    justify-content: left;
    margin: 0;
    padding: 10px 10px 4px 10px;
    text-align: left;
    /* border-bottom: 1px solid #e0e0e0; */
  }
</style>