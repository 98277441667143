<template>
  <div 
    class="wn-tag"
    :style="{ 
      backgroundColor: color + ($wiDark ? '40' : '30'),
      color: color,
      filter: $wiDark ? 'brightness(1)' : 'brightness(0.7)'
    }"
    v-on="$listeners"
  >
    <v-icon v-if="icon" size="14" :color="color">{{ icon }}</v-icon>
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'WNTag',
  

  props: {
    /**
     * Texto a ser exibido na tag
     */
    text: {
      type: String,
      required: true
    },
    
    /**
     * Ícone a ser exibido (nome do ícone do Material Design)
     */
    icon: {
      type: String,
      default: null
    },
    
    /**
     * Cor principal da tag (código hexadecimal)
     */
    color: {
      type: String,
      default: '#1976D2'
    }
  }
}
</script>

<style scoped>
.wn-tag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px !important;
}
</style> 