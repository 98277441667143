<template>
  <div>
    <view-detail
      title="Cadastro" :apiUrl="apiUrl" :store="store"
      :apiParams="'?with=tags;phones;contacts;adresses;infos;users;users.profile;files;talk_contacts;talk_contacts.file'"
    >
      <template slot="toolbarItensBefore" slot-scope="{ content, refresh, loading }">
        <v-btn v-prms="{'id': '93dd92fc'}" class="hidden-xs-only hidden-sm-only" small outline color="white" @click="profilesFunctions({ content, refresh, loading })" :disabled="loading">
          <v-icon>recent_actors</v-icon>
          <span class="ml-2">Funções/Papeis</span>
        </v-btn>
        <v-btn v-if="portal && $route.name == 'customer' && content && content.access_area && content.email && content.uuid" class="hidden-xs-only hidden-sm-only" :loading="portalLoading" small outline color="white" :disabled="loading" @click="accessPortal(content)">
          <v-icon>launch</v-icon>
          <span>
            <span>Acessar </span> 
            <span v-if="$WiCheckPermission('85352c3c-1321-4cba-b009-d5c4dc909159')"> área do paciente </span>
            <span v-else-if="$WiCheckPermission('0bae9ce3-400f-8818-94a0-15d1b3bdf088')"> área do formando </span>
            <span v-else> área do cliente </span>
          </span>
        </v-btn>
      </template>
      <template slot-scope="{ content, refresh }">
        <div style="height: calc(100vh - 96px); overflow-y: auto;">
          <v-container>
            <v-layout row>
              <v-flex xs12>
                <v-card class="elevation-17" style="border-radius: 10px; margin-bottom: 20px;">
                  <div class="tags-wrapper">
                    <div class="tags-label">
                      <i class="material-icons">sell</i>
                      <span>Tags</span>
                    </div>
                    
                    <div class="tags-list" :class="{'tags-list--empty': !content.tags || !content.tags.length}">
                      <template v-if="content.tags && content.tags.length">
                        <v-chip
                          v-for="tag in content.tags"
                          :key="tag.id"
                          :color="tag.color"
                          small
                          close
                          label
                          style="border-radius: 10px;"
                          class="tag-item"
                          @input="removeTag(tag, refresh)"
                        >
                          <span :style="{ color: getTextColoe(tag.color) }">
                            {{ tag.name }}
                          </span>
                        </v-chip>
                      </template>
                      <span v-else class="tags-empty">Sem tags</span>
                    </div>

                    <div class="tags-action">
                      <v-btn
                        small
                        icon
                        @click="addTag(content, refresh)"
                      >
                        <v-icon>add_circle</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs12>
                <v-card class="elevation-17" style="border-radius: 10px;">
                  <PersonInfo
                    :person="content"
                    :refresh="refresh"
                    :update-profile-image="true"
                    @onUpdateInfo="onUpdateInfo"
                  ></PersonInfo>
                </v-card>
              </v-flex>
            </v-layout>
            <PersonGroups
              v-if="content && content.groups"
              :person="content"
            ></PersonGroups>
            <PersonStatistics
              v-if="content && content.statistics"
              :person="content"
            ></PersonStatistics>
            <PersonTabs
              :person="content"
              :refresh="refresh"
            ></PersonTabs>
          </v-container>
        </div>
      </template>
    </view-detail>
  </div>
</template>

<script>
  import { callApi } from '@/default/service/Api'
  import PersonInfo from '@/modules/register/pages/Person/PersonInfo'
  import PersonGroups from '@/modules/register/pages/Person/PersonGroups'
  import PersonStatistics from './PersonStatistics/index'
  import PersonTabs from './PersonTabs/index'
  export default {
    name: 'Person',
    data () {
      return {
        apiUrl: '',
        apiParams: '?with=contacts;adresses;infos;users',
        store: null,
        tabAtiva: 'tab-1',
        portalLoading: false
      }
    },
    created: function () {
      this.loadPessoa()
    },
    watch: {
      '$route': 'loadPessoa'
    },
    computed: {
      user () {
        return this.$store.state.User || {}
      },
      wiConfig () {
        return this.$store.state.pages[this.$route.name] || {}
      },
      portal () {
        return this.$Auth.account().portal
      }
    },
    methods: {
      loadPessoa: function () {
        this.apiUrl = 'register/person/' + this.$route.name
        this.store = this.$route.name
      },
      accessPortal: function (person) {
        this.portalLoading = true
        callApi.post({
          uri: 'portal-login-super/' + person.uuid,
          data: {
            email: person.email
          },
          sucess: response => {
            if (response?.data?.status === 'success' && response?.data?.token) {
              window.open(this.portal + '/#/dd18fa5b-3818-4177-a2cf-151b90cd58c8/' + response.data.token, '_blank')
            } else {
              this.$swal({
                type: 'warning',
                title: 'Erro ao buscar credenciais.',
                html: 'Tente novamente ou contacte o suporte.'
              })
            }
            this.portalLoading = false
          },
          error: error => {
            console.log(error)
            this.portalLoading = false
          }
        })
      },
      getTextColoe: function (corHexadecimal) {
          // Converte a cor hexadecimal para RGB
          const r = parseInt(corHexadecimal.slice(1, 3), 16);
          const g = parseInt(corHexadecimal.slice(3, 5), 16);
          const b = parseInt(corHexadecimal.slice(5, 7), 16);

          // Calcula o valor de luminosidade usando a fórmula YIQ
          const luminosidade = (r * 299 + g * 587 + b * 114) / 1000;

          // Define um valor de limiar para determinar a cor do texto
          const limiar = 128;

          // Verifica se a luminosidade é maior que o limiar
          if (luminosidade > limiar) {
              // Se a cor de fundo for clara, retorna cor escura
              return "#000000"; // Cor preta
          } else {
              // Se a cor de fundo for escura, retorna cor clara
              return "#FFFFFF"; // Cor branca
          }
      },
      addTag: function (person, refresh) {
        if (person?.id) {
          this.$WiListDialog({
            wiConfig: 'tag',
            callback: (tags) => {
              this.$WiApiPost({
                uri: 'register/person/tag',
                data: {
                  multiple: Array.isArray(tags) ? tags.map(tag => ({
                    person_id: person.id,
                    tag_id: tag.id
                  })) : [{
                    person_id: person.id,
                    tag_id: tags.id
                  }]
                },
                config: {
                  msgLoad: {
                    title: `Adicionando tag(s)`,
                    text: `Aguarde enquanto as tag(s) são adicionadas.`
                  },
                  msgSucess: {
                    title: 'Tag(s) adicionadas com sucesso!',
                    text: 'As tag(s) foram adicionadas com sucesso.'
                  },
                  msgError: {
                    title: 'Erro ao adicionar tag(s)',
                    text: 'Tente novamente ou contacte o suporte.'
                  }
                },
                callback: () => {
                  if (refresh) {
                    refresh()
                  }
                }
              })
            },
            mode: 'select'
          })
        } else {
          this.setPersonProfiledialog = true
        }
      },
      removeTag: function (tag, refresh) {
        this.$WiApiDelete({
          uri: `register/person/tag`,
          id: tag.person_tag_id,
          config: {
            msgLoad: {
              title: `Removendo tag`,
              text: `Aguarde enquanto a tag é removida.`
            },
            msgSucess: {
              title: 'Tag removida com sucesso!',
              text: 'A tag foi removida com sucesso.'
            },
            msgError: {
              title: 'Erro ao remover tag',
              text: 'Tente novamente ou contacte o suporte.'
            },
            msgAsk: false
          },
          callback: () => {
            if (refresh) {
              refresh()
            }
          }
        })
      },
      profilesFunctions: function ({ content, refresh }) {
        const profilesEditorData = {
          id: content.id,
          type: content.type,
          customer: 'no',
          provider: 'no',
          collaborator: 'no',
          prospect: 'no',
          company: 'no'
        }
        content.profiles.forEach(profile => {
          profilesEditorData[profile.name] = profile.status
        })
        this.$WiEditDialog({
          wiConfig: this.store + '-function', 
          onSubmit: () => {
            if (refresh) {
              refresh()
            }
          }, 
          data: profilesEditorData
        })
      },
      onUpdateInfo: function ({ info, refresh }) {
        if (Object.keys(info).length > 0) {
          this.$WiApiPut({
            uri: this.apiUrl,
            data: info,
            id: this.$route.params.id,
            config: {
              msgLoad: {
                title: 'Atualizando informações',
                text: 'Aguarde enquanto as informações são atualizadas.'
              },
              msgSucess: {
                title: 'Informações atualizadas com sucesso!',
                text: 'As informações foram atualizadas com sucesso.'
              },
              msgError: {
                title: 'Erro ao atualizar informações',
                text: 'Tente novamente ou contacte o suporte.'
              }
            },
            callback: () => {
              if (refresh) {
                refresh()
              }
            }
          })
        }
      }
    },
    components: {
      PersonTabs,
      PersonInfo,
      PersonGroups,
      PersonStatistics
    }
  }
</script>

<style>
.tags-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-height: 48px;
}

.theme--dark .tags-wrapper {
  background: #1E1E1E;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tags-label {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #757575;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  padding-right: 12px;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.theme--dark .tags-label {
  color: #9e9e9e;
  border-right-color: rgba(255, 255, 255, 0.08);
}

.tags-label i {
  font-size: 16px;
  color: #1976d2;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  flex: 1;
  min-height: 32px;
  align-items: center;
}

.tags-list--empty {
  min-height: 0;
}

.tag-item {
  height: 24px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0.2px !important;
  transition: all 0.2s ease !important;
}

.tag-item:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.tag-item .v-chip__close {
  font-size: 16px !important;
  margin-left: 4px !important;
  opacity: 0.7;
}

.tag-item .v-chip__close:hover {
  opacity: 1;
}

.tags-empty {
  color: #9e9e9e;
  font-size: 12px;
  font-style: italic;
}

.theme--dark .tags-empty {
  color: #757575;
}

.tags-action {
  margin-left: auto;
  padding-left: 12px;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
}

.theme--dark .tags-action {
  border-left-color: rgba(255, 255, 255, 0.08);
}

.add-tag-btn {
  min-width: 32px !important;
  width: 32px;
  height: 32px !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.add-tag-btn i {
  font-size: 20px;
}

.add-tag-btn:hover {
  background: rgba(25, 118, 210, 0.04) !important;
}

.theme--dark .add-tag-btn:hover {
  background: rgba(255, 255, 255, 0.05) !important;
}

@media (max-width: 600px) {
  .tags-wrapper {
    padding: 6px 12px;
    min-height: 40px;
  }

  .tags-label {
    font-size: 12px;
  }

  .tags-label i {
    font-size: 14px;
  }

  .tag-item {
    height: 22px !important;
    font-size: 11px !important;
  }

  .add-tag-btn {
    min-width: 28px !important;
    width: 28px;
    height: 28px !important;
  }

  .add-tag-btn i {
    font-size: 18px;
  }

  .tags-action {
    padding-left: 8px;
  }
}
</style>