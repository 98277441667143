<template>
  <div class="business-card-dates">
    <!-- Data de Entrega -->
    <template v-if="shouldShowDates && item.date_end_format">
      <v-card-title
        v-if="isExpired"
        class="business-card-dates__item business-card-dates__item--expired"
      >
        <v-icon class="business-card-dates__icon">date_range</v-icon>
        <label class="business-card-dates__label">
          <strong v-text="config.date_end || 'Entrega'"></strong>: {{item.date_end_format}}
        </label>
        <v-spacer></v-spacer>
        <strong class="business-card-dates__badge business-card-dates__badge--expired">
          A {{Math.abs(item.date_end_days)}} dia(s)
        </strong>
      </v-card-title>

      <v-card-title
        v-else-if="isWarning"
        class="business-card-dates__item business-card-dates__item--warning"
      >
        <v-icon class="business-card-dates__icon">date_range</v-icon>
        <label class="business-card-dates__label">
          <strong v-text="config.date_end || 'Entrega'"></strong>: {{item.date_end_format}}
        </label>
        <v-spacer></v-spacer>
        <strong class="business-card-dates__badge business-card-dates__badge--warning">
          <span v-if="item.date_end_days">Em {{item.date_end_days}} dia(s)</span>
          <span v-else>Hoje</span>
        </strong>
      </v-card-title>

      <v-card-title
        v-else
        class="business-card-dates__item"
      >
        <v-icon class="business-card-dates__icon">date_range</v-icon>
        <label class="business-card-dates__label">
          <strong v-text="config.date_end || 'Entrega'"></strong>: {{item.date_end_format}}
        </label>
        <v-spacer></v-spacer>
        <strong
          v-if="item.stage == 1"
          class="business-card-dates__badge"
        >
          Em {{item.date_end_days}} dia(s)
        </strong>
      </v-card-title>
    </template>

    <!-- Data de Validade -->
    <template v-if="shouldShowDates && showValidityDate">
      <v-card-title
        v-if="isValidityExpired"
        class="business-card-dates__item business-card-dates__item--expired"
      >
        <v-icon class="business-card-dates__icon">date_range</v-icon>
        <label class="business-card-dates__label"><strong>Validade</strong>: {{item.date_validity_format}}</label>
        <v-spacer></v-spacer>
        <strong class="business-card-dates__badge business-card-dates__badge--expired">
          A {{Math.abs(item.date_validity_days)}} dia(s)
        </strong>
      </v-card-title>

      <v-card-title
        v-else-if="isValidityWarning"
        class="business-card-dates__item business-card-dates__item--warning"
      >
        <v-icon class="business-card-dates__icon">date_range</v-icon>
        <label class="business-card-dates__label"><strong>Validade</strong>: {{item.date_validity_format}}</label>
        <v-spacer></v-spacer>
        <strong class="business-card-dates__badge business-card-dates__badge--warning">
          <span v-if="item.date_validity_days">
            Em {{item.date_validity_days}} dia(s)
          </span>
          <span v-else>Hoje</span>
        </strong>
      </v-card-title>

      <v-card-title
        v-else-if="item.date_validity_format"
        class="business-card-dates__item"
      >
        <v-icon class="business-card-dates__icon">date_range</v-icon>
        <label class="business-card-dates__label"><strong>Validade</strong>: {{item.date_validity_format}}</label>
        <v-spacer></v-spacer>
        <strong 
          v-if="item.stage == 1" 
          class="business-card-dates__badge"
        >
          Em {{item.date_validity_days}} dia(s)
        </strong>
      </v-card-title>
    </template>
  </div>
</template>

<script>
import { BUSINESS_TYPES } from '../config/businessConfig'
import url from '@/default/store/modules/url/url'

export default {
  name: 'BusinessCardDates',
  
  props: {
    item: { type: Object, required: true },
    config: { type: Object, required: true }
  },

  computed: {
    shouldShowDates() {
      if (this.config.type === BUSINESS_TYPES.BUDGET && 
          url.state.system === 'wise-message') {
        return this.item.advanced_view
      }
      return true
    },

    showValidityDate() {
      return this.config.type === BUSINESS_TYPES.BUDGET || 
             this.config.type === BUSINESS_TYPES.QUOTATION
    },

    isExpired() {
      return this.item.stage == 1 && this.item.date_end_format && this.item.date_end_days < 0
    },

    isWarning() {
      return this.item.stage == 1 && this.item.date_end_format && this.item.date_end_days < 2
    },

    isValidityExpired() {
      return this.item.stage == 1 && this.item.date_validity_format && this.item.date_validity_days < 0
    },

    isValidityWarning() {
      return this.item.stage == 1 && this.item.date_validity_format && this.item.date_validity_days < 2
    }
  }
}
</script>

<style scoped>
.business-card-dates__divider {
  margin: 0;
  opacity: 0.5;
}

.business-card-dates__item {
  padding: 4px 8px;
  min-height: 32px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  position: relative;
}

.theme--light .business-card-dates__item--expired {
  background-color: #fff5f5;
}

.theme--dark .business-card-dates__item--expired {
  background-color: #3d1f1f;
}

.theme--light .business-card-dates__item--warning {
  background-color: #fff9e6;
}

.theme--dark .business-card-dates__item--warning {
  background-color: #3d331f;
}

.business-card-dates__icon {
  margin-right: 6px;
  font-size: 18px;
}

.theme--light .business-card-dates__icon {
  color: #495057;
}

.theme--dark .business-card-dates__icon {
  color: #e9ecef;
}

.theme--light .business-card-dates__label {
  color: #212529;
}

.theme--dark .business-card-dates__label {
  color: #ffffff;
}

.business-card-dates__label {
  font-size: 0.85em;
  font-weight: 500;
}

.business-card-dates__badge {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.theme--light .business-card-dates__badge {
  background-color: #e9ecef;
  color: #495057;
  border: 1px solid #dee2e6;
}

.theme--dark .business-card-dates__badge {
  background-color: #2d2d2d;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.08);
}

.business-card-dates__badge--expired {
  background-color: #ff6b6b;
  color: #ffffff;
  border: none;
  box-shadow: 0 1px 3px rgba(255, 107, 107, 0.2);
}

.business-card-dates__badge--expired::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  border-radius: 4px;
  z-index: -1;
}

.business-card-dates__badge--warning {
  background-color: #ffd93d;
  color: #212529;
  border: none;
  box-shadow: 0 1px 3px rgba(255, 217, 61, 0.2);
}

.theme--dark .business-card-dates__badge--warning {
  color: #ffffff;
}

.business-card-dates__badge--warning::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  border-radius: 4px;
  z-index: -1;
}
</style> 