import User from '@/default/config/User/User'
import UserPassword from '@/default/config/User/UserPassword'
import UserProfile from '@/default/config/User/UserProfile'
import NotificationPerson from '@/default/config/Notification/NotificationPerson'
import { File as FileConfig } from '@/default/config/Common/File'
import { Log } from '@/default/config/Common/Log'
import { LayoutVariable } from '@/modules/wells/config/Config/LayoutVariable'
import { ChatGroup } from '@/default/config/Chat/ChatGroup'
import { ChatMembers } from '@/default/config/Chat/ChatMembers'
import { ChatTopic } from '@/default/config/Chat/ChatTopic'
import { ChatTopicGroup } from '@/default/config/Chat/ChatTopic'

export default {
  'log': new Log(),
  'file': new FileConfig(),
  'user': User,
  'user-password': UserPassword,
  'user-profile': UserProfile,
  'notifications': NotificationPerson,
  'business-layout-variables': new LayoutVariable(),
  'chat-group': new ChatGroup(),
  'chat-members': new ChatMembers(),
  'chat-topic': new ChatTopic(),
  'chat-topic-group': new ChatTopicGroup()
}
