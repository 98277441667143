<template>
  <div class="talk-campaign">
    <!-- Loading State -->
    <div class="talk-campaign__loading-overlay" v-if="loading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
      <p>Carregando dados da campanha...</p>
    </div>

    <template v-else>
      <!-- Cabeçalho e Progresso -->
      <div class="talk-campaign__top-section">
        <!-- Cabeçalho -->
        <div class="talk-campaign__header">
          <div class="talk-campaign__header-content">
            <v-btn icon small class="talk-campaign__back-btn" @click="$router.go(-1)">
              <v-icon>arrow_back</v-icon>
            </v-btn>
            <h1 class="talk-campaign__title">
              {{ content ? `#${content.id} - ${content.name}` : 'Carregando...' }}
            </h1>
            <div class="talk-campaign__status" v-if="content && content.status">
              <v-icon small :color="content.status.color">{{ content.status.icon }}</v-icon>
              <span>{{ content.status.name }}</span>
            </div>
          </div>
          <div v-if="content" class="talk-campaign__actions">
            <v-tooltip v-if="content.is_executable" bottom lazy>
              <template v-slot:activator="{ on }">
                <v-btn v-prms="{id: '74e8cb98', mode: 'disabled'}" v-on="on" @click="execute(content)" icon small color="green">
                  <v-icon>play_arrow</v-icon>
                </v-btn>
              </template>
              <span>Executar campanha</span>
            </v-tooltip>
            <v-tooltip v-if="content.is_pausable" bottom lazy>
              <template v-slot:activator="{ on }">
                <v-btn v-prms="{id: '4caaddce', mode: 'disabled'}" v-on="on" @click="pause(content)" icon small color="orange">
                  <v-icon>pause</v-icon>
                </v-btn>
              </template>
              <span>Pausar campanha</span>
            </v-tooltip>
            <v-tooltip bottom lazy>
              <template v-slot:activator="{ on }">
                <v-btn v-prms="{id: '74e8cb98', mode: 'disabled'}" v-on="on" icon @click="calculateStatistics(content)" small class="talk-campaign__reload-btn">
                  <v-icon>published_with_changes</v-icon>
                </v-btn>
              </template>
              <span>
                Atualizar estatísticas desta campanha
              </span>
            </v-tooltip>
            <v-btn 
              icon
              small
              class="talk-campaign__reload-btn" 
              @click="getTalkCampaign"
              :loading="loading"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- Progresso da campanha -->
        <div v-if="content" class="talk-campaign__progress-section">
          <div class="talk-campaign__progress-container">
            <div class="talk-campaign__progress-header">
              <h2 class="talk-campaign__section-title">Progresso</h2>
              <div class="talk-campaign__progress-info">
                <span class="talk-campaign__progress-percentage">{{ content.progress }}%</span>
                <span class="talk-campaign__progress-detail">{{ content.total_sended }} de {{ content.total_contacts }}</span>
              </div>
            </div>
            <div class="talk-campaign__progress-bar">
              <div 
                class="talk-campaign__progress-fill" 
                :style="{ width: content && typeof content.progress === 'number' ? `${content.progress}%` : '0%' }"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Mensagem de erro -->
      <div class="talk-campaign__error" v-if="apiError">
        <v-alert type="error" :value="true" outline>
          {{ apiError }}
          <div class="talk-campaign__error-actions">
            <v-btn color="error" small @click="getTalkCampaign">Tentar novamente</v-btn>
          </div>
        </v-alert>
      </div>

      <!-- Conteúdo principal -->
      <div class="talk-campaign__content" v-if="content">
        <!-- Cards de métricas principais -->
        <div class="talk-campaign__metrics">
          <div class="talk-campaign__metric-card">
            <div class="talk-campaign__metric-icon">
              <v-icon color="blue">people_outline</v-icon>
            </div>
            <div class="talk-campaign__metric-content">
              <h3 class="talk-campaign__metric-title">Total de contatos</h3>
              <p class="talk-campaign__metric-value">{{ content.total_contacts }}</p>
            </div>
          </div>

          <div class="talk-campaign__metric-card">
            <div class="talk-campaign__metric-icon">
              <v-icon color="green">check_circle</v-icon>
            </div>
            <div class="talk-campaign__metric-content">
              <h3 class="talk-campaign__metric-title">Contatos processados</h3>
              <p class="talk-campaign__metric-value">
                {{ content.total_sended }}
                <span class="talk-campaign__metric-percentage">({{ content.total_sended_percentage.toFixed(1) }}%)</span>
              </p>
            </div>
          </div>

          <div class="talk-campaign__metric-card">
            <div class="talk-campaign__metric-icon">
              <v-icon color="orange">hourglass_empty</v-icon>
            </div>
            <div class="talk-campaign__metric-content">
              <h3 class="talk-campaign__metric-title">Contatos pendentes</h3>
              <p class="talk-campaign__metric-value">
                {{ content.total_pending }}
                <span class="talk-campaign__metric-percentage">({{ content.total_pending_percentage.toFixed(1) }}%)</span>
              </p>
            </div>
          </div>

          <div class="talk-campaign__metric-card">
            <div class="talk-campaign__metric-icon">
              <v-icon color="grey">phone_disabled</v-icon>
            </div>
            <div class="talk-campaign__metric-content">
              <h3 class="talk-campaign__metric-title">Não é WhatsApp</h3>
              <p class="talk-campaign__metric-value">
                {{ content.messages_error_contact }}
                <span class="talk-campaign__metric-percentage">({{ content.messages_error_contact_percentage.toFixed(1) }}%)</span>
              </p>
            </div>
          </div>

          <div class="talk-campaign__metric-card">
            <div class="talk-campaign__metric-icon">
              <v-icon color="teal">visibility</v-icon>
            </div>
            <div class="talk-campaign__metric-content">
              <h3 class="talk-campaign__metric-title">Mensagens lidas</h3>
              <p class="talk-campaign__metric-value">
                {{ content.messages_seened }}
                <span class="talk-campaign__metric-percentage">({{ content.messages_seened_percentage.toFixed(1) }}%)</span>
              </p>
            </div>
          </div>

          <div class="talk-campaign__metric-card">
            <div class="talk-campaign__metric-icon">
              <v-icon color="purple">question_answer</v-icon>
            </div>
            <div class="talk-campaign__metric-content">
              <h3 class="talk-campaign__metric-title">Mensagens recebidas</h3>
              <p class="talk-campaign__metric-value">
                {{ content.messages_received }}
                <span class="talk-campaign__metric-percentage">({{ content.messages_received_percentage.toFixed(1) }}%)</span>
              </p>
            </div>
          </div>

          <div class="talk-campaign__metric-card">
            <div class="talk-campaign__metric-icon">
              <v-icon color="blue-grey">trending_up</v-icon>
            </div>
            <div class="talk-campaign__metric-content">
              <h3 class="talk-campaign__metric-title">Engajamento</h3>
              <p class="talk-campaign__metric-value">{{ content.engagement_percentage.toFixed(1) }}%</p>
            </div>
          </div>

          <div class="talk-campaign__metric-card">
            <div class="talk-campaign__metric-icon">
              <v-icon color="red">error_outline</v-icon>
            </div>
            <div class="talk-campaign__metric-content">
              <h3 class="talk-campaign__metric-title">Não enviados</h3>
              <p class="talk-campaign__metric-value">
                {{ content.messages_other_error }}
                <span class="talk-campaign__metric-percentage">({{ content.messages_other_error_percentage.toFixed(1) }}%)</span>
              </p>
            </div>
          </div>
        </div>

        <!-- Estatísticas e Mensagens -->
        <div class="talk-campaign__stats-container">
          <!-- Estatísticas por Canal -->
          <div class="talk-campaign__stats-card">
            <div class="talk-campaign__stats-header">
              <h2 class="talk-campaign__section-title">
                <v-icon class="talk-campaign__section-icon">phone_iphone</v-icon>
                Canais da Campanha
              </h2>
              <div class="talk-campaign__stats-actions">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" class="talk-campaign__action-btn" @click="addChannels()">
                      <v-icon>mobile_friendly</v-icon>
                    </v-btn>
                  </template>
                  <span>Adicionar canais</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" class="talk-campaign__action-btn" @click="listChannels()">
                      <v-icon>phonelink_setup</v-icon>
                    </v-btn>
                  </template>
                  <span>Gerenciar canais</span>
                </v-tooltip>
              </div>
            </div>
            <div class="talk-campaign__channels-list" v-if="content.channels_statistics && content.channels_statistics.length > 0">
              <talk-campaign-channel
                v-for="(channel, index) in content.channels_statistics"
                :key="index"
                :channel="channel"
              />
            </div>
            <div v-else class="talk-campaign__empty-state">
              <v-icon class="talk-campaign__empty-icon">device_hub</v-icon>
              <p class="talk-campaign__empty-text">Nenhum canal configurado para esta campanha ainda</p>
            </div>
          </div>

          <!-- Últimos Envios -->
          <div class="talk-campaign__stats-card">
            <div class="talk-campaign__stats-header">
              <h2 class="talk-campaign__section-title">
                <v-icon class="talk-campaign__section-icon">history</v-icon>
                Últimas mensagens enviadas
              </h2>
              <div class="talk-campaign__stats-actions">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" class="talk-campaign__action-btn" :to="{
                      name: 'talk-list',
                      params: { page: 'talk' },
                      query: { where: `talks.talk_campaign_id,${content.id}` }
                    }">
                      <v-icon>checklist</v-icon>
                    </v-btn>
                  </template>
                  <span>Atualizar histórico</span>
                </v-tooltip>
              </div>
            </div>
            <div class="talk-campaign__messages-list" v-if="content.last_talks && content.last_talks.length > 0">
              <talk-campaign-talk
                v-for="(talk, index) in content.last_talks"
                :key="index"
                :talk="talk"
              />
            </div>
            <div v-else class="talk-campaign__empty-state">
              <v-icon class="talk-campaign__empty-icon">chat_bubble_outline</v-icon>
              <p class="talk-campaign__empty-text">Nenhuma mensagem enviada até o momento</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {callApi} from '@/default/service/Api'
import TalkCampaignChannel from './components/TalkCampaignChannel'
import TalkCampaignTalk from './components/TalkCampaignTalk'

export default {
  name: 'TalkCampaign',
  components: {
    TalkCampaignChannel,
    TalkCampaignTalk
  },
  data() {
    return {
      content: null,
      loading: false,
      apiError: null,
      debugInfo: null,
      isDebugMode: true,
      showDebugInfo: false
    }
  },
  computed: {
    contentDebugInfo() {
      if (!this.content) return {}
      const info = {}
      for (const key in this.content) {
        if (typeof this.content[key] !== 'object' || this.content[key] === null) {
          info[key] = this.content[key]
        } else if (Array.isArray(this.content[key])) {
          info[key] = `Array com ${this.content[key].length} item(s)`
        } else {
          info[key] = 'Objeto'
        }
      }
      return info
    }
  },
  methods: {
    getTalkCampaign() {
      this.loading = true
      this.apiError = null
      this.debugInfo = null
      this.content = null

      const campaignId = this.$route.params.id
      if (!campaignId) {
        this.apiError = 'ID da campanha não encontrado na URL'
        this.loading = false
        return
      }

      callApi.get({
        uri: `talks/campaign/${campaignId}/detail`,
        params: '',
        sucess: ({ data: { data: response } }) => {
          this.content = response
          this.loading = false
        },
        error: (err) => {
          console.error('Erro na chamada da API:', err)
          this.loading = false
          this.apiError = 'Não foi possível conectar ao servidor. Verifique sua conexão e tente novamente.'
        }
      })
    },
    formatDate(dateString) {
      if (!dateString) return ''
      const date = new Date(dateString)
      return date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })
    },
    formatPhoneNumber(number) {
      if (!number) return ''
      const numberStr = String(number)
      if (numberStr.length < 10) return numberStr
      const country = numberStr.substring(0, 2)
      const ddd = numberStr.substring(2, 4)
      const part1 = numberStr.substring(4, 9)
      const part2 = numberStr.substring(9)
      return `+${country} (${ddd}) ${part1}-${part2}`
    },
    toggleDebugInfo() {
      this.showDebugInfo = !this.showDebugInfo
    },
    calculateStatistics: function (campaign) {
      this.$WiApiPost({
        uri: `talks/campaign/${campaign.id}/calculate-statistics`,
        data: {},
        config: {
          msgLoad: {
            title: `Calculando estatísticas da campanha...`,
            text: 'Aguarde um momento por favor.'
          },
          msgSucess: {
            title: 'Estatísticas calculadas com sucesso',
            text: 'As estatísticas foram calculadas com sucesso.'
          },
          msgError: {
            title: 'Erro ao calcular estatísticas',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Calcular as estatísticas da campanha?`,
            text: 'As estatísticas serão calculadas e atualizadas.'
          }
        },
        callback: () => {
          this.getTalkCampaign()
        }
      })
    },
    listChannels: function () {
      this.$WiListDialog({
        wiConfig: 'talk-campaign-channel',
        filter: { talk_campaign_id: this.content.id },
        wiEditData: { talk_campaign_id: this.content.id },
        callback: () => {
          this.getTalkCampaign()
        }
      })
    },
    addChannels: function () {
      this.$WiListDialog({
        wiConfig: 'talk-channel-select',
        mode: 'select',
        callback: selected => {
          let channels = selected
          if (!Array.isArray(selected)) {
            channels = [selected]
          }
          if (selected && channels.length) {
            this.$WiApiPost({
              uri: `talks/campaign-channels`,
              data: {
                talk_campaign_id: this.content.id,
                channels: channels.map(channel => channel.id)
              },
              config: {
                msgLoad: {
                  title: `Adicionando canais à campanha "${this.content.name}"...`,
                  text: 'Aguarde enquanto os canais são adicionados.'
                },
                msgSucess: {
                  title: 'Canais adicionados com sucesso',
                  text: 'Os canais foram adicionados com sucesso.'
                },
                msgError: {
                  title: 'Erro ao adicionar canais',
                  text: 'Tente novamente ou contacte o suporte.'
                }
              },
              callback: () => {
                this.getTalkCampaign()
              }
            })
          }
        }
      })
    },
    execute: function (campaign) {
      this.$WiApiPost({
        uri: `talks/campaign/${campaign.id}/execute`,
        data: {},
        config: {
          msgLoad: {
            title: `Inicializando a campanha "${campaign.name}"...`,
            text: 'Aguarde enquanto a campanha é inicializada.'
          },
          msgSucess: {
            title: 'Campanha inicializada com sucesso',
            text: 'O comando de inicialização foi enviado com sucesso.'
          },
          msgError: {
            title: 'Erro ao inicializar a campanha',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja inicializar a campanha?`,
            text: 'Um comando de inicialização será enviado para a campanha.'
          }
        },
        callback: () => {
          this.getTalkCampaign()
        }
      })
    },
    pause: function (campaign) {
      this.$WiApiPost({
        uri: `talks/campaign/${campaign.id}/pause`,
        data: {},
        config: {
          msgLoad: {
            title: `Pausando a campanha...`,
            text: 'Aguarde enquanto a campanha é pausada.'
          },
          msgSucess: {
            title: 'Campanha pausada com sucesso',
            text: 'O comando de pausa foi enviado com sucesso.'
          },
          msgError: {
            title: 'Erro ao pausar a campanha',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja pausar a campanha?`,
            text: 'Um comando de pausa será enviado para a campanha.'
          }
        },
        callback: () => {
          this.getTalkCampaign()
        }
      })
    },
    stop: function (campaign) {
      this.$WiApiPost({
        uri: `talks/campaign/${campaign.id}/stop`,
        data: {},
        config: {
          msgLoad: {
            title: `Encerrando a campanha...`,
            text: 'Aguarde enquanto a campanha é encerrada.'
          },
          msgSucess: {
            title: 'Campanha encerrada com sucesso',
            text: 'O comando de encerramento foi enviado com sucesso.'
          },
          msgError: {
            title: 'Erro ao encerrar a campanha',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja encerrar a campanha?`,
            text: 'Um comando de encerramento será enviado para a campanha.'
          }
        },
        callback: () => {
          this.getTalkCampaign()
        }
      })
    }
  },
  mounted() {
    this.getTalkCampaign()
  },
  watch: {
    '$route': 'getTalkCampaign'
  }
}
</script>

<style scoped>
.talk-campaign {
  padding: 24px;
  min-height: 100vh;
  background-color: #f5f7fa;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: 1240px;
}

.theme--dark .talk-campaign {
  background-color: #121212;
}

.talk-campaign__top-section {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
  margin-bottom: 24px;
  align-items: stretch;
}

@media (max-width: 960px) {
  .talk-campaign__top-section {
    grid-template-columns: 1fr;
  }
}

.talk-campaign__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 6px 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-width: 0;
  height: 100%;
  min-height: 46px;
}

.theme--dark .talk-campaign__header {
  background-color: #1E1E1E;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.talk-campaign__header-content {
  display: flex;
  align-items: center;
  gap: 12px;
  min-width: 0;
  flex: 1;
}

.talk-campaign__back-btn {
  flex-shrink: 0;
  margin-right: -4px;
}

.talk-campaign__title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  line-height: 1;
}

.theme--dark .talk-campaign__title {
  color: #ffffff;
}

.talk-campaign__status {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.04);
  flex-shrink: 0;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

@media (max-width: 600px) {
  .talk-campaign__status {
    display: none;
  }
}

.theme--dark .talk-campaign__status {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
}

.talk-campaign__actions {
  margin-left: 16px;
  flex-shrink: 0;
}

.talk-campaign__reload-btn {
  transition: transform 0.2s ease;
}

.talk-campaign__reload-btn:hover {
  transform: rotate(180deg);
}

.talk-campaign__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.talk-campaign__loader p {
  margin-top: 16px;
  color: #666666;
}

.theme--dark .talk-campaign__loader p {
  color: #b3b3b3;
}

.talk-campaign__content {
  animation: fadeIn 0.5s ease;
}

.talk-campaign__metrics {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.talk-campaign__metric-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.theme--dark .talk-campaign__metric-card {
  background-color: #1E1E1E;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.talk-campaign__metric-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.theme--dark .talk-campaign__metric-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.talk-campaign__metric-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  background-color: #f5f7fa;
}

.theme--dark .talk-campaign__metric-icon {
  background-color: #2d2d2d;
}

.talk-campaign__metric-content {
  flex: 1;
}

.talk-campaign__metric-title {
  font-size: 14px;
  color: #666666;
  margin: 0 0 4px 0;
  font-weight: 500;
}

.theme--dark .talk-campaign__metric-title {
  color: #b3b3b3;
}

.talk-campaign__metric-value {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.theme--dark .talk-campaign__metric-value {
  color: #ffffff;
}

.talk-campaign__metric-percentage {
  font-size: 14px;
  color: #666666;
  font-weight: 500;
}

.theme--dark .talk-campaign__metric-percentage {
  color: #b3b3b3;
}

.talk-campaign__progress-section {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 6px 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 46px;
}

.theme--dark .talk-campaign__progress-section {
  background-color: #1E1E1E;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.talk-campaign__progress-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.talk-campaign__section-title {
  font-size: 15px;
  font-weight: 600;
  color: #333333;
  margin: 0;
  line-height: 1;
}

.talk-campaign__progress-info {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  white-space: nowrap;
}

.talk-campaign__progress-percentage {
  font-weight: 600;
  color: #333333;
  font-size: 18px;
  line-height: 1;
}

.theme--dark .talk-campaign__progress-percentage {
  color: #ffffff;
}

.talk-campaign__progress-detail {
  color: #666666;
  font-size: 13px;
  line-height: 1;
}

.theme--dark .talk-campaign__progress-detail {
  color: #b3b3b3;
}

.talk-campaign__progress-container {
  display: flex;
  flex-direction: column;
}

.talk-campaign__progress-bar {
  height: 3px;
  background-color: #f5f7fa;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 3px;
}

.talk-campaign__progress-fill {
  height: 100%;
  background-color: #4CAF50;
  border-radius: 2px;
  transition: width 0.5s ease;
}

.talk-campaign__stats-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 24px 0;
  max-width: 100%;
}

@media (max-width: 768px) {
  .talk-campaign__stats-container {
    grid-template-columns: 1fr;
  }

  .talk-campaign {
    padding: 16px;
  }
}

.talk-campaign__stats-card {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 5px 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  min-height: 300px;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.theme--dark .talk-campaign__stats-card {
  background-color: #1E1E1E;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.talk-campaign__channels-list,
.talk-campaign__messages-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  padding-right: 8px;
  max-width: 100%;
  box-sizing: border-box;
}

.talk-campaign__channels-list::-webkit-scrollbar,
.talk-campaign__messages-list::-webkit-scrollbar {
  width: 6px;
}

.talk-campaign__channels-list::-webkit-scrollbar-track,
.talk-campaign__messages-list::-webkit-scrollbar-track {
  background: #f5f7fa;
  border-radius: 3px;
}

.theme--dark .talk-campaign__channels-list::-webkit-scrollbar-track,
.theme--dark .talk-campaign__messages-list::-webkit-scrollbar-track {
  background: #2d2d2d;
}

.talk-campaign__channels-list::-webkit-scrollbar-thumb,
.talk-campaign__messages-list::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 3px;
}

.theme--dark .talk-campaign__channels-list::-webkit-scrollbar-thumb,
.theme--dark .talk-campaign__messages-list::-webkit-scrollbar-thumb {
  background: #666666;
}

.talk-campaign__channels-list::-webkit-scrollbar-thumb:hover,
.talk-campaign__messages-list::-webkit-scrollbar-thumb:hover {
  background: #a0a0a0;
}

.theme--dark .talk-campaign__channels-list::-webkit-scrollbar-thumb:hover,
.theme--dark .talk-campaign__messages-list::-webkit-scrollbar-thumb:hover {
  background: #808080;
}

.talk-campaign__error {
  margin-bottom: 24px;
}

.talk-campaign__error-actions {
  margin-top: 16px;
}

.talk-campaign__debug-info {
  margin-bottom: 24px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.talk-campaign__loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.theme--dark .talk-campaign__loading-overlay {
  background-color: rgba(18, 18, 18, 0.9);
}

.talk-campaign__loading-overlay p {
  margin-top: 16px;
  color: #666666;
  font-size: 16px;
}

.theme--dark .talk-campaign__loading-overlay p {
  color: #b3b3b3;
}

.talk-campaign__empty-state {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  text-align: center;
  color: #666666;
}

.theme--dark .talk-campaign__empty-state {
  color: #b3b3b3;
}

.talk-campaign__empty-icon {
  font-size: 48px !important;
  margin-bottom: 16px;
  opacity: 0.5;
}

.talk-campaign__empty-text {
  font-size: 14px;
  max-width: 240px;
  line-height: 1.4;
}

.talk-campaign__stats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.theme--dark .talk-campaign__stats-header {
  border-bottom-color: rgba(255, 255, 255, 0.08);
}

.talk-campaign__section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin: 0;
  line-height: 1;
}

.theme--dark .talk-campaign__section-title {
  color: #ffffff;
}

.talk-campaign__section-icon {
  color: #666666;
  opacity: 0.8;
}

.theme--dark .talk-campaign__section-icon {
  color: #b3b3b3;
}

.talk-campaign__stats-actions {
  display: flex;
  gap: 4px;
}

.talk-campaign__action-btn {
  transition: all 0.2s ease;
  opacity: 0.7;
}

.talk-campaign__action-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

.talk-campaign__action-btn .v-icon {
  color: #666666;
}

.theme--dark .talk-campaign__action-btn .v-icon {
  color: #b3b3b3;
}
</style>
