import { formatCountryCode, formatPhone, formatDocument, formatDate, formatEmail, formatName } from '@/default/component/WNImportFromSheet/utils/formatFields'
import { validateCountryCode, validatePhone, validateDocument, validateDate, validateEmail, validateName } from '@/default/component/WNImportFromSheet/utils/validateFields'

export const personImportFields = [
  {
    value: 'country_code',
    title: 'Código do País',
    description: 'Código do país no formato +XX (Ex: +55 para Brasil)',
    format: formatCountryCode,
    validate: validateCountryCode
  },
  {
    value: 'phone',
    title: 'Telefone',
    required: true,
    description: 'Número de telefone sem código do país e sem formatação',
    format: formatPhone,
    validate: validatePhone
  },
  {
    value: 'document',
    title: 'Documento (CPF/CNPJ)',
    required: true,
    description: 'CPF ou CNPJ do contato (apenas números)',
    format: formatDocument,
    validate: validateDocument
  },
  {
    value: 'name',
    title: 'Nome',
    required: true,
    description: 'Primeiro nome ou nome de exibição do contato',
    format: formatName,
    validate: validateName
  },
  {
    value: 'full_name',
    title: 'Nome Completo',
    description: 'Nome completo do contato',
    format: formatName,
    validate: validateName
  },
  {
    value: 'tags',
    title: 'Tags',
    description: 'Tags para categorizar o contato (separadas por vírgula)',
    format: (value) => {
      if (!value) return ''
      const cleanValue = value.toString().trim()
      return cleanValue
    },
    validate: (value) => {
      if (!value) return true // Tags são opcionais
      return typeof value.toString().trim() === 'string'
    }
  },
  {
    value: 'email',
    title: 'E-mail',
    description: 'Endereço de e-mail do contato',
    format: formatEmail,
    validate: validateEmail
  },
  {
    value: 'date_of_birth',
    title: 'Data de Nascimento',
    description: 'Data de nascimento no formato DD/MM/AAAA',
    format: formatDate,
    validate: validateDate
  }
]

export default personImportFields
