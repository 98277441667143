import { Filters } from '@/default/model/Filters'

export class HostFilters extends Filters {
  constructor () {
    super()
    this.id = ''
    this.title = 'Filtrar Equipamentos'
    this.fields = {
      subHeader1: this.fieldSubHeader({
        text: 'Filtrar por Mac Address, IP Address ou Status Online/Offline'
      }),
      mac_address: this.fieldText ({
        value: 'mac_address',
        text: 'Mac Address',
        placeholder: 'Ex: ##:##:##:##:##:##',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      }),
      ip_address: this.fieldText ({
        value: 'ip_address',
        text: 'IP Address',
        placeholder: 'Ex: 192.168.1.1',
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      }),
      online: this.fieldSelect ({
        value: 'online',
        text: 'Online/Offline',
        placeholder: 'Ex: Somente Online',
        options: [
          { value: 'online', text: 'Somente Online' },
          { value: 'offline', text: 'Somente Offline' }
        ],
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ]
      }),
      subHeader2: this.fieldSubHeader({
        text: 'Filtrar por data da última leitura ou data de cadastro no sistema'
      }),
      dateField: this.fieldSelect({
        value: 'date_field',
        text: 'Campo de Data',
        placeholder: 'Selecione o campo...',
        size: ['xs12', 'sm12', 'md4', 'lg4'],
        required: false,
        options: [
          {
            value: 'checked_at',
            text: 'Última leitura'
          },
          {
            value: 'created_at',
            text: 'Data de criação'
          }
        ],
        defaultOption: 'checked_at'
      }),
      dateStart: this.fieldDateTime({
        value: 'date_start',
        text: 'Data Inicial',
        placeholder: 'Ex: 01/01/2024',
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      dateEnd: this.fieldDateTime({
        value: 'date_end',
        text: 'Data Final',
        placeholder: 'Ex: 31/01/2024',
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      subHeader3: this.fieldSubHeader({
        text: 'Filtrar por tecnologia e fabricante'
      }),
      technology_id: this.fieldSelectApi ({
        value: 'technology_id',
        text: 'Tecnologia',
        show: 'name',
        api: {
          url: 'network/host/technology'
        },
        size: form => form.producer_id ? [ 'xs12', 'sm12', 'md4', 'lg4' ] : [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      producer_id: this.fieldSelectApi ({
        value: 'producer_id',
        text: 'Fabricante',
        show: 'name',
        api: {
          url: 'network/host/producer'
        },
        size: form => form.producer_id ? [ 'xs12', 'sm12', 'md4', 'lg4' ] : [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      producer_model_id: this.fieldSelectApi ({
        value: 'producer_model_id',
        text: 'Modelo',
        show: 'name',
        api: {
          url: 'network/host/producer/model',
          params: (form) => ({
            producer_id: form.producer_id
          })
        },
        size: [ 'xs12', 'sm12', 'md4', 'lg4' ],
        showField: filters => !!filters.producer_id
      }),
      subHeader4: this.fieldSubHeader({
        text: 'Filtrar por tipo de rede e rede ou dispositivo'
      }),
      network_type_id: this.fieldSelectApi ({
        value: 'network_type_id',
        text: 'Tipo de Rede',
        show: 'nome_tipo',
        api: {
          url: 'grupo/tipo',
          params: {
            order: 'nome_tipo,asc'
          }
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      network_id: this.fieldSelectApi ({
        value: 'network_id',
        text: 'Rede',
        show: 'nome_grupo',
        api: {
          url: 'grupo/dados',
          params: filters => ({
            network_type_id: filters.network_type_id,
            order: 'nome_grupo,asc'
          })
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
        showField: filters => !!filters.network_type_id
      }),
      device_id: this.fieldWiListDialog ({
        value: 'device_id',
        text: 'Dispositivo',
        show: 'nome_disp',
        wiConfig: 'devices',
        filters: filters => ({
          network_id: filters.network_id || null
        }),
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ]
      }),
      device_child_slot_id: this.fieldSelectApi ({
        value: 'device_child_slot_id',
        text: 'Placa',
        show: 'name',
        api: {
          url: 'network/child',
          params: filters => ({
            device_id: filters.device_id,
            type_id: 1
          })
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
        showField: filters => !!filters.device_id
      }),
      device_child_port_id: this.fieldSelectApi ({
        value: 'device_child_port_id',
        text: 'Porta',
        show: 'name',
        api: {
          url: 'network/child',
          params: filters => ({
            child_id: filters.device_child_slot_id,
            type_id: 2
          })
        },
        size: [ 'xs12', 'sm12', 'md6', 'lg6' ],
        showField: filters => !!filters.device_child_slot_id
      })
    }
  }
}
