<template>
  <div class="custom-table" :class="{ 'theme--dark': $wiDark }">
    <div class="custom-table__header">
      <div class="custom-table__title">
        <v-icon :color="$wiDark ? 'white' : 'primary'" class="custom-table__icon">{{ icon }}</v-icon>
        <span class="custom-table__title-text">{{ title }}</span>
      </div>
      <div class="custom-table__actions">
        <slot name="actions"></slot>
        <!-- <v-btn small flat icon class="custom-table__more-btn" @click="$emit('more')">
          <v-icon small>more_horiz</v-icon>
        </v-btn> -->
      </div>
    </div>
    
    <div class="custom-table__container">
      <table class="custom-table__table">
        <thead>
          <tr>
            <th 
              v-for="(header, index) in headers" 
              :key="index" 
              :class="[
                'custom-table__th', 
                `custom-table__th--${header.align || 'left'}`
              ]"
              :data-type="header.type"
            >
              <div class="custom-table__th-content">
                {{ header.text }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(item, index) in limitedItems" 
            :key="index" 
            class="custom-table__tr"
          >
            <slot name="row" :item="item" :index="index">
              <td 
                v-for="(header, headerIndex) in headers" 
                :key="headerIndex" 
                :class="[
                  'custom-table__td', 
                  `custom-table__td--${header.align || 'left'}`
                ]"
                :data-type="header.type"
              >
                <template v-if="header.type === 'image' && item[header.value]">
                  <img :src="item[header.value]" :alt="item[header.value]">
                </template>
                <template v-else>
                  {{ item[header.value] }}
                </template>
              </td>
            </slot>
          </tr>
          <tr v-if="items.length === 0" class="custom-table__empty-row">
            <td :colspan="headers.length" class="custom-table__empty-message">
              Nenhum dado encontrado
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomTable',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  computed: {
    limitedItems: function() {
      return this.items.slice(0, this.limit);
    }
  }
};
</script>

<style>
.custom-table {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.theme--dark.custom-table {
  background-color: #1e1e1e;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.custom-table__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.theme--dark .custom-table__header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.custom-table__title {
  display: flex;
  align-items: center;
}

.custom-table__icon {
  margin-right: 12px;
  opacity: 1;
}

.custom-table__title-text {
  font-size: 16px;
  font-weight: 600;
  color: #3f51b5;
  letter-spacing: 0.3px;
}

.theme--dark .custom-table__title-text {
  color: #7986cb;
}

.custom-table__actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-table__more-btn {
  margin-left: 8px;
  opacity: 0.8;
  transition: all 0.2s ease;
}

.custom-table__more-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

.custom-table__container {
  overflow-x: hidden;
  background-color: #ffffff;
  flex: 1;
  padding: 0 4px;
}

.theme--dark .custom-table__container {
  background-color: #1e1e1e;
}

.custom-table__table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
}

.custom-table__th {
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: rgba(0, 0, 0, 0.7);
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
  position: relative;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme--dark .custom-table__th {
  color: rgba(255, 255, 255, 0.8);
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.custom-table__th[data-type="name"],
.custom-table__td[data-type="name"] {
  width: 25%;
  max-width: none;
  min-width: auto;
}

.custom-table__th[data-type="number"],
.custom-table__td[data-type="number"] {
  width: 15%;
  font-size: 15px;
  font-weight: 600;
  font-family: 'Roboto Mono', monospace;
  color: #1976d2;
}

.theme--dark .custom-table__th[data-type="number"],
.theme--dark .custom-table__td[data-type="number"] {
  color: #64b5f6;
}

.custom-table__th[data-type="datetime"],
.custom-table__td[data-type="datetime"] {
  width: 18%;
  font-family: 'Roboto Mono', monospace;
  font-size: 13px;
  color: #666;
}

.theme--dark .custom-table__th[data-type="datetime"],
.theme--dark .custom-table__td[data-type="datetime"] {
  color: #aaa;
}

.custom-table__th[data-type="image"],
.custom-table__td[data-type="image"] {
  width: 80px;
  text-align: center;
}

.custom-table__th--center {
  text-align: center;
}

.custom-table__th--right {
  text-align: right;
}

.custom-table__th-content {
  display: flex;
  align-items: center;
  justify-content: inherit;
}

.custom-table__tr {
  transition: all 0.2s ease;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
}

.theme--dark .custom-table__tr {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.custom-table__tr:last-child {
  border-bottom: none;
}

.custom-table__tr:hover {
  background-color: rgba(63, 81, 181, 0.04);
  transform: translateY(-1px);
}

.theme--dark .custom-table__tr:hover {
  background-color: rgba(121, 134, 203, 0.08);
}

.custom-table__td {
  padding: 12px 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease;
}

.theme--dark .custom-table__td {
  color: rgba(255, 255, 255, 0.87);
}

.custom-table__td img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
}

.custom-table__td img:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.theme--dark .custom-table__td img {
  border-color: #2d2d2d;
}

.custom-table__td--center {
  text-align: center;
}

.custom-table__td--right {
  text-align: right;
}

.custom-table__empty-row {
  height: 200px;
}

.custom-table__empty-message {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  font-style: italic;
  font-size: 15px;
  padding: 40px 0;
}

.theme--dark .custom-table__empty-message {
  color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 600px) {
  .custom-table {
    border-radius: 12px;
  }
  
  .custom-table__header {
    padding: 12px 16px;
  }
  
  .custom-table__title-text {
    font-size: 14px;
  }
  
  .custom-table__th, 
  .custom-table__td {
    padding: 10px;
  }
  
  .custom-table__th {
    font-size: 11px;
  }
  
  .custom-table__td {
    font-size: 13px;
  }

  .custom-table__td img {
    width: 40px;
    height: 40px;
  }

  .custom-table__th[data-type="name"],
  .custom-table__td[data-type="name"] {
    width: 30%;
  }

  .custom-table__th[data-type="datetime"],
  .custom-table__td[data-type="datetime"] {
    width: 22%;
    font-size: 12px;
  }
}
</style> 