<template>
  <v-card class="business-card">
    <div class="business-card__content" @click="$emit('click:router', item)">
      <business-card-header 
        :item="item" 
        :config="config" 
      />
      
      <business-card-details 
        :item="item"
        :config="config"
      />
      
      <business-card-dates 
        :item="item" 
        :config="config"
      />

      <business-card-value 
        :item="item"
      />
    </div>

    <business-card-actions 
      :item="item" 
      :config="config"
      :refresh="refresh"
      :wi-dialogs="wiDialogs"
      @open:talk="$emit('open:talk', item)"
    />
  </v-card>
</template>

<script>
import BusinessCardHeader from './BusinessCardHeader'
import BusinessCardDetails from './BusinessCardDetails'
import BusinessCardDates from './BusinessCardDates'
import BusinessCardValue from './BusinessCardValue'
import BusinessCardActions from './BusinessCardActions'

export default {
  name: 'BusinessCard',
  
  components: {
    BusinessCardHeader,
    BusinessCardDetails,
    BusinessCardDates,
    BusinessCardValue,
    BusinessCardActions
  },

  props: {
    item: { type: Object, required: true },
    config: { type: Object, required: true },
    refresh: { type: Function, required: true },
    wiDialogs: { type: Object, required: true }
  }
}
</script>

<style scoped>
.business-card {
  margin-bottom: 7px;
  cursor: grab;
  width: 100%;
  min-width: 274px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.theme--light .business-card {
  background: #ffffff;
  border-color: rgba(0, 0, 0, 0.08);
}

.theme--dark .business-card {
  background: #1e1e1e;
  border-color: rgba(255, 255, 255, 0.08);
}

.business-card__content {
  transition: background-color 0.2s ease;
}

/* Quando dentro de um container com scroll */
.scrollable-container .business-card {
  width: 270px !important;
  max-width: 270px !important;
}

/* Para Firefox */
@-moz-document url-prefix() {
  .scrollable-container .business-card {
    width: 270px;
    max-width: 270px;
  }
}

/* Para IE e Edge */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .scrollable-container .business-card {
    width: 270px;
    max-width: 270px;
  }
}
</style> 