var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"500px","dark":_vm.$wiDark,"fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"category-selector"},[_c('v-toolbar',{staticClass:"dialog-toolbar",attrs:{"dense":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"primary"}},[_vm._v(_vm._s(_vm.icon))]),_c('v-toolbar-title',[_c('div',{staticClass:"title-content"},[_c('strong',[_vm._v(_vm._s(_vm.title))]),(_vm.content && _vm.content.data && _vm.content.data.length)?_c('span',{staticClass:"subtitle"},[_c('span',[_vm._v("Clique no ")]),_c('v-icon',{staticStyle:{"border":"1px solid green","border-radius":"50%","padding":"2px"},attrs:{"color":"green","small":""}},[_vm._v("done")]),_c('span',[_vm._v(" para selecionar a categoria.")])],1):_c('span',{staticClass:"subtitle"},[_c('span',[_vm._v("Nenhuma categoria encontrada.")])])])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.content && _vm.content.data && _vm.content.data.length)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.closeTreeview}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.isExpanded ? 'unfold_less' : 'unfold_more'))])],1):_vm._e()]}}])},[_c('span',[_vm._v(_vm._s(_vm.isExpanded ? 'Recolher todas' : 'Expandir todas'))])]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.handleClose}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('WiView',{ref:"WNCategorySelector",attrs:{"index":"wn-category-selector","apiUrl":_vm.apiUrl,"apiParams":{ returnInactiveCategories: false },"notShowToolbar":true,"notGetID":true},on:{"afterLoad":_vm.setContent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var content = ref.content;
return [_c('v-card-text',{staticClass:"dialog-content"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(content.data && content.data.length)?_c('v-treeview',{staticClass:"category-tree",attrs:{"items":content.data,"open":_vm.expandedItems,"item-key":_vm.itemIdField,"item-text":_vm.itemTextField,"item-children":_vm.itemChildrenField,"open-on-click":"","hoverable":"","dense":"","shaped":"","open-all":false},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center category-item"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outline":"","icon":"","color":"green","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectItem(item)}}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" done ")])],1),_c('v-icon',{staticClass:"category-icon",attrs:{"color":item[_vm.itemColorField] || 'primary'}},[_vm._v(" "+_vm._s(item[_vm.itemIconField] || 'folder')+" ")])],1)]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tree-label",class:{
                  'current-category': _vm.isCurrentCategory(item),
                  'parent-category': _vm.isParentCategory(item)
                }},[_c('span',[_vm._v(_vm._s(_vm.itemText(item)))])])]}}],null,true)}):_c('div',{staticClass:"empty-state"},[_c('div',{staticClass:"empty-state-container"},[_c('div',{staticClass:"empty-state-icon-wrapper"},[_c('v-icon',{staticClass:"empty-state-icon",attrs:{"color":_vm.emptyStateIconColor}},[_vm._v(" "+_vm._s(_vm.emptyStateIcon)+" ")]),_c('div',{staticClass:"empty-state-icon-circle"})],1),_c('div',{staticClass:"empty-state-content"},[_c('h3',{staticClass:"empty-state-title primary--text"},[_vm._v(_vm._s(_vm.emptyStateTitle))]),_c('p',{staticClass:"empty-state-message"},[_vm._v(_vm._s(_vm.emptyStateMessage))])]),_c('div',{staticClass:"empty-state-footer"},[_c('v-btn',{staticClass:"mt-4",attrs:{"flat":"","color":"black"},on:{"click":_vm.handleClose}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("arrow_back")]),_vm._v(" Cancelar ")],1),(
                      _vm.emptyStateAddCategory &&
                      _vm.emptyStateAddCategoryRoute &&
                      _vm.emptyStateAddCategoryPermission &&
                      _vm.$WiCheckPermission(_vm.emptyStateAddCategoryPermission)
                    )?_c('v-btn',{staticClass:"mt-4",attrs:{"flat":"","color":"green"},on:{"click":_vm.handleAddCategory}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("open_in_new")]),_vm._v(" Adicionar Categoria ")],1):_vm._e()],1)])])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }