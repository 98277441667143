const formatName = (value) => {
  if (!value) return ''
  let cleanValue = value.toString().trim()
  if (cleanValue.length > 100) {
    cleanValue = cleanValue.substring(0, 100)
  }
  // deixar primeira letra de cada nome ou sobrenome maiúscula e o restante em minúsculas
  return cleanValue.split(' ').map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()).join(' ')
}

const formatPhone = (value) => {
  if (!value) return ''
  const cleanValue = value.toString().replace(/\D/g, '')
  return cleanValue
}

const formatCountryCode = (value) => {
  if (!value) return '55'
  const cleanValue = value.toString().trim()
  return cleanValue
}

const formatDate = (value) => {
  if (!value) return ''
  
  let date
  const cleanValue = value.toString().trim()
  
  // Tenta diferentes formatos de data
  try {
    // Caso seja um número (formato Excel)
    if (/^\d+$/.test(cleanValue)) {
      // Converte número Excel para data JavaScript
      // Excel: dias desde 01/01/1900
      const excelDate = parseInt(cleanValue)
      const excelEpoch = new Date(1900, 0, 1)
      date = new Date(excelEpoch.getTime() + (excelDate - 1) * 24 * 60 * 60 * 1000)
      
      // Verifica se é uma data válida
      if (isNaN(date.getTime())) return ''
    }
    // Tenta formatos comuns
    else {
      // Remove possíveis horas/minutos/segundos
      const dateOnly = cleanValue.split(/[\s,T]/)[0]
      
      // Tenta diferentes separadores (/ ou -)
      const parts = dateOnly.split(/[-/]/)
      
      // Verifica ordem das partes (dd/mm/yyyy ou yyyy/mm/dd)
      if (parts.length === 3) {
        if (parts[0].length === 4) {
          // Formato yyyy/mm/dd
          date = new Date(
            parseInt(parts[0]), 
            parseInt(parts[1]) - 1, 
            parseInt(parts[2])
          )
        } else {
          // Formato dd/mm/yyyy
          date = new Date(
            parseInt(parts[2]), 
            parseInt(parts[1]) - 1, 
            parseInt(parts[0])
          )
        }
      } else {
        // Tenta parse direto
        date = new Date(cleanValue)
      }
      
      // Verifica se é uma data válida
      if (isNaN(date.getTime())) return ''
    }
    
    // Validações adicionais
    const year = date.getFullYear()
    const currentYear = new Date().getFullYear()
    
    // Verifica se o ano é razoável
    if (year < 1900 || year > currentYear + 100) return ''
    
    // Formata no padrão desejado YYYY-MM-DD
    return date.toISOString().split('T')[0]
    
  } catch (error) {
    console.error('Erro ao processar data:', error)
    return ''
  }
}

const formatEmail = (value) => {
  if (!value) return ''
  const cleanValue = value.toString().trim()
  return cleanValue
}

const formatDocument = (value) => {
  if (!value) return ''
  let cleanValue = value.toString().replace(/\D/g, '')
  if (cleanValue.length < 11) {
    cleanValue = cleanValue.padStart(11, '0')
  }
  return cleanValue
}

export {
  formatDate,
  formatName,
  formatPhone,
  formatEmail,
  formatDocument,
  formatCountryCode
}
