<template>
  <div class="wn-talk">
    <div v-if="loadings.load">
      <TalkLoading :mode="mode" :showDrawer="showContactInfo" />
    </div>
    <div v-else>
      <div style="
        padding:0 !important;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-content: stretch;
      ">
        <div v-if="talk && talk.id" style="width: 100%;">
          <TalkToolbar
            :mode="mode"
            :talkId="talkId"
            @onClose="closeAction()"
          ></TalkToolbar>
          <div
            style="
              padding:0 !important;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: stretch;
              align-content: stretch;
            "
          >
            <v-container
              id="container"
              fluid
              class="talk__message-area"
              style="margin-top: 0px;"
              :style="{
                'background-image': $wiDark ? 'url(\'static/background/background-whatsapp-dark.jpg?url=123\')' : 'url(\'static/background/background-whatsapp.jpg?url=123\')',
                height: mode == 'dialog' ? 'calc(min(90vh - 120px, 100vh - 220px))' : 'calc(100vh - 200px)',
                'max-height': mode == 'dialog' ? '80vh' : 'none',
                'margin-bottom': mode == 'dialog' ? 'calc(10vh + 70px)' : '45px'
              }"
            >
              <div style="padding: 0px 20px 20px 20px;">
                <div style="width: 100%; text-align: center; padding-top: 20px;">
                  <v-btn
                    @click="paginateMessages()"
                    :loading="loadings.paginate"
                    :disabled="!paginate.next_page_url"
                  >
                    <v-icon>refresh</v-icon>
                    <span
                      v-t="'modules.talks.pages.talkView.talk.labels.loadMore'"
                    ></span>
                  </v-btn>
                </div>
                <TalkMessage
                  v-for="(message, messageKey) in messagesOrdered"
                  :key="`${messageKey}-${message.id}`"
                  :talk="talk"
                  :message="message"
                  @share="shareMessage"
                  @resendMessage="resendMessage"
                ></TalkMessage>
                <div
                  class="elevation-10"
                  v-if="talk.contact.checked == 3"
                  style="margin: 20px 0 20px 0; width: 100%;">
                  <div>
                    <v-alert
                      :value="true" type="warning"
                      style="padding: 5px; border-radius: 5px; color: black;"
                    >
                      <strong>Atenção:</strong><br>
                      <span>Este contato é inválido!</span>
                    </v-alert>
                  </div>
                </div>
                <div
                  class="elevation-10"
                  style="margin: 20px 0 20px 0; width: 100%;"
                  v-if="talk.channel && talk.channel.status_id != 2"
                >
                  <div>
                    <v-alert
                      :value="true" type="error"
                      style="padding: 5px; border-radius: 5px; cursor: pointer;"
                      @click="$router.push({
                        name: 'talk-list',
                        params: { page: 'talk-channel' },
                        query: { where: `id,${talk.channel.id}` }
                      })"
                    >
                      <strong>Atenção:</strong><br>
                      <span>O canal de atendimento <strong>"{{ talk.channel.name }}"</strong> está desconectado.</span>
                    </v-alert>
                  </div>
                </div>
              </div>
              <div>
                <TalkFooter
                  v-on:scroll="scrollToEnd"
                  v-on:refresh="() => { getTalk(talkId) }"
                ></TalkFooter>
              </div>
            </v-container>
            <div v-if="talk && talk.id">
              <div v-if="!$vuetify.breakpoint.xsOnly">
                <div v-if="showContactInfo" class="hidden-xs-only hidden-sm-only">
                  <TalkContact
                    :contact="talk.contact"
                    :refresh="() => { getTalk(talkId) }"
                    :mode="mode"
                    :talkId="talkId"
                    @onClose="closeAction()"
                  ></TalkContact>
                </div>
              </div>
              <div v-else>
                <v-dialog
                  v-model="showContactInfo"
                  :fullscreen="$vuetify.breakpoint.xsOnly" 
                  lazy scrollable
                >
                  <v-card :dark="$wiDark">
                    <v-toolbar dense flat :dark="$wiDark" :color="$wiDark ? 'dark' : 'primary'" class="white--text ml-0 pl-0">
                      <h3
                        v-if="talk.contact && talk.contact.type == 1"
                        v-t="'modules.talks.pages.talkView.talk.contact.title.contact'"
                      ></h3>
                      <h3
                        v-if="talk.contact && talk.contact.type == 2"
                        v-t="'modules.talks.pages.talkView.talk.contact.title.group'"
                      ></h3>
                      <v-spacer></v-spacer>
                      <v-btn icon small ripple @click="TOUGGLE_SHOW_CONTACT_INFO()" style="color: white;">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text style="padding: 0;">
                      <TalkContact
                        :contact="talk.contact"
                        :refresh="() => { getTalk(talkId) }"
                        :mode="mode"
                        :talkId="talkId"
                        @onClose="closeAction()"
                      ></TalkContact>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>      
        </div>
        <div v-else style="width: 100%; display: flex; flex-direction: column; justify-content: center;">
          <div style="width: 100%; text-align: center; margin: auto; max-width: 400px;">
            <img src="static/background/whatsapp-not-found.png?url=temp1" alt="" style="width: 100%">
            <br>
            <span
              style="font-size: 20px;"
              v-t="'modules.talks.pages.talkView.talk.notFound'"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import TalkContact from './TalkContact'
  import TalkLoading from './TalkLoading'
  import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
  import TalkToolbar from '@/modules/talk/pages/TalkView/Talk/TalkToolbar'
  import TalkMessage from '@/modules/talk/pages/TalkView/Talk/TalkMessage'
  import TalkFooter from '@/modules/talk/pages/TalkView/Talk/TalkFooter'
  export default {
    name: 'Talk',
    data: () => ({
      currentHeight: 0,
      pageHeight: innerHeight,
      drawer: false
    }),
    computed: {
      ...mapState('Talk', ['talk', 'messages', 'paginate', 'loadings', 'lastMessage']),
      ...mapGetters('Talk', ['messagesOrdered']),
      talkId () {
        return this.setTalkId || this.$route.params.id
      },
      showContactInfo: {
        get () {
          return this.$store.state.Talk.showContactInfo
        },
        set (value) {
          this.$store.commit('Talk/SET_SHOW_CONTACT_INFO', value)
        }
      }
    },
    methods: {
      ...mapMutations('Talk', ['TOUGGLE_SHOW_CONTACT_INFO']),
      ...mapActions('Talk', ['getTalk', 'resetTalk', 'subscribeOnTalk', 'unsubscribeOnTalk', 'paginateMessages', 'unseenMessages']),
      talkChange: function () {
        this.resetTalk()
        this.getTalk(this.talkId)
        this.unsubscribeOnTalk(this.$route.query.previousTalkId)
        this.subscribeOnTalk(this.talkId)
      },
      scrollToEnd: function (timeout) {
        setTimeout(() => {
          var container = this.$el.querySelector('#container')
          if (container) {
            container.scrollTop = container?.scrollHeight || 0
          }
        }, timeout)
      },
      onMessageChange: function () {
        this.scrollToEnd(500)
        this.scrollToEnd(1000)
        this.talkUnseenMessages()
        this.$forceUpdate()
      },
      talkUnseenMessages: function () {
        var previousTalkId = this.$route.query.previousTalkId
        if (previousTalkId) {
          this.unseenMessages(previousTalkId)
        }
      },
      setCurrentHeight: function (heigth) {
        this.currentHeight = heigth
        this.setInnerHeight()
      },
      setInnerHeight: function () {
        let heightPerson = 46
        let heightDiff = 145
        const breackPoint = this.$vuetify.breakpoint.name
        if (this.mode == 'dialog') {
          if (breackPoint === 'xs' || breackPoint === 'sm') {
            heightDiff = 93
          } else {
            heightDiff = 188
          }
        }
        if (breackPoint === 'xs' || breackPoint === 'sm') {
          heightPerson = 0
        }
        this.pageHeight = (((this.currentHeight || innerHeight) - heightDiff) - heightPerson) + 'px'
      },
      closeAction: function () {
        this.$emit('onClose')
      },
      shareMessage: function (message) {
        this.$WiListDialog({
          wiConfig: 'talk-contact-all-multiple',
          mode: 'select',
          filter: {
            order: 'last_event_at,desc'
          },
          callback: (response) => {
            if (response) {
              let contacts = []
              if (Array.isArray(response) && response.length > 1) {
                contacts = response.map(contact => (contact.id))
              } else if (response?.id) {
                contacts = [response.id]
              }
              if (contacts.length > 0) {
                this.$WiApiPost({
                  uri: 'talk/message/share',
                  data: {
                    message_id: message.id,
                    contacts: contacts,
                    talk_channel_id: this.talk.talk_channel_id
                  },
                  config: {
                    msgLoad: true,
                    msgError: true,
                    msgSucess: true,
                    msgAsk: {
                      title: `Deseja compartilhar com ${contacts.length} ${contacts.length > 1 ? 'contatos' : 'contato'}?`,
                      text: 'ATENÇÃO: Compartilhar com muitos contatos pode ocasionar banimento!',
                    }
                  }
                })
              }
            }
          }
        })
      },
      resendMessage: function (message) {
        this.$WiApiPost({
          uri: 'talk/message/share',
          data: {
            message_id: message.id,
            contacts: [{
              ...message.contact
            }],
            talk_channel_id: this.talk.talk_channel_id
          },
          config: {
            msgLoad: true,
            msgError: true,
            msgSucess: {
              title: 'Mensagem reenviada com sucesso!',
              text: 'A mensagem irá aparecer na conversa em instantes.',
            },
            msgAsk: {
              title: `Deseja reenviar a mensagem para o contato?`,
              text: 'Se precisar, contate o suporte!',
            }
          }
        })
      }
    },
    mounted: function () {
      // window.addEventListener('resize', () => {
      //   this.setInnerHeight(innerHeight)
      // }, true)
      // this.setInnerHeight(innerHeight)
      this.getTalk(this.talkId)
      this.subscribeOnTalk(this.talkId)
    },
    beforeDestroy: function () {
      this.unsubscribeOnTalk(this.talkId || this.talk.id)
      this.resetTalk()
      this.unseenMessages(this.talkId || this.talk.id)
    },
    props: {
      setTalkId: {
        type: Number,
        default: null
      },
      mode: {
        type: String,
        default: 'page'
      }
    },
    watch: {
      '$route': 'talkChange',
      'loadings.load': 'onMessageChange',
      'lastMessage': 'onMessageChange',
      innerHeight: 'setCurrentHeight',
      talk: 'setInnerHeight',
    },
    components: {
      TalkToolbar,
      TalkMessage,
      TalkFooter,
      TalkContact,
      TalkLoading
    }
  }
</script>

<style scoped>
  .talk__message-area { 
    padding: 0 !important;
    overflow-y: auto;
    background-repeat: repeat;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
</style>