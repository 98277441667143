import {Lang} from '@/default/service/Lang'
import { Auth } from '@/default/service/Auth'

export class Filters {
  constructor () {
    this.id = null
    this.title = null
    this.fields = []
  }
  fieldText ({ value, text, placeholder, required, action, size, showField, mask }) {
    return {
      value: value || null,
      text: text || null,
      type: 'text',
      required: required || false,
      placeholder: placeholder || 'Insira aqui...',
      edit: action || {
        create: true,
        update: true
      },
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      showField: showField,
      mask: mask || null
    }
  }
  fieldSubHeader ({text, showField}) {
    return {
      text: text || 'Informe os dados solicitados nos campos abaixo.',
      type: 'subheader',
      edit: true,
      showField: showField
    }
  }
  fieldSelect ({
    value,
    text,
    placeholder = null,
    required,
    action,
    size,
    disabled,
    options,
    showField,
    multiple,
    clearable,
    defaultOption
  }) {
    
    return {
      value: value,
      text: text,
      placeholder: placeholder,
      type: 'select',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      options: options || [{ value: 'undefined', text: 'Não definido' }],
      showField: showField,
      multiple: multiple || false,
      clearable: clearable || false,
      defaultOption: defaultOption || null
    }
  }
  fieldSelectApi ({ value,
    text,
    placeholder,
    required,
    action,
    size,
    disabled,
    showField,
    api: {
      url,
      params
    },
    show,
    selectValue,
    icon,
    color,
    change,
    multiple,
    clearable
  }) {
    return {
      value: value,
      text: text,
      autocomplete: true,
      required: required || false,
      placeholder: placeholder || 'Selecione um item..',
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'select-api',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      apiUrl: url,
      apiParams: params || {},
      show: show || 'id',
      selectValue: selectValue || 'id',
      showField: showField,
      icon: icon || null,
      color: color || null,
      change: change || null,
      multiple: multiple || false,
      clearable: clearable || false
    }
  }
  fieldDate ({ value, text, required, action, size, disabled, showField }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'date',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldDateTime ({ value, text, required, action, size, disabled, showField }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'datetime',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldTime ({ value, text, required, action, size, disabled, showField }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'time',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField
    }
  }
  fieldNumber ({ value, text, required, action, size, disabled, showField, min, max, prefix, suffix, step, rules }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'int',
      min: min || null,
      max: max || null,
      step: step || null,
      prefix: prefix,
      suffix: suffix,
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      showField: showField,
      rules: rules
    }
  }
  fieldWiListDialog ({ value, text, wiConfig, required, action, size, disabled, show, icon, showField, filter, callbackFields, wiEditData }) {
    return {
      value: value,
      text: text,
      required: required || false,
      size: size || ['xs12', 'sm12', 'md12', 'lg12'],
      type: 'wi-list-dialog',
      disabled: disabled || false,
      edit: action || {
        create: true,
        update: true
      },
      wiConfig: wiConfig,
      show: show || 'id',
      icon: icon || 'edit',
      showField: showField,
      filter: filter,
      wiEditData: wiEditData,
      callbackFields: callbackFields
    }
  }
  fieldCategorySelector ({
    value, text, required, size,
    disabled, showField, placeholder,
    selectedShow, apiUrl, apiParams,
    show
  }) {
    return {
      value,
      text,
      type: 'wn-category-selector',
      required,
      size,
      disabled,
      showField,
      placeholder,
      selectedShow,
      apiUrl,
      apiParams,
      show
    }
  }
  setFields (fields) {
    if (Array.isArray(fields)) {
      this.setFieldsArray(fields)
    } else {
      this.setFieldsObject(fields)
    }
  }
  setFieldsArray (arrayFields) {
    arrayFields.filter((field) => {
      this.fields.push(field)
    })
  }
  setFieldsObject (objectFields) {
    this.setFieldsArray(Object.values(objectFields))
  }
  getLangText (textId, text) {
    return Lang.langText(textId, text)
  }
  permissionIsAvaiable (permissionId) {
    return Auth.checkPermission(permissionId)
  }
  clearFields () {
    this.fields = []
  }
}
