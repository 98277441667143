<template>
  <v-card v-if="category" class="wn-selected-category" elevation="10">
    <div class="category-header" :style="{ backgroundColor: category.color + '15' }">
      <div class="header-content">
        <div class="category-title">
          <!-- Breadcrumb de categorias pai -->
          <div class="category-path" v-if="category.parents.length">
            <div v-for="parent in orderedParents" :key="parent.id" class="path-item">
              <v-icon small color="rgba(0, 0, 0, 0.4)" class="path-icon">subdirectory_arrow_left</v-icon>
              <span class="parent-name">
                <div class="name-container">
                  <span class="level-badge">{{ parent.level }}</span>
                  {{ parent.name }}
                </div>
              </span>
            </div>
          </div>
          
          <!-- Categoria atual -->
          <div class="current-category">
            <v-icon :color="category.color" size="36">{{ category.icon }}</v-icon>
            <div class="current-category-info">
              <h2>{{ category.name }}</h2>
              <div class="current-amount-info">
                <span class="current-amount-total">R$ {{ category.amount_total | money }}</span>
                <span class="current-amount-percentage">({{ category.percentage_total }}%)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    <div class="category-content">
      <!-- Novo gráfico de distribuição -->
      <div v-if="category.children && category.children.length" class="distribution-chart mb-4">
        <div class="chart-header">
          <v-icon>pie_chart</v-icon>
          <h4 class="ml-2">Distribuição por Subcategorias</h4>
        </div>
        <apexchart
          width="100%"
          type="donut"
          :series="chartSeriesValues"
          :options="chartOptions"
        ></apexchart>
      </div>
      
      <div class="info-grid">
        <div v-if="category.id > -1" class="info-item">
          <div class="info-label">ID</div>
          <div class="info-value">{{ category.id }}</div>
        </div>
        
        <div v-if="category.level > -1" class="info-item">
          <div class="info-label">Nível</div>
          <div class="info-value">{{ category.level }}</div>
        </div>

        <div v-if="category.children_count" class="info-item">
          <div class="info-label">Subcategorias diretas</div>
          <div class="info-value">{{ category.children_count }}</div>
        </div>

        <div v-if="category.all_children_count" class="info-item">
          <div class="info-label">Total de subcategorias</div>
          <div class="info-value">{{ category.all_children_count }}</div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'SelectedCategory',
  props: {
    category: {
      type: Object,
      default: null
    }
  },
  computed: {
    orderedParents() {
      return [...this.category.parents]
        .sort((a, b) => a.level - b.level)
        .reverse()
    },
    
    chartSeries() {
      if (!this.category?.children?.length) return []
      return this.category.children.map(child => child.percentage_total)
    },
    
    chartSeriesValues() {
      if (!this.category?.children?.length) return []
      return this.category.children.map(child => child.amount_total)
    },
    
    adjustedColors() {
      if (!this.category?.children?.length) return []
      
      const colors = this.category.children.map(child => child.color)
      const colorCount = {}
      const adjustedColors = []

      colors.forEach((color, index) => {
        // Conta quantas vezes cada cor aparece
        colorCount[color] = (colorCount[color] || 0) + 1
        
        if (colorCount[color] === 1) {
          // Se é a primeira ocorrência, usa a cor original
          adjustedColors[index] = color
        } else {
          // Se é uma repetição, ajusta o brilho com um contraste maior
          // Aumenta 25% de brilho para cada repetição (era 10%)
          const lightenAmount = (colorCount[color] - 1) * 25
          adjustedColors[index] = this.lightenColor(color, lightenAmount)
        }
      })

      return adjustedColors
    },
    
    chartOptions() {
      const hasMoreThanTenChildren = this.category?.children?.length > 10
      
      return {
        chart: {
          id: 'subcategories-distribution'
        },
        labels: this.category?.children?.map(child => child.name) || [],
        colors: this.adjustedColors,
        tooltip: {
          enabled: true,
          custom: function({ seriesIndex, w }) {
            const name = w.globals.labels[seriesIndex]
            const value = w.globals.initialSeries[seriesIndex]
            
            return `<div class="custom-tooltip" style="padding: 8px;">
              <div style="font-weight: 500; margin-bottom: 4px;">${name}</div>
              <div style="font-size: 0.9em; opacity: 0.8;">
                Valor: R$ ${Number(value).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </div>
            </div>`
          }
        },
        legend: {
          show: !hasMoreThanTenChildren,
          position: 'bottom',
          horizontalAlign: 'center'
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  label: 'Total',
                  formatter: function() {
                    return '100%'
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    handleEdit() {
      this.$emit('edit-category', this.category)
    },
    handleAddChild() {
      this.$emit('add-child-category', this.category)
    },
    handleDelete() {
      this.$emit('delete-category', this.category)
    },
    lightenColor(color, amount) {
      // Remove o # se existir
      color = color.replace('#', '')
      
      // Converte hex para RGB
      let r = parseInt(color.substring(0, 2), 16)
      let g = parseInt(color.substring(2, 4), 16)
      let b = parseInt(color.substring(4, 6), 16)
      
      // Aumenta o brilho com uma fórmula melhorada para mais contraste
      const factor = 1 + (amount / 100)
      r = Math.min(255, Math.round(r * factor))
      g = Math.min(255, Math.round(g * factor))
      b = Math.min(255, Math.round(b * factor))
      
      // Converte de volta para hex
      const rHex = r.toString(16).padStart(2, '0')
      const gHex = g.toString(16).padStart(2, '0')
      const bHex = b.toString(16).padStart(2, '0')
      
      return `#${rHex}${gHex}${bHex}`
    }
  },
  filters: {
    money(value) {
      return Number(value).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    }
  }
}
</script>

<style scoped>
.wn-selected-category {
  border-radius: 16px !important;
  overflow: hidden;
}

.theme--dark.wn-selected-category {
  background-color: #1E1E1E !important;
}

.category-header {
  padding: 24px;
  transition: background-color 0.3s ease;
}

.theme--dark .category-header {
  background-color: #272727 !important;
}

.header-content {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
}

.category-title {
  flex: 1;
}

.category-title h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.theme--dark .category-title h2 {
  color: rgba(255, 255, 255, 0.87);
}

.current-category {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

.category-path {
  margin-bottom: 12px;
  padding-left: 8px;
  border-left: 2px solid rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.path-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
}

/* Inverte a ordem dos itens no flex container */
.category-path {
  display: flex;
  flex-direction: column-reverse;
}

/* Ajusta o ícone para apontar para a esquerda */
.path-icon {
  transform: rotate(180deg);
}

/* Tema escuro */
.theme--dark .category-path {
  border-left-color: rgba(255, 255, 255, 0.08);
}

.theme--dark .path-item {
  color: rgba(255, 255, 255, 0.6);
}

.parent-name {
  display: flex;
  padding: 8px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.04);
  width: 100%;
}

.theme--dark .parent-name {
  background: rgba(255, 255, 255, 0.04);
}

.parent-name:hover {
  background: rgba(0, 0, 0, 0.08);
}

.theme--dark .parent-name:hover {
  background: rgba(255, 255, 255, 0.08);
}

.name-container {
  margin-bottom: 4px;
}

.amount-info {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
}

.theme--dark .amount-info {
  color: rgba(255, 255, 255, 0.6);
}

.amount-total {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark .amount-total {
  color: rgba(255, 255, 255, 0.87);
}

.amount-percentage {
  margin-left: 4px;
  opacity: 0.7;
}

.current-category-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.current-amount-info {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark .current-amount-info {
  color: rgba(255, 255, 255, 0.87);
}

.current-amount-total {
  font-weight: 600;
  font-size: 1.1rem;
}

.current-amount-percentage {
  margin-left: 8px;
  opacity: 0.7;
}

.breadcrumb {
  margin-top: 8px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.breadcrumb-item {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.parent-name {
  transition: all 0.2s ease;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.parent-name:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.87);
}

.breadcrumb-separator {
  margin: 0 4px;
  opacity: 0.5;
  font-size: 18px !important;
}

.breadcrumb-item.current {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 600px) {
  .breadcrumb {
    font-size: 0.75rem;
  }
  
  .breadcrumb-separator {
    font-size: 16px !important;
  }
}

.action-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  /* margin-left: 60px; */
}

.category-content {
  padding: 24px;
}

.theme--dark .category-content {
  background-color: #1E1E1E;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

@media (max-width: 1264px) {
  .info-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 960px) {
  .info-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .info-grid {
    grid-template-columns: 1fr;
  }
}

.info-item {
  background: #f5f5f5;
  padding: 16px;
  border-radius: 12px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.theme--dark .info-item {
  background: #272727;
}

.info-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}

.theme--dark .info-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  background: #323232;
}

.info-label {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
}

.theme--dark .info-label {
  color: rgba(255, 255, 255, 0.6);
}

.info-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark .info-value {
  color: rgba(255, 255, 255, 0.87);
}

/* Animações */
.wn-selected-category {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Estilos dos botões de ação */
.action-button {
  transition: all 0.3s ease;
}

.action-button:hover {
  transform: scale(1.1);
}

.edit-button {
  background-color: rgba(72, 137, 242, 0.1) !important;
}

.edit-button:hover {
  background-color: rgba(72, 137, 242, 0.2) !important;
}

.edit-button .v-icon {
  color: #4889f2 !important;
}

.add-button {
  background-color: rgba(76, 175, 80, 0.1) !important;
}

.add-button:hover {
  background-color: rgba(76, 175, 80, 0.2) !important;
}

.add-button .v-icon {
  color: #4CAF50 !important;
}

.delete-button {
  background-color: rgba(244, 67, 54, 0.1) !important;
}

.delete-button:hover {
  background-color: rgba(244, 67, 54, 0.2) !important;
}

.delete-button .v-icon {
  color: #F44336 !important;
}

.delete-button:disabled {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.delete-button:disabled .v-icon {
  color: rgba(0, 0, 0, 0.26) !important;
}

.parent-name::before {
  content: '';
  position: absolute;
  left: -2px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: transparent;
  transition: background-color 0.2s ease;
}

.parent-name:hover::before {
  background: currentColor;
}

@media (max-width: 600px) {
  .category-path {
    margin-top: 8px;
  }

  .path-item {
    font-size: 0.75rem;
  }

  .path-icon {
    font-size: 16px !important;
  }
}

.level-badge {
  background: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.6);
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-right: 8px;
  font-weight: 500;
}

.theme--dark .level-badge {
  background: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.6);
}

.parent-name:hover .level-badge {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark .parent-name:hover .level-badge {
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
}

.distribution-chart {
  background: #f5f5f5;
  padding: 20px;
  border-radius: 12px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.theme--dark .distribution-chart {
  background: #272727;
}

.distribution-chart:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  background: #ffffff;
}

.theme--dark .distribution-chart:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  background: #323232;
}

.chart-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.chart-header h4 {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark .chart-header h4 {
  color: rgba(255, 255, 255, 0.87);
}
</style> 