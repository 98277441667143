<template>
  <div class="talks-tools">
    <v-container>
      <header class="talks-tools__header">
        <h1 class="talks-tools__title">Ferramentas de Atendimento</h1>
        <p class="talks-tools__description">
          Selecione uma das ferramentas disponíveis abaixo
        </p>
        --<v-icon>sell</v-icon>
      </header>

      <div class="tools-grid">
        <v-card class="tools-grid__item">
          <div class="tools-grid__item-icon tools-grid__item-icon--success">
            <svg class="tools-grid__item-svg" viewBox="0 0 24 24" width="28" height="28">
              <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"/>
            </svg>
          </div>
          <div class="tools-grid__item-content">
            <h2 class="tools-grid__item-title">Encerrar Atendimentos em Lote</h2>
            <p class="tools-grid__item-description">
              Encerre múltiplos atendimentos usando filtros personalizados. 
              <span class="tools-grid__item-warning">Ação irreversível.</span>
            </p>
            <v-btn
              color="success"
              class="tools-grid__item-button"
              small
              outlined
              @click="navigateToBatchClose"
            >
              Acessar
            </v-btn>
          </div>
        </v-card>

        <v-card class="tools-grid__item">
          <div class="tools-grid__item-icon tools-grid__item-icon--info">
            <svg class="tools-grid__item-svg" viewBox="0 0 24 24" width="28" height="28">
              <path fill="currentColor" d="M16,9V7H8V9H11V18H13V9H16M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z"/>
            </svg>
          </div>
          <div class="tools-grid__item-content">
            <h2 class="tools-grid__item-title">Transferir Atendimentos em Lote</h2>
            <p class="tools-grid__item-description">
              Transfira múltiplos atendimentos para um mesmo colaborador. 
              <span class="tools-grid__item-warning">Ação irreversível.</span>
            </p>
            <v-btn
              color="info"
              class="tools-grid__item-button"
              small
              outlined
              @click="navigateToBatchTransfer"
            >
              Acessar
            </v-btn>
          </div>
        </v-card>

        <v-card class="tools-grid__item">
          <div class="tools-grid__item-icon tools-grid__item-icon--error">
            <svg class="tools-grid__item-svg" viewBox="0 0 24 24" width="28" height="28">
              <path fill="currentColor" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
            </svg>
          </div>
          <div class="tools-grid__item-content">
            <h2 class="tools-grid__item-title">Cancelar Atendimentos em Lote</h2>
            <p class="tools-grid__item-description">
              Cancele múltiplos atendimentos usando filtros personalizados. 
              <span class="tools-grid__item-warning">Ação irreversível.</span>
            </p>
            <v-btn
              color="error"
              class="tools-grid__item-button"
              small
              outlined
              @click="navigateToBatchCancel"
            >
              Acessar
            </v-btn>
          </div>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'TalksTools',
  methods: {
    navigateToBatchClose() {
      console.log('Navegando para ferramenta de encerramento em lote')
    },
    navigateToBatchTransfer() {
      console.log('Navegando para ferramenta de transferência em lote')
    },
    navigateToBatchCancel() {
      console.log('Navegando para ferramenta de cancelamento em lote')
    }
  }
}
</script>

<style scoped>
.talks-tools {
  padding: 24px 0;
}

.talks-tools__header {
  text-align: center;
  margin-bottom: 40px;
}

.talks-tools__title {
  font-size: 28px;
  color: #2c3e50;
  margin-bottom: 8px;
  font-weight: 500;
}

.talks-tools__description {
  font-size: 16px;
  color: #666;
}

.tools-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  padding: 8px;
  max-width: 1200px;
  margin: 0 auto;
}

.tools-grid__item {
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #e0e0e0;
  background: #ffffff;
  text-align: center;
}

.tools-grid__item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
}

.tools-grid__item:has(.tools-grid__item-icon--success):hover {
  border-color: #4caf50;
}

.tools-grid__item:has(.tools-grid__item-icon--error):hover {
  border-color: #f44336;
}

.tools-grid__item:has(.tools-grid__item-icon--info):hover {
  border-color: #2196f3;
}

.tools-grid__item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
}

.tools-grid__item-icon--success {
  color: #4caf50;
  background: rgba(76, 175, 80, 0.08);
}

.tools-grid__item-icon--error {
  color: #f44336;
  background: rgba(244, 67, 54, 0.08);
}

.tools-grid__item-icon--info {
  color: #2196f3;
  background: rgba(33, 150, 243, 0.08);
}

.tools-grid__item-svg {
  transition: transform 0.2s ease;
}

.tools-grid__item:hover .tools-grid__item-svg {
  transform: scale(1.1);
}

.tools-grid__item-content {
  text-align: center;
  width: 100%;
}

.tools-grid__item-title {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.tools-grid__item-description {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.tools-grid__item-warning {
  color: #ff9800;
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-top: 4px;
}

.tools-grid__item-button {
  text-transform: none;
  min-width: 100px;
  letter-spacing: 0.5px;
  font-weight: 500;
  padding: 0 16px;
}

@media (max-width: 600px) {
  .tools-grid {
    grid-template-columns: 1fr;
  }
  
  .tools-grid__item {
    padding: 16px;
  }
}
</style>