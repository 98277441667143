<template>
  <div>
    <v-data-table
      v-model="selected"
      :dark="$wiDark"
      :select-all="config.config.fieldsSelected ? true : false"
      :headers="config.tableHeader"
      :items="config.tableItems"
      :loading="config.loading"
      flat
      class="elevation-0 wi-list-data-table" 
      :pagination.sync="pagination"
      :total-items="config.totalItems"
      no-results-text="Nenhum resultado encontrado"
      :rows-per-page-items="[5, 10, 50, 100, 200]" 
      rows-per-page-text="Itens por página"
      disable-initial-sort
      hide-actions
      :hide-headers="!config.totalItems"
    >
    <template v-if="selected.length > 0" v-slot:headers="props">
      <tr><th :colspan="config.tableHeader.length + 1"></th></tr>
      <div v-if="config.config.fieldsSelected">
        <v-toolbar class="selected_search_action" color="lightgray" flat absolute style="height: 56px; margin-top: 0px !important;">
          <div style="width: 50px !important; max-width: 50px !important;">
            <v-checkbox :input-value="props.all" :indeterminate="props.indeterminate" primary hide-details @click.stop="toggleAll" ></v-checkbox>
          </div>
          <span>
            <strong style="font-size: 18px;">{{selected.length}} </strong>
            <span v-html="config.config.fieldsSelected.itemName || ' itens '"></span>
            <span> selecionados</span>
          </span>
          <v-spacer></v-spacer>
          <wi-list-data-table-delete-selected :config="config"></wi-list-data-table-delete-selected>
          <slot v-if="selectedActionCustom" name="table-selected-action" :config="config" :selected="selected"></slot>
          <v-menu v-else-if="config.config.fieldsSelected.options" offset-y>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on" >
                Opções
              </v-btn>
            </template>
            <v-list>
              <v-list-tile>
                <v-list-tile-title>Selecionar todos ({{config.totalItems}})</v-list-tile-title>
              </v-list-tile>
              <v-list-tile v-for="(item, index) in config.config.fieldsSelected.options" :key="index" @click="item.action(selected)" >
                <v-list-tile-title>{{ item.text }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-toolbar>
      </div>
    </template>
    <v-progress-linear slot="progress" color="primary" :indeterminate="true"></v-progress-linear>
    <template slot="items" slot-scope="props">
      <slot v-if="rowCustom" name="table-row" :item="props.item" :props="props"></slot>
      <tr v-else>
        <td v-if="config.config.fieldsSelected" style="width: 50px !important; min-width: 50px !important;">
          <div style="width: 40px !important; max-width: 40px !important;">
            <v-checkbox v-model="props.selected" primary hide-details></v-checkbox>
          </div>
        </td>
        <!-- <td v-if="rowActions" class="justify-center layout px-0">
          <v-btn v-if="!config.config.config || (typeof config.config.config.edit == 'function' ? config.config.config.edit(props.item) : config.config.config.edit)" @click="config.openEditModal(config.store, props.item)" v-prms="{'id': config.config.id, mode: 'disabled'}" slot="active" icon class="mx-0">
            <v-icon>edit</v-icon>
          </v-btn>
          <db-delete v-if="!config.config.config || (typeof config.config.config.delete == 'function' ? config.config.config.delete(props.item) : config.config.config.delete)" v-prms="{'id': config.config.id, mode: 'disabled'}" class="mx-0" v-on:action="config.refresh" :store="config.store" :id="props.item.id"></db-delete>
        </td> -->
        <wi-list-data-table-actions v-if="rowActions" :config="config" :props="props"></wi-list-data-table-actions>
        <!-- eslint-disable -->
        <td :class="{'pointer': isDetail}" @click="detailAction(props.item)" v-for="(head, keyHeader) in config.tableHeader" :key="keyHeader" v-if="head.value && head.list">
          <span v-if="resolve(head.value, props.item)">
            <span v-if="head.mask">{{resolve(head.value, props.item) | mask(typeof head.mask == 'function' ? head.mask(props.item) : head.mask)}}</span>
            <span v-else v-html="resolve(head.value, props.item)"></span>
          </span>
          <span v-else-if="head.type == 'int'"> 0 </span>
          <span v-else> - </span>
        </td>
      </tr>
    </template>
    <template v-slot:expand="props">               
      <slot name="table-expand" :item="props.item" :props="props"></slot>          
    </template>
    <template slot="no-data">
      <div v-if="!config.loading" style="width: 100%; text-align: center; padding: 50px; font-size: 20px;">
        <div style="padding-bottom: 20px;">
          <div v-if="config.search.run">
            <span>Nenhuma informação encontrada para <strong v-if="config.search.query">"{{config.search.query}}"</strong>.</span>. 
            <br>Tente novamente ou adicione um novo registro.
          </div>
          <div v-else>
            <span v-if="config.config && config.config.noData && config.config.noData.text" v-html="config.config.noData.text"></span>
            <span v-else>Nenhuma informação encontrada <br> Tente novamente ou adicione um novo registro.</span>
          </div>
        </div>
        <v-btn @click="config.refresh()"><v-icon>refresh</v-icon> <span style="padding-left: 5px;">Buscar novamente</span></v-btn>
        <v-btn 
          v-prms="{'id': getIdPermission()}" 
          v-if="checkButtonConfig('create')" 
          @click="$WiEditDialog({wiConfig: config.store, onSubmit: config.refresh, data: config.wiEditData, redirectOnSubmit: false})"
          color="primary"
        >
          <v-icon>add</v-icon>
          <span> Adicionar Novo</span>
        </v-btn>
      </div>
      <div v-else center style="width: 100%; text-align:center; padding: 40px;">
        <br><br>
        <v-progress-circular indeterminate :size="70" :width="7" color="blue"></v-progress-circular>
        <h1>Carregando...</h1>
      </div>
    </template>
  </v-data-table>
</div>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  import WiListDataTableDeleteSelected from '@/default/component/WiList/WiListDataTableDeleteSelected'
  export default {
    data () {
      return {
        savePagination: {},
        orderCount: 0
      }
    },
    props: {
      'config': {
        type: Object,
        required: true
      },
      'rowCustom': Boolean,
      'rowActions': Boolean,
      'selectedActionCustom': Boolean,
      'setRoute': {
        type: Boolean,
        default: false
      }
    },
    computed: {
      selected: {
        get: function () {
          return this.config.selected
        },
        set: function (value) {
          this.config.setSelected(value)
        }
      },
      auth () {
        return this.$store.state.auth || {}
      },
      isDetail () {
        return (this.config.config.urlDetails && this.config.config.urlDetails.name) || false
      },
      pagination: {
        get: function () {
          return this.savePagination
        },
        set: function (newValue) {
          this.savePagination = newValue
          var order = newValue.descending ? ',desc' : ',asc'
          order = newValue.sortBy !== null ? newValue.sortBy + order : 'created_at,desc'
          this.config.setParams({page: 1, order: order}, this.orderCount)
          if (!this.orderCount) {
            this.config.setParams(this.config.config.apiParams, this.orderCount)
          }
          if (this.setRoute && this.orderCount) {
            this.$router.push({query: this.config.params})
          }
          this.orderCount++
        }
      }
    },
    methods: {
      selectAction: function (row) {
        if (this.select) {
          this.select(row)
        }
      },
      detailAction: function (row) {
        if (this.detail) {
          this.detail(row)
        } else {
          if (this.config.config && this.config.config.urlDetails && this.config.config.urlDetails.name) {
            var route = this.config.config.urlDetails
            if (route.params && route.params.id && typeof route.params.id === 'string') {
              route.params.id = row[route.params.id]
            } else {
              route.params.id = row.id
            }
            this.$router.push(route)
          }
        }
      },
      multipleAction: function () {
        if (this.selected) {
          this.multiple(this.selected)
        }
      },
      resolve: function (path, obj) {
        return path.split('.').reduce(function (prev, curr) {
          return prev ? prev[curr] : null
        }, obj || self)
      },
      hasSlot: function (slot) {
        return !!this.$slots[slot]
      },
      toggleAll () {
        if (this.selected.length) this.selected = []
        else this.selected = this.config.tableItems.slice()
      },
      getIdPermission: function () {
        if (this.config && this.config.config && this.config.config.id) {
          return this.config.config.id['create'] || this.config.config.id
        }
        return null
      },
      checkButtonConfig: function (action) {
        if (this.config.config.actions) {
          if (typeof this.config.config.actions[action] === 'function') {
            return this.config.config.actions[action](this.form)
          } else {
            return this.config.config.actions[action]
          }
        }
        return true
      }
    },
    components: {
      WiListDataTableActions,
      WiListDataTableDeleteSelected
    }
  }
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.v-datatable__actions {
  bottom: 0;
  position: fixed;
  width: 100%;
  left: 0;
  height: 40px;
}
.v-datatable__actions__select {
  height: 30px;
}
.v-datatable__actions__range-controls {
  min-height: 30px;
}
.v-datatable__actions .v-btn {
  height: 30px;
}
.selected_search_action .v-toolbar__content {
  height: 56px !important;
}
.v-datatable .v-input--selection-controls {
  max-width: 0;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 1 !important;
}
.wi-list-data-table >>> tr {
  margin: 50px !important;
}
</style>