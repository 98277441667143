var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list-tile-title',{staticClass:"talk-list-item-labels"},[_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('WNTag',_vm._g({attrs:{"text":_vm._f("stringlength")(_vm.channel.name,_vm.getMaxCharLength),"icon":_vm.labelsCount <= 2 ? _vm.channel.status.icon : null,"color":_vm.channel.color}},on))]}}])},[_c('span',[_c('span',{directives:[{name:"t",rawName:"v-t",value:('modules.talks.pages.talkView.talks.labels.channel'),expression:"'modules.talks.pages.talkView.talks.labels.channel'"}]}),_vm._v(": "),_c('span',[_vm._v(" "+_vm._s(_vm.channel.name))])])]),(_vm.team)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('WNTag',_vm._g({attrs:{"text":_vm._f("stringlength")(_vm.team.name,_vm.getMaxCharLength),"color":_vm.team.color,"icon":_vm.labelsCount <= 2 ? 'groups' : null}},on))]}}],null,false,62834445)},[_c('span',[_c('span',{directives:[{name:"t",rawName:"v-t",value:('modules.talks.pages.talkView.talks.labels.team'),expression:"'modules.talks.pages.talkView.talks.labels.team'"}]}),_vm._v(": "),_c('span',[_vm._v(" "+_vm._s(_vm.team.name))])])]):_vm._e(),(_vm.stage)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('WNTag',_vm._g({attrs:{"text":_vm._f("stringlength")(_vm.stage.name,_vm.getMaxCharLength),"color":_vm.stage.color,"icon":_vm.labelsCount <= 2 ? _vm.stage.icon : null}},on))]}}],null,false,1140362368)},[_c('span',[_c('span',[_vm._v("Etapa:")]),_c('span',[_vm._v(" "+_vm._s(_vm.stage.name))])])]):_vm._e(),(_vm.category)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('WNTag',_vm._g({attrs:{"text":_vm._f("stringlength")(_vm.category.name,_vm.getMaxCharLength),"color":_vm.category.color,"icon":_vm.labelsCount <= 2 ? _vm.category.icon : null}},on))]}}],null,false,4212065428)},[_c('span',[_c('span',[_vm._v("Categoria: ")]),_c('span',[_vm._v(" "+_vm._s(_vm.category.name))])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }