var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"talk-campaign-talk",on:{"click":function($event){return _vm.$emit('onTalkAction', _vm.talk)}}},[_c('div',{staticClass:"talk-campaign-talk__header"},[_c('div',{staticClass:"talk-campaign-talk__avatar"},[_c('v-avatar',{attrs:{"size":"40"}},[(_vm.talk.contact.file && _vm.talk.contact.file.url)?_c('img',{attrs:{"src":_vm.talk.contact.file.url,"alt":_vm.talk.contact.name}}):(_vm.talk.contact.type == 2)?_c('img',{attrs:{"src":"static/icons/user_icon_1.png","alt":""}}):_c('img',{attrs:{"src":"static/icons/user_icon_1.png","alt":""}})])],1),_c('div',{staticClass:"talk-campaign-talk__content"},[_c('div',{staticClass:"talk-campaign-talk__contact-info"},[_c('div',{staticClass:"talk-campaign-talk__name-container"},[_c('h3',{staticClass:"talk-campaign-talk__contact-name"},[_vm._v(" "+_vm._s(_vm.talk.contact.name || 'Sem nome')+" ")]),(_vm.talk.contact.checked == 2)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"talk-campaign-talk__check-icon",attrs:{"color":"green","small":""}},on),[_vm._v(" verified ")])]}}],null,false,393633567)},[_c('span',[_vm._v("Contato validado")])]):_vm._e(),(_vm.talk.contact.checked == 3)?_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"talk-campaign-talk__check-icon",attrs:{"color":"red","small":""}},on),[_vm._v(" do_not_disturb_on ")])]}}],null,false,552936350)},[_c('span',[_vm._v("Contato inválido")])]):_vm._e()],1),_c('span',{staticClass:"talk-campaign-talk__time"},[_vm._v(_vm._s(_vm.formatDate(_vm.talk.created_at)))])]),_c('div',{staticClass:"talk-campaign-talk__details"},[_c('div',{staticClass:"talk-campaign-talk__phone"},[_vm._v(" "+_vm._s(_vm.formatPhone(_vm.talk.contact.number))+" ")]),_c('div',{staticClass:"talk-campaign-talk__tag",style:({ 
            backgroundColor: _vm.getTagColor + (_vm.$wiDark ? '40' : '30'),
            color: _vm.getTagColor,
            filter: _vm.$wiDark ? 'brightness(1)' : 'brightness(0.7)'
          })},[(_vm.talk.channel.status.icon)?_c('v-icon',{attrs:{"size":"14","color":_vm.getTagColor}},[_vm._v(" "+_vm._s(_vm.talk.channel.status.icon)+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.talk.channel.name))])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }