var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"business-card-actions"},[_c('v-divider',{staticClass:"business-card-actions__divider"}),_c('v-card-text',{staticClass:"business-card-actions__content pa-0"},[_c('v-card-actions',{staticClass:"business-card-actions__buttons"},[_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"business-card-actions__button",attrs:{"icon":"","flat":""},on:{"click":_vm.openEditDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}])},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"business-card-actions__button",attrs:{"icon":"","flat":""},on:{"click":_vm.openEventsDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("timeline")])],1)]}}])},[_c('span',[_vm._v("Histórico de Eventos")])]),_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"business-card-actions__button",attrs:{"icon":"","flat":""},on:{"click":_vm.openFilesDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("attachment")])],1)]}}])},[_c('span',[_vm._v("Arquivos Anexados")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","lazy":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"prms",rawName:"v-prms",value:({ 'id': 'f55197d7' }),expression:"{ 'id': 'f55197d7' }"}],staticClass:"business-card-actions__button business-card-actions__button--chat",attrs:{"icon":"","flat":""},on:{"click":function($event){return _vm.$emit('open:talk', _vm.item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("message")])],1)]}}])},[_c('span',[_vm._v("Abrir Chat")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }