<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable max-width="900">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="primary">
          {{ buttonText }}
        </v-btn>
      </template>
      <v-card>
        <v-card-text style="padding: 0px;">
          <v-stepper v-model="e1" small dense>
            <v-toolbar color="transparent" class="wn-sheet-import-from-sheet__toolbar">

              <v-stepper-header small dense style="width: 100%;">
                <v-stepper-step :complete="e1 > 1" step="1">
                  Configuração
                </v-stepper-step>

                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2">
                  Upload da planilha
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3">
                  Definir Campos 
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="4">
                  Confirmar Dados
                </v-stepper-step>
              </v-stepper-header>

            </v-toolbar>

            <v-stepper-items style="max-height: calc(100vh - 150px); overflow-y: auto;">
              <v-stepper-content step="1" class="wn-import-from-sheet__step-content-2">
                <WNSheetConfiguration 
                  v-if="e1 === 1"
                  :config="config"
                  @continue="handleConfigurationContinue"
                  @cancel="handleCancel"
                />
              </v-stepper-content>
              
              <v-stepper-content step="2" class="wn-import-from-sheet__step-content-2">
                <WNUploadFile 
                  v-if="e1 === 2"
                  :config="config"
                  @back="handleBack"
                  @continue="handleFileProcessed"
                  @cancel="handleCancel"
                />
              </v-stepper-content>

              <v-stepper-content step="3" class="wn-import-from-sheet__step-content-2">
                <WNSheetFromTo 
                  v-if="processedData && processedData.headers.length > 0 && e1 === 3"
                  :config="config"
                  :processed-data="processedData"
                  @back="handleBack"
                  @continue="handleFromToContinue"
                  @cancel="handleCancel"

                />
              </v-stepper-content>

              <v-stepper-content step="4" class="wn-import-from-sheet__step-content-3">
                <WNSheetDateValidate
                  v-if="
                    fromToData &&
                    fromToData.headers.length > 0 &&
                    fromToData.data.length > 0
                    && e1 === 4
                  "
                  :config="config"
                  :mapped-data="fromToData"
                  @back="handleBack"
                  @cancel="handleCancel"
                  @continue="dataValidateContinue"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Novo dialog para progresso do envio -->
    <v-dialog v-model="uploadProgress.show" persistent max-width="500">
      <v-card class="progress-dialog">
        <v-card-title class="headline pb-2">
          <v-icon large left color="primary" class="mr-3">cloud_upload</v-icon>
          Enviando contatos
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pt-4">
          <div class="d-flex align-center mb-3">
            <v-icon color="primary" class="mr-2 rotating-icon">sync</v-icon>
            <span class="subtitle-1">
              Processando lote {{ uploadProgress.currentBatch }} de {{ uploadProgress.totalBatches }}
            </span>
          </div>

          <v-progress-linear
            :value="uploadProgress.progress"
            color="primary"
            height="20"
            striped
            class="progress-bar"
          >
            <template v-slot:default>
              <div class="progress-text">
                <strong>{{ uploadProgress.progress.toFixed(1) }}%</strong>
              </div>
            </template>
          </v-progress-linear>

          <div class="status-message mt-4">
            <div class="status-step mb-4">
              <div class="d-flex align-center">
                <span class="text-body-1">
                  <strong>Fase 1:</strong> Upload em andamento
                </span>
                
                <v-icon color="primary" class="mr-2">computer</v-icon>
                <v-icon color="primary" class="mx-2 flying-icon">forward</v-icon>
                <v-icon color="primary" class="mr-3">cloud</v-icon>
              </div>
              <p class="text-caption grey--text mt-1 ml-8">
                Seus arquivos estão voando do seu computador para nossas nuvens! 🚀
              </p>
            </div>

            <div class="status-step">
              <div class="d-flex align-left">
                <span class="text-body-1 grey--text">
                  <strong>Fase 2:</strong> Processamento
                </span>
              </div>
              <p class="text-caption grey--text mt-1 ml-8">
                Em seguida, nossos robôs trabalharão arduamente para processar seus dados. ⚙️
                <br>Você poderá acompanhar todo o processo na página de importações. 🔍
              </p>
            </div>
          </div>

          <v-slide-y-transition>
            <div v-if="uploadProgress.failedItems.length > 0" class="mt-4 error-section">
              <v-alert
                :value="true"
                color="error"
                icon="warning"
                outline
              >
                <div class="d-flex align-center justify-space-between flex-wrap">
                  <div class="mr-3">
                    <strong>{{ uploadProgress.failedItems.length }}</strong> itens falharam durante o envio
                  </div>
                  <v-btn
                    small
                    color="error"
                    outline
                    @click="downloadFailedItems"
                    class="mt-2 mt-sm-0 download-btn"
                  >
                    <v-icon left small>file_download</v-icon>
                    Baixar itens com falha
                  </v-btn>
                </div>
              </v-alert>
            </div>
          </v-slide-y-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de confirmação -->
    <v-dialog v-model="confirmDialog.show" max-width="400">
      <v-card>
        <v-card-title class="headline">
          Confirmar importação
        </v-card-title>
        
        <v-card-text>
          <p>Tem certeza que deseja importar {{ confirmDialog.itemCount }} contatos?</p>
          <p class="subtitle-2 grey--text">
            Esta ação pode levar alguns minutos para ser concluída e não pode ser desfeita.
          </p>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            flat
            @click="confirmDialog.show = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="confirmAndProceed"
          >
            Confirmar importação
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import WNUploadFile from './components/WNUploadFile.vue'
import WNSheetFromTo from './components/WNSheetFromTo.vue'
import WNSheetDateValidate from './components/WNSheetDateValidate.vue'
import WNSheetConfiguration from './components/WNSheetConfiguration.vue'

export default {
  name: 'WNImportFromSheet',
  data: () => ({
    e1: 1,
    dialog: false,
    fromToData: null,
    processedData: null,
    configurationData: null,
    uploadProgress: {
      show: false,
      progress: 0,
      currentBatch: 0,
      totalBatches: 0,
      failedItems: []
    },
    confirmDialog: {
      show: false,
      itemCount: 0,
      resolveCallback: null
    }
  }),
  methods: {
    handleConfigurationContinue (data) {
      this.configurationData = data
      this.e1 = 2
    },
    handleFileProcessed(data) {
      this.processedData = data
      this.e1 = 3
    },
    handleFromToContinue(data) {
      this.fromToData = data
      this.e1 = 4
    },
    handleCancel() {
      this.dialog = false
      this.processedData = null
      this.fromToData = null
      this.configurationData = null
      this.e1 = 1
    },
    handleDone(import_id) {
      this.dialog = false
      this.processedData = null
      this.fromToData = null
      this.configurationData = null
      this.e1 = 1
      this.$emit('done', { import_id })
    },
    handleBack() {
      this.e1 = this.e1 - 1
    },
    async dataValidateContinue(data) {
      // Mostra diálogo de confirmação e aguarda resposta
      const confirmed = await this.showConfirmDialog(data.length);
      if (!confirmed) return;

      const BATCH_SIZE = 500;
      const batches = this.splitIntoBatches(data, BATCH_SIZE);
      
      this.uploadProgress = {
        show: true,
        progress: 0,
        currentBatch: 0,
        totalBatches: batches.length,
        failedItems: []
      };

      try {
        // Envia primeiro lote
        const firstBatchResult = await this.sendInitialBatch(batches[0]);
        const importId = firstBatchResult?.data?.id;

        if (!importId) {
          throw new Error('Falha ao iniciar importação');
        }

        // Envia lotes restantes
        for (let i = 1; i < batches.length; i++) {
          this.uploadProgress.currentBatch = i + 1;
          this.uploadProgress.progress = (i / batches.length) * 100;

          try {
            await this.sendBatch(importId, batches[i]);
          } catch (error) {
            this.uploadProgress.failedItems.push(...batches[i]);
            console.error(`Erro no lote ${i + 1}:`, error);
          }
        }

        this.uploadProgress.progress = 100;
        
        if (this.uploadProgress.failedItems.length === 0) {
          this.handleDone(importId);
        } else {
          alert(`Importação parcialmente concluída. ${this.uploadProgress.failedItems.length} itens falharam durante o envio.`);
        }
      } catch (error) {
        alert('Erro ao processar importação. Tente novamente ou contacte o suporte.');
      } finally {
        setTimeout(() => {
          this.uploadProgress.show = false;
        }, 1500);
      }
    },

    splitIntoBatches(items, size) {
      const batches = [];
      for (let i = 0; i < items.length; i += size) {
        batches.push(items.slice(i, i + size));
      }
      return batches;
    },

    async sendInitialBatch(items) {
      return new Promise((resolve, reject) => {
        this.$WiApiPost({
          uri: 'import/items',
          data: {
            name: this.configurationData?.name || 'Importação de contatos',
            type_id: this.configurationData?.type_id || 'contacts',
            items: items
          },
          callback: ({ sucess, error }) => {
            if (sucess) {
              resolve(sucess);
            } else {
              reject(error);
            }
          }
        });
      });
    },

    async sendBatch(importId, items) {
      return new Promise((resolve, reject) => {
        this.$WiApiPost({
          uri: `import/items/${importId}`,
          data: {
            items: items
          },
          callback: ({ sucess, error }) => {
            if (sucess) {
              resolve(sucess);
            } else {
              reject(error);
            }
          }
        });
      });
    },

    downloadFailedItems() {
      const csvContent = this.convertToCSV(this.uploadProgress.failedItems);
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      
      link.setAttribute('href', url);
      link.setAttribute('download', 'itens_com_falha.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    convertToCSV(items) {
      if (!items.length) return '';
      
      const header = Object.keys(items[0]).join(',');
      const rows = items.map(item => 
        Object.values(item).map(value => 
          typeof value === 'string' ? `"${value}"` : value
        ).join(',')
      );
      
      return [header, ...rows].join('\n');
    },

    showConfirmDialog(itemCount) {
      return new Promise(resolve => {
        this.confirmDialog = {
          show: true,
          itemCount,
          resolveCallback: resolve
        };
      });
    },

    confirmAndProceed() {
      this.confirmDialog.show = false;
      if (this.confirmDialog.resolveCallback) {
        this.confirmDialog.resolveCallback(true);
      }
    }
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  components: {
    WNUploadFile,
    WNSheetFromTo,
    WNSheetDateValidate,
    WNSheetConfiguration
  }
}
</script>

<style scoped>
.sheet-header {
  margin-bottom: 24px;
}

.wn-sheet-import-from-sheet__toolbar {
  width: 100% !important;
  z-index: 1 !important;
  padding: 0px !important;
}

.wn-sheet-import-from-sheet__toolbar >>> .v-toolbar__content {
  width: 100% !important;
  padding: 0px !important;
  height: 71px !important;
}

.sheet-header h2 {
  margin-bottom: 8px;
  font-size: 20px;
  color: #333;
}

.theme--dark .sheet-header h2 {
  color: #fff;
}

.description {
  color: #666;
  font-size: 14px;
}

.theme--dark .description {
  color: #aaa;
}

.wn-import-from-sheet__step-content-2 {
  padding: 0 !important;
}

.wn-import-from-sheet__step-content-3 {
  padding: 0 !important;
}

.progress-dialog {
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.progress-bar {
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
}

.progress-text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  mix-blend-mode: difference; /* Garante que o texto seja visível independente da cor do fundo */
}

.rotating-icon {
  animation: rotate 2s linear infinite;
}

.rotating-slow {
  animation: rotate 8s linear infinite;
}

.flying-icon {
  animation: fly 2s ease-in-out infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes fly {
  0% { transform: translateX(0); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.status-message {
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.status-step {
  padding: 12px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.status-step:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.theme--dark .status-message {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
}

.theme--dark .status-step:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.theme--dark .progress-dialog {
  background-color: #1E1E1E;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.theme--light .progress-dialog {
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.download-btn {
  transition: all 0.3s ease;
}

.download-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.error-section {
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.theme--dark .error-section {
  border-color: rgba(255, 255, 255, 0.12);
}

.theme--light .error-section {
  border-color: rgba(0, 0, 0, 0.12);
}

.headline {
  font-size: 20px !important;
  font-weight: 500;
  letter-spacing: 0.0125em;
}

.subtitle-1 {
  font-size: 16px;
  letter-spacing: 0.009375em;
}

.text-body-1 {
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.03125em;
}

.text-caption {
  font-size: 13px;
  line-height: 1.4;
  letter-spacing: 0.0333333333em;
}
</style>