<template>
  <v-dialog v-model="menu" width="500">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" outline small>
        <span class="pr-2 hidden-xs-only hidden-sm-only">Data inicial: </span>
        <strong class="pr-2">{{ formattedStartDate }}</strong>
        <span class="pr-2 hidden-xs-only hidden-sm-only">Data final: </span>
        <strong>{{ formattedEndDate }}</strong>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dense flat color="transparent">
        <v-toolbar-title>Insira uma data inicial e final.</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="menu = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          dense
          outline
          v-model="startDate"
          type="datetime-local"
          label="Data Inicial"
          :rules="[v => !!v || 'Data inicial é obrigatória']"
        ></v-text-field>
        <br>
        <v-text-field
          dense
          outline
          v-model="endDate"
          type="datetime-local"
          label="Data Final"
          :rules="[
            v => !!v || 'Data final é obrigatória',
            v => !startDate || !v || new Date(v) >= new Date(startDate) || 'Data final deve ser maior que a data inicial'
          ]"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn block outline color="green" @click="applyFilter()">
          <v-icon>filter_list</v-icon>
          <span>filtrar</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DateRangePicker',
  props: {
    value: {
      type: Object,
      default: function() {
        return {
          start: null,
          end: null
        };
      }
    }
  },
  data: function() {
    return {
      menu: false,
      startDate: this.value.start || null,
      endDate: this.value.end || null
    };
  },
  computed: {
    formattedStartDate: function() {
      if (!this.startDate) return '--/--';
      return this.$options.filters.moment(this.startDate, 'DD/MM/YY');
    },
    formattedEndDate: function() {
      if (!this.endDate) return '--/--';
      return this.$options.filters.moment(this.endDate, 'DD/MM/YY');
    }
  },
  methods: {
    applyFilter: function() {
      if (!this.startDate || !this.endDate) {
        return;
      }

      if (new Date(this.endDate) < new Date(this.startDate)) {
        return;
      }

      this.$emit('input', {
        start: this.startDate,
        end: this.endDate
      });
      this.$emit('change');
      this.menu = false;
    }
  },
  watch: {
    value: {
      handler: function(newVal) {
        this.startDate = newVal.start;
        this.endDate = newVal.end;
      },
      deep: true
    }
  }
};
</script>

<style>
.date-picker__btn {
  border-radius: 16px !important;
  height: 22px !important;
  padding: 0 8px !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
  color: white !important;
  transition: all 0.2s ease;
  min-width: auto !important;
  letter-spacing: 0 !important;
  margin-right: 4px !important;
}

.date-picker__btn:hover {
  background-color: rgba(255, 255, 255, 0.2) !important;
  transform: translateY(-1px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.date-picker__icon {
  margin-right: 4px;
  opacity: 0.9;
}

.date-picker__range {
  display: flex;
  align-items: center;
}

.date-picker__date {
  font-size: 10px;
  font-weight: 500;
}

.date-picker__arrow {
  margin: 0 2px;
  opacity: 0.7;
  font-size: 10px !important;
}

.date-picker__card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.theme--dark .date-picker__card {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3) !important;
  background-color: #1e1e1e !important;
}

.date-picker__card-title {
  padding: 12px 16px;
  font-size: 14px;
}

.date-picker__card-icon {
  margin-right: 8px;
}

.date-picker__card-content {
  padding: 16px;
}

.date-picker__input {
  margin-bottom: 0;
}

.date-picker__input + .date-picker__input {
  margin-top: 16px;
}

.date-picker__actions {
  padding: 8px 16px;
}

.date-picker__apply-btn {
  min-width: 90px;
  text-transform: none;
  letter-spacing: 0;
}

.date-picker__cancel-btn {
  text-transform: none;
  letter-spacing: 0;
}

/* Tema escuro */
.theme--dark .date-picker__btn {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.theme--dark .date-picker__btn:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

@media (max-width: 600px) {
  .date-picker__btn {
    height: 20px !important;
    padding: 0 6px !important;
  }
  
  .date-picker__date {
    font-size: 9px;
  }
  
  .date-picker__icon {
    margin-right: 2px;
  }
}
</style> 