<template>
  <WNKanban 
    title="Etapas de Atendimento" 
    store-stage="talk-stage"
    api-get-url="talks/stage-cluster/1/talks" 
    api-put-url="talks/talk"
    field-reference-in-item="talk_stage_id"
    message-alert-title="Mover atendimento para esta etapa?"
    message-alert-text="A etapa selecionada será a etapa atual do atendimento."
    :router-click="goRouter()"
    :show-amount-sum="false"
    :hideReorderButton="true"
    :hideAddButton="true"
    :can-move-item="() => true"
    :onStageChange="onStageChange"
    :onPaginate="onPaginate"
    :onReorder="onReorder"
    :order-options="orderOptions"
    @order-change="handleOrderChange"
    default-order="last_event_at,desc"
    :show-stage-action="true"
    stage-action-icon="more_vert"
    :stage-menu-items="stageMenuItems"
    @stage-menu-action="handleStageMenuAction"
    @empty-stages-action="handleEmptyStagesAction"
    :reorder-cards="reorderCards"
    :subscribe-on-cards="subscribeOnCards"
  >
    <template slot="items" slot-scope="{ item }">
      <TalkCard :talk-data="item" @click="onCardClick(item)" />
    </template>
  </WNKanban>
</template>

<script>
// import { Auth } from '@/default/service/Auth'
import WNKanban from '@/default/component/WNKanban/WNKanban'
import talkViewMixin from '@/modules/talk/pages/TalkView/talkViewMixin'
import { callApi } from '@/default/service/Api'
import TalkCard from './components/TalkCard.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'BusinessOfProcess',
  components: {
    WNKanban,
    TalkCard
  },
  mixins: [talkViewMixin],
  
  data() {
    return {
      orderOptions: [
        {
          value: 'last_event_at,desc',
          label: 'Última interação (Mais recente)',
          icon: 'event'
        },
        {
          value: 'last_event_at,asc',
          label: 'Última interação (Mais antigo)',
          icon: 'event'
        },
        {
          value: 'created_at,desc',
          label: 'Data de início (Mais recente)',
          icon: 'add_circle_outline'
        },
        {
          value: 'created_at,asc',
          label: 'Data de início (Mais antigo)',
          icon: 'add_circle_outline'
        }
      ],
      stageMenuItems: [
        {
          type: 'subheader',
          title: 'Ações em Massa (Indisponíveis no momento)'
        },
        {
          id: 'send-message',
          title: 'Enviar mensagem para todos',
          icon: 'message',
          color: 'blue',
          disabled: () => true
        },
        {
          id: 'complete-all',
          title: 'Concluir todos desta etapa',
          icon: 'check_circle',
          color: 'green',
          disabled: () => true
        },
        {
          id: 'cancel-all',
          title: 'Cancelar todos desta etapa',
          icon: 'cancel',
          color: 'orange',
          disabled: () => true
        },
        {
          id: 'transfer-all',
          title: 'Transferir todos para um atendente',
          icon: 'person_add',
          color: 'indigo',
          disabled: () => true
        },
        {
          type: 'subheader',
          title: 'Gerenciar Etapa'
        },
        {
          id: 'edit',
          title: 'Editar',
          icon: 'edit',
          color: 'primary',
          disabled: () => false
        },
        // {
        //   id: 'delete',
        //   title: 'Excluir',
        //   icon: 'delete',
        //   color: 'red',
        //   disabled: (stage) => stage.items.total > 0
        // }
      ],
      setTalkChanged: null
    }
  },

  computed: {
    ...mapGetters('Talks', ['allTalks'])
  },
  
  methods: {
    ...mapActions('Talks', ['addTalksSubscriber', 'removeTalksSubscriber']),
    goRouter() {
      return {}
    },
    
    listTalkContactsByPerson(business) {
      this.$WiListDialog({
        wiConfig: 'talk-contact-all',
        mode: 'select',
        callback: (contact) => {
          this.openTalkContact(contact)
        },
        filter: {
          person_id: business.person_id,
          order: 'talk_contacts.last_event_at,desc;talk_contacts.name,asc'
        }
      })
    },

    onStageChange(data) {
      let updateData = {
        id: data.id,
        talk_stage_id: data.stage.id
      }
      if (data.stage.id === 'closed') {
        updateData.talk_stage_id = null
        updateData.status = 3
      }
      if (data.stage.id === 'canceled') {
        updateData.talk_stage_id = null
        updateData.status = 4
      }
      return new Promise((resolve) => {
        callApi.put({
          uri: 'talks/talk',
          id: data.id,
          data: updateData,
          sucess: (response) => {
            if (response.status === 201) {
              resolve({
                success: true
              })
            } else {
              resolve({
                success: false,
                error: {
                  message: 'Não foi possível atualizar o estágio do atendimento'
                }
              })
            }
          },
          error: (error) => {
            console.error('Erro ao atualizar estágio:', error)
            resolve({
              success: false,
              error: {
                message: 'Erro ao tentar atualizar o estágio do atendimento',
                details: error
              }
            })
          }
        })
      })
    },

    onPaginate(data) {
      return new Promise((resolve) => {
        this.$WiApiGet({
          uri: 'talks/talk',
          params: {
            status: '1,2',
            page: data.nextPage,
            limit: data.params.limit,
            ...data.params,
            order: 'last_event_at,desc',
            talk_stage_id: data.stageId
          },
          callback: response => {
            console.log(response)
            if (response.success) {
              resolve({
                success: true,
                data: {
                  items: response.success.data,
                  total: response.success.total,
                  currentPage: response.success.current_page,
                  lastPage: response.success.last_page
                }
              })
            } else {
              resolve({
                success: false,
                error: {
                  message: 'Erro ao carregar mais itens',
                  details: response.error
                }
              })
            }
          }
        })
      })
    },

    onReorder() {
      return new Promise((resolve) => {
        resolve({
          success: true,
          error: {
            message: 'A reordenação de itens MANUAL ainda não está disponível. Utilize o botão de reordenação de colunas.'
          }
        })
      })
    },

    handleOrderChange(orderValue) {
      console.log('Nova ordenação:', orderValue)
      // Implementar lógica específica de ordenação
    },

    handleStageMenuAction({ menuItemId, stage }) {
      switch (menuItemId) {
        case 'edit':
          this.$WiEditDialog({
            wiConfig: 'talk-stage',
            data: stage
          })
          break
        case 'delete':
          this.$swal({
            title: 'Atenção',
            text: 'Tem certeza que deseja excluir esta etapa?',
            icon: 'warning',
            showCancelButton: true, 
            confirmButtonText: 'Sim, excluir',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              this.$WiApiDelete({
                uri: 'talks/talk',
                id: stage.id,
                callback: () => {
                  this.$emit('refresh')
                }
              })
            } 
          })
          break
      }
    },

    onCardClick(talkData) {
      this.$router.push({
        name: 'talk-view',
        params: { id: talkData.id }
      })
    },

    handleEmptyStagesAction() {
      this.$WiEditDialog({
        wiConfig: 'talk-stage',
        data: {}
      })
    },

    reorderCards(cards, order) {
      if (!cards || !cards.length || !order) return cards

      const [field, direction] = order.split(',')
      
      return [...cards].sort((a, b) => {
        // Converte as datas para timestamps para comparação
        const dateA = new Date(a[field]).getTime()
        const dateB = new Date(b[field]).getTime()
        
        // Ordena de acordo com a direção (asc ou desc)
        if (direction === 'desc') {
          return dateB - dateA
        } else {
          return dateA - dateB
        }
      })
    },

    subscribeOnCards(callback) {
      this.setTalkChanged = callback
    },
    onTalksChanged ({ talk }) {
      console.log('talk--talk-stages-kanban', talk)
      if (talk) {
        this.setTalkChanged(talk)
      }
    }
  },
  mounted() {
    this.addTalksSubscriber({ key: 'talk-stages-kanban', callback: this.onTalksChanged })
  },
  beforeDestroy() {
    this.removeTalksSubscriber({ key: 'talk-stages-kanban' })
  }
}
</script>