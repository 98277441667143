var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"category-tree-card",attrs:{"elevation":"10"}},[_c('v-card-text',{staticClass:"tree-content"},[_c('div',{staticClass:"search-box",staticStyle:{"display":"none"}},[_c('v-text-field',{staticClass:"search-field",attrs:{"value":_vm.search,"dense":"","outlined":"","hide-details":"","placeholder":"Pesquisar categorias...","clearable":""},on:{"input":_vm.debounceSearch,"click:clear":_vm.clearSearch},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"search-icon",attrs:{"color":"grey darken-2"}},[_vm._v("search")])]},proxy:true},(_vm.search)?{key:"append",fn:function(){return [_c('div',{staticClass:"search-results"},[_c('v-chip',{staticClass:"result-chip",attrs:{"x-small":"","label":"","color":"primary","text-color":"white"}},[_vm._v(" "+_vm._s(_vm.filteredItems.length)+" ")]),_c('span',{staticClass:"result-text"},[_vm._v("resultado(s)")])],1)]},proxy:true}:null],null,true)})],1),_c('v-treeview',{ref:"treeview",staticClass:"custom-tree",attrs:{"items":_vm.filteredItems,"item-key":"id","item-text":"name","item-children":"children","open-on-click":"","hoverable":"","transition":"","shaped":"","dense":"","open-all":false},on:{"update:active":_vm.handleSelect},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"tree-icon",class:{ 'selected': _vm.isSelected(item) },attrs:{"color":item.color},on:{"click":function($event){return _vm.handleSelect([item.id])}}},[_vm._v(" "+_vm._s(item.icon)+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tree-label",class:{ 
            'has-children': item.children_count > 0,
            'selected': _vm.isSelected(item)
          },on:{"click":function($event){return _vm.handleSelect([item.id])}}},[_c('div',{staticClass:"label-content"},[(item.highlightedName)?_c('span',{domProps:{"innerHTML":_vm._s(item.highlightedName)}}):_c('span',[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"label-info"},[(item.amount_total_paid !== null)?_c('v-chip',{staticClass:"count-chip",attrs:{"x-small":"","color":"green","text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("money")(item.amount_total_paid))+" ")]):_vm._e(),(item.amount_total_unpaid !== null)?_c('v-chip',{staticClass:"count-chip",attrs:{"x-small":"","color":"orange","text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("money")(item.amount_total_unpaid))+" ")]):_vm._e(),(item.amount_total !== null)?_c('v-chip',{staticClass:"count-chip",attrs:{"x-small":"","color":"blue","text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("money")(item.amount_total))+" ")]):_vm._e()],1)])])]}}]),model:{value:(_vm.activeItems),callback:function ($$v) {_vm.activeItems=$$v},expression:"activeItems"}}),(_vm.search && _vm.filteredItems.length === 0)?_c('div',{staticClass:"no-results"},[_c('v-icon',{attrs:{"large":"","color":"grey lighten-1"}},[_vm._v("search_off")]),_c('p',[_vm._v("Nenhuma categoria encontrada para \""+_vm._s(_vm.search)+"\"")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }