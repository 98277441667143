<template>
  <div>
    <v-dialog
      v-model="action" :dark="$wiDark"
      @keydown.esc="closeEdit()"
      return-value="dialog" persistent scrollable
      :max-width="config.maxWidth || defaultMaxWidth"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card style="border-radius: 5px;">
        <v-toolbar dense style="border-radius: 5px;">
          <v-btn v-if="add && form && form.id && (!config.config || config.config.new)" fab small color="success" bottom left absolute @click.native.stop="newForm()">
            <v-icon>add</v-icon>
          </v-btn>
          <v-icon large v-if="config.icon">{{config.icon && config.icon.edit ? (typeof config.icon.edit == 'object' ? config.icon.edit[form.id ? 'update' : (config.icon.edit['insert'] || config.icon.edit['create'])] : config.icon.edit) : config.icon}}</v-icon>
          <v-toolbar-title>
            <strong v-if="config.title">{{config.title && config.title.edit ? (typeof config.title.edit == 'object' ? config.title.edit[form.id ? 'update' : (config.title.edit['insert'] ? 'insert' : 'create')] : config.title.edit) : config.title}}</strong>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeEdit()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- <div v-if="action && !form.id" style="display: none;">{{$refs.wiEditDialog)}}</div> -->
          <v-container fluid style="padding: 0px !important; ">
            <div v-if="description" style="width: 100%; text-align: center;">
              <span v-html="description"></span>
            </div>
            <v-form v-model="valid" ref="wiEditDialog" autocomplete="off">
              <v-layout v-if="action && store" row wrap>
                <!-- <code>{{config.config}}</code>-->
                <!-- <code>{{form}}</code>  -->
                <!-- eslint-disable -->
                <v-flex v-for="(field, keyField) in config.fields.edit || config.fields" :key="keyField" v-if="viewField(field) && (!field.showField || field.showField(form, auth))" v-bind="sizeField(field.size)" d-flex style="padding: 0 10px 0 10px;">
                  <div :style="{maxWidth: field.button ? '80% !important' : '100% !important'}">
                    <div v-if="field.type === 'divider'" style="width: 100%; padding: 0 0 20px 0;">
                      <v-divider></v-divider>
                    </div>
                    <div v-if="field.type === 'subheader'" class="hidden-xs-only hidden-sm-only">
                      <v-subheader style="margin-bottom: 20px; height: 30px !important; border-radius: 5px;" :style="{ background: $wiDark ? '#212121' : '#ECEFF1', ...(field.style || {}) }">
                        <span style="padding: 5px;" :style="{...(field.style || {})}">
                          <span v-html="typeof field.text == 'function' ? field.text(form, auth) : field.text"></span>
                        </span>
                      </v-subheader>
                    </div>
                    <input v-if="field.type === 'hidden'" v-model="form[field.value]" type="hidden" >
                    <v-text-field 
                      v-if="field.type === 'int'"
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="field['append-icon']"
                      :prefix="field.prefix"
                      :suffix="field.suffix"
                      :min="typeof field.min == 'function' ? field.min(form, auth) : field.min"
                      :max="typeof field.max == 'function' ? field.max(form, auth) : field.max"
                      type="number"
                      :step="field.step"
                      :placeholder="field.placeholder || field.text"
                      v-model="form[field.value]" 
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form, auth) : field.required"
                      :label="formatLabel(field)" 
                      :counter="field.counter"  
                      :mask="field.mask" 
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form, auth) : field.disabled"
                    ></v-text-field>
                    
                    <WiEditFieldShowResult 
                      v-if="field.type === 'show-result'" 
                      :form="form" 
                      :field="field"
                    ></WiEditFieldShowResult>
                    
                    <WiEditFieldAmountMargin 
                      v-if="field.type === 'amount-margin'" 
                      :form="form" 
                      :field="field"
                      @onChange="addToForm"
                    ></WiEditFieldAmountMargin>

                    <!-- <v-text-field 
                    v-if="field.type === 'money'"
                    :prepend-icon="field['prepend-icon']"
                    :prefix="field.prefix"
                    type="text"
                    :step="field.step"
                    :placeholder="field.placeholder || field.text"
                    v-model.lazy="form[field.value]" 
                    :rules="field.required ? validField(field) : []"
                    :required="field.required"
                    :label="field.text" 
                    :counter="field.counter"  
                    :mask="field.mask" 
                    :disabled="field.disabled"
                    v-money="money"
                    ></v-text-field> -->

                    <div v-if="field.type === 'money'" class="v-input v-text-field v-text-field--placeholder v-input--is-label-active v-input--is-dirty theme--light">
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <div class="v-text-field__slot">
                            <label aria-hidden="true" class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">{{field.text}}<span v-if="field.required">*</span></label>
                            <money 
                              :aria-label="field.placeholder || field.text"
                              v-model="form[field.value]" 
                              v-bind="money"
                              :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                              :required="typeof field.required == 'function' ? field.required(form) : field.required"
                              :rules="field.required ? validField(field) : []"
                              :placeholder="field.placeholder || field.text"
                            ></money>
                          </div>
                        </div>
                        <div class="v-text-field__details">
                          <div class="v-messages theme--light">
                            <div class="v-messages__wrapper"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <v-text-field 
                      v-if="field.type === 'text'"
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="field['append-icon']"
                      :prefix="field.prefix"
                      :placeholder="typeof field.placeholder == 'function' ? field.placeholder(form) : (field.placeholder || field.text)"
                      v-model="form[field.value]"
                      :rules="field.rules || (field.required ? validField(field) : [])"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :label="formatLabel(field)" 
                      :counter="field.counter"
                      :mask="typeof field.mask == 'function' ? field.mask(form) : field.mask" 
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                      autocomplete="off"
                    ></v-text-field>
                    
                    <!-- <div v-if="field.type === 'mac-address'">{{ form[field.value] }}</div> -->
                    <v-text-field 
                      v-if="field.type === 'mac-address'"
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="field['append-icon']"
                      :prefix="field.prefix"
                      :placeholder="typeof field.placeholder == 'function' ? field.placeholder(form) : (field.placeholder || field.text)"
                      v-model="form[field.value]"
                      :rules="field.rules || (field.required ? validField(field) : [])"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :label="formatLabel(field)" 
                      :counter="field.counter"
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                      autocomplete="off"
                      v-wi-mac-address="true"
                    ></v-text-field>
                    
                    <v-text-field 
                      v-if="field.type === 'email'"
                      prepend-icon="mail"
                      :append-icon="field['append-icon']"
                      :prefix="field.prefix"
                      :placeholder="typeof field.placeholder == 'function' ? field.placeholder(form) : (field.placeholder || field.text)"
                      v-model="form[field.value]"
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :label="formatLabel(field)" 
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                      autocomplete="off"
                    ></v-text-field>

                    <v-text-field 
                      v-if="field.type === 'time'"
                      type="time"
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="field['append-icon']"
                      :prefix="field.prefix"
                      :placeholder="field.placeholder || field.text"
                      v-model="form[field.value]"
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :label="formatLabel(field)" 
                      :counter="field.counter"
                      :mask="field.mask" 
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                      autocomplete="off"
                    ></v-text-field>

                    <div v-if="field.type === 'color'" style="margin-bottom: 12px;">
                      <label aria-hidden="true" class="v-label v-label--active theme--light" style="left: 0px; right: auto; font-size: 12px;">
                        {{field.text}}
                      </label>
                      <input 
                      type="color"
                      :placeholder="field.placeholder || field.text"
                      v-model="form[field.value]"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                      style="width: 100%; margin-top: 0px; margin-bottom: 3px;">
                    </div>

                    <v-text-field 
                      v-if="field.type === 'password'"
                      :placeholder="field.placeholder || field.text"
                      v-model="form[field.value]"
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="typePassword ? 'visibility' : 'visibility_off'"
                      @click:append="() => (typePassword = !typePassword)"
                      :type="typePassword ? 'password' : 'text'"
                      :rules="field.required ? validField(field) : []"
                      :required="field.required"
                      :label="formatLabel(field)" 
                      :counter="field.counter"
                      min="8"
                      :mask="field.mask" 
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    ></v-text-field>
                    <WiEditLocationField 
                      v-if="field.type === 'location'"
                      v-model="form[field.value]"
                      :form="form"
                      :field="field"
                    ></WiEditLocationField>
                    <v-text-field 
                      v-if="field.type === 'datetime'"
                      :prefix="field.prefix"
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="field['append-icon']"
                      type="datetime-local"
                      :placeholder="field.placeholder" 
                      v-model="form[field.value]"
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :label="formatLabel(field)" 
                      :counter="field.counter"  
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    ></v-text-field>
                    <v-text-field 
                      v-if="field.type === 'date'"
                      :prefix="field.prefix"
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="field['append-icon']"
                      type="date"
                      :placeholder="field.placeholder || field.text"
                      v-model="form[field.value]"
                      :rules="isRequired(field) ? validField(field) : []"
                      :required="isRequired(field)"
                      :label="formatLabel(field)" 
                      :counter="field.counter"  
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    ></v-text-field>
                    <v-textarea
                      outline
                      class="pt-2"
                      auto-grow
                      v-if="field.type === 'textarea'"
                      :placeholder="field.placeholder || field.text"
                      v-model="form[field.value]" 
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :label="formatLabel(field)" 
                      :counter="field.counter" 
                      :rows="field.rows || 3"
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                    ></v-textarea>
                    <WiEditTextEditor
                      v-if="field.type === 'editor'"
                      :label="field.text"
                      v-model="form[field.value]"
                      :required="field.required"
                      :rules="field.required ? validField(field) : []"
                      :disabled="field.disabled"
                    ></WiEditTextEditor>
                    <div v-if="field.type === 'tiny-editor'" style="padding-bottom: 20px;">
                      <WiEditTinyMCE
                        :form="form"
                        :field="field"
                        v-on:select="addToForm"
                      ></WiEditTinyMCE>
                    </div>
                    <WiEditFieldSwitch
                      v-if="field.type === 'switch'"
                      :field="field"
                      :form="form"
                      v-model="form[field.value]"
                      @change="onChange"
                    ></WiEditFieldSwitch>
                    <!-- <v-switch v-model="switch1" :label="`Switch 1: ${switch1.toString()}`"></v-switch> -->
                    <!-- <input v-if="field.type === 'checkbox'" type="checkbox" v-model="form[field.value]">
                    <label v-if="field.type === 'checkbox'" for="checkbox">{{ form[field.value] }}</label> -->
                    <div v-if="field.type === 'checkbox'">
                      <label aria-hidden="true" class="v-label v-label--active theme--light" style="font-size: 12px !important;" for="checkbox">
                        {{ field.text }}
                      </label>
                      <br>
                      <v-checkbox
                        :label="`${ form[field.value] ? (field.trueText || 'Sim'): (field.falseText || 'Não') }`"
                        v-model="form[field.value]" 
                        @change="onChange($event)"
                        :rules="field.required ? validField(field) : []"
                        :required="field.required"
                        :disabled="field.disabled"
                      ></v-checkbox>
                    </div>

                    <WiEditFieldSelect
                      v-if="field.type === 'select'"
                      v-model="form[field.value]"
                      :field="field"
                      :form="form"
                      @onChange="onChange"
                    ></WiEditFieldSelect>
                    
                    <!---
                    <v-select
                      v-if="field.type === 'select'"
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="field['append-icon']"
                      :items="field.options"
                      v-model="form[field.value]"
                      :label="formatLabel(field)"
                      :placeholder="field.placeholder || field.text"
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      item-text="text"
                      item-value="value"
                      @change="onChange($event)"
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form) : field.disabled"
                      :multiple="field.multiple"
                      :return-object="field.object"
                      :autocomplete="field.autocomplete"
                      :value="field.defaultOption || field.value || null"
                    ></v-select>
                    --->
                    <dbselect 
                      v-if="field.type === 'dbselect'" 
                      :prepend-icon="field['prepend-icon']"
                      :append-icon="field['append-icon']"
                      :instore="field.instore" 
                      :outstore="field.outstore" 
                      :id="field.value" 
                      :form="form" 
                      :show="field.show" 
                      :label="formatLabel(field)"
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :autocomplete="field.autocomplete"
                      :value="field.value"
                      :fields="field.fields"
                      :multiple="field.multiple"
                      :order="field.order"
                    ></dbselect>
                    <db-select 
                      v-if="field.type === 'db-select'" 
                      :apiUrl="field.apiUrl"
                      :apiParams="field.apiParams"
                      :formField="field.value" 
                      :form="form" 
                      :show="field.show" 
                      :label="formatLabel(field)"
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form) : field.required"
                      :autocomplete="field.autocomplete"
                      :multiple="field.multiple"
                      :placeholder="field.placeholder || field.text"
                    ></db-select>
                    <div v-if="action">
                    <db-autocomplete 
                      v-if="field.type === 'db-autocomplete'" 
                      :apiUrl="typeof field.apiUrl == 'function' ? field.apiUrl(form, auth) : field.apiUrl"
                      :apiParams="typeof field.apiParams == 'function' ? field.apiParams(form, auth) : field.apiParams"
                      :formField="field.value" 
                      :form="form" 
                      :show="field.show"
                      :value="field.selectValue"
                      :comparator="field.comparator"
                      :label="formatLabel(field)"
                      :rules="field.required ? validField(field) : []"
                      :required="typeof field.required == 'function' ? field.required(form, auth) : field.required"
                      :autocomplete="field.autocomplete"
                      :multiple="field.multiple"
                      :placeholder="field.placeholder || field.text"
                      :disabled="typeof field.disabled == 'function' ? field.disabled(form, auth) : field.disabled"
                      :object="field.object"
                      :icon="field.icon"
                      :color="field.color"
                      :change="field.change"
                      :lastFormFieldChanged="lastFormFieldChanged"
                      @onChange="onAutocompleteChange"
                      :clearable="field.clearable"
                      :dependOn="field.dependOn"
                    ></db-autocomplete>
                    </div>
                    <div v-if="field.type === 'list'">
                      <v-list dense two-line class="listmodal">
                        <v-list-tile avatar>
                          <v-list-tile-avatar>
                            <v-icon>{{ field.icon }}</v-icon>
                          </v-list-tile-avatar>
                          <v-list-tile-content>
                            <v-list-tile-title>{{ form[field.show] || 'Selecione...' }}</v-list-tile-title>
                            <v-list-tile-sub-title v-if="field.showSecond">form[field.showSecond]</v-list-tile-sub-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-btn icon @click="listModal = true">
                              <v-icon>search</v-icon>
                            </v-btn>
                            <list-modal 
                              :action="listModal"
                              :store="field.store"
                              :id="field.value" 
                              :multiple="field.multiple ? getMultipleListModal : false"
                              v-on:action="closeListModal"
                            ></list-modal>
                          </v-list-tile-action>
                        </v-list-tile>
                      </v-list><br>
                    </div>
                    <div v-if="field.type === 'list-ticket-category'">
                      <ocorrencias-dialog 
                        v-on:select="selectCategory" 
                        :data="form" :form="form" 
                        :ticket-function="field.ticketFunction"
                        :rules="field.required ? validField(field) : []"
                        :required="typeof field.required == 'function' ? field.required(form, auth) : field.required"
                      ></ocorrencias-dialog>
                    </div>
                    <div v-if="field.type === 'wi-list-dialog'">
                      <wi-edit-wi-list-dialog
                        :form="form"
                        :field="field"
                        v-on:select="addToForm"
                      ></wi-edit-wi-list-dialog>
                    </div>
                    <div v-if="field.type === 'wi-file-dialog'">
                      <wi-edit-wi-file-dialog
                        :form="form"
                        :field="field"
                        v-on:select="addToForm"
                      ></wi-edit-wi-file-dialog>
                    </div>
                    <div v-if="field.type === 'wi-google-icons'">
                      <wi-google-icons-dialog
                        :field="field.value"
                        :icon="form[field.value]" 
                        v-on:select="setIcon"
                      ></wi-google-icons-dialog>
                    </div>
                    <div v-if="field.type === 'message'">
                      <WiEditMessageField
                        :field="field"
                        :form="form"
                      ></WiEditMessageField>
                    </div>
                    <div v-if="field.type === 'file-download'">
                      <WiEditFileDownloadField
                        :field="field"
                        :form="form"
                      ></WiEditFileDownloadField>
                    </div>
                    <div v-if="field.type === 'wn-category-selector'">
                      <WiEditCategorySelector
                        v-model="form[field.value]"
                        :field="field"
                        :form="form"
                      ></WiEditCategorySelector>
                    </div>
                    <WiEditFieldTextAreaFormat
                      v-if="field.type == 'textarea-format'"
                      v-model="form[field.value]"
                      :field="field"
                      :form="form"
                    ></WiEditFieldTextAreaFormat>
                  </div>
                  <div v-if="field.button" style="width: 20%;">
                    <v-tooltip bottom>  
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="field.button.type === 'icon'"
                          icon v-bind="attrs" v-on="on"
                          :color="field.button.color"
                          :loading="field.button.loading ? field.button.loading(form, field) : false"
                          :disabled="field.button.disabled ? field.button.disabled(form, field) : false"
                          @click="field.button.action({ form, field, setField })"
                        >
                          <v-icon>{{ field.button.icon }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ field.button.text }}</span>
                    </v-tooltip>
                  </div>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container><br><br>
        </v-card-text>
        <v-toolbar v-if="action && store" dense class="elevation-17" style="position: absolute; bottom: 0;width: 100%; z-index: 999 !important; box-shadow: 10px 10px 5px grey;">
          <small>*Campos obrigatórios</small>
          <v-spacer></v-spacer>
          <v-btn 
            v-prms="{'id': getIdPermission('create'), notCheck: config.disablePermission}" 
            v-if="action && form && !form.id && checkButtonConfig('create')" 
            :disabled="!valid" 
            color="success" 
            @click.native="insert(form)"
          >
            <span style="padding-right: 5px;">Inserir</span>
            <v-icon>check</v-icon>
          </v-btn>
          <v-btn 
            v-prms="{'id': getIdPermission('delete'), notCheck: config.disablePermission}" 
            v-if="action && form && form.id && checkButtonConfig('delete')" 
            color="error" 
            @click.native="apagar(form)"
          >
            <span style="padding-right: 5px;">Apagar</span>
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn 
            v-prms="{'id': getIdPermission('update'), notCheck: config.disablePermission}" 
            v-if="action && form && form.id && checkButtonConfig('update')" 
            :disabled="!valid" 
            color="info" 
            @click.native="update(form)"
          >
            <span style="padding-right: 5px;">Atualizar</span>
            <v-icon>update</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  
  import WiEditTextEditor from './Fields/WiEditTextEditor'
  import WiEditFieldShowResult from './Fields/WiEditFieldShowResult'
  import WiEditFieldAmountMargin from './Fields/WiEditFieldAmountMargin'
  import OcorrenciasDialog from '@/modules/service-desk/pages/Ocorrencias/OcorrenciasDialog'
  import WiGoogleIconsDialog from '@/default/component/WiGoogleIcons/WiGoogleIconsDialog'
  import WiEditWiFileDialog from '@/default/component/WiEdit/Fields/WiEditWiFileDialog'
  import WiEditWiListDialog from '@/default/component/WiEdit/Fields/WiEditWiListDialog'
  import WiEditTinyMCE from '@/default/component/WiEdit/Fields/WiEditTinyMCE'
  import DbAutocomplete from '@/default/components/DBAutocomplete'
  import WiEditLocationField from './Fields/WiEditLocationField/index'
  import WiEditMessageField from './Fields/WiEditMessageField'
  import WiEditFileDownloadField from './Fields/WiEditFileDownloadField'
  import ListModal from '@/default/components/Modal/ListModal'
  import DbSelect from '@/default/components/DBSelectV2'
  import DBSelect from '@/default/components/DBSelect'
  import {Api} from '@/default/service/Api'
  import {VMoney} from 'v-money'
  import WiEditFieldSelect from './Fields/WiEditFieldSelect.vue'
  import WiEditFieldSwitch from './Fields/WiEditFieldSwitch.vue'
  import WiEditCategorySelector from './Fields/WiEditCategorySelector'
  import WiEditFieldTextAreaFormat from './Fields/WiEditFieldTextAreaFormat'
  export default {
    name: 'WiEditDialog',
    data () {
      return {
        valid: false,
        editorTeste: '',
        form: {
          checkbox: false,
          setField: this.setField
        },
        checkboxs: [],
        typePassword: true,
        money: {
          required: true,
          prefix: this.$WiLangText('system.currency.simbol', 'R$') + ' ',
          decimal: ',',
          thousands: '.',
          precision: 2,
          masked: false /* doesn't work with directive */
        },
        listModal: false,
        initialData: {},
        lastFormFieldChanged: {}
      }
    },
    created: function () {
      this.form = {}
      Object.assign(this.form, this.setform)
      if (this.config.fieldsFixed) {
        this.config.fieldsFixed.forEach((fixed) => {
          this.form[fixed.value] = fixed.data
        })
      }
      if (this.apagarAoIniciar) {
        this.apagar(this.form)
      }
      this.checkDefaultFieldValues()
      this.initialData = JSON.parse(JSON.stringify(this.form))
      // console.log('this.initialData', this.initialData)
    },
    computed: {
      config () {
        var storeConfig = this.$store.state.pages[this.store] || {}
        return this.localConfig || storeConfig
      },
      url () {
        const url = this.$store.state.url
        return url.base + url.version + url.group
      },
      dark () {
        return this.$store.state.style.dark || false
      },
      auth () {
        return this.$store.state.auth || {}
      },
      WiEditDialog () {
        return this.$store.state.WiEditDialog
      },
      store () {
        return this.WiEditDialog.dialogs[this.index].wiConfig
      },
      setform () {
        return this.WiEditDialog.dialogs[this.index].data
      },
      action () {
        return this.WiEditDialog.dialogs[this.index].active
      },
      redirectOnSubmit () {
        var redirectOnSubmit = this.WiEditDialog.dialogs[this.index].redirectOnSubmit
        if (redirectOnSubmit === undefined || redirectOnSubmit === null) {
          redirectOnSubmit = true
        }
        return redirectOnSubmit
      },
      defaultMaxWidth () {
        return window.innerWidth < 1370 ? ((95 - (this.index * 2)) + '%') : '1288px'
      }
    },
    props: ['index', 'id', 'add', 'params', 'description', 'localConfig'],
    methods: {
      checkDefaultFieldValues: function () {
        if (!this.form.id && (this.config.fields.edit || this.config.fields)) {
          const fields = this.config.fields.edit || this.config.fields
          const form = {}
          fields.forEach(field => {
            if (field.default) {
              form[field.value] = field.default
            }
          })
          setTimeout(() => {
            Object.assign(this.form, form)
            this.$forceUpdate()
          }, 100);
        }
      },
      formatLabel: function ({ required = false, text = 'Campo' }) {
        if (required) {
          return `${text}*`
        }
        return text
      },
      setField: function ({ field, data }) {
        this.form[field] = data
        this.$forceUpdate()
      },
      setIcon: function (icon) {
        if (icon && icon.field && icon.icon) {
          this.form[icon.field] = icon.icon
          this.$forceUpdate()
        }
      },
      getIdPermission: function (action) {
        if (this.config && this.config.id) {
          return this.config.id[action] || this.config.id
        }
        return null
      },
      checkButtonConfig: function (action) {
        if (this.config.actions) {
          if (typeof this.config.actions[action] === 'function') {
            return this.config.actions[action](this.form)
          } else {
            return this.config.actions[action]
          }
        }
        return true
      },
      addToForm: function (newData) {
        console.log('newData', newData)
        if (newData) {
          Object.assign(this.form, newData)
          this.$forceUpdate()
        }
      },
      onChange: function () {
        this.$forceUpdate()
      },
      isRequired: function (field) {
        if (typeof field.required === 'function') {
          return field.required(this.form)
        } else {
          return field.required
        }
      },
      changed: function (field, value, index, valueField) {
        // console.log(this.checkboxs)
        // console.log('field: ' + field)
        if (value === undefined) {
          this.checkboxs[index] = valueField
        }
        // console.log(valueField + ' === ' + value)
        // console.log('valueField: ' + valueField)
        this.form[field] = !value
      },
      viewField: function (field) {
        if (field.edit && ((field.edit.insert || field.edit.create) || field.edit.update)) {
          if (this.form.id) {
            if (field.edit.update) {
              return true
            } else {
              return false
            }
          } else {
            if (field.edit.insert || field.edit.create) {
              return true
            } else {
              return false
            }
          }
        } else {
          return field.edit
        }
      },
      selectCategory: function (select) {
        Object.assign(this.form, select)
        this.$forceUpdate()
      },
      newForm: function () {
        this.form = {}
        if (this.config.fieldsFixed) {
          this.config.fieldsFixed.forEach((fixed) => {
            this.form[fixed.value] = fixed.data
          })
        }
      },
      validField: function (field) {
        if (field.rules) {
          if (typeof field.rules === 'function') {
            return field.rules(this.form)
          } else {
            return field.rules
          }
        }
        return [v => !!v || 'Campo obrigatório']
      },
      sizeField: function (sizes) {
        var size = {}
        sizes = sizes ? (typeof sizes === 'function' ? sizes(this.form) : sizes) : ['xs12', 'sm12', 'md12', 'lg12']
        for (var i in sizes) {
          size[sizes[i]] = true
        }
        return size
      },
      closeEdit: function () {
        // var timerInterval
        // this.$swal({
        //   title: 'Deseja realmente fechar?',
        //   text: 'Informações não salvas serão perdidas!',
        //   type: 'info',
        //   showCancelButton: true,
        //   confirmButtonColor: '#3085d6',
        //   cancelButtonColor: '#d33',
        //   confirmButtonText: 'Sim, Fechar!',
        //   cancelButtonText: 'Cancelar',
        //   footer: '<h4 id="alert-footer" style="color: red;">Fechando em 2s</h4>',
        //   timer: 2000,
        //   timerProgressBar: true,
        //   onBeforeOpen: () => {
        //     timerInterval = setInterval(() => {
        //       const content = this.$swal.getFooter()
        //       if (content) {
        //         const b = content.querySelector('#alert-footer')
        //         if (b) {
        //           b.textContent = 'Fechando em ' + (this.$swal.getTimerLeft() / 1000).toFixed(0) + 's'
        //         }
        //       }
        //     }, 1000)
        //   },
        //   onClose: () => {
        //     clearInterval(timerInterval)
        //   }
        // })
        // .then((response) => {
        //   if (response.value || response.dismiss === 'timer') {
        //     this.$store.dispatch('WiEditDialog/close', null)
        //   }
        // })
        this.$store.dispatch('WiEditDialog/close', {index: this.index})
      },
      getMultipleListModal: function () {},
      closeListModal: function (row) {
        if (row) {
          Object.assign(this.form, row)
          this.$forceUpdate()
          this.listModal = false
        }
      },
      loading: function (title, text) {
        this.$swal({
          title: title || 'Carregando...',
          text: text || 'Aguarde até que a ação seja finalizada.',
          onOpen: () => { this.$swal.showLoading() }
        })
      },
      success: function (title, text) {
        this.$swal({
          type: 'success',
          title: title || 'Tudo certo!',
          text: text || 'Concluído com sucesso.'
        })
      },
      error: function (title, text) {
        this.$swal({
          type: 'error',
          title: title || 'Oops... Algo deu errado!',
          text: text || 'Tente novamente ou contacte o suporte.'
        })
      },
      getConfigUri: function (type) {
        return this.config.apiUrl && this.config.apiUrl.edit ? (typeof this.config.apiUrl.edit === 'object' ? this.config.apiUrl.edit[type] : this.config.apiUrl.edit) : this.config.apiUrl
      },
      warning: function (title, text) {
        this.$swal({
          type: 'warning',
          title: title || 'Verifique os dados inseridos.',
          html: text || 'Tente novamente ou contacte o suporte.'
        })
      },
      insert: function (data) {
        this.loading('Inserindo...')
        for (var param in this.config.editParams) {
          data[param] = this.config.editParams[param]
        }
        var uri = this.getConfigUri('insert')
        var urlDetails = null
        if (this.config && this.config.urlDetails) {
          urlDetails = this.config.urlDetails
        }
        Api.post({url: this.url + uri}, data)
        .then((data) => {
          if (data.status === 201) {
            this.$emit('action', true)
            this.$emit('refresh', false)
            this.$store.dispatch('WiEditDialog/submit', {index: this.index, callback: data})
            if (this.redirectOnSubmit && (urlDetails && data.data.id)) {
              urlDetails['params']['id'] = data.data.id
              this.$router.push(urlDetails)
            }
            this.success()
          } else {
            this.error(data.data.message || null, data.data.action || null)
          }
        })
        .catch((error) => {
          this.$WiMakeError({error: error, id: 202, title: 'Erro ao inserir informações.'})
        })
      },
      update: function (data) {
        this.loading('Atualizando...')
        var uri = this.getConfigUri('update')

        // filter updated fields
        var updatedData = data // this.filterUpdatedData(data)

        Api.put({url: this.url + uri + '/' + data.id}, updatedData)
        .then((data) => {
          if (data.status === 201) {
            this.$emit('action', true)
            this.$emit('refresh', false)
            this.$store.dispatch('WiEditDialog/submit', {index: this.index, callback: data})
            this.success()
          } else {
            this.error(data.data.message || null, data.data.action || null)
          }
        })
        .catch((error) => {
          this.$WiMakeError({error: error, id: 302, title: 'Erro ao atualizar informações.'})
        })
      },
      apagar: function (data) {
        this.$swal({
          title: 'Deseja realmente apagar?',
          text: 'Esta ação não poderá ser desfeita!',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, Apagar!',
          cancelButtonText: 'Cancelar'
        })
        .then((response) => {
          if (response.value) {
            this.loading('Apagando...')
            var uri = this.getConfigUri('delete')
            Api.delete({url: this.url + uri + '/' + data.id})
            .then((data) => {
              if (data.status === 201) {
                this.$emit('action', true)
                this.$emit('refresh', false)
                this.$store.dispatch('WiEditDialog/submit', {index: this.index, callback: data})
                this.success()
              } else {
                this.error(data.data.message || null, data.data.action || null)
              }
            })
            .catch((error) => {
              this.$WiMakeError({error: error, id: 402, title: 'Erro ao apagar informações.'})
            })
          }
        })
      },
      ActiveInactive: function () {
        this.form = {}
        this.$refs.wiEditDialog.reset()
        Object.assign(this.form, this.setform)
        if (this.config.fieldsFixed) {
          this.config.fieldsFixed.forEach((fixed) => {
            this.form[fixed.value] = fixed.data
          })
        }
      },
      formChange: function () {
        // console.log(this.form, 'formChange')
      },
      filterUpdatedData: function (data) {
        var updatedData = {}
        for (var i in data) {
          if (data[i] !== this.initialData[i]) {
            updatedData[i] = data[i]
          }
        }
        return updatedData
      },
      onAutocompleteChange: function (data) {
        this.lastFormFieldChanged = {}
        this.lastFormFieldChanged = data
      }
    },
    watch: {
      'action': 'ActiveInactive',
      '$route': 'closeEdit',
      'form': 'formChange'
    },
    components: {
      dbselect: DBSelect,
      ListModal,
      DbSelect,
      DbAutocomplete,
      WiEditFieldSelect,
      OcorrenciasDialog,
      WiEditWiFileDialog,
      WiEditWiListDialog,
      WiEditTinyMCE,
      WiGoogleIconsDialog,
      WiEditMessageField,
      WiEditFileDownloadField,
      WiEditLocationField,
      WiEditFieldShowResult,
      WiEditFieldAmountMargin,
      WiEditTextEditor,
      WiEditFieldSwitch,
      WiEditCategorySelector,
      WiEditFieldTextAreaFormat
    },
    directives: {
      money: VMoney
    }
  }
</script>
<style>
.listmodal {
  border-style: solid;
  border-color: gray;
  border-width: 1px;
}
@media only screen and (min-width: 400px) {
  .header_edit_modal {
    padding-right: 20px;
    padding-left: 20px;
  }
}
</style>
<style scoped>
  .asdasdasd >>> a {
    height: 45px !important;
  }
</style>
