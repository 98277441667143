var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"width":"350px"},style:(_vm.$vuetify.breakpoint.xsOnly ? {
    width: '100% !important',
    'max-width': '100% !important'
  } : {
    'overflow-y': 'auto',
    'height': _vm.mode == 'dialog' ? 'calc(min(90vh - 120px, 100vh - 220px))' : 'calc(100vh - 202px)',
    'margin-bottom': _vm.mode == 'dialog' ? 'calc(10vh + 70px)' : '45px'
  }),attrs:{"flat":""}},[_c('div',{staticStyle:{"text-align":"center !important","border-left":"solid 1px #80808038","border-right":"solid 1px #80808038"}},[_c('TalkContactName',{attrs:{"contact":_vm.contact,"refresh":_vm.refresh}}),_c('TalkContactProfiles',{attrs:{"contact":_vm.contact,"refresh":_vm.refresh}})],1),_c('v-card-text',{staticStyle:{"text-align":"center !important","padding":"0","border-left":"solid 1px #80808038","border-right":"solid 1px #80808038"}},[_c('TalkContactObservations',{attrs:{"contact":_vm.contact}}),_c('TalkContactMembers',{attrs:{"contact":_vm.contact}}),_c('TalkContactTabs',{attrs:{"talk":_vm.talk,"contact":_vm.contact,"refresh":_vm.refresh}}),_c('TalkContactSelectProfile',{attrs:{"contact":_vm.contact,"refresh":_vm.refresh}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }