var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background":"white !important"}},[_c('v-toolbar',{staticStyle:{"color":"white"},attrs:{"dense":"","color":!_vm.$wiDark ? 'primary' : 'dark'}},[_c('v-btn',{staticStyle:{"color":"white"},attrs:{"icon":"","ripple":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("arrow_back")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.title || 'Processos')+" ")]),_c('v-spacer'),(_vm.wiConfig && _vm.wiConfig.filters)?_c('WiListFilters',{attrs:{"config":_vm.wiConfig.filters,"params":_vm.apiGetParams,"disabled":_vm.loading},on:{"filter":function (filters) { _vm.setFilters(filters) }}}):_vm._e(),(_vm.orderOptions && _vm.orderOptions.length > 0)?_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":300},model:{value:(_vm.orderMenu),callback:function ($$v) {_vm.orderMenu=$$v},expression:"orderMenu"}},[_c('v-btn',{staticClass:"hidden-xs-only hidden-sm-only",attrs:{"slot":"activator","small":"","outline":"","color":"white","disabled":_vm.loading},slot:"activator"},[_c('v-icon',[_vm._v("sort")]),_c('span',[_vm._v("Reordenar")])],1),_c('v-card',[_c('v-subheader',[_vm._v("Selecione a ordem")]),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.orderOptions),function(option){return _c('v-list-tile',{key:option.value,class:{
              'wi-kanban__order-item': true,
              'wi-kanban__order-item--selected': option.value === _vm.order
            },on:{"click":function($event){return _vm.handleOrderChange(option.value)}}},[_c('v-list-tile-avatar',[_c('v-icon',{class:{'wi-kanban__order-icon--selected': option.value === _vm.order}},[_vm._v(" "+_vm._s(option.icon)+" ")])],1),_c('v-list-tile-title',[_vm._v(_vm._s(option.label))])],1)}),1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"flat":""},on:{"click":function($event){_vm.orderMenu = false}}},[_vm._v("Fechar")])],1)],1)],1):_vm._e(),(!_vm.hideAddButton)?_c('v-btn',{staticClass:"hidden-xs-only hidden-sm-only",attrs:{"small":"","outline":"","color":"white","disabled":_vm.loading},on:{"click":function($event){return _vm.$WiEditDialog({wiConfig: _vm.store + '-open', onSubmit: _vm.load, data: {}})}}},[_c('v-icon',[_vm._v("add")]),_c('span',[_vm._v("Adicionar Novo")])],1):_vm._e(),_c('v-btn',{attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){return _vm.load()}}},[_c('v-icon',{staticStyle:{"color":"white"}},[_vm._v("refresh")])],1)],1),(_vm.loading)?_c('v-progress-linear',{staticStyle:{"margin":"unset","position":"absolute"},attrs:{"indeterminate":true}}):_vm._e(),(_vm.stages.length < 1 && !_vm.loading)?_c('v-flex',{attrs:{"sm12":""}},[_c('KanbanEmptyStages',{attrs:{"icon":_vm.emptyStagesIcon,"title":_vm.emptyStagesTitle,"description":_vm.emptyStagesDescription,"buttonText":_vm.emptyStagesButtonText},on:{"action":_vm.handleEmptyStagesAction}})],1):_vm._e(),(_vm.stages.length < 1 && _vm.loading)?_c('v-flex',{attrs:{"sm12":""}},[_c('LoadingIndicator')],1):_vm._e(),(_vm.stages.length > 0)?_c('div',{staticStyle:{"height":"calc(100vh - 97px) !important","overflow-x":"auto !important","overflow-y":"hidden !important","padding":"5px 5px"}},[_c('div',{staticClass:"wi-kanban__container",style:({width: (_vm.getActiveStagesCount * 301) + 'px'})},_vm._l((_vm.stages),function(stage,stageIndex){return _c('div',{key:stageIndex,class:{
          'box': true,
          'wi-kanban__stage': true,
          'wi-kanban__stage--closed': stage.stage_action === 'closed',
          'wi-kanban__stage--canceled': stage.stage_action === 'canceled',
          'wi-kanban__stage--floating': stage.stage_action === 'closed' || stage.stage_action === 'canceled',
          'wi-kanban__stage--dragging': _vm.isCardDragging
        }},[(stage.stage_action === 'open')?[_c('div',{staticStyle:{"padding":"5px","max-width":"300px !important","margin":"unset"}},[_c('div',{staticClass:"elevation-0"},[_c('KanbanColumnHeader',{attrs:{"stage-color":stage.color,"stage-icon":stage.icon,"stage-name":stage.name,"items-total":stage.items.total,"store-stage":_vm.storeStage,"stage-data":stage,"show-stage-action":_vm.showStageAction,"stage-action-icon":_vm.stageActionIcon,"stage-menu-items":_vm.stageMenuItems},on:{"refresh":_vm.load,"stage-menu-action":function (data) { _vm.$emit('stage-menu-action', data) }}}),_c('v-divider'),_c('div',{staticClass:"wi-kanban__header",staticStyle:{"height":"calc(100vh - 180px) !important","overflow-y":"auto","padding":"5px","background":"#DFE1E6"}},[(_vm.$Auth.user().account.id == '490fba83-2a5e-4b11-935a-754b25000880' && stage.items.data && stage.items.data.length > 0 && stage.sum_quantity_unit)?_c('v-toolbar',{staticClass:"elevation-10 toolbar_status",staticStyle:{"padding":"0px !important","margin-bottom":"5px"},attrs:{"dense":""}},[_c('v-icon',[_vm._v("list")]),_c('strong',{staticStyle:{"font-size":"14px"}},[_vm._v(" Produtos/Serviços: ")]),_c('v-spacer'),_c('strong',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(stage.sum_quantity_unit)+" ")])],1):_vm._e(),(_vm.showAmountSum && _vm.$Auth.user().account.id != '490fba83-2a5e-4b11-935a-754b25000880' && stage.items.data && stage.items.data.length > 0)?_c('v-toolbar',{staticClass:"elevation-10 toolbar_status wi-kanban__column-amount-total",staticStyle:{"padding":"0px !important","margin-bottom":"5px"},attrs:{"dense":""}},[_c('v-icon',[_vm._v("attach_money")]),_c('strong',{staticStyle:{"font-size":"14px"}},[_vm._v(" Valor total: ")]),_c('v-spacer'),_c('strong',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm._f("money")(stage.sum_total_liquid))+" ")])],1):_vm._e(),_c('Container',{staticClass:"wi-kanban__container",attrs:{"group-name":"col","get-child-payload":function (index) {
                    return stage.items.data[index]
                  },"drag-class":"card-ghost","drop-class":"card-ghost-drop","drop-placeholder":{
                    className: 'cards-drop-preview',
                    animationDuration: '150',
                    showOnTop: true
                  },"should-animate-drop":function () { return false; }},on:{"drag-start":_vm.handleDragStart,"drag-end":_vm.handleDragEnd,"drop":function($event){return _vm.onDrop(stageIndex, $event)}}},[_vm._l((_vm.reorderCards(stage.items.data, _vm.order)),function(item,itemIndex){return _c('Draggable',{key:itemIndex},[_vm._t("items",null,{"item":item,"index":itemIndex,"refresh":_vm.load,"loading":_vm.loading})],2)}),(!_vm.noPaginate && stage.items.data && stage.items.data.length > 0)?_c('v-btn',{attrs:{"block":"","outline":"","color":"black","disabled":stage.items.last_page == stage.items.current_page,"loading":stage.loading},on:{"click":function($event){return _vm.itemPaginate(stage)}}},[_vm._v("Buscar Mais")]):_vm._e()],2)],1),_c('v-footer',{staticStyle:{"background":"#DFE1E6","min-height":"5px !important","height":"5px !important"}})],1)])]:[_c('div',{staticClass:"wi-kanban__stage-floating-content"},[_c('v-icon',{attrs:{"color":stage.color,"size":"32"}},[_vm._v(" "+_vm._s(stage.icon)+" ")]),_c('div',{staticClass:"wi-kanban__stage-floating-title"},[_vm._v(_vm._s(stage.name))]),_c('Container',{attrs:{"group-name":"col","get-child-payload":function () { return null; },"should-animate-drop":function () { return false; },"drag-class":"card-ghost","drop-class":"card-ghost-drop","drop-placeholder":{
                className: 'cards-drop-preview',
                animationDuration: '150',
                showOnTop: true
              }},on:{"drop":function($event){return _vm.onDrop(stageIndex, $event)}}})],1)]],2)}),0)]):_vm._e(),_c('v-btn',{staticClass:"white--text hidden-md-only hidden-lg-only hidden-xl-only elevation-17",attrs:{"color":"green","fab":"","fixed":"","bottom":"","right":""},on:{"click":function($event){return _vm.$WiEditDialog({wiConfig: _vm.store + '-open', onSubmit: _vm.load, data: {}})}}},[_c('v-icon',[_vm._v("add")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }