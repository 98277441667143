<template>
  <div class="business-card-header">
    <v-card-title class="business-card-header__title">
      <span class="business-card-header__label">ID:</span>
      <strong class="business-card-header__id">#{{item.id}}</strong>
      <v-spacer></v-spacer>
      <span v-if="item.stage == 1">
        <strong
          v-if="showExpiredBadge"
          class="business-card-header__badge business-card-header__badge--expired"
        >
          EXPIRADO
        </strong>
      </span>
    </v-card-title>

    <template v-if="item.external_code">
      <v-card-title class="business-card-header__title">
        <span class="business-card-header__code">
          <span class="business-card-header__label">Cod. Proposta:</span>
          <strong class="business-card-header__value">{{item.external_code | stringlength(20)}}</strong>
        </span>
      </v-card-title>
    </template>
  </div>
</template>

<script>
import { BUSINESS_TYPES } from '../config/businessConfig'

export default {
  name: 'BusinessCardHeader',
  
  props: {
    item: { type: Object, required: true },
    config: { type: Object, required: true }
  },

  computed: {
    showExpiredBadge() {
      const { type } = this.config
      const isBudgetOrQuotation = type === BUSINESS_TYPES.BUDGET || type === BUSINESS_TYPES.QUOTATION
      const isPurchaseOrSale = type === BUSINESS_TYPES.PURCHASE || type === BUSINESS_TYPES.SALE

      return (isBudgetOrQuotation && this.item.date_validity_days < 0) ||
             (isPurchaseOrSale && this.item.date_end_days < 0)
    }
  }
}
</script>

<style scoped>
.business-card-header {
  border-radius: 8px 8px 0 0;
  position: relative;
  overflow: hidden;
}

.business-card-header__title {
  padding: 4px 8px;
  min-height: 32px;
  position: relative;
  z-index: 1;
}

.business-card-header__label {
  font-size: 0.85em;
  font-weight: 500;
}

.theme--light .business-card-header__label {
  color: #495057;
}

.theme--dark .business-card-header__label {
  color: #e9ecef;
}

.business-card-header__id {
  font-size: 0.95em;
  margin-left: 2px;
  font-weight: 600;
}

.theme--light .business-card-header__id {
  color: #212529;
}

.theme--dark .business-card-header__id {
  color: #ffffff;
}

.business-card-header__divider {
  margin: 0;
  opacity: 0.5;
}

.business-card-header__code {
  display: flex;
  align-items: center;
  gap: 2px;
}

.theme--light .business-card-header__value {
  color: #212529;
}

.theme--dark .business-card-header__value {
  color: #ffffff;
}

.business-card-header__badge {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  position: relative;
  z-index: 2;
}

.business-card-header__badge--expired {
  color: #ffffff;
  background: #ff6b6b;
  border: none;
  box-shadow: 0 1px 3px rgba(255, 107, 107, 0.2);
}

.business-card-header__badge--expired::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  border-radius: 4px;
  z-index: -1;
}
</style> 