import { BusinessFinanceEdit } from '@/modules/shared/config/Business/BusinessFinanceEdit'

export class FinancePurchaseEdit extends BusinessFinanceEdit {
  constructor() {
    super()
    this.id = '7ea05f3a'
    this.title = {
      list: '',
      edit: {
        create: 'Criar Parcela da compra',
        update: 'Editar parcela da compra'
      }
    }
    this.apiUrl = 'purchases/purchase/finance'
    this.fieldsFixed = [
      { value: 'in_out', data: 2 }
    ]
  }
}
