<template>
  <v-select
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :placeholder="placeholder"
    :items="options"
    v-model="model"
    :label="text" 
    item-text="text"
    item-value="value"
    :multiple="multiple"
    :return-object="returnObject"
    :clearable="clearable"
  ></v-select>
</template>
<script>
  export default {
    name: 'FilterFieldSelect',
    computed: {
      model: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    mounted () {
      if (!this.value && this.defaultOption && this.options.length > 0) {
        this.model = this.defaultOption
      }
    },
    props: {
      value: {
        required: true
      },
      prependIcon: {
        default: null
      },
      appendIcon: {
        default: null
      },
      placeholder: {
        default: 'Insira aqui...'
      },
      text: {
        required: true
      },
      options: {
        required: true
      },
      multiple: {
        default: false
      },
      returnObject: {
        default: false
      },
      clearable: {
        default: false
      },
      defaultOption: {
        default: null
      }
    }
  }
</script>