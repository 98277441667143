<template>
  <div>
    <div class="wi-edit-category-selector" :class="{ 'wi-edit-category-selector-dark': $wiDark }">
      <div 
        class="category-field"
        :class="{ 
          'has-value': selectedCategoryId,
          'disabled': field.disabled 
        }"
        @click="handleClick"
      >
        <div class="category-value">
          <strong v-if="selectedCategoryTitle">{{ selectedCategoryTitle }}</strong>
          <span v-else>{{ field.placeholder || 'Selecione uma categoria' }}</span>
        </div>
        <label class="category-label">{{ label }}</label>
        <v-btn
          v-if="selectedCategoryId"
          small
          icon
          class="clear-button"
          @click.stop="clearCategory"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </div>
    </div>
    <WNCategorySelectorDialog
      v-if="categorySelectorOpened"
      v-model="categorySelectorOpened"
      :apiUrl="apiUrl"
      :apiParams="apiParams"
      :currentCategoryId="selectedCategoryId"
      @select="selectCategory"
      @cancel="closeCategorySelector"
    />
  </div>
</template>

<script>
  import WNCategorySelectorDialog from '@/default/component/WNCategory/WNCategorySelectorDialog'
  export default {
    name: 'WiEditCategorySelector',
    data () {
      return {
        selectedCategoryId: null,
        selectedCategoryTitle: null,
        categorySelectorOpened: false
      }
    },
    computed: {
      apiUrl() {
        return this.field.apiUrl
      },
      apiParams() {
        return this.field?.apiParams || {}
      },
      currentCategoryId() {
        return this.form[this.field.value]
      },
      label() {
        return this.field.text
      },
      show() {
        return this.form[this.field.show]
      }
    },
    methods: {
      handleClick() {
        if (!this.field.disabled) {
          this.categorySelectorOpened = true
        }
      },
      selectCategory(category) {
        this.form[this.field.value] = category.id
        console.log('category', category.id)
        this.$emit('input', category.id)
        this.selectedCategoryId = category.id
        let categoryFormAppend = {}
        categoryFormAppend[this.field.value] = category.id
        categoryFormAppend[this.field.show] = category[this.field.selectedShow]
        categoryFormAppend[this.field.selectedShow] = category[this.field.selectedShow]
        this.$emit('select', categoryFormAppend)
        this.selectedCategoryTitle = `${category[this.field.selectedShow]}`
        this.categorySelectorOpened = false
        console.log('categoryFormAppend', categoryFormAppend)
        this.$forceUpdate()
      },
      closeCategorySelector() {
        this.categorySelectorOpened = false
      },
      clearCategory() {
        this.form[this.field.value] = null
        this.selectedCategoryTitle = null
        this.$emit('input', null)
        this.selectedCategoryId = null
        let categoryFormAppend = {}
        categoryFormAppend[this.field.value] = null
        categoryFormAppend[this.field.show] = null
        categoryFormAppend[this.field.selectedShow] = null
        this.$emit('select', categoryFormAppend)
        this.$forceUpdate()
      }
    },
    mounted: function() {
      this.selectedCategoryTitle = this.form[this.field.show]
    },
    props: {
      value: {},
      field: {
        type: Object,
        required: true
      },
      form: {
        type: Object,
        default: () => ({})
      }
    },
    components: {
      WNCategorySelectorDialog
    }
  } 
</script>

<style scoped>
.wi-edit-category-selector {
  margin: 0px 0;
  cursor: pointer;
}

.wi-edit-category-selector-dark {
  background: #1212127c !important;
}

.wi-edit-category-selector-dark .category-field {
  background: #1212127c !important;
  color: #ffffff !important;
}

.wi-edit-category-selector-dark .category-field .category-label {
  color: #ffffff !important;
}

.wi-edit-category-selector-dark .category-field .category-value {
  color: #ffffff !important;
}

.wi-edit-category-selector-dark .category-field .category-value strong {
  color: #ffffff !important;
}

.category-field {
  position: relative;
  padding: 18px 12px 6px;
  border-radius: 4px;
  background: #ffffff;
  cursor: pointer;
  /* min-height: 56px; */
  border: 1px solid #ccc;
  transition: all 0.2s ease;
  width: 100%;
  box-sizing: border-box;
}

.category-field:hover {
  border-color: #666;
}

.category-field:focus-within {
  border-color: #1976d2;
}

.category-value {
  font-size: 14px;
  color: #333;
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  margin-top: 4px;
  transition: padding-right 0.2s ease;
}

.category-field.has-value:hover .category-value {
  padding-right: 34px;
}

.category-label {
  position: absolute;
  left: 12px;
  top: 4px;
  font-size: 12px;
  color: #666;
  transition: all 0.2s ease;
}

.category-line {
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  width: calc(100% + 2px);
  height: 2px;
  background: #1976d2;
  transform: scaleX(0);
  transition: transform 0.2s ease;
  transform-origin: center;
}

.category-field:hover .category-line {
  transform: scaleX(0.5);
}

.category-field:focus-within .category-line {
  transform: scaleX(1);
}

.category-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #666;
  transition: all 0.2s ease;
}

.category-field.has-value:hover .category-icon {
  right: 12px;
}

.category-field.has-value .category-value {
  color: #333;
}

/* .category-field:not(.has-value) .category-value {
  color: #999;
  font-weight: normal;
} */

.clear-button {
  position: absolute !important;
  right: 0px !important;
  top: 40% !important;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease;
  display: none;
  z-index: 1;
}

.category-field.has-value:hover .clear-button {
  opacity: 1;
  display: flex;
}

.category-field.disabled {
  background: #f5f5f5;
  cursor: not-allowed;
  border-color: #ddd;
}

.category-field.disabled:hover {
  border-color: #ddd;
}

.category-field.disabled .category-value {
  color: #999;
}

.category-field.disabled .category-label {
  color: #999;
}

.wi-edit-category-selector-dark .category-field.disabled {
  background: #2c2c2c7c !important;
  border-color: #444;
}

.wi-edit-category-selector-dark .category-field.disabled .category-value,
.wi-edit-category-selector-dark .category-field.disabled .category-label {
  color: #999 !important;
}
</style>