import { BusinessFinance } from '@/modules/shared/config/Business/BusinessFinance'

export class FinanceReceive extends BusinessFinance {
  constructor() {
    super()
    this.id = '7ea05f3a'
    this.actions = {
      update: true
    }
    this.title = 'Pagar'
  }
}