import { Model } from '@/default/model/Model'

export class TalkChannel extends Model {
  constructor () {
    super()
    this.list = true
    this.id = {
      id: 'b49f9043',
      create: 'f2ef3194',
      update: 'dd919a8b',
      delete: '911cbaaa'
    }
    this.name = 'talk-channel'
    this.actions = {
      list: true,
      insert: false,
      update: true,
      delete: false
    }
    this.title = {
      list: 'Canais de Atendimento',
      edit: {
        insert: 'Criar canal de atendimento',
        update: 'Editar canal de atendimento'
      }
    }
    this.apiUrl = 'talk/channel'
    this.icon = 'message'
    this.params = {
      limit: 10,
      with: 'category',
      fields: [
        'id',
        'uuid',
        'name',
        'type_id',
        'color',
        'user_identity_id',
        'recipient',
        'provider_id',
        'description',
        'status_id',
        'talk_menu_id',
        'push_name',
        'qrcode_url',
        'talk_category_id',
        'talk_stage_id'
      ].join(','),
    }
    this.search = {
      placeholder: 'Pesquise pelo nome do canal',
      fields: ['id', 'name']
    }
    this.size = ['xs12', 'sm12', 'md12', 'ls12', 'xl10', 'offset-xl1']
    this.maxWidth = '800px'
    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkChannels/TalkChannels')
    this.setFields([
      this.fieldList({
        value: 'id',
        text: 'ID',
        align: 'center'
      }),
      this.fieldList({
        value: 'type.name',
        text: 'Canal',
        sortable: false,
        align: 'center'
      }),
      this.fieldList({
        value: 'name',
        text: 'Nome'
      }),
      this.fieldList({
        value: 'recipient',
        text: 'Número'
      }),
      this.fieldList({
        value: 'type.status_id',
        text: 'Status',
        sortable: false,
        align: 'center',
      }),
      this.fieldList({
        value: 'id',
        text: 'Opções',
        sortable: false,
        align: 'center',
      })
    ])
    this.setFields([
      this.fieldSubHeader({
        text: 'Informe o nome e a configuração de identificação.'
      }),
      this.fieldText({
        value: 'name',
        text: 'Nome',
        placeholder: 'Ex: WhatsApp...',
        required: true,
        size: ['xs12', 'sm12', 'md6', 'lg6']
      }),
      this.fieldWiListDialog({
        value: 'talk_menu_id',
        text: 'Chatbot',
        required: false,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'talk-menu',
        show: 'menu.name',
        icon: 'smart_toy'
      }),
      this.fieldSelect({
        value: 'user_identity_id',
        text: 'Identificação do usuário nas mensagens',
        options: [
          {
            value: 1,
            text: 'Sem identificação'
          },
          {
            value: 2,
            text: 'Nome. Ex: João Silva'
          },
          {
            value: 3,
            text: 'Nome completo. Ex: João Silva Pereira'
          },
          {
            value: 4,
            text: 'Primeiro Nome. Ex: João'
          },
          {
            value: 5,
            text: 'Primeiro Nome completo. Ex: João'
          }
        ],
        size: ['xs12', 'sm12', 'md8', 'lg8'],
        required: true
      }),
      this.fieldColor({
        value: 'color',
        text: 'Cor',
        required: true,
        size: ['xs12', 'sm12', 'md4', 'lg4']
      }),
      ...this.wppBusinessApi,
      ...this.instagramApi,
      this.fieldWiListDialog({
        value: 'talk_stage_id',
        text: 'Etapa de atendimento padrão',
        required: false,
        size: ['xs12', 'sm12', 'md6', 'lg6'],
        wiConfig: 'talk-stage',
        show: 'stage.name',
        icon: 'sell'
      }),
      {
        value: 'talk_category_id',
        text: 'Categoria de atendimento padrão',
        type: 'wn-category-selector',
        autocomplete: true,
        list: false,
        disabled: true,
        edit: true,
        apiUrl: 'talks/category-hierarchy',
        apiParams: {order: 'level,asc;name,asc'},
        show: 'category.name',
        placeholder: 'Ex: Vendas, Suporte, etc.',
        selectedShow: 'name',
        size: ['xs12', 'sm12', 'md6', 'lg6']
      },
      this.fieldSubHeader({
        text: 'Observações do canal.'
      }),
      this.fieldTextArea({
        value: 'description',
        text: 'Observações do contato',
        placeholder: 'Use este campo para observações'
      })
    ])
  }

  wppBusinessApi = [
    this.fieldSubHeader({
      text: 'Informações de conexão com WhatsApp Business API.',
      showField: form => form.provider_id == 3 && form.status_id != 2,
      style: { height: '35px !important', background: 'black', color: 'white' }
    }),
    this.fieldSubHeader({
      text: 'Copie aqui os dados de Webhook.',
      showField: form => form.provider_id == 3 && form.status_id != 2
    }),
    this.fieldSubHeader({
      text: form => (`
        <strong style="color: yellow;">URL de retorno de chamada:</strong><br>
        <span style="font-weight: 100;">https://workflow-wh.wnewer.com.br:2053/webhook/e3908744-a3cc-4855-a2be-ff56222af1fc/meta/whatsapp/${form.uuid}</span>
      `),
      showField: form => form.provider_id == 3 && form.status_id != 2,
      style: { height: '78px !important', background: 'seagreen', color: 'white' }
    }),
    this.fieldSubHeader({
      text: `
        <strong style="color: yellow;">Verificar token:</strong><br>
        <span style="font-weight: 100;">257a2031-08e4-4921-8122-758aeae4b184</span>
      `,
      showField: form => form.provider_id == 3 && form.status_id != 2,
      style: { height: '58px !important', background: 'seagreen', color: 'white' }
    }),
    this.fieldSubHeader({
      text: 'Informe as credenciais de acesso ao canal.',
      showField: form => form.provider_id == 3 && form.status_id != 2
    }),
    this.fieldText({
      value: 'cid',
      text: 'ID da conta comercial',
      placeholder: 'Ex: 1234567890',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: form => form.provider_id == 3 && form.status_id != 2
    }),
    this.fieldText({
      value: 'external_code',
      text: 'Identificação do número de telefone',
      placeholder: 'Ex: 1234567890',
      required: true,
      size: ['xs12', 'sm12', 'md6', 'lg6'],
      showField: form => form.provider_id == 3 && form.status_id != 2
    }),
    this.fieldTextArea({
      value: 'token',
      text: 'Token de acesso',
      placeholder: 'Ex: 1234567890',
      required: true,
      showField: form => form.provider_id == 3 && form.status_id != 2
    })
  ]

  instagramApi = [
    this.fieldSubHeader({
      text: 'Informações de conexão com Instagram API.',
      showField: form => form.provider_id == 7 && form.status_id != 2,
      style: { height: '35px !important', background: 'black', color: 'white' }
    }),
    this.fieldSubHeader({
      text: 'Copie aqui os dados de Webhook.',
      showField: form => form.provider_id == 7 && form.status_id != 2
    }),
    this.fieldSubHeader({
      text: form => (`
        <strong style="color: yellow;">URL de retorno de chamada:</strong><br>
        <span style="font-weight: 100;">https://workflow-wh.wnewer.com.br:2053/webhook/36b0aed3-97dd-4526-8cd1-4978edb89d00/meta/instagram/${form.uuid}</span>
      `),
      showField: form => form.provider_id == 7 && form.status_id != 2,
      style: { height: '78px !important', background: '#CD3688', color: 'white' }
    }),
    this.fieldSubHeader({
      text: `
        <strong style="color: yellow;">Verificar token:</strong><br>
        <span style="font-weight: 100;">824e9903-4e40-4194-94a8-34b74159801f</span>
      `,
      showField: form => form.provider_id == 7 && form.status_id != 2,
      style: { height: '58px !important', background: '#CD3688', color: 'white' }
    }),
    this.fieldSubHeader({
      text: 'Informe as credenciais de acesso ao canal.',
      showField: form => form.provider_id == 7 && form.status_id != 2
    }),
    // this.fieldText({
    //   value: 'cid',
    //   text: 'ID da conta comercial',
    //   placeholder: 'Ex: 1234567890',
    //   required: true,
    //   size: ['xs12', 'sm12', 'md6', 'lg6'],
    //   showField: form => form.provider_id == 7 && form.status_id != 2
    // }),
    this.fieldText({
      value: 'external_code',
      text: 'Identificação da conta',
      placeholder: 'Ex: 1234567890',
      required: true,
      size: ['xs12', 'sm12', 'md12', 'lg12'],
      showField: form => form.provider_id == 7 && form.status_id != 2
    }),
    this.fieldTextArea({
      value: 'token',
      text: 'Token de acesso',
      placeholder: 'Ex: 1234567890',
      required: true,
      showField: form => form.provider_id == 7 && form.status_id != 2
    })
  ]
}

export class TalkChannelSelect extends TalkChannel {
  constructor () {
    super()
    this.fieldsSelected = {
      itemName: 'Canais'
    }
    this.components.list.tableRow = () => import('@/modules/talk/pages/TalkChannels/TalkChannels')

  }
}
