<template>
  <v-menu 
    v-model="menu"
    :close-on-content-click="false"
    offset-y 
    :loading="aiLoading" 
    z-index="300" 
    lazy
  >
    <template v-slot:activator="{ on }">
      <v-btn v-if="!customActivator" 
        :icon="!buttonText" 
        ripple 
        v-on="on"
        :small="smallButton" 
        :style="buttonStyle" 
        :loading="aiLoading"
      >
        <v-icon v-if="buttonIcon" :small="smallIcon">{{ buttonIcon }}</v-icon>
        <span v-if="buttonText">{{ buttonText }}</span>
      </v-btn>
      <div v-else>
        <slot name="activator" :on="on" :loading="aiLoading"></slot>
      </div>
    </template>

    <v-card style="min-width: 280px;">
      <div style="text-align: center; min-height: 100px; padding: 10px;">
        <v-icon style="font-size: 30px;">{{ headerIcon }}</v-icon>
        <h3 style="margin: 0;">{{ headerTitle }}</h3>
        <p style="max-width: 230px; font-size: 80%;">
          {{ headerDescription }}
        </p>
      </div>
      <v-divider></v-divider>
      <div style="overflow-y: scroll; max-height: 300px;">
        <div v-for="(aiOption, aiOptionIndex) in filteredAiOptions" :key="aiOptionIndex">
          <v-subheader style="height: 25px;" class="grey lighten-3 black--text">
            <span
              v-if="!message && (aiOption.action != 'talk-options')"
              class="orange--text"
              style="font-size: 12px;"
            >
                Digite algo na caixa de texto para habilitar
            </span>
            <span v-else>{{ aiOption.section }}</span>
          </v-subheader>
          <v-divider></v-divider>
          <v-list dense avatar style="padding: 0;">
            <v-list-tile
              v-for="(item, itemIndex) in aiOption.items"
              :key="itemIndex"
              style="height: 28px;"
              class="message-ai-menu__option-item"
              @click="handleAIAction(item.action)"
              :disabled="!message && (item.action != 'summarize-talk' && item.action != 'suggest-response')"
            >
              <v-list-tile-avatar>
                <v-icon>
                  {{ item.icon }}
                </v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-sub-title :class="!message && (item.action != 'summarize-talk' && item.action != 'suggest-response') ? '' : 'black--text'">
                  {{ item.name }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'MessageAIMenu',
  props: {
    message: {
      type: String,
      default: null
    },
    talkId: {
      type: [Number, String],
      required: false,
      default: null
    },
    talkContactId: {
      type: [Number, String],
      required: false,
      default: null
    },
    buttonIcon: {
      type: String,
      default: 'emoji_objects'
    },
    buttonText: {
      type: String,
      default: ''
    },
    smallButton: {
      type: Boolean,
      default: false
    },
    smallIcon: {
      type: Boolean,
      default: false
    },
    customActivator: {
      type: Boolean,
      default: false
    },
    buttonStyle: {
      type: Object,
      default: () => ({ margin: '3px 8px' })
    },
    headerIcon: {
      type: String,
      default: 'emoji_objects'
    },
    headerTitle: {
      type: String,
      default: 'Inteligência Artificial'
    },
    headerDescription: {
      type: String,
      default: 'Transforme o seu texto com inteligência artificial antes de o enviar.'
    }
  },
  data: () => ({
    menu: false,
    aiLoading: false,
    aiOptions: [
      {
        "action": "talk-options",
        "section": "Atendimento",
        "items": [
          { "icon": "list", "name": "Resumir Atendimento", "action": "summarize-talk" },
          { "icon": "chat", "name": "Sugerir resposta", "action": "suggest-response" }
        ]
      },
      {
        "action": "change-text",
        "section": "Mudar texto",
        "items": [
          { "icon": "short_text", "name": "Resumir", "action": "summarize" },
          { "icon": "short_text", "name": "Simplificar", "action": "simplify" },
          { "icon": "short_text", "name": "Reescrever", "action": "rewrite" },
          { "icon": "open_in_full", "name": "Expandir", "action": "expand" },
          { "icon": "info", "name": "Explicar", "action": "explain" },
          { "icon": "spellcheck", "name": "Corrigir erros de português", "action": "correct-portuguese-errors" },
          { "icon": "translate", "name": "Traduzir para o inglês", "action": "translate-to-english" },
          { "icon": "translate", "name": "Traduzir para o espanhol", "action": "translate-to-spanish" },
          { "icon": "translate", "name": "Traduzir", "action": "translate" }
        ]
      },
      {
        "action": "change-tone",
        "section": "Mudar tom para",
        "items": [
          { "icon": "emoji_emotions", "name": "Amigável", "action": "friendly" },
          { "icon": "emoji_objects", "name": "Empolgante", "action": "exciting" },
          { "icon": "favorite", "name": "Fofinho", "action": "cute" },
          { "icon": "error", "name": "Urgente", "action": "urgent" },
          { "icon": "sentiment_dissatisfied", "name": "Triste", "action": "sad" },
          { "icon": "emoji_people", "name": "Casual", "action": "casual" },
          { "icon": "work", "name": "Formal", "action": "formal" }
        ]
      },
      {
        "action": "generate-text",
        "section": "Gerar texto",
        "items": [
          { "icon": "campaign", "name": "Promocional", "action": "promotional" },
          { "icon": "sentiment_very_dissatisfied", "name": "Pedido de desculpas", "action": "apology" },
          { "icon": "celebration", "name": "Felicitação", "action": "congratulations" }
        ]
      }
    ]
  }),
  computed: {
    filteredAiOptions() {
      if (this.talkId && this.talkContactId) {
        return this.aiOptions
      }
      return this.aiOptions.filter(option => option.action !== 'talk-options')
    }
  },
  methods: {
    handleAIAction(action) {
      this.aiLoading = true
      this.menu = false

      if ((action === 'summarize-talk' || action === 'suggest-response') && (!this.talkId || !this.talkContactId)) {
        this.aiLoading = false
        return
      }

      const data = {
        action,
        message: this.message
      }

      if (this.talkId && this.talkContactId) {
        data.talk_id = this.talkId
        data.talk_contact_id = this.talkContactId
      }

      this.$WiApiPost({
        uri: 'talks/talk/ai-assistent',
        data,
        config: {
          msgLoad: true,
          msgSucess: true,
          msgError: true
        },
        callback: ({ sucess }) => {
          if (sucess && sucess.status === 'success' && sucess?.text) {
            this.$emit('text-updated', sucess.text)
          }
          this.aiLoading = false
        }
      })
    }
  }
}
</script>

<style scoped>
.message-ai-menu__option-item >>> .v-list__tile:not(.v-list__tile--avatar) {
  height: 28px !important;
}
</style>