<template>
  <div class="wn-sheet-date-validate">
    <div class="filter-container elevation-1">
      <div class="filter-buttons">
        <v-btn
          small
          :class="['filter-btn', { active: currentFilter === 'all' }]"
          @click="currentFilter = 'all'"
        >
          <v-icon small left>list</v-icon>
          Todos
        </v-btn>
        <v-btn
          small
          :class="['filter-btn', { active: currentFilter === 'valid' }]"
          @click="currentFilter = 'valid'"
        >
          <v-icon small left color="success">check_circle</v-icon>
          Válidos
        </v-btn>
        <v-btn
          small
          :class="['filter-btn', { active: currentFilter === 'invalid' }]"
          @click="currentFilter = 'invalid'"
        >
          <v-icon small left color="error">error</v-icon>
          Inválidos
        </v-btn>
      </div>
      <div class="filter-actions">
        <div class="filter-summary">
          <span class="summary-item">
            <v-icon small color="success">check_circle</v-icon>
            {{ validCount }} válidos
          </span>
          <span class="summary-divider">|</span>
          <span class="summary-item">
            <v-icon small color="error">error</v-icon>
            {{ invalidCount }} inválidos
          </span>
        </div>
        <v-btn
          v-if="invalidCount > 0"
          small
          color="error"
          class="remove-invalid-btn"
          @click="showRemoveInvalidDialog = true"
        >
          <v-icon small left>delete_sweep</v-icon>
          Remover Inválidos
        </v-btn>
      </div>
    </div>

    <div class="scrollable-content">
      <v-data-table
        :headers="tableHeaders"
        :items="filteredData"
        :items-per-page="10"
        :loading="!localData"
        class="elevation-1 wn-sheet-date-validate-table"
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </template>

        <template v-slot:no-data>
          <div class="text-center">
            Nenhum dado disponível
          </div>
        </template>

        <template v-slot:items="{ item }">
          <tr :class="{ 'error-row': !item.is_valid }">
            <td v-for="header in tableHeaders" :key="header.value">
              <template v-if="header.value === 'validation_status'">
                <v-chip
                  small
                  :color="item.is_valid ? 'success' : 'error'"
                  text-color="white"
                  class="status-chip"
                >
                  {{ item.is_valid ? 'Válido' : 'Inválido' }}
                </v-chip>
              </template>
              <template v-else-if="header.value === 'actions'">
                <v-btn
                  icon
                  small
                  class="delete-btn"
                  @click="showRemoveDialog(item)"
                >
                  <v-icon small color="grey darken-1">delete</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <span :class="{ 'invalid-field': !isFieldValid(item, header.value) }">
                  {{ item[header.value] }}
                </span>
              </template>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>

    <!-- Diálogo de confirmação para remover linha individual -->
    <v-dialog v-model="showSingleRemoveDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirmar Remoção</v-card-title>
        <v-card-text>
          Deseja realmente remover esta linha?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text flat @click="showSingleRemoveDialog = false">Cancelar</v-btn>
          <v-btn color="error" text @click="confirmRemove">Remover</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Diálogo de confirmação para remover todos inválidos -->
    <v-dialog v-model="showRemoveInvalidDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirmar Remoção em Massa</v-card-title>
        <v-card-text>
          Deseja realmente remover todas as {{ invalidCount }} linhas inválidas?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text flat @click="showRemoveInvalidDialog = false">Cancelar</v-btn>
          <v-btn color="error" text @click="removeAllInvalid">Remover Todos</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="fixed-footer elevation-2">
      <v-btn 
        text flat
        @click="cancel()"
      >
        Cancelar
      </v-btn>
      <v-btn 
        text
        @click="back()"
      >
        Voltar
      </v-btn>
      <v-btn
        color="primary"
        @click="handleContinue"
        :disabled="hasInvalidData"
        class="pulse-button"
      >
        Continuar
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WNSheetDateValidate',
  props: {
    config: {
      type: Object,
      required: false,
      default: () => ({
        title: 'Importação',
        description: 'Configure o mapeamento dos campos da sua planilha.',
        fields: []
      })
    },
    mappedData: {
      type: Object,
      required: false,
      default: () => ({
        fields: [],
        headers: [],
        data: []
      })
    }
  },
  data() {
    return {
      localData: [],
      tableHeaders: [],
      fieldValidations: {},
      currentFilter: 'all',
      showSingleRemoveDialog: false,
      showRemoveInvalidDialog: false,
      itemToRemove: null
    }
  },
  computed: {
    hasInvalidData() {
      return this.localData.some(item => !item.is_valid)
    },
    filteredData() {
      if (!this.localData) return []
      
      switch (this.currentFilter) {
        case 'valid':
          return this.localData.filter(item => item.is_valid)
        case 'invalid':
          return this.localData.filter(item => !item.is_valid)
        default:
          return this.localData
      }
    },
    validCount() {
      return this.localData ? this.localData.filter(item => item.is_valid).length : 0
    },
    invalidCount() {
      return this.localData ? this.localData.filter(item => !item.is_valid).length : 0
    }
  },
  mounted() {
    this.initializeLocalData()
  },
  methods: {
    initializeLocalData() {
      if (this.mappedData?.data) {
        // Cria um mapa de campos para validação (uma única vez)
        const fieldValidators = new Map(
          this.config.fields
            .filter(f => f.validate)
            .map(f => [f.value, { validate: f.validate, required: f.required }])
        )

        // Processa os dados uma única vez
        this.localData = this.mappedData.data.map(item => {
          const validations = {}
          let isValid = true

          for (const [key, value] of Object.entries(item)) {
            const validator = fieldValidators.get(key)
            if (validator) {
              validations[key] = validator.validate(value)
              if (!validations[key] && validator.required) {
                isValid = false
              }
            }
          }

          return {
            ...item,
            is_valid: isValid,
            field_validations: validations
          }
        })

        // Usa os headers já formatados do mappedData
        this.tableHeaders = [
          ...this.mappedData.headers.map(header => ({
            text: header.title,
            value: header.value,
            sortable: true,
            align: 'left'
          })),
          {
            text: 'Status',
            value: 'validation_status',
            sortable: true,
            align: 'left'
          },
          {
            text: 'Ações',
            value: 'actions',
            sortable: false,
            align: 'center',
            width: '80px'
          }
        ]
      }
    },
    isFieldValid(item, fieldName) {
      return item.field_validations?.[fieldName] !== false
    },
    handleContinue() {
      this.$emit('continue', this.localData)
    },
    back() {
      this.$emit('back')
    },
    cancel() {
      this.$emit('cancel')
    },
    showRemoveDialog(item) {
      this.itemToRemove = item
      this.showSingleRemoveDialog = true
    },
    confirmRemove() {
      if (this.itemToRemove) {
        this.localData = this.localData.filter(item => item !== this.itemToRemove)
        this.showSingleRemoveDialog = false
        this.itemToRemove = null
      }
    },
    removeAllInvalid() {
      this.localData = this.localData.filter(item => item.is_valid)
      this.showRemoveInvalidDialog = false
      this.currentFilter = 'all'
    }
  }
}
</script>

<style scoped>
.wn-sheet-date-validate {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 80px;
  min-height: 0;
  max-height: calc(100vh - 300px);
  padding-top: 12px;
}

.fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: #fff;
  padding: 2px 16px;
  display: flex;
  justify-content: center;
  gap: 12px;
  border-top: 1px solid #eee;
}

/* Tema escuro */
.theme--dark .fixed-footer {
  background: #1E1E1E;
  border-top-color: #424242;
}

.wn-sheet-date-validate-table {
  width: 100%;
}

.status-chip {
  font-size: 12px;
  height: 24px !important;
}

.invalid-field {
  color: #ff5252;
}

.error-row {
  background-color: #fff5f5;
}

.theme--dark .error-row {
  background-color: #2d1c1c;
}

.theme--dark .stepper-actions {
  border-top-color: #424242;
}

.wn-sheet-date-validate-table >>> .v-datatable >>> .v-datatable__actions {
  bottom: unset !important;
  position: unset !important;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
}

.pulse-button:not(:disabled) {
  animation: pulse 2s infinite;
}

/* Tema escuro */
.theme--dark .pulse-button:not(:disabled) {
  animation-name: pulse-dark;
}

@keyframes pulse-dark {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(100, 181, 246, 0.4);
  }
  
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(100, 181, 246, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(100, 181, 246, 0);
  }
}

.filter-container {
  margin: 20px 20px 0 20px;
  padding: 8px 16px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.theme--dark .filter-container {
  background-color: #1E1E1E;
}

.filter-buttons {
  display: flex;
  gap: 8px;
}

.filter-btn {
  text-transform: none !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.theme--dark .filter-btn {
  border-color: rgba(255, 255, 255, 0.08) !important;
}

.filter-btn.active {
  background-color: rgba(0, 0, 0, 0.05) !important;
  border-color: rgba(0, 0, 0, 0.15) !important;
}

.theme--dark .filter-btn.active {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.filter-actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.remove-invalid-btn {
  text-transform: none !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  border-radius: 4px !important;
}

.actions-cell {
  width: 80px;
  text-align: center;
}

/* Ajuste para o botão de remoção em massa */
.remove-invalid-btn {
  height: 28px !important;
  padding: 0 12px !important;
}

/* Estilo para os diálogos */
.v-dialog .headline {
  font-size: 1.25rem !important;
  font-weight: 500;
}

.v-dialog .v-card__text {
  color: rgba(0, 0, 0, 0.6) !important;
}

.theme--dark .v-dialog .v-card__text {
  color: rgba(255, 255, 255, 0.7) !important;
}
</style> 