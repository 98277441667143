<template>
  <v-card class="category-tree-card" elevation="10">
    <v-card-title class="tree-header">
      <div class="header-content">
        <v-icon large color="primary" class="mr-2">account_tree</v-icon>
        <div class="title-content">
          <h3>Estrutura de Categorias</h3>
          <span class="subtitle">Selecione uma categoria para ver os detalhes</span>
        </div>
      </div>
      <div class="tree-stats">
        <v-btn @click="handleAddCategory" color="primary" outline small>
          <v-icon small>add</v-icon>
          <span class="ml-1"> Adicionar categoria</span>
        </v-btn>
      </div>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text class="tree-content">
      <div style="display: none;" class="search-box">
        <v-text-field
          :value="search"
          @input="debounceSearch"
          dense
          outlined
          hide-details
          placeholder="Pesquisar categorias..."
          class="search-field"
          @click:clear="clearSearch"
          clearable
        >
          <template v-slot:prepend-inner>
            <v-icon color="grey darken-2" class="search-icon">search</v-icon>
          </template>
          
          <template v-slot:append v-if="search">
            <div class="search-results">
              <v-chip
                x-small
                label
                color="primary"
                text-color="white"
                class="result-chip"
              >
                {{ filteredItems.length }}
              </v-chip>
              <span class="result-text">resultado(s)</span>
            </div>
          </template>
        </v-text-field>
      </div>

      <v-treeview
        ref="treeview"
        v-model="activeItems"
        :items="filteredItems"
        item-key="id"
        item-text="name"
        item-children="children"
        open-on-click
        hoverable
        transition
        shaped
        dense
        open-all
        class="custom-tree"
        @update:active="handleSelect"
      >
        <template v-slot:prepend="{ item }">
          <v-icon 
            :color="item.color" 
            @click="handleSelect([item.id])"
            class="tree-icon"
            :class="{ 'selected': isSelected(item), 'inactive': !item.active }"
          >
            {{ item.icon }}
          </v-icon>
        </template>

        <template v-slot:label="{ item }">
          <div 
            class="tree-label"
            :class="{ 
              'has-children': item.children_count > 0,
              'selected': isSelected(item),
              'inactive': !item.active
            }"
            @click="handleSelect([item.id])"
          >
            <div class="label-content">
              <span 
                v-if="item.highlightedName" 
                v-html="item.highlightedName"
                :class="{ 'inactive-text': !item.active }"
              ></span>
              <span 
                v-else
                :class="{ 'inactive-text': !item.active }"
              >
                {{ item.name }}
                <span v-if="!item.active" class="inactive-badge">(Desativada)</span>
              </span>
              <div class="label-info" v-if="item.children_count > 0">
                <v-chip 
                  x-small
                  :color="item.active ? item.color : 'grey'"
                  text-color="white"
                  class="count-chip"
                  :class="{ 'inactive-chip': !item.active }"
                >
                  {{ item.all_children_count }}
                </v-chip>
              </div>
            </div>
            
            <div class="hover-actions">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small icon
                    class="add-subcategory-btn"
                    :class="{ 'inactive-btn': !item.active }"
                    @click.stop="$emit('add-child-category', item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>subdirectory_arrow_right</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar subcategoria</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-treeview>

      <div v-if="search && filteredItems.length === 0" class="no-results">
        <v-icon large color="grey lighten-1">search_off</v-icon>
        <p>Nenhuma categoria encontrada para "{{ search }}"</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
const SELECTED_CATEGORY_KEY = 'selectedCategoryId'

export default {
  name: 'CategoryTree',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    colorField: {
      type: String,
      default: 'color'
    },
    iconField: {
      type: String,
      default: 'icon'
    },
    activeField: {
      type: String,
      default: 'active'
    }
  },
  data: () => ({
    search: '',
    debouncedSearch: null,
    activeItems: [],
    selectedId: null,
    openNodes: []
  }),
  created() {
    // Recupera a categoria selecionada do sessionStorage
    this.selectedId = sessionStorage.getItem(SELECTED_CATEGORY_KEY)
    if (this.selectedId) {
      this.activeItems = [this.selectedId]
    }
  },
  mounted() {
    // Garante que o treeview está montado antes de expandir
    if (this.selectedId) {
      this.$nextTick(() => {
        this.expandToSelected(this.selectedId)
      })
    }
  },
  computed: {
    totalCategories() {
      const countAll = (items) => {
        return items.reduce((acc, item) => {
          return acc + 1 + (item.children ? countAll(item.children) : 0)
        }, 0)
      }
      return countAll(this.items)
    },
    filteredItems() {
      if (!this.search) return this.items
      
      const searchTerm = this.search.toLowerCase().trim()
      
      const filterTree = (items) => {
        return items.reduce((filtered, item) => {
          // Cria uma cópia do item para não modificar o original
          const newItem = { ...item }
          
          // Verifica se o item atual corresponde à busca
          const matchesName = item.name.toLowerCase().includes(searchTerm)
          
          // Se tiver filhos, filtra recursivamente
          if (item.children && item.children.length) {
            const filteredChildren = filterTree(item.children)
            if (filteredChildren.length) {
              newItem.children = filteredChildren
            } else {
              delete newItem.children
            }
          }
          
          // Inclui o item se ele corresponde à busca ou tem filhos que correspondem
          if (matchesName || (newItem.children && newItem.children.length)) {
            // Destaca o texto encontrado se corresponder à busca
            if (matchesName) {
              newItem.highlightedName = this.highlightText(item.name, searchTerm)
            }
            filtered.push(newItem)
          }
          
          return filtered
        }, [])
      }
      
      return filterTree(this.items)
    }
  },
  methods: {
    handleSelect(item) {
      const selectedId = Array.isArray(item) ? item[0] : item.id
      this.selectedId = selectedId
      sessionStorage.setItem(SELECTED_CATEGORY_KEY, selectedId)
      
      const selectedItem = this.findItemById(selectedId, this.items)
      this.$emit('select', selectedItem)

      // Expande até o item selecionado
      this.expandToSelected(selectedId)
    },
    highlightText(text, searchTerm) {
      if (!searchTerm) return text
      
      const regex = new RegExp(`(${searchTerm})`, 'gi')
      return text.replace(regex, '<span class="highlight">$1</span>')
    },
    clearSearch() {
      this.search = ''
    },
    debounceSearch(value) {
      if (this.debouncedSearch) clearTimeout(this.debouncedSearch)
      this.debouncedSearch = setTimeout(() => {
        this.search = value
      }, 300)
    },
    isSelected(item) {
      return item.id === this.selectedId
    },
    findItemById(id, items) {
      for (const item of items) {
        if (item.id === id) return item
        if (item.children) {
          const found = this.findItemById(id, item.children)
          if (found) return found
        }
      }
      return null
    },
    findParentPath(id, items, path = []) {
      for (const item of items) {
        if (item.id === id) {
          return path
        }
        if (item.children && item.children.length) {
          const newPath = [...path, item.id]
          const found = this.findParentPath(id, item.children, newPath)
          if (found) return found
        }
      }
      return null
    },
    expandToSelected(id) {
      // Encontra o caminho até o item selecionado
      const parentPath = this.findParentPath(id, this.items)
      
      if (parentPath) {
        // Garante que o treeview existe
        this.$nextTick(() => {
          if (this.$refs.treeview) {
            // Expande cada nó pai
            parentPath.forEach(parentId => {
              this.$refs.treeview.updateOpen(parentId, true)
            })
            
            // Atualiza a lista de nós abertos
            this.openNodes = [...parentPath]
          }
        })
      }
    },
    handleAddCategory() {
      this.$emit('add-category')
      console.log('add-category')
    }
  },
  watch: {
    // Observa mudanças nos items para reexpandir quando necessário
    items: {
      immediate: true,
      handler() {
        if (this.selectedId) {
          this.$nextTick(() => {
            this.expandToSelected(this.selectedId)
          })
        }
      }
    },
    // Observa mudanças na busca para expandir resultados
    search(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.$refs.treeview) {
            this.$refs.treeview.updateAll(true)
          }
        })
      } else if (this.selectedId) {
        // Quando limpa a busca, volta a expandir apenas até o item selecionado
        this.expandToSelected(this.selectedId)
      }
    }
  }
}
</script>

<style scoped>
.category-tree-card {
  border-radius: 16px !important;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tree-header {
  padding: 16px 20px;
  background: linear-gradient(to right, #f8f9fa, #ffffff);
}

.header-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title-content {
  display: flex;
  flex-direction: column;
}

.title-content h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.87);
}

.title-content .subtitle {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 2px;
}

.tree-stats {
  margin-left: auto;
}

.stat-chip {
  font-weight: 500;
}

.tree-content {
  padding: 20px;
  background-color: #fafafa;
  flex-grow: 1;
  overflow-y: auto;
}

.search-box {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fafafa;
  padding: 16px;
  margin: -20px -20px 16px -20px; /* Compensa o padding do card-text */
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.search-field {
  transition: all 0.3s ease;
}

.search-field :deep(.v-input__slot) {
  background-color: white !important;
  border-radius: 12px !important;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
}

.search-field:hover :deep(.v-input__slot) {
  border-color: rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.search-field.v-text-field--is-focused :deep(.v-input__slot) {
  border-color: #1976d2 !important;
  box-shadow: 0 3px 12px rgba(25, 118, 210, 0.1);
}

.search-icon {
  transition: color 0.3s ease;
}

.search-field.v-text-field--is-focused :deep(.search-icon) {
  color: #1976d2 !important;
}

.search-results {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.08);
  margin-left: 8px;
}

.result-chip {
  font-weight: 600;
  min-width: 24px;
  justify-content: center;
}

.result-text {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
}

/* Animação para o contador de resultados */
.search-results {
  animation: slideIn 0.3s ease;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Responsividade */
@media (max-width: 600px) {
  .search-box {
    padding: 12px;
  }
  
  .result-text {
    display: none; /* Esconde o texto em telas pequenas, mantém só o número */
  }
}

.custom-tree {
  font-size: 0.9375rem;
}

.theme--dark .custom-tree {
  color: rgba(255, 255, 255, 0.87);
}

.tree-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  min-height: 32px;
  margin: 2px 0;
}

.theme--dark .tree-label {
  color: rgba(255, 255, 255, 0.87);
}

.label-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.hover-actions {
  opacity: 0;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.tree-label:hover .hover-actions {
  opacity: 1;
}

.add-subcategory-btn {
  margin: 0 !important;
  width: 24px !important;
  height: 24px !important;
  color: rgba(0, 0, 0, 0.54) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.theme--dark .add-subcategory-btn {
  color: rgba(255, 255, 255, 0.7) !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.add-subcategory-btn:hover {
  background-color: rgba(25, 118, 210, 0.1) !important;
  color: #1976d2 !important;
  transform: scale(1.1);
}

.theme--dark .add-subcategory-btn:hover {
  background-color: rgba(33, 150, 243, 0.2) !important;
  color: #2196f3 !important;
}

.tree-label.selected .add-subcategory-btn {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.theme--dark .tree-label.selected .add-subcategory-btn {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.15) !important;
}

.tree-label.selected .add-subcategory-btn:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.theme--dark .tree-label.selected .add-subcategory-btn:hover {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

/* Animação do botão */
.add-subcategory-btn {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.tree-label.selected {
  background: #1976d2;
  color: white;
  font-weight: 500;
  box-shadow: 0 2px 8px rgba(25, 118, 210, 0.2);
  transform: translateX(4px);
}

.theme--dark .tree-label.selected {
  background: #2196f3;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.2);
}

.tree-label.selected .count-chip {
  background-color: white !important;
  color: #1976d2 !important;
}

.theme--dark .tree-label.selected .count-chip {
  opacity: 0.9;
}

.tree-icon {
  transition: all 0.3s ease;
}

.tree-icon.selected {
  transform: scale(1.2);
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.tree-label:not(.selected):hover {
  background-color: rgba(25, 118, 210, 0.08);
}

.theme--dark .tree-label:not(.selected):hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.label-info {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.count-chip {
  font-size: 0.75rem;
  height: 20px !important;
}

.theme--dark .count-chip {
  opacity: 0.9;
}

.has-children {
  font-weight: 500;
}

/* Animações para o treeview */
.v-treeview-node__root {
  transition: background-color 0.2s ease;
}

.v-treeview-node__root:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

/* Estilização do scrollbar */
.tree-content::-webkit-scrollbar {
  width: 8px;
}

.tree-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.tree-content::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

.tree-content::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.search-results {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 8px;
  white-space: nowrap;
}

.highlight {
  background-color: rgba(255, 213, 79, 0.4);
  padding: 0 2px;
  border-radius: 2px;
  font-weight: 500;
}

.theme--dark .highlight {
  background-color: rgba(255, 213, 79, 0.2);
}

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.no-results .v-icon {
  margin-bottom: 16px;
}

.no-results p {
  margin: 0;
  font-size: 0.875rem;
}

/* Animação para resultados filtrados */
.v-treeview-node-enter-active, 
.v-treeview-node-leave-active {
  transition: all 0.3s ease;
}

.v-treeview-node-enter, 
.v-treeview-node-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

/* Animação para seleção */
.selected {
  animation: selectPulse 0.3s ease;
}

@keyframes selectPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

/* Você também pode usar as classes de cor do Vuetify 1.5 */
.primary--text {
  color: #1976d2 !important;
}

.primary {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}

/* Estilização do scrollbar para tema dark */
.theme--dark .tree-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.theme--dark .tree-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

.theme--dark .tree-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Ajustes para o card e header no tema dark */
.theme--dark.category-tree-card {
  background-color: #1E1E1E !important;
}

.theme--dark .tree-header {
  background: linear-gradient(to right, #272727, #1E1E1E);
}

.theme--dark .title-content h3 {
  color: rgba(255, 255, 255, 0.87);
}

.theme--dark .title-content .subtitle {
  color: rgba(255, 255, 255, 0.6);
}

/* Ajustes para o conteúdo no tema dark */
.theme--dark .tree-content {
  background-color: #1E1E1E;
}

.theme--dark .search-box {
  background-color: #1E1E1E;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.theme--dark .search-field :deep(.v-input__slot) {
  background-color: #272727 !important;
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.theme--dark .search-field:hover :deep(.v-input__slot) {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.theme--dark .search-results {
  border-left-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
}

/* Ajustes para o treeview no tema dark */
.theme--dark .v-treeview-node__root:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.theme--dark .tree-label:not(.selected):hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.theme--dark .tree-label.selected {
  background: #1976d2;
  box-shadow: 0 2px 8px rgba(25, 118, 210, 0.2);
}

/* Ajustes para scrollbar no tema dark */
.theme--dark .tree-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
}

.theme--dark .tree-content::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}

.theme--dark .tree-content::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Ajustes para mensagem de sem resultados no tema dark */
.theme--dark .no-results {
  color: rgba(255, 255, 255, 0.6);
}

/* Ajustes para highlight no tema dark */
.theme--dark .highlight {
  background-color: rgba(255, 213, 79, 0.2);
}

/* Ajustes para botões e ações no tema dark */
.theme--dark .add-subcategory-btn {
  color: rgba(255, 255, 255, 0.7) !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
}

.theme--dark .add-subcategory-btn:hover {
  background-color: rgba(33, 150, 243, 0.2) !important;
  color: #2196f3 !important;
}

.inactive-text {
  text-decoration: line-through;
  opacity: 0.7;
  color: rgba(0, 0, 0, 0.54);
}

.theme--dark .inactive-text {
  color: rgba(255, 255, 255, 0.5);
}

.inactive-badge {
  font-size: 0.85em;
  opacity: 0.7;
  margin-left: 4px;
  font-style: italic;
}

.inactive-chip {
  opacity: 0.7;
}

.tree-icon.inactive {
  opacity: 0.5;
}

.tree-label.inactive:not(.selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.theme--dark .tree-label.inactive:not(.selected):hover {
  background-color: rgba(255, 255, 255, 0.03);
}

.tree-label.inactive.selected {
  background: rgba(25, 118, 210, 0.7);
}

.inactive-btn {
  opacity: 0.7;
}

/* Ajuste para highlight em categorias inativas */
.inactive-text .highlight {
  opacity: 0.8;
  background-color: rgba(255, 213, 79, 0.2);
}

/* Ajuste para tema escuro */
.theme--dark .inactive-text {
  color: rgba(255, 255, 255, 0.4);
}

.theme--dark .tree-label.inactive.selected {
  background: rgba(33, 150, 243, 0.5);
}

/* Ajuste para o ícone quando inativo */
.tree-icon.inactive {
  opacity: 0.5;
  filter: grayscale(0.5);
}
</style> 