<template>
  <v-card
    flat 
    style="width: 350px;"
    :style="$vuetify.breakpoint.xsOnly ? {
      width: '100% !important',
      'max-width': '100% !important'
    } : {
      'overflow-y': 'auto',
      'height': mode == 'dialog' ? 'calc(min(90vh - 120px, 100vh - 220px))' : 'calc(100vh - 202px)',
      'margin-bottom': mode == 'dialog' ? 'calc(10vh + 70px)' : '45px'
    }"
  >
    <div style="text-align: center !important; border-left: solid 1px #80808038; border-right: solid 1px #80808038;">
      <TalkContactName :contact="contact" :refresh="refresh" />
      <TalkContactProfiles :contact="contact" :refresh="refresh" />
    </div>
    <v-card-text style="text-align: center !important; padding: 0; border-left: solid 1px #80808038; border-right: solid 1px #80808038;">
      <TalkContactObservations :contact="contact" />
      <TalkContactMembers :contact="contact" />
      <TalkContactTabs :talk="talk" :contact="contact" :refresh="refresh" />
      <TalkContactSelectProfile :contact="contact" :refresh="refresh" />
    </v-card-text>  
  </v-card>
</template>
<script>
  import talkViewMixin from '../../talkViewMixin'
  import TalkContactTabs from './TalkContactTabs'
  import TalkContactName from './TalkContactName'
  import TalkContactMembers from './TalkContactMembers'
  import TalkContactProfiles from './TalkContactProfiles'
  import { mapState, mapMutations, mapActions } from 'vuex'
  import TalkContactObservations from './TalkContactObservations'
  import TalkContactSelectProfile from './TalkContactSelectProfile'
  export default {
    data () {
      return {
        dialog: false,
        profileImageDialog: false,
        memberSearch: ''
      }
    },
    computed: {
      ...mapState('Talk', ['showContactInfo', 'talk', 'loadings'])
    },
    methods: {
      ...mapMutations('Talk', ['TOUGGLE_SHOW_CONTACT_INFO']),
      ...mapActions('Talk', ['getTalk']),
      onClose: function () {
        this.TOUGGLE_SHOW_CONTACT_INFO()
      },
      closeAction: function () {
        if (this.mode === 'dialog') {
          this.$emit('onClose')
        } else {
          this.$router.push({name: 'talk-view'})
        }
      }
    },
    props: {
      contact: {
        required: true
      },
      refresh: {
        required: true
      },
      mode: {
        type: String,
        default: 'page'
      },
      talkId: {
        required: true
      }
    },
    mixins: [talkViewMixin],
    components: {
      TalkContactTabs,
      TalkContactName,
      TalkContactMembers,
      TalkContactProfiles,
      TalkContactObservations,
      TalkContactSelectProfile
    }
  }
</script>