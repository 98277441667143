<template>
  <div 
    class="talk-channel" 
    :class="{ 
      'theme--dark': $vuetify.theme.dark,
      'talk-channel--removed': channel.removed_to_campaign 
    }"
  >
    <div class="talk-channel__container">
      <div class="talk-channel__icon-wrapper">
        <talk-list-item-channel-icon 
          :channel-type="channel.type.code"
          class="talk-channel__icon"
        />
      </div>

      <div class="talk-channel__name">
        <div class="talk-channel__title-wrapper">
          <span class="talk-channel__title">{{ channel.name }}</span>
          <v-tooltip v-if="channel.removed_to_campaign" bottom>
            <template v-slot:activator="{ on }">
              <v-icon 
                v-on="on"
                x-small
                class="talk-channel__removed-icon"
              >
                remove_circle_outline
              </v-icon>
            </template>
            <span>Canal removido da campanha</span>
          </v-tooltip>
        </div>
      </div>

      <div class="talk-channel__metrics">
        <div class="talk-channel__stat">
          <v-icon x-small color="success">send</v-icon>
          <span class="talk-channel__stat-value">{{ channel.messages_sent }}</span>
          <span class="talk-channel__stat-label">env</span>
        </div>
        <div class="talk-channel__stat">
          <v-icon x-small color="info">reply</v-icon>
          <span class="talk-channel__stat-value">{{ channel.messages_received }}</span>
          <span class="talk-channel__stat-label">rec</span>
        </div>
      </div>

      <div 
        class="talk-channel__status"
        :style="{ 
          backgroundColor: channel.status.color + ($vuetify.theme.dark ? '20' : '15'),
          color: channel.status.color
        }"
      >
        <v-icon size="12">{{ channel.status.icon }}</v-icon>
        <span class="talk-channel__status-text">{{ channel.status.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import TalkListItemChannelIcon from '@/modules/talk/components/TalkListItemChannelIcon.vue'

export default {
  name: 'TalkCampaignChannel',
  components: {
    TalkListItemChannelIcon
  },
  props: {
    channel: {
      type: Object,
      required: true
    }
  },
  computed: {
    tagColor() {
      return this.channel.removed_to_campaign ? '#9E9E9E' : this.channel.color
    },
    getTagStyle() {
      const color = this.tagColor
      return {
        backgroundColor: color + (this.$vuetify.theme.dark ? '40' : '30'),
        color: color,
        filter: this.$vuetify.theme.dark ? 'brightness(1)' : 'brightness(0.7)',
        opacity: this.channel.removed_to_campaign ? '0.7' : '1'
      }
    }
  }
}
</script>

<style scoped>
.talk-channel {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 8px 12px;
  transition: all 0.2s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
}

.theme--dark .talk-channel {
  background-color: #1E1E1E;
  border-color: rgba(255, 255, 255, 0.08);
}

.talk-channel--removed {
  background-color: #F5F5F5;
  opacity: 0.85;
}

.theme--dark .talk-channel--removed {
  background-color: #2C2C2C;
}

.talk-channel__container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.talk-channel__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  flex-shrink: 0;
}

.theme--dark .talk-channel__icon-wrapper {
  background-color: rgba(255, 255, 255, 0.04);
}

.talk-channel__icon {
  width: 18px;
  height: 18px;
  object-fit: contain;
  opacity: 0.85;
}

.talk-channel__name {
  flex: 1;
  min-width: 0;
}

.talk-channel__title-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
}

.talk-channel__title {
  font-size: 14px;
  font-weight: 500;
  color: #2c3e50;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.theme--dark .talk-channel__title {
  color: #e0e0e0;
}

.talk-channel__removed-icon {
  color: #9e9e9e;
  font-size: 14px;
}

.talk-channel__metrics {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
}

.talk-channel__stat {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 3px 8px;
  border-radius: 4px;
}

.theme--dark .talk-channel__stat {
  background-color: rgba(255, 255, 255, 0.03);
}

.talk-channel__stat-value {
  font-size: 13px;
  font-weight: 500;
  color: #2c3e50;
}

.theme--dark .talk-channel__stat-value {
  color: #e0e0e0;
}

.talk-channel__stat-label {
  font-size: 11px;
  color: #64748b;
  margin-left: 2px;
}

.theme--dark .talk-channel__stat-label {
  color: #9e9e9e;
}

.talk-channel__status {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.talk-channel__status-text {
  font-weight: 500;
}

.talk-channel--removed .talk-channel__icon,
.talk-channel--removed .talk-channel__title,
.talk-channel--removed .talk-channel__stat,
.talk-channel--removed .talk-channel__status {
  opacity: 0.7;
}
</style> 