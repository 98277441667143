<template>
  <v-flex sm12 style="padding: 5px 5px 10px 5px;">
    <v-toolbar dense class="elevation-10" style="border-radius: 10px;">
      <v-btn icon ripple @click="$router.go(-1)">
        <v-icon>arrow_back</v-icon>
      </v-btn>
      <v-list-tile v-ripple avatar class="hidden-xs-only hidden-sm-only">
        <v-list-tile-avatar @click="refresh()" style="cursor: pointer;" size="45">
          <img src="static/systems/modules/wises-technology-module-talk.png" alt="Dashboard Atendimentos">
        </v-list-tile-avatar>
        <v-list-tile-content @click="refresh()" style="cursor: pointer;">
          <v-list-tile-sub-title style="font-size: 12px;">Módulo</v-list-tile-sub-title>
          <v-list-tile-title style="font-size:18px;">
            <span>Atendimentos</span>
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-spacer></v-spacer>
      <slot name="actions"></slot>
      <v-btn flat icon color="green" @click="() => { $emit('refresh') }">
        <v-icon>refresh</v-icon>
      </v-btn>
    </v-toolbar>
  </v-flex>
</template>

<script>
export default {
  name: 'CompactHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  }
};
</script>

<style>
.header {
  background: linear-gradient(to right, #3f51b5, #5c6bc0);
  padding: 6px 10px;
  border-radius: 6px;
  margin-bottom: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  height: auto;
  min-height: 36px;
}

.theme--dark.header {
  background: linear-gradient(to right, #303f9f, #3f51b5);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.header:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.header__left {
  display: flex;
  align-items: center;
}

.header__back-btn {
  color: rgba(255, 255, 255, 0.9) !important;
  margin-right: 6px;
  transition: all 0.2s ease;
  height: 24px !important;
  width: 24px !important;
}

.header__back-btn:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  transform: translateX(-2px);
}

.header__title-container {
  display: flex;
  align-items: center;
}

.header__icon-wrapper {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.header__icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.header__title {
  font-size: 13px;
  font-weight: 500;
  color: white;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.header__right {
  display: flex;
  align-items: center;
}

.header__refresh-btn {
  color: rgba(255, 255, 255, 0.9) !important;
  margin-left: 4px;
  transition: all 0.2s ease;
  height: 24px !important;
  width: 24px !important;
}

.header__refresh-btn:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  transform: rotate(30deg);
}

@media (max-width: 600px) {
  .header {
    border-radius: 0;
    margin: -16px -16px 0 -16px;
    padding: 4px 8px;
  }
  
  .header__title {
    font-size: 12px;
  }
  
  .header__icon-wrapper {
    width: 18px;
    height: 18px;
  }
  
  .header__icon {
    width: 14px;
    height: 14px;
  }
}
</style> 