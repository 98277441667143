<template>
  <div class="business-card-details">
    <!-- Person Info -->
    <template v-if="item.person">
      <v-card-title class="business-card-details__item">
        <v-icon class="business-card-details__icon business-card-details__icon--person">person</v-icon>
        <span class="business-card-details__text">{{item.person.name | stringlength(27)}}</span>
      </v-card-title>
    </template>

    <!-- Category Info -->
    <template v-if="item.category">
      <v-card-title class="business-card-details__item">
        <v-icon 
          class="business-card-details__icon"
          :style="{color: item.category.color || '#2c3e50'}"
          :color="item.category.icon"
        >
          {{item.category.icon}}
        </v-icon>
        <span class="business-card-details__text">{{item.category.name | stringlength(27)}}</span>
      </v-card-title>
    </template>

    <!-- Collaborator Info -->
    <template v-if="item.collaborator">
      <v-card-title class="business-card-details__item">
        <v-img
          v-if="hasCollaboratorImage"
          :src="item.collaborator.file.url"
          class="business-card-details__avatar"
        ></v-img>
        <v-icon v-else class="business-card-details__icon business-card-details__icon--collaborator">support_agent</v-icon>
        <span class="business-card-details__text">{{item.collaborator.name | stringlength(27)}}</span>
      </v-card-title>
    </template>

    <!-- Quantity Info -->
    <template v-if="showQuantityInfo">
      <v-card-title class="business-card-details__item">
        <v-icon class="business-card-details__icon business-card-details__icon--quantity">list</v-icon>
        <label class="business-card-details__label">Produtos/Serviços:</label>
        <v-spacer></v-spacer>
        <strong class="business-card-details__value">{{item.sum_quantity_unit}}</strong>
      </v-card-title>
    </template>

    <!-- Attachments Info -->
    <template v-if="item.count_attachment">
      <v-card-text class="business-card-details__attachments pa-0">
        <v-card-title class="business-card-details__item">
          <v-icon class="business-card-details__icon business-card-details__icon--attachment">attachment</v-icon>
          <span class="business-card-details__text">{{item.count_attachment}}</span>
        </v-card-title>
      </v-card-text>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BusinessCardDetails',
  
  props: {
    item: { type: Object, required: true },
    config: { type: Object, required: true }
  },

  computed: {
    hasCollaboratorImage() {
      return this.item.collaborator.file && this.item.collaborator.file.url
    },

    showQuantityInfo() {
      return this.$Auth.user().account.id === '490fba83-2a5e-4b11-935a-754b25000880' && 
             this.item.sum_quantity_unit
    }
  }
}
</script>

<style scoped>
.business-card-details__divider {
  margin: 0;
  opacity: 0.5;
}

.business-card-details__item {
  padding: 4px 8px;
  min-height: 32px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  position: relative;
}

.theme--light .business-card-details__item:hover {
  background-color: #f8f9fa;
}

.theme--dark .business-card-details__item:hover {
  background-color: #2d2d2d;
}

.business-card-details__icon {
  margin-right: 6px;
  font-size: 18px;
}

.business-card-details__icon--person {
  color: #1C8ADB;
}

.business-card-details__icon--collaborator {
  color: #4CAF50;
}

.business-card-details__icon--quantity {
  color: #FF9800;
}

.business-card-details__icon--attachment {
  color: #9C27B0;
}

.theme--light .business-card-details__text {
  color: #212529;
}

.theme--dark .business-card-details__text {
  color: #ffffff;
}

.business-card-details__text {
  font-size: 0.85em;
  font-weight: 500;
}

.theme--light .business-card-details__label {
  color: #495057;
}

.theme--dark .business-card-details__label {
  color: #e9ecef;
}

.business-card-details__label {
  font-size: 0.85em;
  margin-right: 2px;
  font-weight: 500;
}

.theme--light .business-card-details__value {
  color: #212529;
}

.theme--dark .business-card-details__value {
  color: #ffffff;
}

.business-card-details__value {
  font-size: 0.85em;
  font-weight: 600;
}

.business-card-details__avatar {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  margin-right: 6px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.theme--dark .business-card-details__avatar {
  border-color: rgba(255, 255, 255, 0.08);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.theme--light .business-card-details__attachments {
  background-color: #f8f9fa;
}

.theme--dark .business-card-details__attachments {
  background-color: #2d2d2d;
}
</style> 