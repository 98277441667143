<template>
  <div class="wn-sheet-configuration">
    <!-- Área com scroll -->
    <div class="scrollable-content">
      <div class="configuration-container">
        <!-- Seção de Boas-vindas -->
        <div class="welcome-section">
          <v-icon large color="primary">cloud_upload</v-icon>
          <h2 class="welcome-title">
            {{ config.title }}
          </h2>
          <p class="welcome-description">
            {{ config.description }}
          </p>
        </div>

        <!-- Seção de Configuração -->
        <div class="config-section">
          <v-text-field
            v-model="importName"
            label="Defina o nome da importação"
            placeholder="Ex: Lista de Clientes - Janeiro"
            :rules="[rules.required]"
            :error-messages="nameError"
            @input="validateName"
            class="import-name-field"
            clearable
            dense
          ></v-text-field>
        </div>

        <!-- Seção de Etapas -->
        <div class="steps-section">
          <div class="steps-header">
            <h3 class="steps-title">
              <v-icon small color="primary" class="steps-icon">timeline</v-icon>
              Etapas da Importação
            </h3>
          </div>
          <div class="step-list">
            <div class="step-item active">
              <div class="step-icon">
                <v-icon>settings</v-icon>
              </div>
              <div class="step-content">
                <h4>Configuração</h4>
                <p>Defina as informações básicas da importação</p>
              </div>
            </div>

            <div class="step-item">
              <div class="step-icon">
                <v-icon>description</v-icon>
              </div>
              <div class="step-content">
                <h4>Upload da Planilha</h4>
                <p>Faça upload do seu arquivo (XLSX, XLS, CSV)</p>
              </div>
            </div>

            <div class="step-item">
              <div class="step-icon">
                <v-icon>swap_horiz</v-icon>
              </div>
              <div class="step-content">
                <h4>Mapeamento de Campos</h4>
                <p>Associe as colunas da planilha aos campos do sistema</p>
              </div>
            </div>

            <div class="step-item">
              <div class="step-icon">
                <v-icon>rule</v-icon>
              </div>
              <div class="step-content">
                <h4>Validação de Dados</h4>
                <p>Revise e ajuste os registros antes da importação</p>
              </div>
            </div>

            <div class="step-item">
              <div class="step-icon">
                <v-icon>sync</v-icon>
              </div>
              <div class="step-content">
                <h4>Processamento</h4>
                <p>Acompanhe o progresso da importação</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer fixo -->
    <div class="fixed-footer elevation-2">
      <v-btn 
        text flat
        @click="cancel()"
      >
        Cancelar
      </v-btn>
      <v-btn
        color="primary"
        @click="handleContinue"
        :disabled="!isValid"
        class="pulse-button"
      >
        Continuar
        <v-icon right>arrow_forward</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WNSheetConfiguration',
  
  data() {
    return {
      importName: '',
      nameError: '',
      rules: {
        required: value => !!value || 'Campo obrigatório'
      }
    }
  },

  computed: {
    isValid() {
      return this.importName.length > 0 && !this.nameError
    }
  },

  methods: {
    validateName(value) {
      this.nameError = ''
      if (value.length > 100) {
        this.nameError = 'O nome não pode ter mais que 100 caracteres'
      }
    },

    handleContinue() {
      if (this.isValid) {
        this.$emit('continue', {
          name: this.importName
        })
      }
    },

    cancel() {
      this.$emit('cancel')
    }
  },

  props: {
    config: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.wn-sheet-configuration {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.scrollable-content {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  min-height: 0;
  max-height: calc(100vh - 300px);
}

.configuration-container {
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.welcome-section {
  text-align: center;
  margin-bottom: 8px;
}

.welcome-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #333;
  margin: 8px 0;
}

.welcome-description {
  font-size: 0.875rem;
  color: #666;
  line-height: 1.4;
}

.config-section {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.steps-section {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.step-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.step-item {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 8px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.step-item.active {
  background: rgba(25, 118, 210, 0.05);
}

.step-icon {
  background: #f5f5f5;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: all 0.2s ease;
}

.step-item.active .step-icon {
  background: #1976d2;
  color: white;
}

.step-content {
  flex: 1;
}

.step-content h4 {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 2px;
}

.step-content p {
  font-size: 0.8rem;
  color: #666;
  line-height: 1.3;
  margin: 0;
}

.fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background: #fff;
  padding: 0px;
  display: flex;
  justify-content: center;
  gap: 12px;
  border-top: 1px solid #eee;
}

/* Tema escuro */
.theme--dark .welcome-title,
.theme--dark .step-content h4 {
  color: #fff;
}

.theme--dark .welcome-description,
.theme--dark .step-content p {
  color: #aaa;
}

.theme--dark .config-section,
.theme--dark .steps-section {
  background: #1E1E1E;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.theme--dark .step-icon {
  background: #333;
}

.theme--dark .step-item.active {
  background: rgba(25, 118, 210, 0.15);
}

.theme--dark .step-item.active .step-icon {
  background: #1976d2;
}

.theme--dark .fixed-footer {
  background: #1E1E1E;
  border-top-color: #424242;
}

/* Animação do botão */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
  }
  
  70% {
    transform: scale(1.02);
    box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
}

.pulse-button:not(:disabled) {
  animation: pulse 2s infinite;
}

.theme--dark .pulse-button:not(:disabled) {
  animation-name: pulse-dark;
}

@keyframes pulse-dark {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(100, 181, 246, 0.4);
  }
  
  70% {
    transform: scale(1.02);
    box-shadow: 0 0 0 10px rgba(100, 181, 246, 0);
  }
  
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(100, 181, 246, 0);
  }
}

@media (max-width: 600px) {
  .scrollable-content {
    padding: 12px;
  }

  .welcome-title {
    font-size: 1.25rem;
  }

  .config-section,
  .steps-section {
    padding: 12px;
  }

  .step-icon {
    width: 32px;
    height: 32px;
  }
}

.steps-header {
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 12px;
}

.steps-title {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0;
}

.steps-icon {
  opacity: 0.9;
}

.theme--dark .steps-title {
  color: #fff;
}

.theme--dark .steps-header {
  border-bottom-color: rgba(255, 255, 255, 0.05);
}
</style>
