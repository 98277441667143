import {
  PERMISSION_PROCESS,
  PERMISSION_BUDGET,
  PERMISSION_SALE,
  PERMISSION_WELL,
  PERMISSION_TICKET,
  PERMISSION_ORDER,
  PERMISSION_PURCHASE,
  PERMISSION_CONTRACT,
  PERMISSION_GUARANTEE,
  PERMISSION_TEST_MANAGEMENT_VTAL_IN,
  PERMISSION_INTERNET_MODULE
} from '@/default/constants/permissions'

export default {
  computed: {
    statistics () {
      return {
        process: {
          permission: PERMISSION_PROCESS,
          showInProfiles: ['prospect', 'customer'],
          image: '/static/systems/modules/wises-technology-module-juridical.png',
          title: 'Processos',
          data: this.businessData('process'),
          content: ({ statistic }) => (statistic?.data?.total + ' Processo(s), ' + statistic?.data?.opened + ' em andamento'),
          list: {
            title: 'Ver Processo(s)',
            action: this.businessListAction('process')
          },
          add: {
            title: 'Novo Processo',
            action: this.businessAddAction('process')
          }
        },
        well: {
          permission: PERMISSION_WELL,
          showInProfiles: ['prospect', 'customer'],
          image: '/static/icons/monitor/drilled_well_96.png',
          title: 'Poços Tubulares',
          data: this.businessData('well'),
          content: ({ statistic }) => (statistic?.data?.total + ' Poço(s), ' + statistic?.data?.opened + ' em construção'),
          list: {
            title: 'Ver Poço(s)',
            action: this.businessListAction('well')
          },
          add: {
            title: 'Novo Poço',
            action: this.businessAddAction('well')
          }
        },
        contract: {
          permission: PERMISSION_CONTRACT,
          showInProfiles: ['prospect', 'customer'],
          name: 'contract',
          image: '/static/icons/monitor/signing_a_document_96.png',
          title: 'Contratos',
          data: this.businessData('contract'),
          content: ({ statistic }) => (statistic?.data?.total + ' Contratos(s), ' + statistic?.data?.opened + ' em aberto'),
          list: {
            title: 'Ver Contratos',
            action: this.businessListAction(
              this.$WiCheckPermission(PERMISSION_INTERNET_MODULE) ? 'internet-contract' : 'contract'
            )
          },
          add: {
            title: 'Novo Contrato',
            action: this.businessAddAction(
              this.$WiCheckPermission(PERMISSION_INTERNET_MODULE) ? 'internet-contract' : 'contract'
            )
          }
        },
        guarantee: {
          permission: PERMISSION_GUARANTEE,
          showInProfiles: ['prospect', 'customer'],
          image: '/static/icons/monitor/guarantee_96.png',
          title: 'Termos de Garantia',
          data: this.businessData('guarantee'),
          content: ({ statistic }) => (statistic?.data?.total + ' Garantia(s), ' + statistic?.data?.opened + ' em vigência'),
          list: {
            title: 'Ver Garantia(s)',
            action: this.businessListAction('guarantee')
          },
          add: {
            title: 'Nova Garantia',
            action: this.businessAddAction('guarantee')
          }
        },
        sale: {
          permission: PERMISSION_SALE,
          showInProfiles: ['prospect', 'customer'],
          name: 'sale',
          image: '/static/systems/modules/wises-technology-module-sale.png',
          title: 'Vendas',
          data: this.businessData('sale'),
          content: ({ statistic }) => (statistic?.data?.total + ' venda(s), ' + statistic?.data?.opened + ' em aberto'),
          list: {
            title: 'Ver Vendas/Planos',
            action: this.businessListAction('sale')
          },
          add: {
            title: 'Nova Venda/Plano',
            action: this.businessAddAction('sale')
          }
        },
        budget: {
          permission: PERMISSION_BUDGET,
          showInProfiles: ['prospect', 'customer'],
          name: 'budget',
          image: '/static/icons/monitor/budget_icon_96.png',
          title: 'Orçamentos',
          data: this.businessData('budget'),
          content: ({ statistic }) => (statistic?.data?.total + ' orçamento(s), ' + statistic?.data?.opened + ' em aberto'),
          list: {
            title: 'Ver Orçamentos',
            action: this.businessListAction('budget')
          },
          add: {
            title: 'Novo Orçamento',
            action: this.businessAddAction('budget')
          }
        },
        ticket: {
          permission: PERMISSION_TICKET,
          showInProfiles: ['prospect', 'customer', 'provider'],
          name: 'ticket',
          image: '/static/systems/modules/wises-technology-module-service-desk.png',
          title: 'Chamados',
          data: this.businessData('ticket'),
          content: ({ statistic }) => (statistic?.data?.total + ' Chamado(s), ' + statistic?.data?.opened + ' em aberto'),
          list: {
            title: 'Ver Chamados',
            action: this.businessListAction('ticket-list')
          },
          add: {
            title: 'Nova Venda/Plano',
            action: this.businessAddAction('chamados-todos')
          }
        },
        order: {
          permission: PERMISSION_ORDER,
          showInProfiles: ['prospect', 'customer'],
          image: '/static/icons/monitor/support_96.png',
          title: 'Ordens',
          data: this.businessData('order'),
          content: ({ statistic }) => (statistic?.data?.total + ' Orden(s), ' + statistic?.data?.opened + ' em aberto'),
          list: {
            title: 'Ver Ordens de Serviço',
            action: this.businessListAction('order')
          },
          add: {
            title: 'Nova Ordens de Serviço',
            action: this.businessAddAction('order')
          }
        },
        diagnostic: {
          permission: PERMISSION_TEST_MANAGEMENT_VTAL_IN,
          showInProfiles: ['prospect', 'customer'],
          image: '/static/icons/monitor/evidence_96.png',
          title: 'Diagnósticos',
          data: this.businessData('test-management-vtal'),
          content: ({ statistic }) => (statistic?.data?.total + ' Diagnóstico(s), ' + statistic?.data?.opened + ' em execução'),
          list: {
            title: 'Ver Diagnóstico(s)',
            action: this.businessListAction('diagnostic')
          },
          add: {
            title: 'Novo Diagnóstico',
            action: this.businessAddAction('diagnostic')
          }
        },
        purchase: {
          permission: PERMISSION_PURCHASE,
          showInProfiles: ['provider'],
          name: 'purchase',
          image: '/static/systems/modules/wises-technology-module-purchase.png?url=123',
          title: 'Compras',
          data: this.businessData('purchase'),
          content: ({ statistic }) => (statistic?.data?.total + ' Compra(s), ' + statistic?.data?.opened + ' em aberto'),
          list: {
            title: 'Ver Compras',
            action: this.businessListAction('purchase')
          },
          add: {
            title: 'Nova Compra',
            action: this.businessAddAction('purchase-open')
          }
        }
      }
    },
    statisticsArray () {
      const statistics = []
      const profiles = this.person.profiles.map(profile => profile.name)
      console.log(profiles)
      for (const key in this.statistics) {
        if (
          this.$Auth.checkPermission(this.statistics[key].permission) &&
          this.statistics[key].showInProfiles.some(profile => profiles.includes(profile))
        ) {
          statistics.push(this.statistics[key])
        }
      }
      return statistics
    }
  }
}
